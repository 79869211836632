import React from 'react'
import { Button, Loader, Heading, Box, Image } from 'react-bulma-components/dist'
import singalButton from '../assets/singalButton.png'


const ChooseBox = (props) => {



    const choiceClicked = (name, code) => {
        props.choiceMade(name, code)
    }

    const isCancelled = () => {
        props.cancelled()
    }


    const imageBox = (name, imageSrc, code) => {
        return <Box key={name} className='is-radiusless   stylesBox chooseBox square160 pad0' onClick={() => choiceClicked(name, code)} >
            <div className='has-items-vertical-center square140'>
                <Image className='is-clipped square140' src={imageSrc} size={'1by1'} />
            </div>
        </Box>
    }


    const option = (name, imageSrc, code) => {

        if (imageSrc) {
            return imageBox(name, imageSrc, code)
        }

        return <Box key={name} className='is-radiusless stylesBox chooseBox square160' onClick={() => choiceClicked(name, code)} >
            <div className='has-items-vertical-center'>
                <Heading className='' size={6}>{name}</Heading>
            </div>
        </Box>
    }

    return (

        <div>
            <div className='is-centered has-text-centered'>
                <Heading className='' size={6}> SELECT APPAREL </Heading>
            </div>
            <div className="myFlexContainer marginTop40" >
                {
                    props.choices.map((choice) => {
                        return option(choice.name, choice.imageSrc, choice.code)
                    })
                }
            </div>
        </div>

    )

}

export default ChooseBox