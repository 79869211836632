import React, { Component } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Heading, Button, Image, Box, Columns
} from 'react-bulma-components/dist'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { imageNamed, UNIT_TYPES, MEASURE_TYPE, UnitGroupOptions } from '../utilities/utils'
import ColumnAux from './ColumnAux'




class MeasureStep extends Component {

    state = {
        currenValue: null,
    }
    _hasBeenEdited = false
    _mounted = false

    componentDidMount = () => {
        this._mounted = true
        if (this._mounted) {
            let ns = { ...this.state }
            console.log('Incoming Value =', this.props.selectedValue)
            console.log('Incoming Unit =', this.props.currentUnit)

            if (this.props.selectedValue !== '' && this.props.selectedValue !== null) {
                ns.currenValue = this.props.selectedValue
            }
            // if (this.props.currentUnit !== '' && this.props.currentUnit !== null) {
            //     ns.currentUnit = this.props.currentUnit
            // }
            this.setState(ns)
        }
    }


    componentWillUnmount = () => {
        this._mounted = false
    }

    unitsPicked = (event) => {
        // this.props.unitPicked(val) 
        this.props.unitChanged(event.target.value)
    }


    unitChoice = () => {
        return (
            <div className='marginTop40 '>
                {/* <p className='has-text-centered size70 marginBottom20'> Enter values In </p> */}
                <div className="control">
                    <div className="select is-fullwidth">
                        <select className='' value={this.props.UnitGroupOptions} onChange={this.unitsPicked} >
                            <option value={UnitGroupOptions.FtInchKg}>{UnitGroupOptions.FtInchKg}</option>
                            <option value={UnitGroupOptions.CmLb}>{UnitGroupOptions.CmLb}</option>
                        </select>
                    </div>
                </div>
            </div>
        )
    }

    messageView = () => {
        console.log('BUILDING MESSAGE STEP')
        let imageVideoBox = null
        if (this.props.imageSrc || this.props.videoSrc) {
            imageVideoBox = (
                <div className='imageVideoContainer16by9 is-clipped is-centered has-text-centered' >
                    <Image className='is-clipped ' src={this.props.imageSrc} size={this.props.imageSize} />
                    {/* <video autoPlay={true} src={sampleVideo} playsInline={true} muted={true} controls={true}></video> */}
                </div>
            )
        }

        let points = null
        if (this.props.points) {
            points = (
                <ColumnAux noPaddingSides={true} isMiddle={true} size={10} >
                    <ul className='list has-background-transparent is-shadowless is-borderless'>
                        {this.props.points.map((pt, index) => {

                            return <li key={index} className='type60 list-item'> • {pt} </li>
                        })}
                    </ul>
                </ColumnAux>
            )
        }


        console.log('Got Measurement Unit =', this.props.currentUnit)

        return (

            <div className=' measurementsContainer'>
                <br />
                <Heading className='has-text-centered has-text-weight-normal padHorizontal10 stickTop' size={4}>
                    <p>{this.props.title}</p>
                </Heading>
                <p className='type60 has-text-centered padHorizontal10'>{this.props.subtitle}</p>



                {imageVideoBox}

                {this.props.showUnitChoice ? this.unitChoice() : null}
                {points}

                <div className='has-text-centered'>

                    {this.props.isLast ?
                        <p className='topMarginMinus20'> <Button className=' is-radiusless nextButton   ' onClick={() => { this.navigationHappened(true, true) }}> Done </Button>  </p>
                        :
                        <p className='topMarginMinus20'>
                            {/* <Button disabled={this.state.selectedValue === null && !this.props.skipable} className={this.props.isFirst ? 'is-radiusless nextButton is-black marginTop60' : 'is-radiusless nextButton marginTop40 is-black'} onClick={() => { this.doneHandler(true) }}> Next */}

                            <Button className={this.props.isFirst ? 'is-radiusless nextButton  marginTop60' : 'is-radiusless nextButton marginTop40 '} onClick={() => { this.navigationHappened(true) }}> {this.props.overrideNext ?? 'Next'}
                                <i className=" has-texh-weight-thin marginLeft10"> <FontAwesomeIcon className='' icon={faArrowRight} /></i>
                            </Button>
                        </p>
                    }
                    {this.props.isFirst ? null :
                        <p className=''>
                            <Button className=' is-radiusless is-text has-text-grey-lighter ' onClick={() => { this.navigationHappened(false) }}>
                                <i className=" has-texh-weight-thin "> <FontAwesomeIcon className='' icon={faArrowLeft} /></i>
                            </Button>
                        </p>
                    }
                </div>
            </div>

        )
    }


    navigationHappened = (isNext, isLast = false, value, unit) => {
        if (isNext) {
            if (isLast) {
                this.props.onNavChange('done', this.props.type, this.state.currenValue)
                // this.props.nextStep()
            } else {
                this.props.onNavChange('next', this.props.type, this.state.currenValue)
                this.props.nextStep()
            }
        } else {
            this.props.previousStep()
            this.props.onNavChange('back')
        }

    }

    ageSelected = (value) => {
        console.log('Age has been selected =', value)

    }

    buttonClicked = (value) => {
        console.log('Button is selected =', value)
        this.changeInputValNow(value)
    }
    valueSelected = (event) => {
        console.log('Input Value Changed =', event.target.value)
        this.changeInputValNow(event.target.value)
    }

    changeInputValNow = (val) => {
        this._hasBeenEdited = true
        if (this._mounted) {
            let ns = { ...this.state }

            if (val === '') {
                ns.currenValue = null
            } else {
                ns.currenValue = val
            }
            console.log('CHANGNG CURRENT VALUE =', val)

            this.setState(ns)
        }
    }

    setValNull = () => {
        if (this._mounted) {
            console.log('SETTING CURRENT VALUE NULLL')
            let ns = { ...this.state }
            ns.currenValue = null
            this.setState(ns)
        }
    }

    field = () => {

        let val = this.state.currenValue ?? ''

        if ((this.props.selectedWithUnit !== this.props.currentUnit) && !this._hasBeenEdited && this.state.currenValue) {
            this.setValNull()
        }

        return (
            <Box className=" is-radiusless is-marginless pad0">
                <div className="field is-radiusless  has-addons has-addons-centered">
                    <p className="control">
                        < input className=" is-medium has-text-weight-semibold has-text-right is-radiusless input  is-fullwidth minWidth120" onChange={this.valueSelected} value={val} type="text" />
                    </p>
                    {/* <span className='help type50 '> {((this.props.selectedWithUnit !== this.props.currentUnit) && !this._hasBeenEdited && this.state.currenValue) ? 'Unit Changed' : ''}</span> */}

                    <p className="control">
                        <span className="button is-medium is-static is-radiusless">
                            {this.props.currentUnit}
                        </span>
                    </p>
                </div>
            </Box>

        )
    }

    toNextWithKey = (event) => {
        console.log('KEY PRESS DETECTED')
        if (event.key === 'Enter') {
            if (this.state.currenValue !== null || this.state.currenValue !== '') {
                this.navigationHappened(true)
            }
        }

    }



    measureView = () => {


        let options = null
        let selectableOptions = options
        if (this.props.currentUnit && this.props.showOptions) {
            if (this.props.currentUnit === UNIT_TYPES.inchs) {
                selectableOptions = this.props.options
            }
            if (this.props.currentUnit === UNIT_TYPES.ft) {
                selectableOptions = this.props.ftOptions
            }
            if (this.props.currentUnit === UNIT_TYPES.cm) {
                selectableOptions = this.props.cmOptions
            }
        }
        if (selectableOptions && selectableOptions.length > 0) {
            options = <div className='buttons  is-centered '>
                {selectableOptions.map((opt, index) => {
                    return <Button key={index} onClick={() => { this.buttonClicked(opt) }} className={this.state.selectedValue === opt ? ' is-radiusless   type50 has-text-weight-medium sizeSelectButton is-active' : ' is-radiusless type50 has-text-weight-medium sizeSelectButton '} > {opt} </Button>
                })}
            </div>
        }

        // let ageOptions = []
        // for (let i = 16; i <= 60; i++) {
        //     ageOptions.push(<option key={i} value={i}>{i}</option>)
        // }
        // let ageSelectOption = (
        //     <div className="control is-expanded">
        //         < div className="select is-fullwidth is-expanded" >
        //             <select className=' is-fullwidth is-expanded' placeholder='Select Age' name="age" onChange={this.ageSelected}>
        //                 {ageOptions}
        //             </select>
        //         </div >
        //     </div>
        // )




        let enterMessage = (this.props.showOptions && options) ? 'Choose or Enter Size' : 'Enter Size'
        if (this.props.type === MEASURE_TYPE.age) {
            enterMessage = 'Enter Age'
        }



        if (this.props.type === MEASURE_TYPE.neck) {
            console.log(' [ GOT IMAGE SRC  ] = ', this.props.imageSrc)
        }

        

        const boxClasses = this.props.videoSrc ? 'imageViewBox pad0 is-radiusless is-clipped is-centered has-text-centered' : 'imageViewBox is-shadowless pad0 is-radiusless is-clipped is-centered has-text-centered'

        return (

            <div className=' ' >
                <Heading className='has-text-centered has-text-weight-normal padHorizontal10 ' size={5}> {this.props.name} </Heading>
                <div className='maxHeight60P is-clipped '>
                    <Columns >
                        <Columns.Column />
                        <Columns.Column size={8}>

                            <Box className={boxClasses} >
                                {
                                    (this.props.videoSrc && this.props.videoSrc !== '' ) ?
                                        <video className=" imgVideo" loop autoPlay muted poster={this.props.imageSrc} controls={false} playsInline={true} >
                                            <source src={this.props.videoSrc} />

                                        </video>
                                        :
                                        <Image className='is-clipped imgVideo  ' src={this.props.imageSrc} size={this.props.imageSize ?? '16by9'} />
                                    }
                            </Box>

                        </Columns.Column>
                        <Columns.Column />
                    </Columns>
                </div>
                { this.props.remark ?  <p className="has-text-grey-light type50 marginTop10 has-text-centered">{this.props.remark}</p> : null } 


                <p className='type45 has-text-weight-light has-text-grey is-uppercase marginTop20 has-text-centered marginBottom10' > {enterMessage} </p>

                <ColumnAux className=' is-marginless pad0' noPaddingSides={true} isMiddle={true} size={6} >
                    {options}
                    <ColumnAux size={8} isMiddle={true} noPaddingSides={true} >

                        {this.field()}

                    </ColumnAux>

                </ColumnAux>

                <div className='has-text-centered'>

                    {this.props.isLast ?
                        <p className=' marginTop20'> <Button hidden={this.state.currenValue === null && !this.props.skipable} className=' is-radiusless nextButton   ' onClick={() => { this.navigationHappened(true, true) }}> Done </Button>  </p>
                        :
                        <p className='topMarginMinus20'>
                            {/* <Button disabled={this.state.selectedValue === null && !this.props.skipable} className={this.props.isFirst ? 'is-radiusless nextButton is-black marginTop60' : 'is-radiusless nextButton marginTop40 is-black'} onClick={() => { this.doneHandler(true) }}> Next */}

                            <Button hidden={this.state.currenValue === null && !this.props.skipable} className={this.props.isFirst ? 'is-radiusless nextButton  marginTop60' : 'is-radiusless nextButton marginTop40 '} onClick={() => { this.navigationHappened(true) }}> {this.props.overrideNext ?? 'Next'}
                                <i className=" has-texh-weight-thin marginLeft10"> <FontAwesomeIcon className='' icon={faArrowRight} /></i>
                            </Button>
                        </p>
                    }
                    {this.props.isFirst ? null :
                        <p className={(this.state.currenValue === null) ? (this.props.skipable ? '' : 'marginTop40') : ' '}>
                            <Button className=' is-radiusless is-text has-text-grey-lighter ' onClick={() => { this.navigationHappened(false) }}>
                                <i className=" has-texh-weight-thin "> <FontAwesomeIcon className='' icon={faArrowLeft} /></i>
                            </Button>
                        </p>
                    }
                </div>
            </div >

        )
    }


    render() {
        let thisStep = null
        if (this.props.isMessageScreen === true) {
            thisStep = this.messageView()
        } else {
            thisStep = this.measureView()
        }

        return (
            thisStep
        )
    }

}


export default MeasureStep