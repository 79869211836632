import React from 'react'
import { Button, Loader } from 'react-bulma-components/dist'
import ColumnAux from './ColumnAux'


const normalSide = 20;
const mediumSide = 30;
const largeSide = 40;



const loaderMainStyle = (size, isMain ) => { 

    let side = normalSide
    if (size === 'medium') {
        side = mediumSide
    }
    if (size === 'large') {
        side = largeSide
    }

    let color = '2px solid lightGrey'
    if (isMain) {
        color = '2px solid darkBlue'
    }

    return (
    {
        width: side,
        height: side,
        border: color,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
    } )

}

const columnStyle = (height) => {
    return (
        {
            height: height
        }
    )
}

const containterClasses = (hasBg) => {
        let allClasses = ''
        allClasses = hasBg ?  'centerContainer loaderBG' :  'centerContainer'
        return allClasses
} 

const centeredError = (props) => {
    return (
        <div className={containterClasses(props.hasBg ?? false)} style={columnStyle(props.loaderHeight)}>
            <p> <span className=' subtitle is-7 has-text-grey textDown5Perc'> {props.loadSettleMessage} : </span> <Button className='is-small is-text' onClick={props.tryAgainPressed}>Try Again</Button></p>
        </div>
    )
}

const centeredLoader = (props) => {
    return (
        <div className={containterClasses(props.hasBg ?? false)} style={columnStyle(props.loaderHeight)}>
            {/* <Loader style={props.isMain ? loaderMainStyle : null} /> */}
            <Loader style={ loaderMainStyle(props.size, props.isMain) } />
        </div>
    )
}


const LoadingAux = (props) => {
    if (props.loadSettleMessage && props.isLoading === false) {
        return centeredError(props)
    }

    if (props.isLoading === true) {
        // return <div className="centerCard"><Loader style={props.isMain ? loaderMainStyle : null} /> </div>;
        return centeredLoader(props)
    }
    else {
        return props.children;
    }
}

export default LoadingAux