import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Button, Columns, Heading } from 'react-bulma-components/dist'
import { Formik, Form, Field } from 'formik'
import { getCurrentCustomer, saveCurrentCustomer } from '../utilities/Login'
import { Customer } from '../utilities/classes'
import cogoToast from 'cogo-toast'




const MyAddressForm = (props) => {


    let currentCust = getCurrentCustomer()

    let billingAddressValues = {
        address: currentCust.address,
        state: currentCust.state,
        country: currentCust.country,
        pincode: currentCust.pincode
    }


    const validateAddress = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 5) {
            error = 'Please enter full info';
        }
        return error;
    }

    const validateCityState = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 3) {
            error = 'Please enter full info';
        }
        return error;
    }

    const validateCountry = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 5) {
            error = 'Please enter full info';
        }
        return error;
    }

    const validatePincode = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 5) {
            error = 'Please enter full info';
        }
        return error;
    }



    function saveAddress(values, callback) {

            // Save and return

            // setTimeout(() => {
            //     console.log('Profile Saved succesfully. ' )
            //     callback({ success: true, message: 'Profile Saved succesfully. ' })
    
            // }, 800);




            let cust = getCurrentCustomer()
            let thisCust = Customer.copyFrom(cust)

            thisCust.address = values.address
            thisCust.state = values.state
            thisCust.pincode = values.pincode
            thisCust.country = values.country

            thisCust.update((succ, errMsg)=> {
                if (succ === true) {
                    saveCurrentCustomer(thisCust)
                    currentCust = thisCust
                    cogoToast.success("Address Saved")
                    callback({ success: true, message: 'Address Saved succesfully. ' })
                }else{
                    cogoToast.warn("Could not update address. Check valid entries, internet connection and try again.")
                    callback({ success: false, message: 'Could not update address. ' + errMsg })
                }

            })



        // callback({ success: false, message: error.message })



        // Parse.User
        //     .logIn(values.email, values.password).then(function (user) {
        //         console.log('User created successful with name: ' + user.get("username") + ' and email: ' + user.get("email"));
        //         console.log('       LoginUser METHOD Success block -' + Parse.User.current())
        //         callback({ success: true, message: 'User created successful with name: ' + user.get("username") + ' and email: ' + user.get("email") })

        //     }).catch(function (error) {
        //         console.log("Error: " + error.code + " " + error.message);
        //         // callback({success: false, message:  "Error: " + error.code + " " + error.message})
        //         callback({ success: false, message: error.message })

        //     });
        // console.log('User is %j', user)
    }

    return (

        <div className='is-two-fifths layerGreyBG pad20 fadeIn'>
            <br />
            <Heading className=' has-text-grey' size={6}>  {props.title ?? 'Deliver To'}  </Heading>

            <Formik
                initialValues={billingAddressValues}



                onSubmit={(data, { setSubmitting, resetForm }) => {
                    setSubmitting(true)
                    console.log('Form Submitted : ', data)


                    saveAddress(data, (response) => {
                        if (response.success === true) {
                            setSubmitting(false)
                            // resetForm(true)
                            props.success(data)
                        } else {
                            setSubmitting(false)
                            resetForm(true)
                            props.error({ message: response.message })
                        }
                    })


                }}
            >
                {({ values, isSubmitting, errors, touched, isValidating }) => (

                    <Form >
                        <div className='field'>
                            {errors.address && touched.address && <div className="help type55 is-pulled-right is-danger">{errors.address}</div>}
                            <label className="label type60">Address</label>
                            <Field name='address' type='input' className='input has-text-weight-medium type65'  validate={validateAddress}  />
                        </div>
                        <div className='field'>
                            {errors.state && touched.state && <div className="help type55 is-pulled-right is-danger">{errors.state}</div>}
                            <label className="label type60 marginTop10">City, State</label>
                            <Field name='state' type='input' className='input has-text-weight-medium type65' validate={validateCityState}  />
                        </div>
                        <div className='field'>
                            {errors.country && touched.country && <div className="help type55 is-pulled-right is-danger">{errors.country}</div>}
                            <label className="label type60 marginTop10">Country</label>
                            <Field name='country' type='input' className='input has-text-weight-medium type65' validate={validateCountry}  />
                        </div>

                        <div className='field'>
                            {errors.pincode && touched.pincode && <div className="help type55 is-pulled-right is-danger">{errors.pincode}</div>}
                            <label className="label type60 ">Pincode</label>
                            <Field name='pincode' type='input' className='input has-text-weight-medium type65' validate={validatePincode}  />
                        </div>



                            <div className='control is-expanded'>
                                    <Button  disabled={isSubmitting || isValidating} loading={isSubmitting}  className=' is-white ' type='submit' > Save </Button>

                            </div>

                    </Form>

                )}

            </Formik>
        </div>


    )
}

export default MyAddressForm