import React from 'react'
import { Button, Loader, Heading, Box, Image, Columns, Card } from 'react-bulma-components/dist'
import { imageNamed, scrollToRef } from '../utilities/utils'
import { currencySymbol } from '../clientInfo/clientInfo'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCross, faCrosshairs, faRemoveFormat } from '@fortawesome/free-solid-svg-icons'

const StyleBox = (props) => {


    // productType: PRODUCT_TYPE.suit,
    // styleName: 'Style A',
    // description: 'Its my style man. Give it to me',
    // style: {
    //     front: 'sixButton',
    //     tone: 'blues',
    //     buttonDesign: 'buttonClassic',
    //    fabric: 'fab_blue1',

    //     collar: 'collarSlim',
    //     back: 'sideCut',
    //     pantStyle: 'plain'
    // }

    console.log('IMAGE NAMED fourButton =', imageNamed('fourButton'))

    console.log('Product Props Key =', props.style)


    return (
        <Card className=' selectedStyleBox margin10 is-clipped is-radiusless pad0 stylesBox'>
            <Card.Header className=' has-background-light'>
                <p className='is-size-7 width246 marginLeft10    has-text-weight-medium'>{props.style.name}</p>

                <Button onClick={() => { props.removePressed(props.style.key) }} className=' is-pulled-right  marginTop4 is-shadowless is-borderless has-background-light has-text-grey-light  type50 is-small'> Remove
                {/* <FontAwesomeIcon className=' type50' icon={faRemoveFormat} /> */}
                </Button>


                {props.style.isBespoke ?
                    <div></div>
                    // <Button disabled={!props.style.isBespoke} onClick={ () => { props.editPressed(props.key) } } className='  marginTop4 is-radiusless is-shadowless has-text-grey has-background-light is-borderless type55 is-small'>EDIT</Button>

                    :
                    <p className='type50 has-text-grey-light marginTop4'>Made To Order</p>
                }

            </Card.Header>
            <Card.Content className=' is-marginless is-paddingless'>

                {/* <div className=' flexColumn width80'>
                <Button className=' delete is-pulled-left marginTopLeft4  is-small '></Button>
                <Button className=' sele   is-small '>Edit </Button>
            </div> */}
                <div className=' choiceContainer'>
                    <Box className='  is-radiusless pad0 is-shadowless is-clipped styleBox100'>
                        <Image className='is-clipped ' src={props.style.imageUrl} size='1by1' />
                    </Box>


                    <div className='marginTop20  styleCardInfoColumn'>
                        <p className='type50 has-text-weight-semibold'> {currencySymbol + " " + props.style.price} </p>
                        <p className='type50'> {props.style.description} </p>
                        {/* <p className='type50'>Fabric: {props.selectedSelections.style.fabric}</p>
                        <p className='type50'>Collar: {props.selectedSelections.style.collar}</p>
                        <p className='type50'>3 More Selection. {props.selectedSelections.type} </p> */}
                    </div>

                    {props.style.fabricImage ?
                        <Box className='  is-radiusless pad0 is-shadowless is-clipped styleBox80'>
                            <Image className='is-clipped ' src={props.style.fabricImage} size='1by1' />
                        </Box>
                        : null
                    }



                </div>
                {/* <div className='width80'>
                    <Image className='is-clipped ' src={imageNamed(props.selectedSelections.style.fabric)} size='1by1' />
            </div> */}
            </Card.Content>

        </Card>
    )

}

export default StyleBox