
import { awsBaseUrl, commonAwsBaseUrl } from '../clientInfo/clientInfo'

export function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }

function importAll(r) {
    return r.keys().map(r);
}

const allImages = importAll(require.context('../assets/', false, /\.(png|jpe?g|svg)$/));


const getImageNamed = (name) => {
    // console.log('ALL IMAGES = ', allImages)
    let images = allImages.filter(path => {
        return path.includes(name)
    })
    return images ? images[0] : null   // add a placeholder image here
}


// export const  scrollToTop = () => {
//     const c = document.documentElement.scrollTop || document.body.scrollTop;
//     if (c > 0) {
//         window.requestAnimationFrame(this.scrollToTop);
//         window.scrollTo(0, c - c / 8);
//     }
// };


export const createCMOptions = (fromInch, toInch, title) => {
    let fromCM = parseInt(fromInch * 2.54, 10); 
    let toCM = parseInt(toInch * 2.54, 10); 

    let i = fromCM
    let allCMVals = []

    while (i <= toCM) {
        allCMVals.push(i)
        i++
    }
    
    let allValsString = allCMVals.join(", ")
    console.log("\n")
    console.log(title)
    console.log(allValsString)
    console.log("\n")
    // return allValsString
}

export const makeid = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 export const randomAlphabets = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }


 export const webImageNamed = (name, isCommon) => {
     if (isCommon){
        return commonAwsBaseUrl + name
     }
    return awsBaseUrl + name
}

export const imageNamed = (name) => {
    let img = getImageNamed(name)
    return img ?? getImageNamed('placeholder')
}

export const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)


export const uniqId = () => 'id' + (new Date()).getTime();

export const newOrderNo = () => {

    let date = new Date()

    var d = date.getDate();
    var m = date.getMonth() + 1; //Month from 0 to 11
    var y = ('' + date.getFullYear()).substr(2,2)
    let no =  '' + y +  m  + d + randomAlphabets(3)
    console.log('Year Value = ', y)
    console.log('Order No = ', no)
    return no
}


export const PRODUCT_TYPE = {
    suit: 'SUIT',
    blazer: 'BLAZER',
    jacket: 'JACKET',
    shirt: 'SHIRT',
    pant: 'TROUSER',
    shoe: 'SHOES',
    waistCoat: 'WAIST COAT',
    overCoat: 'OVER COAT',
    tuxedo: 'TUXEDO'
}

export  const EXTRA_STEP_TYPE = {
    essential : 'Essential',
    welcome : 'Welcome',
    units : 'Units',
}


// export const ageGroupForAge = (age) => {
   
//     switch (age) {
//         case (age === 0):
//             alert("less than five");
//             break;
//         case (x < 9):
//             alert("between 5 and 8");
//             break;
//         case (x < 12):
//             alert("between 9 and 11");
//             break;
//         default:
//             alert("none");
//             break;
//     }
    

//         switch (age)  {
//             case (age === 0)  : return .None
//             case 1..<21   : return .to20
//             case 21..<26   : return .to25
//             case 26..<31   : return .to30
//             case 31..<36   : return .to35
//             case 36..<41   : return .to40
//             case 41..<46   : return .to45
//             case 46..<51   : return .to50
//             case 51..<56   : return .to55
//             case 56..<61    : return .to60
//             case 61..<100  : return .above60
//             default: return .None
//         }

// }

export const UNIT_TYPES = {
    ft: 'Ft',
    inchs: 'Inchs',
    cm: 'Cms',
    kg: 'Kg',
    lb: 'Lb',
    year: 'Years'    
}

export const UnitGroupOptions = {
    FtInchKg : 'Feet,   Inches',
    CmLb : 'Centimeters',
}

export const UNIT_KIND = {
    lengthBig: 'Length Big',
    length: 'Length',
    weight: 'Weight',
    age: 'Age'
}

// export const MEASURE_TYPE = {
//     age: 'Age',
//     height: 'Height',
//     weight: 'Weight',
//     neck: 'Neck',
//     shoulder: 'Shoulder',
//     sleeve: 'Sleeve',
//     overarm: 'Overarm',
//     hip: 'Hip',
//     pant: 'Pant',
//     chest: 'Chest',
//     arms: 'Arms',
//     biceps: 'Biceps',
//     wrist: 'Wrist',
//     waiste: 'Waiste',
//     inseem: 'Inseem',
//     suitLength: 'SuitLength',
//     shirtLength: 'ShirtLength',
//     null: null
// }


export const MEASURE_TYPE = {
    age: 'Age',
    height: 'Height',
    weight: 'Weight',
    NECK: 'NECK',
    SHOULDER: 'SHOULDER (FRONT & BACK)',
    SHOULDER_BACK: 'SHOULDER BACK',
    SHOULDER_FRONT: 'SHOULDER FRONT',
    SLEEVE: 'SLEEVE (RIGHT & LEFT)',
    SEAT: 'SEAT',
    CHEST: 'CHEST',
    STOMACH: 'STOMACH',
    BICEP: 'BICEP (RIGHT & LEFT)',
    CUFF: 'CUFF (RIGHT & LEFT)',
    WAIST: 'WAIST',
    OUTSEAM: 'OUTSEAM',
    THIGH: 'THIGH',
    KNEE: 'KNEE',
    ANKLE: 'ANKLE (CUFF)',
    URISE: 'U-RISE (CROTCH)',
    FRONT_WAIST_HEIGHT : 'FRONT WAIST HEIGHT',
    BACK_WAIST_HEIGHT  : 'BACK WAIST HEIGHT',
    FRONT_WAIST_LENGTH : 'FRONT WAIST LENGTH',
    NAPE_TO_WAIST : 'NAPE TO WAIST',
    WAISTCOAT_LENGTH : 'WAISTCOAT LENGTH',
    OVERCOAT_LENGTH : 'OVERCOAT LENGTH',
    JACKET_LENGTH : 'JACKET LENGTH',
    SHIRT_LENGTH : 'SHIRT LENGTH'
}

export const appliesOnProducts = (measureType) => {

    let prodsArray = []

    // PRODUCT_TYPE.pant
    // PRODUCT_TYPE.waistCoat
    // PRODUCT_TYPE.shirt
    // PRODUCT_TYPE.overCoat
    // PRODUCT_TYPE.jacket

    switch (measureType) {
        case MEASURE_TYPE.age                : prodsArray = [PRODUCT_TYPE.pant, PRODUCT_TYPE.waistCoat, PRODUCT_TYPE.shirt, PRODUCT_TYPE.overCoat, PRODUCT_TYPE.jacket] ; break;
        case MEASURE_TYPE.height             : prodsArray = [PRODUCT_TYPE.pant, PRODUCT_TYPE.waistCoat, PRODUCT_TYPE.shirt, PRODUCT_TYPE.overCoat, PRODUCT_TYPE.jacket] ; break;
        case MEASURE_TYPE.weight             : prodsArray = [PRODUCT_TYPE.pant, PRODUCT_TYPE.waistCoat, PRODUCT_TYPE.shirt, PRODUCT_TYPE.overCoat, PRODUCT_TYPE.jacket] ; break;
        case MEASURE_TYPE.NECK               : prodsArray = [PRODUCT_TYPE.waistCoat, PRODUCT_TYPE.shirt, PRODUCT_TYPE.overCoat, PRODUCT_TYPE.jacket] ; break;
        case MEASURE_TYPE.SHOULDER           : prodsArray = [PRODUCT_TYPE.waistCoat, PRODUCT_TYPE.shirt, PRODUCT_TYPE.overCoat, PRODUCT_TYPE.jacket] ; break;
        case MEASURE_TYPE.SLEEVE             : prodsArray = [PRODUCT_TYPE.shirt, PRODUCT_TYPE.overCoat, PRODUCT_TYPE.jacket] ; break;
        case MEASURE_TYPE.SEAT               : prodsArray = [PRODUCT_TYPE.pant, PRODUCT_TYPE.shirt, PRODUCT_TYPE.overCoat, PRODUCT_TYPE.jacket] ; break;        
        case MEASURE_TYPE.CHEST              : prodsArray = [PRODUCT_TYPE.waistCoat, PRODUCT_TYPE.shirt, PRODUCT_TYPE.overCoat, PRODUCT_TYPE.jacket] ; break;
        case MEASURE_TYPE.STOMACH            : prodsArray = [PRODUCT_TYPE.waistCoat, PRODUCT_TYPE.shirt, PRODUCT_TYPE.overCoat, PRODUCT_TYPE.jacket] ; break;
        case MEASURE_TYPE.BICEP              : prodsArray = [PRODUCT_TYPE.shirt, PRODUCT_TYPE.overCoat, PRODUCT_TYPE.jacket] ; break;
        case MEASURE_TYPE.CUFF               : prodsArray = [PRODUCT_TYPE.shirt, PRODUCT_TYPE.overCoat, PRODUCT_TYPE.jacket] ; break;
        case MEASURE_TYPE.WAIST              : prodsArray = [PRODUCT_TYPE.pant, PRODUCT_TYPE.waistCoat, PRODUCT_TYPE.shirt, PRODUCT_TYPE.overCoat, PRODUCT_TYPE.jacket] ; break;
        case MEASURE_TYPE.OUTSEAM            : prodsArray = [PRODUCT_TYPE.pant] ; break;
        case MEASURE_TYPE.THIGH              : prodsArray = [PRODUCT_TYPE.pant] ; break;
        case MEASURE_TYPE.KNEE               : prodsArray = [PRODUCT_TYPE.pant] ; break;
        case MEASURE_TYPE.ANKLE              : prodsArray = [PRODUCT_TYPE.pant] ; break;
        case MEASURE_TYPE.URISE              : prodsArray = [PRODUCT_TYPE.pant] ; break;
        case MEASURE_TYPE.FRONT_WAIST_HEIGHT : prodsArray = [PRODUCT_TYPE.pant, PRODUCT_TYPE.shirt, PRODUCT_TYPE.jacket] ; break;
        case MEASURE_TYPE.BACK_WAIST_HEIGHT  : prodsArray = [PRODUCT_TYPE.pant, PRODUCT_TYPE.shirt, PRODUCT_TYPE.overCoat, PRODUCT_TYPE.jacket] ; break;
        case MEASURE_TYPE.FRONT_WAIST_LENGTH : prodsArray = [PRODUCT_TYPE.waistCoat, PRODUCT_TYPE.shirt, PRODUCT_TYPE.overCoat, PRODUCT_TYPE.jacket] ; break;
        case MEASURE_TYPE.NAPE_TO_WAIST      : prodsArray = [PRODUCT_TYPE.waistCoat, PRODUCT_TYPE.shirt, PRODUCT_TYPE.overCoat, PRODUCT_TYPE.jacket] ; break;
        case MEASURE_TYPE.WAISTCOAT_LENGTH   : prodsArray = [PRODUCT_TYPE.waistCoat] ; break;
        case MEASURE_TYPE.OVERCOAT_LENGTH    : prodsArray = [PRODUCT_TYPE.overCoat] ; break;
        case MEASURE_TYPE.JACKET_LENGTH      : prodsArray = [PRODUCT_TYPE.jacket] ; break;
        case MEASURE_TYPE.SHIRT_LENGTH       : prodsArray = [PRODUCT_TYPE.shirt] ; break;
        default:
            break;
    }
    return prodsArray
} 

// NECK  					[WAISTCOAT, SHIRT, OVERCOAT,  JACKET]
// SHOULDER (FRONT & BACK)  [WAISTCOAT, SHIRT, OVERCOAT,  JACKET]
// SLEEVE (RIGHT & LEFT)  	[SHIRT, OVERCOAT,  JACKET]
// SEAT  					[PANT, SHIRT, OVERCOAT,  JACKET]
// CHEST  					[WAISTCOAT, SHIRT, OVERCOAT,  JACKET]
// STOMACH  				[WAISTCOAT, SHIRT, OVERCOAT,  JACKET]
// BICEP (RIGHT & LEFT)  		[SHIRT, OVERCOAT,  JACKET]
// CUFF (RIGHT & LEFT)  		[SHIRT, OVERCOAT,  JACKET]
// WAIST  					[PANT, WAISTCOAT, SHIRT, OVERCOAT,  JACKET]
// OUTSEAM  				[PANT]
// THIGH  					[PANT]
// KNEE 			 		[PANT]
// ANKLE (CUFF)  			[PANT]
// U-RISE (CROTCH)  			[PANT]

// FRONT WAIST HEIGHT  		[PANT, JACKET, SHIRT]
// BACK WAIST HEIGHT  		[PANT, SHIRT, OVERCOAT,  JACKET]

// FRONT WAIST LENGTH  		[WAISTCOAT, SHIRT, OVERCOAT,  JACKET]
// NAPE TO WAIST  			[WAISTCOAT, SHIRT, OVERCOAT,  JACKET]

// WAISTCOAT LENGTH  		[WAISTCOAT]
// OVERCOAT LENGTH  		[OVERCOAT]
// JACKET LENGTH  			[JACKET]
// SHIRT LENGTH  			[SHIRT]
