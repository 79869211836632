import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Button, Columns, Heading } from 'react-bulma-components/dist'
import { Formik, Form, Field } from 'formik'
import { getCurrentCustomer, saveCurrentCustomer } from '../utilities/Login'
import { Customer } from '../utilities/classes'
import cogoToast from 'cogo-toast'




const ProfileForm = (props) => {

    let profileValues = {
        name: props.customer.name,
        phone: props.customer.phone1
        // country: '',
        // pincode: ''
    }


    const validateName = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 4) {
            error = 'Please enter full name';
        }
        return error;
    }

    const validatePhone = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 8) {
            error = 'Please enter valid phone';
        }
        return error;
    }




    function profileSaved(values, callback) {

            // Save and return

            // setTimeout(() => {
            //     console.log('Profile Saved succesfully. ' )
            //     callback({ success: true, message: 'Profile Saved succesfully. ' })
    
            // }, 800);



            let cust = getCurrentCustomer()
            let thisCust = Customer.copyFrom(cust)

            thisCust.name = values.name
            thisCust.phone1 = values.phone

            thisCust.update((succ, errMsg)=> {
                if (succ === true) {
                    saveCurrentCustomer(thisCust)
                    callback({ success: true, message: 'Profile Saved succesfully. ' })
                }else{
                    cogoToast.warn("Could not update.  Check valid entries, internet connection and try again.")
                    callback({ success: false, message: 'Could not update profile. ' + errMsg })
                }

            })



        // callback({ success: false, message: error.message })

        // Parse.User
        //     .logIn(values.email, values.password).then(function (user) {
        //         console.log('User created successful with name: ' + user.get("username") + ' and email: ' + user.get("email"));
        //         console.log('       LoginUser METHOD Success block -' + Parse.User.current())
        //         callback({ success: true, message: 'User created successful with name: ' + user.get("username") + ' and email: ' + user.get("email") })

        //     }).catch(function (error) {
        //         console.log("Error: " + error.code + " " + error.message);
        //         // callback({success: false, message:  "Error: " + error.code + " " + error.message})
        //         callback({ success: false, message: error.message })

        //     });
        // console.log('User is %j', user)
    }



    return (

        <div className='is-two-fifths layerGreyBG pad20 fadeIn'>
            <br />
            <Heading className=' has-text-grey' size={6}>  Profile Details  </Heading>

            <Formik
                initialValues={profileValues}



                onSubmit={(data, { setSubmitting, resetForm }) => {
                    setSubmitting(true)
                    console.log('Form Submitted : ', data)


                    profileSaved(data, (response) => {
                        if (response.success === true) {
                            setSubmitting(false)
                            resetForm(true)
                            // props.profileChanged(data)
                        } else {
                            setSubmitting(false)
                            resetForm(true)
                            // props.error({ message: response.message })
                        }
                    })


                }}
            >
                {({ values, isSubmitting, errors, touched, isValidating }) => (

                    <Form >
                        <div className='field'>
                            {errors.name && touched.name && <div className="help type55 is-pulled-right is-danger">{errors.name}</div>}
                            <label className="label type60">Name</label>
                            <Field name='name' type='input' className='input has-text-weight-medium type65'  validate={validateName}  />
                        </div>
                        <div className='field'>
                            {errors.phone && touched.phone && <div className="help type55 is-pulled-right is-danger">{errors.phone}</div>}
                            <label className="label type60 marginTop10">Phone</label>
                            <Field name='phone' type='input' className='input has-text-weight-medium type65' validate={validatePhone}  />
                        </div>
                        {/* <div className='field'>
                            {errors.country && touched.country && <div className="help type55 is-pulled-right is-danger">{errors.country}</div>}
                            <label className="label type60 marginTop10">Country</label>
                            <Field name='country' type='input' className='input has-text-weight-medium type65' validate={validateCountry}  />
                        </div>

                        <div className='field'>
                            {errors.pincode && touched.pincode && <div className="help type55 is-pulled-right is-danger">{errors.pincode}</div>}
                            <label className="label type60 ">Pincode</label>
                            <Field name='pincode' type='input' className='input has-text-weight-medium type65' validate={validatePincode}  />
                        </div> */}



                            <div className='control is-expanded'>
                                    <Button  disabled={isSubmitting || isValidating} loading={isSubmitting}  className=' is-white ' type='submit' > Save </Button>

                            </div>

                    </Form>

                )}

            </Formik>
        </div>


    )
}

export default ProfileForm