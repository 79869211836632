import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Button } from 'react-bulma-components/dist';
import { Formik, Form, Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Service from './Service';
import Parse from 'parse'
import { saveCurrentAdmin, tryAdminLogin, tryCustomerLogin } from '../utilities/Login'
import cogoToast from 'cogo-toast'


const SignIn = (props) => {


    let formValues = {
        email: '',
        password: ''
    }


    function loginUser(values, callback) {


        console.log('SUBMITTING CALLED')


        let isThisWebAdmin = false


        // let isMobile = false;

        // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        //     isMobile = true            
        // }

        // Service.saveSession(values.email, values.password, 'SIGN IN. IsMobile = ' + isMobile ) 

        // setTimeout(() => {
        //     callback({ success: true, message: 'User signed In succesfully. ' })
        // }, 800);
        // callback({ success: false, message: error.message })

        if (values.email.includes("admin")){
            isThisWebAdmin = true
            // cogoToast.success('SETTING ISWEBADMIN = ', isWebAdmin)

            tryAdminLogin(values.email, values.password, (success, adminObj, errMsg) => {
                if (success === true) {
                    saveCurrentAdmin(adminObj)
                    // cogoToast.success('Welcome back Admin')
                    callback({ success: true, message: 'Admin Login Successful ', isWebAdmin: isThisWebAdmin })
                } else {
                    cogoToast.error('Invalid Credentials');
                    // console.log(errMsg)
                    // callback({ success: false, message: "Error: " + errMsg })
                    callback({ success: false, message: errMsg, isWebAdmin: isThisWebAdmin })
                }
            })
    

            return
        }

        tryCustomerLogin(values.email, values.password, (success, customer, errMsg) => {
            if (success === true) {
                // console.log(user)
                        console.log('Customer is %j', customer)

                // callback({ success: true, message: 'User login successful ' })
                callback({ success: true, message: 'Customer created successful with name: ' + customer.username + ' and email: ' + customer.email, isWebAdmin: isThisWebAdmin })

            } else {
                cogoToast.error('Invalid Credentials');
                // console.log(errMsg)
                // callback({ success: false, message: "Error: " + errMsg })
                callback({ success: false, message: errMsg, isWebAdmin: isThisWebAdmin })
            }
        })


        // Parse.User
        //     .logIn(values.email, values.password).then(function (user) {
        //         console.log('User created successful with name: ' + user.get("username") + ' and email: ' + user.get("email"));
        //         console.log('       LoginUser METHOD Success block -' + Parse.User.current())
        //         callback({ success: true, message: 'User created successful with name: ' + user.get("username") + ' and email: ' + user.get("email") })

        //     }).catch(function (error) {
        //         console.log("Error: " + error.code + " " + error.message);
        //         // callback({success: false, message:  "Error: " + error.code + " " + error.message})
        //         callback({ success: false, message: error.message })

        //     });
        // console.log('User is %j', user)
    }


    const validateEmail = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = 'Invalid email address';
        }
        return error;
    }



    const validatePassword = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 8) {
            error = 'Password should have atleast 8 characters';
        }
        return error;
    }



    const formDiv = (props) => {
        console.log('RENDERING SIGN IN ')
        return (
            <div >
                <Formik
                    initialValues={formValues}


                    onSubmit={(data, { setSubmitting, resetForm }) => {
                        setSubmitting(true)
                        // Make Any Async Request
                        console.log('Sign IN Submitted : ', data)

     

                        loginUser(data, (response) => {
                            setSubmitting(false)
                            resetForm(true)
                            
                            if (response.success === true) {

                                let loginData = {...data}
                                loginData.isWebAdmin =  response.isWebAdmin

                                props.isSignedIn(loginData)
                            } else {
                                props.errorOccured({ message: response.message })

                            }
                        })
                    }}
                >
                    {({ values, isSubmitting, errors, touched, isValidating }) => (

                        <Form >
                            <br />
                            <div className='field'>
                                {errors.email && touched.email && <div className="help is-pulled-right is-danger">{errors.email}</div>}
                                <label className="label is-size-7 marginTop10">Email</label>
                                <Field name='email' type='input' className='input ' validate={validateEmail} />
                            </div>
                            <div className='field'>
                                {errors.password && touched.password && <div className="help is-pulled-right is-danger">{errors.password}</div>}
                                <label className="label is-size-7 marginTop10">Password</label>
                                <Field name='password' type='password' className='input ' validate={validatePassword} />

                            </div>

                            {/* <div className='field signUpButton'>
                                <Button disabled={isSubmitting || isValidating} loading={isSubmitting} className='button is-link is-fullwidth marginTop10' type='submit'>Submit</Button>
                            </div> */}

                            <div className=''>

                                <Button disabled={isSubmitting || isValidating  } loading={isSubmitting} className=' marginTop20 is-radiusless is-pulled-right  is-black' type='submit' > Sing In &nbsp;
            <i className=" has-texh-weight-thin marginLeft10"> <FontAwesomeIcon className='padRight20' icon={faArrowRight} /></i> </Button>
                            
                            <p>
                            {/* <Button disabled={isSubmitting || isValidating}  className='is-text no-decoration is-small  marginTop10 is-white has-text-grey-light ' onClick={() => { props.hasForgotPassword('forgotPassword') }} >Forgot Password</Button> */}

                            </p>

                            </div>

                            <br />


                            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

                        </Form>

                    )}

                </Formik>
            </div>
        )
    }





    return (
        formDiv(props)
    )
}

export default SignIn