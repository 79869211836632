import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faCheck, faArrowRight, faArrowAltCircleRight, faArrowCircleDown, faForward, faPlus, faPlusCircle, faPlusSquare, faPhone, faPhoneAlt, faSms, faMailBulk, faEnvelope, faSurprise, faSmileBeam, faSmile, faRibbon, faRainbow, faHatCowboy, faAirFreshener, faBirthdayCake, faHatCowboySide } from '@fortawesome/free-solid-svg-icons'




import SignIn from './SignIn'
import SignUp from './SignUp'


import LoadingAux from './LoadingAux'
import ColumnAux from './ColumnAux'
import ChooseBox from './ChooseBox'
import StyleChooserWizard from './StyleChooserWizard'
import MeasurementsWizard from './MeasurementsWizard'
import StyleBox from './StyleBox'
import StyleCard from './StyleCard'
import OrderBox from './OrderBox'
import OrderModal from './OrderModal'


import ModalFullPage from './ModalFullPage'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal, Tag, Pagination, Dropdown
} from 'react-bulma-components/dist'

import { Order, Product, Customer } from '../utilities/classes'
import { imageNamed, PRODUCT_TYPE, uuidv4 } from '../utilities/utils'
import cogoToast from 'cogo-toast'
import { getCurrentAdmin, saveCurrentAdmin, removeCurrentAdmin } from '../utilities/Login'
import AdminOrders from './AdminOrders'
import AdminCustomers from './AdminCustomers'
import AdminProducts from './AdminProducts'

const WebAdmin = (props) => {

    const AdminTabs = {
        orders: 'Orders',
        customers: 'Customers',
        products: 'Products'
    }


    const [currentTab, setCurrentTab] = useState(AdminTabs.orders)


    const changeMainTabHandler = (tabName) => {
        setCurrentTab(tabName)
    }




    const currentHomeScreen = () => {
        switch (currentTab) {
            case AdminTabs.customers: return <AdminCustomers />;
            case AdminTabs.products: return <AdminProducts />;
            default: return <AdminOrders />;
        }
    }




    const mainTabs = () => {
        return (<div className=' fadeIn '>

            <Heading size={4} className='is-size-4-mobile  has-text-centered  noLastMargin ' >
                Concierge Dashboard
            </Heading>
            <Tabs fullwidth align='centered' className='is-mobile is-small  boldTabs has-text-black  marginTop20'>
                <Tabs.Tab active={currentTab === AdminTabs.orders} onClick={() => changeMainTabHandler(AdminTabs.orders)}>
                    ORDERS
                </Tabs.Tab>
                <Tabs.Tab active={currentTab === AdminTabs.customers} onClick={() => changeMainTabHandler(AdminTabs.customers)}>
                    CUSTOMERS
                </Tabs.Tab>
                {/* <Tabs.Tab active={currentTab === AdminTabs.products} onClick={() => changeMainTabHandler(AdminTabs.products)}>
                    PRODUCTS
                </Tabs.Tab> */}
            </Tabs>


        </div>)
    }



    const currentPage = () => {

        console.log('RERENDERING WEBADMIn')

        return (
            <div className=''>

                <Columns className='is-centered  ' >
                    <Columns.Column size={8} className=' is-marginless   bottomPadding0'>
                        {mainTabs()}
                    </Columns.Column>
                </Columns>

                <Container className=''>
                    {/* <Columns className='is-centered  ' > */}
                    {/* <Columns.Column size={8} className=' is-marginless   bottomPadding0'> */}
                    {currentHomeScreen()}
                    {/* </Columns.Column> */}
                    {/* </Columns> */}
                </Container>
            </div>
        )

    }


    return currentPage()

}


export default WebAdmin