


/* eslint-disable */

import React, { Component } from 'react'
import StepWizard from 'react-step-wizard'
import Step from '../containers/Step'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal
} from 'react-bulma-components/dist'

import ColumnAux from '../containers/ColumnAux'
import LoadingAux from '../containers/LoadingAux'
import ChooseBox from '../containers/ChooseBox'

import { imageNamed, webImageNamed, PRODUCT_TYPE, makeid } from '../utilities/utils'
import { C_Product, Product } from '../utilities/classes'
import { currencySymbol } from './clientInfo'







// console.log('Images Folder =', allImages)
// console.log('Images with ````` =', imageNamed('`````'))



class WizardForSuit extends Component {

    basePrice = 0



    // NEW ENTRIES
    // lapelButtonHoleThread_Opts
    // SleeveButtonHoleContrast_Opts
    // sleeveButtonHoleContrastThread_Opts

        // lapelButtonHoleThread_Opts
    // sleeveButtonHoleContrast_Opts
    // sleeveButtonHoleContrastThread_Opts
    // monogramThreadColor_Opts



    // TO ADD NEW ENTRIES
    // Collar_felt
    // Elbow_felt
    // Satin_felt
    // thread_sewing



    STEP_KEYS = {
        productType: '',
        suitStyle: 'SUIT STYLE',
        construction: 'CONSTRUCTION',
        fitPreference: 'FIT PREFERENCE',
        lapel: 'LAPEL',
        lapelWidth: 'LAPEL WIDTH',
        lapelButtonHole: 'LAPEL BUTTON HOLE',
        lapelButtonHoleThread: 'LAPEL BUTTON HOLE THREAD', // NEW (conditional) - Show on first 2 options
        pocketStyle: 'POCKET STYLE',
        vent: 'VENT',
        sleeveButton: 'SLEEVE BUTTON',
        sleeveButtonOrientation: 'SLEEVE BUTTON ORIENTATION',
        sleeveButtonHoleContrast: 'SLEEVE BUTTON HOLE CONTRAST', // NEW
        sleeveButtonHoleContrastThread: 'SLEEVE BUTTON HOLE CONTRAST_THREAD',  // NEW (conditional) - dont show on first
        liningOption: 'LINING OPTION',
        monogram: 'MONOGRAM', // - add no monogram option
        monogramThreadColor: 'MONOGRAM THREAD COLOR', // NEW (conditional) - dont show on no monogram
        pantFitPreference: 'PANT FIT PREFERENCE',
        pantPleat: 'PANT PLEAT',
        pantSidePockets: 'PANT SIDE POCKETS',
        pantBackPockets: 'PANT BACK POCKETS',
        waistBandType: 'WAIST BAND TYPE',
        pantBottom: 'PANT BOTTOM',
        pantMonogram: 'PANT MONOGRAM',  // - add no monogram option
        pantMonogramThreadColor: 'MONOGRAM THREAD COLOR', // NEW (conditional)  - dont show on no monogram
        tone: 'TONE',
        fabric: 'FABRIC',
        fabricLining: '-FABRIC LINING', //NEW
        suitButtons: 'SUIT BUTTONS', //NEW
        contrastOption: 'CONTRAST OPTION', // CHANGE (add pricing)
        contrastFabric: 'CONTRAST FABRIC' // CHANGE (conditional)
    }



    TONE_KEYS = {
        AllSeasons1: 'All Seasons 1',
        AllSeasons2: 'All Seasons 2',
        AllSeasons3: 'All Seasons 3',
        AllSeasons4: 'All Seasons 4',
        FallWinter: 'Fall Winter',
        SpringSummer: 'Spring Summer',
        JacquardFabrics: 'Jacquard Fabrics',
        WaterResistant: 'Water Resistant',
        TuxedoContrast: 'Tuxedo Contrast'
    }

    STYLE_START = {
        create: 'Create Style',
        upload: 'Upload Style',
        select: 'Select Style'
    }



    // 1. Suit Style
    // 2. Construction
    // 3. Fit Preference
    // 4. Lapel
    // 5. Lapel Width
    // 6. Lapel Button Hole
    // 7. Pocket Style
    // 8. Vent
    // 9. Sleeve Button
    // 10. Sleeve Button Orientation
    // 11. Lining Option
    // 12. Monogram
    // 13. Contrast Option
    // 14. Pant Fit Preference
    // 15. Pant Pleat
    // 16. Pant Side Pockets
    // 17. Pant Back Pockets
    // 18. Waist Band Type
    // 19. Pant Bottom
    // 20. Pant Monogram





    state = {
        uploadImage: null,

        selected_SuitStyle: this.props.suitStyle ? this.props.editingProduct.selectedSelections.suitStyle : null,
        selected_Construction: this.props.construction ? this.props.editingProduct.selectedSelections.construction : null,
        selected_FitPreference: this.props.fitPreference ? this.props.editingProduct.selectedSelections.fitPreference : null,
        selected_Lapel: this.props.lapel ? this.props.editingProduct.selectedSelections.lapel : null,
        selected_LapelWidth: this.props.lapelWidth ? this.props.editingProduct.selectedSelections.lapelWidth : null,
        selected_LapelButtonHole: this.props.lapelButtonHole ? this.props.editingProduct.selectedSelections.lapelButtonHole : null,
        selected_LapelButtonHoleThread: this.props.lapelButtonHoleThread ? this.props.editingProduct.selectedSelections.lapelButtonHoleThread : null,
        selected_PocketStyle: this.props.pocketStyle ? this.props.editingProduct.selectedSelections.pocketStyle : null,
        selected_Vent: this.props.vent ? this.props.editingProduct.selectedSelections.vent : null,
        selected_SleeveButton: this.props.sleeveButton ? this.props.editingProduct.selectedSelections.sleeveButton : null,
        selected_SleeveButtonOrientation: this.props.sleeveButtonOrientation ? this.props.editingProduct.selectedSelections.sleeveButtonOrientation : null,
        selected_SleeveButtonHoleContrast: this.props.sleeveButtonHoleContrast ? this.props.editingProduct.selectedSelections.sleeveButtonHoleContrast : null,
        selected_SleeveButtonHoleContrastThread: this.props.sleeveButtonHoleContrastThread ? this.props.editingProduct.selectedSelections.sleeveButtonHoleContrastThread : null,
        selected_LiningOption: this.props.liningOption ? this.props.editingProduct.selectedSelections.liningOption : null,
        selected_Monogram: this.props.editingProduct ? this.props.editingProduct.selectedSelections.monogram : null,
        selected_MonogramThreadColor: this.props.editingProduct ? this.props.editingProduct.selectedSelections.monogramThreadColor : null,
        selected_PantFitPreference: this.props.pantFitPreference ? this.props.editingProduct.selectedSelections.pantFitPreference : null,
        selected_PantPleat: this.props.pantPleat ? this.props.editingProduct.selectedSelections.pantPleat : null,
        selected_PantSidePockets: this.props.pantSidePockets ? this.props.editingProduct.selectedSelections.pantSidePockets : null,
        selected_PantBackPockets: this.props.pantBackPockets ? this.props.editingProduct.selectedSelections.pantBackPockets : null,
        selected_WaistBandType: this.props.waistBandType ? this.props.editingProduct.selectedSelections.waistBandType : null,
        selected_PantBottom: this.props.pantBottom ? this.props.editingProduct.selectedSelections.pantBottom : null,
        selected_PantMonogram: this.props.pantMonogram ? this.props.editingProduct.selectedSelections.pantMonogram : null,
        selected_PantMonogramThreadColor: this.props.pantMonogramThreadColor ? this.props.editingProduct.selectedSelections.pantMonogramThreadColor : null,


        selected_Tone: this.props.editingProduct ? this.props.editingProduct.selectedSelections.tone : null,
        selected_Fabric: this.props.editingProduct ? this.props.editingProduct.selectedSelections.fabric : null,

        selected_FabricLining: this.props.editingProduct ? this.props.editingProduct.selectedSelections.fabricLining : null,
        selected_SuitButtons: this.props.editingProduct ? this.props.editingProduct.selectedSelections.selected_SuitButtons : null,

        selected_ContrastOption: this.props.contrastOption ? this.props.editingProduct.selectedSelections.contrastOption : null,
        selected_ContrastFabric: this.props.editingProduct ? this.props.editingProduct.selectedSelections.contrastFabric : null,

        isComplete: false,
        isCancelled: false,
        selectedCreateOrUpload: this.STYLE_START.create,
        editingProduct: this.props.editingProduct ?? null,
        productType: PRODUCT_TYPE.jacket
    }



    _mounted = false


    componentDidMount = () => {
        this._mounted = true

        let ns = { ...this.state }
        if (this.props.editingProduct) {
            ns.editingProduct = this.props.editingProduct
        }
        if (this.props.productType) {
            ns.productType = this.props.productType
        }


        // 1. Suit Style
        // 2. Construction
        // 3. Fit Preference
        // 4. Lapel
        // 5. Lapel Width
        // 6. Lapel Button Hole
        // 7. Pocket Style
        // 8. Vent
        // 9. Sleeve Button
        // 10. Sleeve Button Orientation
        // 11. Lining Option
        // 12. Monogram
        // 13. Contrast Option
        // 14. Pant Fit Preference
        // 15. Pant Pleat
        // 16. Pant Side Pockets
        // 17. Pant Back Pockets
        // 18. Waist Band Type
        // 19. Pant Bottom
        // 20. Pant Monogram

        if (this.props.editingProduct) {
            let existingStyle = { ...this.props.editingProduct }
            ns.editingProduct = existingStyle
            // console.log("EDITING WIZRAD OF JACKET")
            // console.log(existingStyle)

            ns.selected_SuitStyle = existingStyle.selectedSelections.suitStyle
            ns.selected_Construction = existingStyle.selectedSelections.construction
            ns.selected_FitPreference = existingStyle.selectedSelections.fitPreference




            ns.selected_Lapel = existingStyle.selectedSelections.lapel
            ns.selected_LapelWidth = existingStyle.selectedSelections.lapelWidth
            ns.selected_LapelButtonHole = existingStyle.selectedSelections.lapelButtonHole
            ns.selected_LapelButtonHoleThread = existingStyle.selectedSelections.lapelButtonHoleThread

            ns.selected_PocketStyle = existingStyle.selectedSelections.pocketStyle
            ns.selected_Vent = existingStyle.selectedSelections.vent
            ns.selected_SleeveButton = existingStyle.selectedSelections.sleeveButton

            ns.selected_SleeveButtonOrientation = existingStyle.selectedSelections.sleeveButtonOrientation
            ns.selected_SleeveButtonHoleContrast = existingStyle.selectedSelections.sleeveButtonHoleContrast
            ns.selected_SleeveButtonHoleContrastThread = existingStyle.selectedSelections.sleeveButtonHoleContrastThread

            ns.selected_LiningOption = existingStyle.selectedSelections.liningOption
            ns.selected_Monogram = existingStyle.selectedSelections.monogram
            ns.selected_MonogramThreadColor = existingStyle.selectedSelections.monogramThreadColor

            ns.selected_PantFitPreference = existingStyle.selectedSelections.pantFitPreference
            ns.selected_PantPleat = existingStyle.selectedSelections.pantPleat

            ns.selected_PantSidePockets = existingStyle.selectedSelections.pantSidePockets
            ns.selected_PantBackPockets = existingStyle.selectedSelections.pantBackPockets
            ns.selected_WaistBandType = existingStyle.selectedSelections.waistBandType
            ns.selected_PantBottom = existingStyle.selectedSelections.pantBottom
            ns.selected_PantMonogram = existingStyle.selectedSelections.pantMonogram
            ns.selected_PantMonogramThreadColor = existingStyle.selectedSelections.pantMonogramThreadColor

            ns.selected_Tone = existingStyle.selectedSelections.tone
            ns.selected_Fabric = existingStyle.selectedSelections.fabric
            ns.selected_FabricLining = existingStyle.selectedSelections.fabricLining
            ns.selected_SuitButtons = existingStyle.selectedSelections.suitButtons

            ns.selected_ContrastOption = existingStyle.selectedSelections.contrastOption
            ns.selected_ContrastFabric = existingStyle.selectedSelections.contrastFabric

        }


        this.setState(ns)

    }

    componentWillUnmount = () => {
        this._mounted = false
    }



    // SUIT STYLE
    suitStyle_Opts = [
        { optType: "SUIT STYLE",  key: 'ONE BUTTON', name: 'ONE BUTTON', imageSrc: webImageNamed('StyleSelections/Suit/SuitStyle/SINGLEbreasted1button.jpg') },
        { optType: "SUIT STYLE",  key: 'TWO BUTTON', name: 'TWO BUTTON', imageSrc: webImageNamed('StyleSelections/Suit/SuitStyle/SINGLEbreasted2button.jpg') },
        { optType: "SUIT STYLE",  key: 'TWO BUTTON WITH ONE FASTEN', name: 'TWO BUTTON WITH ONE FASTEN', imageSrc: webImageNamed('StyleSelections/Suit/SuitStyle/DOUBLEbreasted2button1fasten.jpg') },
        { optType: "SUIT STYLE",  key: 'FOUR BUTTON WITH TWO FASTEN', name: 'FOUR BUTTON WITH TWO FASTEN', imageSrc: webImageNamed('StyleSelections/Suit/SuitStyle/DOUBLEbreasted4button2fasten.jpg') },
        { optType: "SUIT STYLE",  key: 'SIX BUTTON WITH TWO FASTEN', name: 'SIX BUTTON WITH TWO FASTEN', imageSrc: webImageNamed('StyleSelections/Suit/SuitStyle/DOUBLEbreasted6button2fasten.jpg') },
    ]
    suitStyle_Step = () => {
        return {
            name: 'Choose Suit Style',
            key: this.STEP_KEYS.suitStyle,
            opts: this.suitStyle_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }

    // CONSTRUCTION
    construction_Opts = [
        { optType: "CONSTRUCTION",  key: 'FULL CANVAS (+$100)', name: 'FULL CANVAS', imageSrc: webImageNamed('StyleSelections/Suit/Construction/FullCanvas.jpg'), price: 100 },
        { optType: "CONSTRUCTION",  key: 'HALF CANVAS', name: 'HALF CANVAS', imageSrc: webImageNamed('StyleSelections/Suit/Construction/HalfCanvas.jpg') },
    ]
    construction_Step = () => {
        return {
            name: 'Construction',
            key: this.STEP_KEYS.construction,
            opts: this.construction_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }

    // FIT PREFERENCE
    fitPreference_Opts = [
        { optType: "FIT PREFERENCE",  key: 'SLIM', name: 'SLIM', imageSrc: webImageNamed('StyleSelections/Suit/FitPreference/Slim.jpg') },
        { optType: "FIT PREFERENCE",  key: 'STANDARD', name: 'STANDARD', imageSrc: webImageNamed('StyleSelections/Suit/FitPreference/Standard.jpg') },
        { optType: "FIT PREFERENCE",  key: 'COMFORT', name: 'COMFORT', imageSrc: webImageNamed('StyleSelections/Suit/FitPreference/Comfort.jpg') },
    ]
    fitPreference_Step = () => {
        return {
            name: 'Choose Fit Preference',
            key: this.STEP_KEYS.fitPreference,
            opts: this.fitPreference_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }

    // LAPEL
    lapel_Opts = [
        { optType: "LAPEL",  key: 'PEAK', name: 'PEAK', imageSrc: webImageNamed('StyleSelections/Suit/Lapel/PEAK.jpg') },
        { optType: "LAPEL",  key: 'NOTCH', name: 'NOTCH', imageSrc: webImageNamed('StyleSelections/Suit/Lapel/NOTCH.jpg') },
        { optType: "LAPEL",  key: 'SHAWL', name: 'SHAWL', imageSrc: webImageNamed('StyleSelections/Suit/Lapel/SHAWL.jpg') },
    ]
    lapel_Step = () => {
        return {
            name: 'Choose Lapel Style',
            key: this.STEP_KEYS.lapel,
            opts: this.lapel_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }

    // LAPEL WIDTH
    lapelWidth_Opts = [
        { optType: "LAPEL WIDTH",  key: 'SLIM (3 INCH)', name: 'SLIM (3 INCH)', imageSrc: webImageNamed('StyleSelections/Suit/LapelWidth/Slim.jpg') },
        { optType: "LAPEL WIDTH",  key: 'STANDARD (3.25 INCH)', name: 'STANDARD (3.25 INCH)', imageSrc: webImageNamed('StyleSelections/Suit/LapelWidth/Standard.jpg') },
        { optType: "LAPEL WIDTH",  key: 'WIDE (3.5 INCH)', name: 'WIDE (3.5 INCH)', imageSrc: webImageNamed('StyleSelections/Suit/LapelWidth/Wide.jpg') },
        { optType: "LAPEL WIDTH",  key: 'EXTRA WIDE (4 INCH)', name: 'EXTRA WIDE (4 INCH)', imageSrc: webImageNamed('StyleSelections/Suit/LapelWidth/ExtraWide.jpg') },
    ]
    lapelWidth_Step = () => {
        return {
            name: 'Choose Lapel Style',
            key: this.STEP_KEYS.lapelWidth,
            opts: this.lapelWidth_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }

    // LAPEL BUTTON HOLE
    lapelButtonHole_Opts = [
        { optType: "LAPEL BUTTON HOLE",  key: 'NO LAPEL HOLE', name: 'NO LAPEL HOLE', imageSrc: webImageNamed('StyleSelections/Suit/LapelButtonHole/NoLapelHole.jpg') },
        { optType: "LAPEL BUTTON HOLE",  key: 'FUNCTIONAL', name: 'FUNCTIONAL', imageSrc: webImageNamed('StyleSelections/Suit/LapelButtonHole/Functional.jpg') },
        { optType: "LAPEL BUTTON HOLE",  key: 'HANDMADE', name: 'HANDMADE', price: 10, imageSrc: webImageNamed('StyleSelections/Suit/LapelButtonHole/Handmade.jpg') },
    ]
    lapelButtonHole_Step = () => {
        return {
            name: 'Choose Lapel Button Hole',
            key: this.STEP_KEYS.lapelButtonHole,
            opts: this.lapelButtonHole_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }
    

    // NEW ENTRY
    lapelButtonHoleThread_Opts = [
        // { optType: "Threads", key: '2187', name: '2187', imageSrc: webImageNamed('Fabrics/Threads/Buttonhole/2187.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C1172', name: 'C1172', imageSrc: webImageNamed('Fabrics/SewingThread/C1172.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C1202', name: 'C1202', imageSrc: webImageNamed('Fabrics/SewingThread/C1202.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C1231', name: 'C1231', imageSrc: webImageNamed('Fabrics/SewingThread/C1231.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C1257', name: 'C1257', imageSrc: webImageNamed('Fabrics/SewingThread/C1257.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C1424', name: 'C1424', imageSrc: webImageNamed('Fabrics/SewingThread/C1424.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C1740', name: 'C1740', imageSrc: webImageNamed('Fabrics/SewingThread/C1740.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C2376', name: 'C2376', imageSrc: webImageNamed('Fabrics/SewingThread/C2376.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C2427', name: 'C2427', imageSrc: webImageNamed('Fabrics/SewingThread/C2427.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C2740', name: 'C2740', imageSrc: webImageNamed('Fabrics/SewingThread/C2740.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C3156', name: 'C3156', imageSrc: webImageNamed('Fabrics/SewingThread/C3156.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C3166', name: 'C3166', imageSrc: webImageNamed('Fabrics/SewingThread/C3166.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C3646', name: 'C3646', imageSrc: webImageNamed('Fabrics/SewingThread/C3646.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C3853', name: 'C3853', imageSrc: webImageNamed('Fabrics/SewingThread/C3853.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C3867', name: 'C3867', imageSrc: webImageNamed('Fabrics/SewingThread/C3867.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C3952', name: 'C3952', imageSrc: webImageNamed('Fabrics/SewingThread/C3952.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C3966', name: 'C3966', imageSrc: webImageNamed('Fabrics/SewingThread/C3966.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C3993', name: 'C3993', imageSrc: webImageNamed('Fabrics/SewingThread/C3993.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C4106', name: 'C4106', imageSrc: webImageNamed('Fabrics/SewingThread/C4106.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C4300', name: 'C4300', imageSrc: webImageNamed('Fabrics/SewingThread/C4300.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C4328', name: 'C4328', imageSrc: webImageNamed('Fabrics/SewingThread/C4328.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C4351', name: 'C4351', imageSrc: webImageNamed('Fabrics/SewingThread/C4351.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C4983', name: 'C4983', imageSrc: webImageNamed('Fabrics/SewingThread/C4983.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C4987', name: 'C4987', imageSrc: webImageNamed('Fabrics/SewingThread/C4987.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C5140', name: 'C5140', imageSrc: webImageNamed('Fabrics/SewingThread/C5140.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C5229', name: 'C5229', imageSrc: webImageNamed('Fabrics/SewingThread/C5229.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C5337', name: 'C5337', imageSrc: webImageNamed('Fabrics/SewingThread/C5337.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C5345', name: 'C5345', imageSrc: webImageNamed('Fabrics/SewingThread/C5345.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C5744', name: 'C5744', imageSrc: webImageNamed('Fabrics/SewingThread/C5744.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C5943', name: 'C5943', imageSrc: webImageNamed('Fabrics/SewingThread/C5943.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C5987', name: 'C5987', imageSrc: webImageNamed('Fabrics/SewingThread/C5987.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C6178', name: 'C6178', imageSrc: webImageNamed('Fabrics/SewingThread/C6178.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C6935', name: 'C6935', imageSrc: webImageNamed('Fabrics/SewingThread/C6935.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C7201', name: 'C7201', imageSrc: webImageNamed('Fabrics/SewingThread/C7201.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C7279', name: 'C7279', imageSrc: webImageNamed('Fabrics/SewingThread/C7279.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C7305', name: 'C7305', imageSrc: webImageNamed('Fabrics/SewingThread/C7305.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C7930', name: 'C7930', imageSrc: webImageNamed('Fabrics/SewingThread/C7930.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C7978', name: 'C7978', imageSrc: webImageNamed('Fabrics/SewingThread/C7978.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C8501', name: 'C8501', imageSrc: webImageNamed('Fabrics/SewingThread/C8501.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C8587', name: 'C8587', imageSrc: webImageNamed('Fabrics/SewingThread/C8587.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C8989', name: 'C8989', imageSrc: webImageNamed('Fabrics/SewingThread/C8989.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C9114', name: 'C9114', imageSrc: webImageNamed('Fabrics/SewingThread/C9114.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C9313', name: 'C9313', imageSrc: webImageNamed('Fabrics/SewingThread/C9313.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C9623', name: 'C9623', imageSrc: webImageNamed('Fabrics/SewingThread/C9623.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C9631', name: 'C9631', imageSrc: webImageNamed('Fabrics/SewingThread/C9631.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C9666', name: 'C9666', imageSrc: webImageNamed('Fabrics/SewingThread/C9666.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C9770', name: 'C9770', imageSrc: webImageNamed('Fabrics/SewingThread/C9770.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C9913', name: 'C9913', imageSrc: webImageNamed('Fabrics/SewingThread/C9913.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'C9949', name: 'C9949', imageSrc: webImageNamed('Fabrics/SewingThread/C9949.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'WCG001', name: 'WCG001', imageSrc: webImageNamed('Fabrics/SewingThread/WCG001.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'WCG007', name: 'WCG007', imageSrc: webImageNamed('Fabrics/SewingThread/WCG007.jpg'), price: null, company: '' },
    ]

    lapelButtonHoleThread_Step = () => {
        return {
            name: 'Choose Button Hole Thread',
            key: this.STEP_KEYS.buttonHoleThread,
            opts: this.lapelButtonHoleThread_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }

    // POCKET STYLE
    pocketStyle_Opts = [
        { optType: "POCKET STYLE",  key: 'DOUBLE BESOM', name: 'DOUBLE BESOM', imageSrc: webImageNamed('StyleSelections/Suit/PocketStyle/DoubleBesom.jpg') },
        { optType: "POCKET STYLE",  key: 'DOUBLE BESOM WITH TICKET POCKET', name: 'DOUBLE BESOM WITH TICKET POCKET', imageSrc: webImageNamed('StyleSelections/Suit/PocketStyle/DoubleBesomwithTicket.jpg') },
        { optType: "POCKET STYLE",  key: 'HACKING DOUBLE BESOM', name: 'HACKING DOUBLE BESOM', imageSrc: webImageNamed('StyleSelections/Suit/PocketStyle/DoubleBesomSlanted.jpg') },
        { optType: "POCKET STYLE",  key: 'HACKING DOUBLE BESOM WITH TICKET POCKET', name: 'HACKING DOUBLE BESOM WITH TICKET POCKET', imageSrc: webImageNamed('StyleSelections/Suit/PocketStyle/DoubleBesomSLANTEDwithTICKET.jpg') },
        { optType: "POCKET STYLE",  key: 'STRAIGHT FLAPS', name: 'STRAIGHT FLAPS', imageSrc: webImageNamed('StyleSelections/Suit/PocketStyle/FLAPpockets.jpg') },
        { optType: "POCKET STYLE",  key: 'STRAIGHT FLAPS WITH TICKET POCKET', name: 'STRAIGHT FLAPS WITH TICKET POCKET', imageSrc: webImageNamed('StyleSelections/Suit/PocketStyle/FLAPSwithTP.jpg') },
        { optType: "POCKET STYLE",  key: 'HACKING FLAPS', name: 'HACKING FLAPS', imageSrc: webImageNamed('StyleSelections/Suit/PocketStyle/HackingFLAPS.jpg') },
        { optType: "POCKET STYLE",  key: 'HACKING FLAPS WITH TICKET POCKET', name: 'HACKING FLAPS WITH TICKET POCKET', imageSrc: webImageNamed('StyleSelections/Suit/PocketStyle/HackingFLAPSwithTP.jpg') },
        { optType: "POCKET STYLE",  key: 'PATCH POCKETS', name: 'PATCH POCKETS', imageSrc: webImageNamed('StyleSelections/Suit/PocketStyle/PatchPockets.jpg') },
    ]
    pocketStyle_Step = () => {
        return {
            name: 'Choose Pocket Style',
            key: this.STEP_KEYS.pocketStyle,
            opts: this.pocketStyle_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }

    // VENT
    vent_Opts = [
        { optType: "VENT",  key: 'NO VENT', name: 'NO VENT', imageSrc: webImageNamed('StyleSelections/Suit/VentStyle/NoVent.jpg') },
        { optType: "VENT",  key: 'CENTER VENT', name: 'CENTER VENT', imageSrc: webImageNamed('StyleSelections/Suit/VentStyle/CenterVent.jpg') },
        { optType: "VENT",  key: 'Double VENT (Standard)', name: 'Double VENT (Standard)', imageSrc: webImageNamed('StyleSelections/Suit/VentStyle/SideVents.jpg') },
    ]
    vent_Step = () => {
        return {
            name: 'Choose Vent',
            key: this.STEP_KEYS.vent,
            opts: this.vent_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // SLEEVE BUTTON
    sleeveButton_Opts = [
        { optType: this.STEP_KEYS.sleeveButtons,  key: 'THREE', name: 'THREE', imageSrc: webImageNamed('StyleSelections/Suit/SleeveButtons/THREE.jpg') },
        { optType: this.STEP_KEYS.sleeveButtons,  key: 'FOUR (Standard)', name: 'FOUR (Standard)', imageSrc: webImageNamed('StyleSelections/Suit/SleeveButtons/FOUR.jpg') },
        { optType: this.STEP_KEYS.sleeveButtons,  key: 'FIVE', name: 'FIVE', imageSrc: webImageNamed('StyleSelections/Suit/SleeveButtons/FIVE.jpg') },
    ]
    sleeveButton_Step = () => {
        return {
            name: 'How would you like the Sleeve Button?',
            key: this.STEP_KEYS.sleeveButton,
            opts: this.sleeveButton_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // SLEEVE BUTTON ORIENTATION
    sleeveButtonOrientation_Opts = [
        { optType: this.STEP_KEYS.sleeveButtonOrientation,  key: 'SLANTED', name: 'SLANTED', imageSrc: webImageNamed('StyleSelections/Suit/SleeveButtonOrientation/Slanted.jpg') },
        { optType: this.STEP_KEYS.sleeveButtonOrientation,  key: 'STRAIGHT', name: 'STRAIGHT', imageSrc: webImageNamed('StyleSelections/Suit/SleeveButtonOrientation/Straight.jpg') },
    ]
    sleeveButtonOrientation_Step = () => {
        return {
            name: 'Sleeve Button Orientation',
            key: this.STEP_KEYS.sleeveButtonOrientation,
            opts: this.sleeveButtonOrientation_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }




    // NEW ENTRY 
    sleeveButtonHoleContrast_Opts = [
        { optType: this.STEP_KEYS.sleeveButtonHoleContrast,  key: 'NO CONTRAST', name: 'NO CONTRAST', imageSrc: webImageNamed('StyleSelections/Suit/SleeveButtonHoleContrast/No+Contrast.jpg') },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrast,  key: 'ONE', name: 'ONE', imageSrc: webImageNamed('StyleSelections/Suit/SleeveButtonHoleContrast/ONE.jpg') },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrast,  key: 'TWO', name: 'TWO', imageSrc: webImageNamed('StyleSelections/Suit/SleeveButtonHoleContrast/TWO.jpg') },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrast,  key: 'ALL', name: 'ALL', imageSrc: webImageNamed('StyleSelections/Suit/SleeveButtonHoleContrast/All.jpg') },
    ]
    sleeveButtonHoleContrast_Step = () => {
        return {
            name: 'Sleeve Button Hole Contrast',
            key: this.STEP_KEYS.sleeveButtonHoleContrast,
            opts: this.sleeveButtonHoleContrast_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }
    

    
    // NEW ENTRY 
    sleeveButtonHoleContrastThread_Opts = [
        // { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: '2187', name: '2187', imageSrc: webImageNamed('Threads/Buttonhole/2187.jpg'), price: null, company: '' },

        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1172', name: 'C1172', imageSrc: webImageNamed('Fabrics/SewingThread/C1172.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1202', name: 'C1202', imageSrc: webImageNamed('Fabrics/SewingThread/C1202.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1231', name: 'C1231', imageSrc: webImageNamed('Fabrics/SewingThread/C1231.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1257', name: 'C1257', imageSrc: webImageNamed('Fabrics/SewingThread/C1257.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1424', name: 'C1424', imageSrc: webImageNamed('Fabrics/SewingThread/C1424.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1740', name: 'C1740', imageSrc: webImageNamed('Fabrics/SewingThread/C1740.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C2376', name: 'C2376', imageSrc: webImageNamed('Fabrics/SewingThread/C2376.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C2427', name: 'C2427', imageSrc: webImageNamed('Fabrics/SewingThread/C2427.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C2740', name: 'C2740', imageSrc: webImageNamed('Fabrics/SewingThread/C2740.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3156', name: 'C3156', imageSrc: webImageNamed('Fabrics/SewingThread/C3156.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3166', name: 'C3166', imageSrc: webImageNamed('Fabrics/SewingThread/C3166.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3646', name: 'C3646', imageSrc: webImageNamed('Fabrics/SewingThread/C3646.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3853', name: 'C3853', imageSrc: webImageNamed('Fabrics/SewingThread/C3853.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3867', name: 'C3867', imageSrc: webImageNamed('Fabrics/SewingThread/C3867.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3952', name: 'C3952', imageSrc: webImageNamed('Fabrics/SewingThread/C3952.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3966', name: 'C3966', imageSrc: webImageNamed('Fabrics/SewingThread/C3966.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3993', name: 'C3993', imageSrc: webImageNamed('Fabrics/SewingThread/C3993.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4106', name: 'C4106', imageSrc: webImageNamed('Fabrics/SewingThread/C4106.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4300', name: 'C4300', imageSrc: webImageNamed('Fabrics/SewingThread/C4300.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4328', name: 'C4328', imageSrc: webImageNamed('Fabrics/SewingThread/C4328.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4351', name: 'C4351', imageSrc: webImageNamed('Fabrics/SewingThread/C4351.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4983', name: 'C4983', imageSrc: webImageNamed('Fabrics/SewingThread/C4983.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4987', name: 'C4987', imageSrc: webImageNamed('Fabrics/SewingThread/C4987.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5140', name: 'C5140', imageSrc: webImageNamed('Fabrics/SewingThread/C5140.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5229', name: 'C5229', imageSrc: webImageNamed('Fabrics/SewingThread/C5229.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5337', name: 'C5337', imageSrc: webImageNamed('Fabrics/SewingThread/C5337.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5345', name: 'C5345', imageSrc: webImageNamed('Fabrics/SewingThread/C5345.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5744', name: 'C5744', imageSrc: webImageNamed('Fabrics/SewingThread/C5744.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5943', name: 'C5943', imageSrc: webImageNamed('Fabrics/SewingThread/C5943.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5987', name: 'C5987', imageSrc: webImageNamed('Fabrics/SewingThread/C5987.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C6178', name: 'C6178', imageSrc: webImageNamed('Fabrics/SewingThread/C6178.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C6935', name: 'C6935', imageSrc: webImageNamed('Fabrics/SewingThread/C6935.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C7201', name: 'C7201', imageSrc: webImageNamed('Fabrics/SewingThread/C7201.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C7279', name: 'C7279', imageSrc: webImageNamed('Fabrics/SewingThread/C7279.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C7305', name: 'C7305', imageSrc: webImageNamed('Fabrics/SewingThread/C7305.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C7930', name: 'C7930', imageSrc: webImageNamed('Fabrics/SewingThread/C7930.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C7978', name: 'C7978', imageSrc: webImageNamed('Fabrics/SewingThread/C7978.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C8501', name: 'C8501', imageSrc: webImageNamed('Fabrics/SewingThread/C8501.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C8587', name: 'C8587', imageSrc: webImageNamed('Fabrics/SewingThread/C8587.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C8989', name: 'C8989', imageSrc: webImageNamed('Fabrics/SewingThread/C8989.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9114', name: 'C9114', imageSrc: webImageNamed('Fabrics/SewingThread/C9114.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9313', name: 'C9313', imageSrc: webImageNamed('Fabrics/SewingThread/C9313.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9623', name: 'C9623', imageSrc: webImageNamed('Fabrics/SewingThread/C9623.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9631', name: 'C9631', imageSrc: webImageNamed('Fabrics/SewingThread/C9631.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9666', name: 'C9666', imageSrc: webImageNamed('Fabrics/SewingThread/C9666.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9770', name: 'C9770', imageSrc: webImageNamed('Fabrics/SewingThread/C9770.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9913', name: 'C9913', imageSrc: webImageNamed('Fabrics/SewingThread/C9913.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9949', name: 'C9949', imageSrc: webImageNamed('Fabrics/SewingThread/C9949.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'WCG001', name: 'WCG001', imageSrc: webImageNamed('Fabrics/SewingThread/WCG001.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'WCG007', name: 'WCG007', imageSrc: webImageNamed('Fabrics/SewingThread/WCG007.jpg'), price: null, company: '' },
    ]
    sleeveButtonHoleContrastThread_Step = () => {
        return {
            name: 'Sleeve Button Hole Contrast Thread',
            key: this.STEP_KEYS.sleeveButtonHoleContrastThread,
            opts: this.sleeveButtonHoleContrastThread_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }




    // LINING OPTION
    liningOption_Opts = [
        { optType: "LINING OPTION",  key: 'FULL', name: 'FULL', imageSrc: webImageNamed('StyleSelections/Suit/Lining/FULL.jpg') },
        { optType: "LINING OPTION",  key: 'HALF', name: 'HALF', imageSrc: webImageNamed('StyleSelections/Suit/Lining/HALF.jpg') },
    ]
    liningOption_Step = () => {
        return {
            name: 'Choose Lining Option',
            key: this.STEP_KEYS.liningOption,
            opts: this.liningOption_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // MONOGRAM
    monogram_Opts = [
        { optType: "MONOGRAM",  key: 'NO MONOGRAM', name: 'NO MONOGRAM', imageSrc: null, customInput: "" },
        { optType: "MONOGRAM",  key: 'COLLAR FELT', name: 'COLLAR FELT', imageSrc: webImageNamed('StyleSelections/Suit/Monogram/CollarFelt.jpg'), customInput: "" },
        { optType: "MONOGRAM",  key: 'ABOVE INNER LEFT BREAST POCKET', name: 'ABOVE INNER LEFT BREAST POCKET', imageSrc: webImageNamed('StyleSelections/Suit/Monogram/AboveInnerPocket.jpg'), customInput: "" },
    ]
    monogram_Step = () => {
        return {
            name: 'If and where you would like to have monogram',
            key: this.STEP_KEYS.monogram,
            opts: this.monogram_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: true,
            paddingLess: false,
            boldText: false,
            needsCustomInput: true
        }
    }

    


    // NEW ENTRY 
    monogramThreadColor_Opts = [
        { optType: "Threads", key: '101', name: '101', imageSrc: webImageNamed('Fabrics/MonogramThread/101.jpg'), price: null, company: '' },
        { optType: "Threads", key: '102', name: '102', imageSrc: webImageNamed('Fabrics/MonogramThread/102.jpg'), price: null, company: '' },
        { optType: "Threads", key: '103', name: '103', imageSrc: webImageNamed('Fabrics/MonogramThread/103.jpg'), price: null, company: '' },
        { optType: "Threads", key: '138', name: '138', imageSrc: webImageNamed('Fabrics/MonogramThread/138.jpg'), price: null, company: '' },
        { optType: "Threads", key: '144', name: '144', imageSrc: webImageNamed('Fabrics/MonogramThread/144.jpg'), price: null, company: '' },
        { optType: "Threads", key: '145', name: '145', imageSrc: webImageNamed('Fabrics/MonogramThread/145.jpg'), price: null, company: '' },
        { optType: "Threads", key: '177', name: '177', imageSrc: webImageNamed('Fabrics/MonogramThread/177.jpg'), price: null, company: '' },
        { optType: "Threads", key: '189', name: '189', imageSrc: webImageNamed('Fabrics/MonogramThread/189.jpg'), price: null, company: '' },
        { optType: "Threads", key: '262', name: '262', imageSrc: webImageNamed('Fabrics/MonogramThread/262.jpg'), price: null, company: '' },
        { optType: "Threads", key: '312', name: '312', imageSrc: webImageNamed('Fabrics/MonogramThread/312.jpg'), price: null, company: '' },
        { optType: "Threads", key: '321', name: '321', imageSrc: webImageNamed('Fabrics/MonogramThread/321.jpg'), price: null, company: '' },
        { optType: "Threads", key: '352', name: '352', imageSrc: webImageNamed('Fabrics/MonogramThread/352.jpg'), price: null, company: '' },
        { optType: "Threads", key: '392', name: '392', imageSrc: webImageNamed('Fabrics/MonogramThread/392.jpg'), price: null, company: '' },
        { optType: "Threads", key: '432', name: '432', imageSrc: webImageNamed('Fabrics/MonogramThread/432.jpg'), price: null, company: '' },
        { optType: "Threads", key: '455', name: '455', imageSrc: webImageNamed('Fabrics/MonogramThread/455.jpg'), price: null, company: '' },
        { optType: "Threads", key: '489', name: '489', imageSrc: webImageNamed('Fabrics/MonogramThread/489.jpg'), price: null, company: '' },
        { optType: "Threads", key: '572', name: '572', imageSrc: webImageNamed('Fabrics/MonogramThread/572.jpg'), price: null, company: '' },
        { optType: "Threads", key: '598', name: '598', imageSrc: webImageNamed('Fabrics/MonogramThread/598.jpg'), price: null, company: '' },
        { optType: "Threads", key: '599', name: '599', imageSrc: webImageNamed('Fabrics/MonogramThread/599.jpg'), price: null, company: '' },
        { optType: "Threads", key: '624', name: '624', imageSrc: webImageNamed('Fabrics/MonogramThread/624.jpg'), price: null, company: '' },
        { optType: "Threads", key: '633', name: '633', imageSrc: webImageNamed('Fabrics/MonogramThread/633.jpg'), price: null, company: '' },
        { optType: "Threads", key: '714', name: '714', imageSrc: webImageNamed('Fabrics/MonogramThread/714.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1017', name: '1017', imageSrc: webImageNamed('Fabrics/MonogramThread/1017.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1034', name: '1034', imageSrc: webImageNamed('Fabrics/MonogramThread/1034.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1059', name: '1059', imageSrc: webImageNamed('Fabrics/MonogramThread/1059.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1093', name: '1093', imageSrc: webImageNamed('Fabrics/MonogramThread/1093.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1116', name: '1116', imageSrc: webImageNamed('Fabrics/MonogramThread/1116.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1161', name: '1161', imageSrc: webImageNamed('Fabrics/MonogramThread/1161.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1162', name: '1162', imageSrc: webImageNamed('Fabrics/MonogramThread/1162.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1173', name: '1173', imageSrc: webImageNamed('Fabrics/MonogramThread/1173.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1196', name: '1196', imageSrc: webImageNamed('Fabrics/MonogramThread/1196.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1203', name: '1203', imageSrc: webImageNamed('Fabrics/MonogramThread/1203.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3077', name: '3077', imageSrc: webImageNamed('Fabrics/MonogramThread/3077.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3099', name: '3099', imageSrc: webImageNamed('Fabrics/MonogramThread/3099.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3172', name: '3172', imageSrc: webImageNamed('Fabrics/MonogramThread/3172.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3334', name: '3334', imageSrc: webImageNamed('Fabrics/MonogramThread/3334.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3601', name: '3601', imageSrc: webImageNamed('Fabrics/MonogramThread/3601.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3618', name: '3618', imageSrc: webImageNamed('Fabrics/MonogramThread/3618.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3655', name: '3655', imageSrc: webImageNamed('Fabrics/MonogramThread/3655.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3687', name: '3687', imageSrc: webImageNamed('Fabrics/MonogramThread/3687.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3694', name: '3694', imageSrc: webImageNamed('Fabrics/MonogramThread/3694.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3701', name: '3701', imageSrc: webImageNamed('Fabrics/MonogramThread/3701.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3712', name: '3712', imageSrc: webImageNamed('Fabrics/MonogramThread/3712.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3720', name: '3720', imageSrc: webImageNamed('Fabrics/MonogramThread/3720.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3742', name: '3742', imageSrc: webImageNamed('Fabrics/MonogramThread/3742.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3747', name: '3747', imageSrc: webImageNamed('Fabrics/MonogramThread/3747.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3750', name: '3750', imageSrc: webImageNamed('Fabrics/MonogramThread/3750.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3813', name: '3813', imageSrc: webImageNamed('Fabrics/MonogramThread/3813.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'MG2', name: 'MG2', imageSrc: webImageNamed('Fabrics/MonogramThread/MG2.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'MS1', name: 'MS1', imageSrc: webImageNamed('Fabrics/MonogramThread/MS1.jpg'), price: null, company: '' },
    ]
    monogramThreadColor_Step = () => {
        return {
            name: 'Select Monogram Thread Color',
            key: this.STEP_KEYS.monogramThreadColor,
            opts: this.monogramThreadColor_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }



    // PANT FIT PREFERENCE
    pantFitPreference_Opts = [
        { optType: "PANT FIT PREFERENCE",  key: 'SLIM', name: 'SLIM', imageSrc: webImageNamed('StyleSelections/Suit/PantFitPreference/Slim.jpg') },
        { optType: "PANT FIT PREFERENCE",  key: 'COMFORT', name: 'COMFORT', imageSrc: webImageNamed('StyleSelections/Suit/PantFitPreference/Comfort.jpg') },
        { optType: "PANT FIT PREFERENCE",  key: 'LOOSE', name: 'LOOSE', imageSrc: webImageNamed('StyleSelections/Suit/PantFitPreference/Loose.jpg') },
    ]
    pantFitPreference_Step = () => {
        // Change images here with respect to button choices
        return {
            name: 'Pant Fit Preference',
            key: this.STEP_KEYS.pantFitPreference,
            opts: this.pantFitPreference_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // PANT PLEAT
    pantPleat_Opts = [
        { optType: "PANT PLEAT",  key: "PLAIN FRONT", name: 'PLAIN FRONT', imageSrc: webImageNamed('StyleSelections/Suit/Pleats/PLAINFRONT.jpg') },
        { optType: "PANT PLEAT",  key: "SINGLE PLEAT", name: 'SINGLE PLEAT', imageSrc: webImageNamed('StyleSelections/Suit/Pleats/SINGLEPLEAT.jpg') },
    ]
    pantPleat_Step = () => {
        // Change images here with respect to button choices
        return {
            name: 'Pant Pleat',
            key: this.STEP_KEYS.pantPleat,
            opts: this.pantPleat_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // PANT SIDE POCKETS
    pantSidePockets_Opts = [
        { optType: "PANT SIDE POCKETS",  key: 'STANDARD', name: 'STANDARD', imageSrc: webImageNamed('StyleSelections/Suit/Sidepockets/STANDARD.jpg') },
        { optType: "PANT SIDE POCKETS",  key: 'DOUBLE BESOM', name: 'DOUBLE BESOM', imageSrc: webImageNamed('StyleSelections/Suit/Sidepockets/DoubleBesom.jpg') },
        { optType: "PANT SIDE POCKETS",  key: 'JEAN STYLE', name: 'JEAN STYLE', imageSrc: webImageNamed('StyleSelections/Suit/Sidepockets/JeanStyle.jpg') },
    ]
    pantSidePockets_Step = () => {
        // Change images here with respect to button choices
        return {
            name: 'Pant Side Pockets',
            key: this.STEP_KEYS.pantSidePockets,
            opts: this.pantSidePockets_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }



    // PANT BACK POCKETS
    pantBackPockets_Opts = [
        { optType: "PANT BACK POCKETS",  key: 'NO POCKET', name: 'NO POCKET', imageSrc: webImageNamed('StyleSelections/Suit/Backpockets/1NONE.jpg') },
        { optType: "PANT BACK POCKETS",  key: 'BUTTON THROUGH LEFT', name: 'BUTTON THROUGH LEFT ', imageSrc: webImageNamed('StyleSelections/Suit/Backpockets/3ButtonThroughLEFT.jpg') },
        { optType: "PANT BACK POCKETS",  key: 'BUTTON THROUGH RIGHT ', name: 'BUTTON THROUGH RIGHT ', imageSrc: webImageNamed('StyleSelections/Suit/Backpockets/2ButtonThroughRIGHT.jpg') },
        { optType: "PANT BACK POCKETS",  key: 'BUTTON THROUGH BOTH POCKETS (STANDARD)', name: 'BUTTON THROUGH BOTH POCKETS (STANDARD)', imageSrc: webImageNamed('StyleSelections/Suit/Backpockets/4ButtonThroughTwoPockets.jpg') },
        { optType: "PANT BACK POCKETS",  key: 'FLAP LEFT', name: 'FLAP LEFT', imageSrc: webImageNamed('StyleSelections/Suit/Backpockets/6FlapLeft.jpg') },
        { optType: "PANT BACK POCKETS",  key: 'FLAP RIGHT', name: 'FLAP RIGHT', imageSrc: webImageNamed('StyleSelections/Suit/Backpockets/5FlapRight.jpg') },
        { optType: "PANT BACK POCKETS",  key: 'FLAP BOTH POCKETS', name: 'FLAP BOTH POCKETS', imageSrc: webImageNamed('StyleSelections/Suit/Backpockets/7FlapBothPockets.jpg') },
    ]
    pantBackPockets_Step = () => {
        // Change images here with respect to button choices
        return {
            name: 'Pant Back Pockets',
            key: this.STEP_KEYS.pantBackPockets,
            opts: this.pantBackPockets_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }



    // WAIST BAND TYPE
    waistBandType_Opts = [
        { optType: "WAIST BAND TYPE",  key: 'NONE', name: 'NONE', imageSrc: webImageNamed('StyleSelections/Suit/WaistBand/NONE.jpg') },
        { optType: "WAIST BAND TYPE",  key: 'BELT LOOP (STANDARD)', name: 'BELT LOOP (STANDARD)', imageSrc: webImageNamed('StyleSelections/Suit/WaistBand/BeltLoops.jpg') },
        { optType: "WAIST BAND TYPE",  key: 'SIDE ADJUSTER WITH BUCKLE', name: 'SIDE ADJUSTER WITH BUCKLE', imageSrc: webImageNamed('StyleSelections/Suit/WaistBand/SideADJUSTERwithBuckle.jpg') },
    ]
    waistBandType_Step = () => {
        // Change images here with respect to button choices
        return {
            name: 'Waist Band Type',
            key: this.STEP_KEYS.waistBandType,
            opts: this.waistBandType_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // PANT BOTTOM
    pantBottom_Opts = [
        { optType: this.STEP_KEYS.pantBottom,  key: 'NORMAL', name: 'NORMAL', imageSrc: webImageNamed('StyleSelections/Suit/Pantbottom/Plain.jpg') },
        { optType: this.STEP_KEYS.pantBottom,  key: 'SLIM CUFF (1 INCH)', name: 'SLIM CUFF (1 INCH)', imageSrc: webImageNamed('StyleSelections/Suit/Pantbottom/Slim.jpg') },
        { optType: this.STEP_KEYS.pantBottom,  key: 'MEDIUM CUFF (1.5 INCH)', name: 'MEDIUM CUFF (1.5 INCH)', imageSrc: webImageNamed('StyleSelections/Suit/Pantbottom/Medium.jpg') },
        { optType: this.STEP_KEYS.pantBottom,  key: 'WIDE CUFF (2 INCH)', name: 'WIDE CUFF (2 INCH)', imageSrc: webImageNamed('StyleSelections/Suit/Pantbottom/Wide.jpg') },
    ]
    pantBottom_Step = () => {
        // Change images here with respect to button choices
        return {
            name: 'Pant Bottom Opts',
            key: this.STEP_KEYS.pantBottom,
            opts: this.pantBottom_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // PANT MONOGRAM
    pantMonogram_Opts = [
        { optType: this.STEP_KEYS.pantMonogram,  key: 'NO MONOGRAM', name: 'NO MONOGRAM', price: null, imageSrc: null },
        { optType: this.STEP_KEYS.pantMonogram,  key: 'INSIDE WAISTBAND', name: 'INSIDE WAISTBAND',price: 10, imageSrc: webImageNamed('StyleSelections/Trouser/Monogram/MonogramInsideWaistBand.jpg') },
    ]
    pantMonogram_Step = () => {
        // Change images here with respect to button choices
        return {
            name: 'Pant Monogram',
            key: this.STEP_KEYS.pantMonogram,
            opts: this.pantMonogram_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }

    // NEW ENTRY
    pantMonogramThreadColor_Opts = [
        { optType: "Threads", key: '101', name: '101', imageSrc: webImageNamed('Fabrics/MonogramThread/101.jpg'), price: null, company: '' },
        { optType: "Threads", key: '102', name: '102', imageSrc: webImageNamed('Fabrics/MonogramThread/102.jpg'), price: null, company: '' },
        { optType: "Threads", key: '103', name: '103', imageSrc: webImageNamed('Fabrics/MonogramThread/103.jpg'), price: null, company: '' },
        { optType: "Threads", key: '138', name: '138', imageSrc: webImageNamed('Fabrics/MonogramThread/138.jpg'), price: null, company: '' },
        { optType: "Threads", key: '144', name: '144', imageSrc: webImageNamed('Fabrics/MonogramThread/144.jpg'), price: null, company: '' },
        { optType: "Threads", key: '145', name: '145', imageSrc: webImageNamed('Fabrics/MonogramThread/145.jpg'), price: null, company: '' },
        { optType: "Threads", key: '177', name: '177', imageSrc: webImageNamed('Fabrics/MonogramThread/177.jpg'), price: null, company: '' },
        { optType: "Threads", key: '189', name: '189', imageSrc: webImageNamed('Fabrics/MonogramThread/189.jpg'), price: null, company: '' },
        { optType: "Threads", key: '262', name: '262', imageSrc: webImageNamed('Fabrics/MonogramThread/262.jpg'), price: null, company: '' },
        { optType: "Threads", key: '312', name: '312', imageSrc: webImageNamed('Fabrics/MonogramThread/312.jpg'), price: null, company: '' },
        { optType: "Threads", key: '321', name: '321', imageSrc: webImageNamed('Fabrics/MonogramThread/321.jpg'), price: null, company: '' },
        { optType: "Threads", key: '352', name: '352', imageSrc: webImageNamed('Fabrics/MonogramThread/352.jpg'), price: null, company: '' },
        { optType: "Threads", key: '392', name: '392', imageSrc: webImageNamed('Fabrics/MonogramThread/392.jpg'), price: null, company: '' },
        { optType: "Threads", key: '432', name: '432', imageSrc: webImageNamed('Fabrics/MonogramThread/432.jpg'), price: null, company: '' },
        { optType: "Threads", key: '455', name: '455', imageSrc: webImageNamed('Fabrics/MonogramThread/455.jpg'), price: null, company: '' },
        { optType: "Threads", key: '489', name: '489', imageSrc: webImageNamed('Fabrics/MonogramThread/489.jpg'), price: null, company: '' },
        { optType: "Threads", key: '572', name: '572', imageSrc: webImageNamed('Fabrics/MonogramThread/572.jpg'), price: null, company: '' },
        { optType: "Threads", key: '598', name: '598', imageSrc: webImageNamed('Fabrics/MonogramThread/598.jpg'), price: null, company: '' },
        { optType: "Threads", key: '599', name: '599', imageSrc: webImageNamed('Fabrics/MonogramThread/599.jpg'), price: null, company: '' },
        { optType: "Threads", key: '624', name: '624', imageSrc: webImageNamed('Fabrics/MonogramThread/624.jpg'), price: null, company: '' },
        { optType: "Threads", key: '633', name: '633', imageSrc: webImageNamed('Fabrics/MonogramThread/633.jpg'), price: null, company: '' },
        { optType: "Threads", key: '714', name: '714', imageSrc: webImageNamed('Fabrics/MonogramThread/714.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1017', name: '1017', imageSrc: webImageNamed('Fabrics/MonogramThread/1017.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1034', name: '1034', imageSrc: webImageNamed('Fabrics/MonogramThread/1034.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1059', name: '1059', imageSrc: webImageNamed('Fabrics/MonogramThread/1059.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1093', name: '1093', imageSrc: webImageNamed('Fabrics/MonogramThread/1093.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1116', name: '1116', imageSrc: webImageNamed('Fabrics/MonogramThread/1116.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1161', name: '1161', imageSrc: webImageNamed('Fabrics/MonogramThread/1161.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1162', name: '1162', imageSrc: webImageNamed('Fabrics/MonogramThread/1162.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1173', name: '1173', imageSrc: webImageNamed('Fabrics/MonogramThread/1173.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1196', name: '1196', imageSrc: webImageNamed('Fabrics/MonogramThread/1196.jpg'), price: null, company: '' },
        { optType: "Threads", key: '1203', name: '1203', imageSrc: webImageNamed('Fabrics/MonogramThread/1203.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3077', name: '3077', imageSrc: webImageNamed('Fabrics/MonogramThread/3077.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3099', name: '3099', imageSrc: webImageNamed('Fabrics/MonogramThread/3099.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3172', name: '3172', imageSrc: webImageNamed('Fabrics/MonogramThread/3172.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3334', name: '3334', imageSrc: webImageNamed('Fabrics/MonogramThread/3334.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3601', name: '3601', imageSrc: webImageNamed('Fabrics/MonogramThread/3601.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3618', name: '3618', imageSrc: webImageNamed('Fabrics/MonogramThread/3618.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3655', name: '3655', imageSrc: webImageNamed('Fabrics/MonogramThread/3655.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3687', name: '3687', imageSrc: webImageNamed('Fabrics/MonogramThread/3687.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3694', name: '3694', imageSrc: webImageNamed('Fabrics/MonogramThread/3694.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3701', name: '3701', imageSrc: webImageNamed('Fabrics/MonogramThread/3701.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3712', name: '3712', imageSrc: webImageNamed('Fabrics/MonogramThread/3712.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3720', name: '3720', imageSrc: webImageNamed('Fabrics/MonogramThread/3720.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3742', name: '3742', imageSrc: webImageNamed('Fabrics/MonogramThread/3742.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3747', name: '3747', imageSrc: webImageNamed('Fabrics/MonogramThread/3747.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3750', name: '3750', imageSrc: webImageNamed('Fabrics/MonogramThread/3750.jpg'), price: null, company: '' },
        { optType: "Threads", key: '3813', name: '3813', imageSrc: webImageNamed('Fabrics/MonogramThread/3813.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'MG2', name: 'MG2', imageSrc: webImageNamed('Fabrics/MonogramThread/MG2.jpg'), price: null, company: '' },
        { optType: "Threads", key: 'MS1', name: 'MS1', imageSrc: webImageNamed('Fabrics/MonogramThread/MS1.jpg'), price: null, company: '' },
    ]

    pantMonogramThreadColor_Step = () => {
        return {
            name: 'Monogram Thread Color',
            key: this.STEP_KEYS.pantMonogramThreadColor,
            opts: this.pantMonogramThreadColor_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // CONTRAST OPTION
    contrast_Opts = [
        { optType: "CONTRAST OPTION",  key: 'NO', name: 'NO', imageSrc: webImageNamed('StyleSelections/Suit/Contrast/No.jpg') },
        { optType: "CONTRAST OPTION",  key: 'LAPEL CONTRAST ONLY', name: 'LAPEL CONTRAST ONLY ', imageSrc: webImageNamed('StyleSelections/Suit/Contrast/LapelOnly.jpg'), price: 100 },
        { optType: "CONTRAST OPTION",  key: 'TUXEDO CONTRAST (Lapel, Pocket Lining, Button)', name: 'TUXEDO CONTRAST (Lapel, Pocket Lining, Button)', imageSrc: webImageNamed('StyleSelections/Suit/Contrast/TuxedoContrast.jpg'), price: 100 },

    ]
    contrast_Step = () => {
        // Change images here with respect to button choices
        return {
            name: 'Contrast ?',
            key: this.STEP_KEYS.contrastOption,
            opts: this.contrast_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // CONTRAST FABRIC
    contrastFabricOpts = [
        // { optType: "CONTRAST FABRIC", key: 'BlackSatin', name: 'BlackSatin', imageSrc: webImageNamed('Fabrics/TuxedoContrast/BlackSatin.jpg'), company: '' },
        // { optType: "CONTRAST FABRIC", key: 'IvorySatin', name: 'IvorySatin', imageSrc: webImageNamed('Fabrics/TuxedoContrast/IvorySatin.jpg'), company: '' },
        // { optType: "CONTRAST FABRIC", key: 'NavySatin', name: 'NavySatin', imageSrc: webImageNamed('Fabrics/TuxedoContrast/NavySatin.jpeg'), company: '' },
        // { optType: "CONTRAST FABRIC", key: 'WhiteSatin', name: 'WhiteSatin', imageSrc: webImageNamed('Fabrics/TuxedoContrast/WhiteSatin.jpg'), company: '' },

        { optType: "CONTRAST FABRIC", key: 'DXN030A', name: 'DXN030A', imageSrc: webImageNamed('Fabrics/SatinFelt/DXN030A.jpg'), price: null , company: '' },
        { optType: "CONTRAST FABRIC", key: 'DXN031A', name: 'DXN031A', imageSrc: webImageNamed('Fabrics/SatinFelt/DXN031A.jpg'), price: null , company: '' },
        { optType: "CONTRAST FABRIC", key: 'DXN032A', name: 'DXN032A', imageSrc: webImageNamed('Fabrics/SatinFelt/DXN032A.jpg'), price: null , company: '' },
        { optType: "CONTRAST FABRIC", key: 'DXN044A', name: 'DXN044A', imageSrc: webImageNamed('Fabrics/SatinFelt/DXN044A.jpg'), price: null , company: '' },
        { optType: "CONTRAST FABRIC", key: 'DXN045A', name: 'DXN045A', imageSrc: webImageNamed('Fabrics/SatinFelt/DXN045A.jpg'), price: null , company: '' },
        { optType: "CONTRAST FABRIC", key: 'DXN047A', name: 'DXN047A', imageSrc: webImageNamed('Fabrics/SatinFelt/DXN047A.jpg'), price: null , company: '' },
        { optType: "CONTRAST FABRIC", key: 'DXN048A', name: 'DXN048A', imageSrc: webImageNamed('Fabrics/SatinFelt/DXN048A.jpg'), price: null , company: '' },

    ]
    contrastFabricStep = () => {
        // Change images here with respect to button choices
        return {
            name: 'Choose Fabric for Contrast',
            key: this.STEP_KEYS.contrastFabric,
            opts: this.contrastFabricOpts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }



    // pantMonogramThreadColor
    // fabricLining
    // suitButtons

    // FABRIC -- written last

    // FABRIC LINING
    fabricLining_Opts = [
        // { optType: "Fabric Lining", key: 'A01', name: 'A01', imageSrc: webImageNamed('Fabrics/SuitLining/A01.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '4', name: '4', imageSrc: webImageNamed('Fabrics/LiningFabrics/4.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '5', name: '5', imageSrc: webImageNamed('Fabrics/LiningFabrics/5.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '11', name: '11', imageSrc: webImageNamed('Fabrics/LiningFabrics/11.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '12', name: '12', imageSrc: webImageNamed('Fabrics/LiningFabrics/12.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '13', name: '13', imageSrc: webImageNamed('Fabrics/LiningFabrics/13.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '19', name: '19', imageSrc: webImageNamed('Fabrics/LiningFabrics/19.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '20', name: '20', imageSrc: webImageNamed('Fabrics/LiningFabrics/20.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '21', name: '21', imageSrc: webImageNamed('Fabrics/LiningFabrics/21.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '22', name: '22', imageSrc: webImageNamed('Fabrics/LiningFabrics/22.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '23', name: '23', imageSrc: webImageNamed('Fabrics/LiningFabrics/23.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '24', name: '24', imageSrc: webImageNamed('Fabrics/LiningFabrics/24.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '25', name: '25', imageSrc: webImageNamed('Fabrics/LiningFabrics/25.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '26', name: '26', imageSrc: webImageNamed('Fabrics/LiningFabrics/26.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '29', name: '29', imageSrc: webImageNamed('Fabrics/LiningFabrics/29.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '30', name: '30', imageSrc: webImageNamed('Fabrics/LiningFabrics/30.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '31', name: '31', imageSrc: webImageNamed('Fabrics/LiningFabrics/31.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '32', name: '32', imageSrc: webImageNamed('Fabrics/LiningFabrics/32.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '33', name: '33', imageSrc: webImageNamed('Fabrics/LiningFabrics/33.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '34', name: '34', imageSrc: webImageNamed('Fabrics/LiningFabrics/34.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '36', name: '36', imageSrc: webImageNamed('Fabrics/LiningFabrics/36.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '39', name: '39', imageSrc: webImageNamed('Fabrics/LiningFabrics/39.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '40', name: '40', imageSrc: webImageNamed('Fabrics/LiningFabrics/40.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '44', name: '44', imageSrc: webImageNamed('Fabrics/LiningFabrics/44.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '45', name: '45', imageSrc: webImageNamed('Fabrics/LiningFabrics/45.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '47', name: '47', imageSrc: webImageNamed('Fabrics/LiningFabrics/47.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '48', name: '48', imageSrc: webImageNamed('Fabrics/LiningFabrics/48.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '49', name: '49', imageSrc: webImageNamed('Fabrics/LiningFabrics/49.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '50', name: '50', imageSrc: webImageNamed('Fabrics/LiningFabrics/50.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '53', name: '53', imageSrc: webImageNamed('Fabrics/LiningFabrics/53.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '54', name: '54', imageSrc: webImageNamed('Fabrics/LiningFabrics/54.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '56', name: '56', imageSrc: webImageNamed('Fabrics/LiningFabrics/56.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '59', name: '59', imageSrc: webImageNamed('Fabrics/LiningFabrics/59.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '60', name: '60', imageSrc: webImageNamed('Fabrics/LiningFabrics/60.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '63', name: '63', imageSrc: webImageNamed('Fabrics/LiningFabrics/63.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '64', name: '64', imageSrc: webImageNamed('Fabrics/LiningFabrics/64.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '67', name: '67', imageSrc: webImageNamed('Fabrics/LiningFabrics/67.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '68', name: '68', imageSrc: webImageNamed('Fabrics/LiningFabrics/68.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '70', name: '70', imageSrc: webImageNamed('Fabrics/LiningFabrics/70.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '71', name: '71', imageSrc: webImageNamed('Fabrics/LiningFabrics/71.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '75', name: '75', imageSrc: webImageNamed('Fabrics/LiningFabrics/75.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '76', name: '76', imageSrc: webImageNamed('Fabrics/LiningFabrics/76.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '78', name: '78', imageSrc: webImageNamed('Fabrics/LiningFabrics/78.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '81', name: '81', imageSrc: webImageNamed('Fabrics/LiningFabrics/81.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '83', name: '83', imageSrc: webImageNamed('Fabrics/LiningFabrics/83.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '97', name: '97', imageSrc: webImageNamed('Fabrics/LiningFabrics/97.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '101', name: '101', imageSrc: webImageNamed('Fabrics/LiningFabrics/101.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '102', name: '102', imageSrc: webImageNamed('Fabrics/LiningFabrics/102.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '103', name: '103', imageSrc: webImageNamed('Fabrics/LiningFabrics/103.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '106', name: '106', imageSrc: webImageNamed('Fabrics/LiningFabrics/106.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '108', name: '108', imageSrc: webImageNamed('Fabrics/LiningFabrics/108.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '109', name: '109', imageSrc: webImageNamed('Fabrics/LiningFabrics/109.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '111', name: '111', imageSrc: webImageNamed('Fabrics/LiningFabrics/111.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '112', name: '112', imageSrc: webImageNamed('Fabrics/LiningFabrics/112.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '114', name: '114', imageSrc: webImageNamed('Fabrics/LiningFabrics/114.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '122', name: '122', imageSrc: webImageNamed('Fabrics/LiningFabrics/122.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '125', name: '125', imageSrc: webImageNamed('Fabrics/LiningFabrics/125.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '128', name: '128', imageSrc: webImageNamed('Fabrics/LiningFabrics/128.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '129', name: '129', imageSrc: webImageNamed('Fabrics/LiningFabrics/129.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '130', name: '130', imageSrc: webImageNamed('Fabrics/LiningFabrics/130.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '131', name: '131', imageSrc: webImageNamed('Fabrics/LiningFabrics/131.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '133', name: '133', imageSrc: webImageNamed('Fabrics/LiningFabrics/133.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '134', name: '134', imageSrc: webImageNamed('Fabrics/LiningFabrics/134.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '139', name: '139', imageSrc: webImageNamed('Fabrics/LiningFabrics/139.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '143', name: '143', imageSrc: webImageNamed('Fabrics/LiningFabrics/143.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '144', name: '144', imageSrc: webImageNamed('Fabrics/LiningFabrics/144.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '145', name: '145', imageSrc: webImageNamed('Fabrics/LiningFabrics/145.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '146', name: '146', imageSrc: webImageNamed('Fabrics/LiningFabrics/146.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '147', name: '147', imageSrc: webImageNamed('Fabrics/LiningFabrics/147.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '148', name: '148', imageSrc: webImageNamed('Fabrics/LiningFabrics/148.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '151', name: '151', imageSrc: webImageNamed('Fabrics/LiningFabrics/151.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '154', name: '154', imageSrc: webImageNamed('Fabrics/LiningFabrics/154.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '157', name: '157', imageSrc: webImageNamed('Fabrics/LiningFabrics/157.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '181', name: '181', imageSrc: webImageNamed('Fabrics/LiningFabrics/181.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '182', name: '182', imageSrc: webImageNamed('Fabrics/LiningFabrics/182.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '187', name: '187', imageSrc: webImageNamed('Fabrics/LiningFabrics/187.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '190', name: '190', imageSrc: webImageNamed('Fabrics/LiningFabrics/190.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '192', name: '192', imageSrc: webImageNamed('Fabrics/LiningFabrics/192.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '201', name: '201', imageSrc: webImageNamed('Fabrics/LiningFabrics/201.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '202', name: '202', imageSrc: webImageNamed('Fabrics/LiningFabrics/202.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '212', name: '212', imageSrc: webImageNamed('Fabrics/LiningFabrics/212.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '219', name: '219', imageSrc: webImageNamed('Fabrics/LiningFabrics/219.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '220', name: '220', imageSrc: webImageNamed('Fabrics/LiningFabrics/220.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '225', name: '225', imageSrc: webImageNamed('Fabrics/LiningFabrics/225.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '226', name: '226', imageSrc: webImageNamed('Fabrics/LiningFabrics/226.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '227', name: '227', imageSrc: webImageNamed('Fabrics/LiningFabrics/227.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '228', name: '228', imageSrc: webImageNamed('Fabrics/LiningFabrics/228.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '231', name: '231', imageSrc: webImageNamed('Fabrics/LiningFabrics/231.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '232', name: '232', imageSrc: webImageNamed('Fabrics/LiningFabrics/232.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '234', name: '234', imageSrc: webImageNamed('Fabrics/LiningFabrics/234.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '237', name: '237', imageSrc: webImageNamed('Fabrics/LiningFabrics/237.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '238', name: '238', imageSrc: webImageNamed('Fabrics/LiningFabrics/238.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '239', name: '239', imageSrc: webImageNamed('Fabrics/LiningFabrics/239.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '240', name: '240', imageSrc: webImageNamed('Fabrics/LiningFabrics/240.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '241', name: '241', imageSrc: webImageNamed('Fabrics/LiningFabrics/241.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '243', name: '243', imageSrc: webImageNamed('Fabrics/LiningFabrics/243.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '245', name: '245', imageSrc: webImageNamed('Fabrics/LiningFabrics/245.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '246', name: '246', imageSrc: webImageNamed('Fabrics/LiningFabrics/246.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '247', name: '247', imageSrc: webImageNamed('Fabrics/LiningFabrics/247.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '248', name: '248', imageSrc: webImageNamed('Fabrics/LiningFabrics/248.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '254', name: '254', imageSrc: webImageNamed('Fabrics/LiningFabrics/254.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '255', name: '255', imageSrc: webImageNamed('Fabrics/LiningFabrics/255.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '257', name: '257', imageSrc: webImageNamed('Fabrics/LiningFabrics/257.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '259', name: '259', imageSrc: webImageNamed('Fabrics/LiningFabrics/259.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '261', name: '261', imageSrc: webImageNamed('Fabrics/LiningFabrics/261.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '262', name: '262', imageSrc: webImageNamed('Fabrics/LiningFabrics/262.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '267', name: '267', imageSrc: webImageNamed('Fabrics/LiningFabrics/267.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '268', name: '268', imageSrc: webImageNamed('Fabrics/LiningFabrics/268.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '269', name: '269', imageSrc: webImageNamed('Fabrics/LiningFabrics/269.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '270', name: '270', imageSrc: webImageNamed('Fabrics/LiningFabrics/270.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '271', name: '271', imageSrc: webImageNamed('Fabrics/LiningFabrics/271.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '272', name: '272', imageSrc: webImageNamed('Fabrics/LiningFabrics/272.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '273', name: '273', imageSrc: webImageNamed('Fabrics/LiningFabrics/273.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '274', name: '274', imageSrc: webImageNamed('Fabrics/LiningFabrics/274.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '275', name: '275', imageSrc: webImageNamed('Fabrics/LiningFabrics/275.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '276', name: '276', imageSrc: webImageNamed('Fabrics/LiningFabrics/276.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '277', name: '277', imageSrc: webImageNamed('Fabrics/LiningFabrics/277.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '278', name: '278', imageSrc: webImageNamed('Fabrics/LiningFabrics/278.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '289', name: '289', imageSrc: webImageNamed('Fabrics/LiningFabrics/289.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '290', name: '290', imageSrc: webImageNamed('Fabrics/LiningFabrics/290.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '291', name: '291', imageSrc: webImageNamed('Fabrics/LiningFabrics/291.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '292', name: '292', imageSrc: webImageNamed('Fabrics/LiningFabrics/292.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '293', name: '293', imageSrc: webImageNamed('Fabrics/LiningFabrics/293.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '294', name: '294', imageSrc: webImageNamed('Fabrics/LiningFabrics/294.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '295', name: '295', imageSrc: webImageNamed('Fabrics/LiningFabrics/295.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '296', name: '296', imageSrc: webImageNamed('Fabrics/LiningFabrics/296.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '297', name: '297', imageSrc: webImageNamed('Fabrics/LiningFabrics/297.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '298', name: '298', imageSrc: webImageNamed('Fabrics/LiningFabrics/298.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '299', name: '299', imageSrc: webImageNamed('Fabrics/LiningFabrics/299.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '300', name: '300', imageSrc: webImageNamed('Fabrics/LiningFabrics/300.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '301', name: '301', imageSrc: webImageNamed('Fabrics/LiningFabrics/301.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '302', name: '302', imageSrc: webImageNamed('Fabrics/LiningFabrics/302.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '303', name: '303', imageSrc: webImageNamed('Fabrics/LiningFabrics/303.jpg'), price: null, company: '' },
    ]
    fabricLining_Step = () => {
        // Change images here with respect to button choices
        return {
            name: 'Choose Fabric Lining',
            key: this.STEP_KEYS.fabricLining,
            opts: this.fabricLining_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }

    // SUIT BUTTONS
    suitButtons_Opts = [
        { optType: "Suit Buttons", key: 'KB001', name: 'KB001', imageSrc: webImageNamed('Fabrics/SuitButtons/KB001.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KB002', name: 'KB002', imageSrc: webImageNamed('Fabrics/SuitButtons/KB002.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KB008', name: 'KB008', imageSrc: webImageNamed('Fabrics/SuitButtons/KB008.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KB010', name: 'KB010', imageSrc: webImageNamed('Fabrics/SuitButtons/KB010.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KB011', name: 'KB011', imageSrc: webImageNamed('Fabrics/SuitButtons/KB011.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KB016', name: 'KB016', imageSrc: webImageNamed('Fabrics/SuitButtons/KB016.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KB020', name: 'KB020', imageSrc: webImageNamed('Fabrics/SuitButtons/KB020.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KB021', name: 'KB021', imageSrc: webImageNamed('Fabrics/SuitButtons/KB021.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KB022', name: 'KB022', imageSrc: webImageNamed('Fabrics/SuitButtons/KB022.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KB023', name: 'KB023', imageSrc: webImageNamed('Fabrics/SuitButtons/KB023.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KB025', name: 'KB025', imageSrc: webImageNamed('Fabrics/SuitButtons/KB025.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KB026', name: 'KB026', imageSrc: webImageNamed('Fabrics/SuitButtons/KB026.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KB027', name: 'KB027', imageSrc: webImageNamed('Fabrics/SuitButtons/KB027.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KB028', name: 'KB028', imageSrc: webImageNamed('Fabrics/SuitButtons/KB028.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KB029', name: 'KB029', imageSrc: webImageNamed('Fabrics/SuitButtons/KB029.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KB030', name: 'KB030', imageSrc: webImageNamed('Fabrics/SuitButtons/KB030.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG024', name: 'KG024', imageSrc: webImageNamed('Fabrics/SuitButtons/KG024.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG027', name: 'KG027', imageSrc: webImageNamed('Fabrics/SuitButtons/KG027.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG032', name: 'KG032', imageSrc: webImageNamed('Fabrics/SuitButtons/KG032.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG053', name: 'KG053', imageSrc: webImageNamed('Fabrics/SuitButtons/KG053.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG054', name: 'KG054', imageSrc: webImageNamed('Fabrics/SuitButtons/KG054.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG055', name: 'KG055', imageSrc: webImageNamed('Fabrics/SuitButtons/KG055.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG056', name: 'KG056', imageSrc: webImageNamed('Fabrics/SuitButtons/KG056.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG057', name: 'KG057', imageSrc: webImageNamed('Fabrics/SuitButtons/KG057.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG062', name: 'KG062', imageSrc: webImageNamed('Fabrics/SuitButtons/KG062.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG064', name: 'KG064', imageSrc: webImageNamed('Fabrics/SuitButtons/KG064.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG068', name: 'KG068', imageSrc: webImageNamed('Fabrics/SuitButtons/KG068.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG069', name: 'KG069', imageSrc: webImageNamed('Fabrics/SuitButtons/KG069.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG157', name: 'KG157', imageSrc: webImageNamed('Fabrics/SuitButtons/KG157.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG168', name: 'KG168', imageSrc: webImageNamed('Fabrics/SuitButtons/KG168.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG178', name: 'KG178', imageSrc: webImageNamed('Fabrics/SuitButtons/KG178.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG190', name: 'KG190', imageSrc: webImageNamed('Fabrics/SuitButtons/KG190.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG191', name: 'KG191', imageSrc: webImageNamed('Fabrics/SuitButtons/KG191.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG192', name: 'KG192', imageSrc: webImageNamed('Fabrics/SuitButtons/KG192.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG195', name: 'KG195', imageSrc: webImageNamed('Fabrics/SuitButtons/KG195.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG196', name: 'KG196', imageSrc: webImageNamed('Fabrics/SuitButtons/KG196.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG197', name: 'KG197', imageSrc: webImageNamed('Fabrics/SuitButtons/KG197.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG198', name: 'KG198', imageSrc: webImageNamed('Fabrics/SuitButtons/KG198.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG199', name: 'KG199', imageSrc: webImageNamed('Fabrics/SuitButtons/KG199.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG202', name: 'KG202', imageSrc: webImageNamed('Fabrics/SuitButtons/KG202.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG203', name: 'KG203', imageSrc: webImageNamed('Fabrics/SuitButtons/KG203.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG204', name: 'KG204', imageSrc: webImageNamed('Fabrics/SuitButtons/KG204.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG206', name: 'KG206', imageSrc: webImageNamed('Fabrics/SuitButtons/KG206.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KG212', name: 'KG212', imageSrc: webImageNamed('Fabrics/SuitButtons/KG212.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KNJ011', name: 'KNJ011', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ011.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KNJ012', name: 'KNJ012', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ012.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KNJ013', name: 'KNJ013', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ013.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KNJ014', name: 'KNJ014', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ014.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KNJ023', name: 'KNJ023', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ023.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KNJ024', name: 'KNJ024', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ024.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KNJ030', name: 'KNJ030', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ030.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KNJ031', name: 'KNJ031', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ031.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KNJ033', name: 'KNJ033', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ033.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KNJ036', name: 'KNJ036', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ036.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KNJ039', name: 'KNJ039', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ039.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KQ022', name: 'KQ022', imageSrc: webImageNamed('Fabrics/SuitButtons/KQ022.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ133', name: 'KSZ133', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ133.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ134', name: 'KSZ134', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ134.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ137', name: 'KSZ137', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ137.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ138', name: 'KSZ138', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ138.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ139', name: 'KSZ139', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ139.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ140', name: 'KSZ140', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ140.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ150', name: 'KSZ150', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ150.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ151', name: 'KSZ151', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ151.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ152', name: 'KSZ152', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ152.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ153', name: 'KSZ153', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ153.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ154', name: 'KSZ154', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ154.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ155', name: 'KSZ155', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ155.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ161', name: 'KSZ161', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ161.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ162', name: 'KSZ162', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ162.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ165', name: 'KSZ165', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ165.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ175', name: 'KSZ175', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ175.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ176', name: 'KSZ176', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ176.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ177', name: 'KSZ177', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ177.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ191', name: 'KSZ191', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ191.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ192', name: 'KSZ192', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ192.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ193', name: 'KSZ193', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ193.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ195', name: 'KSZ195', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ195.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ196', name: 'KSZ196', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ196.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ197', name: 'KSZ197', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ197.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ198', name: 'KSZ198', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ198.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ199', name: 'KSZ199', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ199.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ200', name: 'KSZ200', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ200.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ201', name: 'KSZ201', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ201.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ205', name: 'KSZ205', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ205.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ206', name: 'KSZ206', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ206.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ207', name: 'KSZ207', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ207.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ220', name: 'KSZ220', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ220.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KSZ221', name: 'KSZ221', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ221.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW001', name: 'KW001', imageSrc: webImageNamed('Fabrics/SuitButtons/KW001.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW002', name: 'KW002', imageSrc: webImageNamed('Fabrics/SuitButtons/KW002.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW003', name: 'KW003', imageSrc: webImageNamed('Fabrics/SuitButtons/KW003.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW004', name: 'KW004', imageSrc: webImageNamed('Fabrics/SuitButtons/KW004.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW005', name: 'KW005', imageSrc: webImageNamed('Fabrics/SuitButtons/KW005.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW006', name: 'KW006', imageSrc: webImageNamed('Fabrics/SuitButtons/KW006.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW017', name: 'KW017', imageSrc: webImageNamed('Fabrics/SuitButtons/KW017.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW042', name: 'KW042', imageSrc: webImageNamed('Fabrics/SuitButtons/KW042.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW043', name: 'KW043', imageSrc: webImageNamed('Fabrics/SuitButtons/KW043.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW044', name: 'KW044', imageSrc: webImageNamed('Fabrics/SuitButtons/KW044.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW045', name: 'KW045', imageSrc: webImageNamed('Fabrics/SuitButtons/KW045.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW063', name: 'KW063', imageSrc: webImageNamed('Fabrics/SuitButtons/KW063.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW065', name: 'KW065', imageSrc: webImageNamed('Fabrics/SuitButtons/KW065.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW066', name: 'KW066', imageSrc: webImageNamed('Fabrics/SuitButtons/KW066.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW067', name: 'KW067', imageSrc: webImageNamed('Fabrics/SuitButtons/KW067.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW068', name: 'KW068', imageSrc: webImageNamed('Fabrics/SuitButtons/KW068.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW069', name: 'KW069', imageSrc: webImageNamed('Fabrics/SuitButtons/KW069.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW070', name: 'KW070', imageSrc: webImageNamed('Fabrics/SuitButtons/KW070.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'KW071', name: 'KW071', imageSrc: webImageNamed('Fabrics/SuitButtons/KW071.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'QMJQZ03', name: 'QMJQZ03', imageSrc: webImageNamed('Fabrics/SuitButtons/QMJQZ03.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'QZMAB01', name: 'QZMAB01', imageSrc: webImageNamed('Fabrics/SuitButtons/QZMAB01.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'QZMAB02', name: 'QZMAB02', imageSrc: webImageNamed('Fabrics/SuitButtons/QZMAB02.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'QZMAB03', name: 'QZMAB03', imageSrc: webImageNamed('Fabrics/SuitButtons/QZMAB03.jpg'), price: null, company: '' },
        { optType: "Suit Buttons", key: 'QZMAB08', name: 'QZMAB08', imageSrc: webImageNamed('Fabrics/SuitButtons/QZMAB08.jpg'), price: null, company: '' },
    ]
    suitButtons_Step = () => {
        // Change images here with respect to button choices
        return {
            name: 'Choose Suit Buttons',
            key: this.STEP_KEYS.suitButtons,
            opts: this.suitButtons_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }
  


// TONE
 toneOpts = [
    { optType: "TONE",  key: this.TONE_KEYS.AllSeasons1, name: 'All Seasons 1', imageSrc: webImageNamed('Fabrics/FabricCategory/AllSeason.jpg') },
    { optType: "TONE",  key: this.TONE_KEYS.AllSeasons2, name: 'All Seasons 2', imageSrc: webImageNamed('Fabrics/FabricCategory/AllSeason.jpg') },
    { optType: "TONE",  key: this.TONE_KEYS.AllSeasons3, name: 'All Seasons 3', imageSrc: webImageNamed('Fabrics/FabricCategory/AllSeason.jpg') },
    { optType: "TONE",  key: this.TONE_KEYS.AllSeasons4, name: 'All Seasons 4', imageSrc: webImageNamed('Fabrics/FabricCategory/AllSeason.jpg') },
    { optType: "TONE",  key: this.TONE_KEYS.FallWinter, name: 'Fall Winter', imageSrc: webImageNamed('Fabrics/FabricCategory/Fall%26Winter.jpg') },
    // { optType: "TONE",  key: this.TONE_KEYS.SpringSummer, name: 'Spring Summer', imageSrc: webImageNamed('Fabrics/FabricCategory/Spring%26Summer.jpg') },
    // { optType: "TONE",  key: this.TONE_KEYS.JacquardFabrics, name: 'Jacquard Fabrics', imageSrc: webImageNamed('Fabrics/FabricCategory/JackardFabric.jpg') },
    // { optType: "TONE",  key: this.TONE_KEYS.WaterResistant, name: 'Water Resistant (All Seasons)', imageSrc: webImageNamed('Fabrics/FabricCategory/WaterResistant.jpg') },
    // { optType: "TONE",  key: this.TONE_KEYS.TuxedoContrast, name: 'Tuxedo Contrast', imageSrc: webImageNamed('playful') }
]
 toneStep = () => {
    // Change images here with respect to button choices
    return {
        name: 'Choose Fabric Collection',
        key: this.STEP_KEYS.tone,
        opts: this.toneOpts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '3by4',
        skipable: false,
        paddingLess: false,
        boldText: false
    }
}






// FABRIC, ["SHIRT"], []
 fabric_AllSeasons1 = [
    { optType: "Fabric", key: 'DBK052A', name: 'DBK052A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBK052A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBK053A', name: 'DBK053A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBK053A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBK504A', name: 'DBK504A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBK504A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBK544A', name: 'DBK544A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBK544A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBL508A', name: 'DBL508A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBL508A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBL622A', name: 'DBL622A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBL622A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBL646A', name: 'DBL646A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBL646A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBL647A', name: 'DBL647A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBL647A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBL648A', name: 'DBL648A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBL648A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBL650A', name: 'DBL650A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBL650A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBM187A', name: 'DBM187A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM187A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBM560A', name: 'DBM560A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM560A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBM561A', name: 'DBM561A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM561A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBM562A', name: 'DBM562A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM562A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBM582A', name: 'DBM582A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM582A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBM587A', name: 'DBM587A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM587A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBM588A', name: 'DBM588A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM588A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBM590A', name: 'DBM590A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM590A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBM599A', name: 'DBM599A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM599A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBM601A', name: 'DBM601A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM601A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBM619A', name: 'DBM619A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM619A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBM706A', name: 'DBM706A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM706A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBM707A', name: 'DBM707A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM707A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBM742A', name: 'DBM742A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM742A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN233A', name: 'DBN233A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN233A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN294A', name: 'DBN294A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN294A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN296A', name: 'DBN296A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN296A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN314A', name: 'DBN314A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN314A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN315A', name: 'DBN315A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN315A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN317A', name: 'DBN317A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN317A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN318A', name: 'DBN318A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN318A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN319A', name: 'DBN319A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN319A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN337A', name: 'DBN337A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN337A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN342A', name: 'DBN342A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN342A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN530A', name: 'DBN530A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN530A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN801A', name: 'DBN801A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN801A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN802A', name: 'DBN802A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN802A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN803A', name: 'DBN803A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN803A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN804A', name: 'DBN804A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN804A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN805A', name: 'DBN805A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN805A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN807A', name: 'DBN807A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN807A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN808A', name: 'DBN808A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN808A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN839A', name: 'DBN839A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN839A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN840A', name: 'DBN840A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN840A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN889A', name: 'DBN889A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN889A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN890A', name: 'DBN890A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN890A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN910A', name: 'DBN910A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN910A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN975A', name: 'DBN975A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN975A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP323A', name: 'DBP323A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP323A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP325A', name: 'DBP325A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP325A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP536A', name: 'DBP536A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP536A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP537A', name: 'DBP537A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP537A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP625A', name: 'DBP625A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP625A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP626A', name: 'DBP626A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP626A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP627A', name: 'DBP627A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP627A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP628A', name: 'DBP628A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP628A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP629A', name: 'DBP629A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP629A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP639A', name: 'DBP639A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP639A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP643A', name: 'DBP643A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP643A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP645A', name: 'DBP645A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP645A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP658A', name: 'DBP658A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP658A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP659A', name: 'DBP659A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP659A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP660A', name: 'DBP660A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP660A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP661A', name: 'DBP661A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP661A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP662A', name: 'DBP662A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP662A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP663A', name: 'DBP663A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP663A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP665A', name: 'DBP665A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP665A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP668A', name: 'DBP668A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP668A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP669A', name: 'DBP669A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP669A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP671A', name: 'DBP671A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP671A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP672A', name: 'DBP672A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP672A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP673A', name: 'DBP673A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP673A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP674A', name: 'DBP674A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP674A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP676A', name: 'DBP676A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP676A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP677A', name: 'DBP677A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP677A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP678A', name: 'DBP678A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP678A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP681A', name: 'DBP681A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP681A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP683A', name: 'DBP683A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP683A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP684A', name: 'DBP684A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP684A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP686A', name: 'DBP686A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP686A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP688A', name: 'DBP688A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP688A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP689A', name: 'DBP689A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP689A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP690A', name: 'DBP690A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP690A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP691A', name: 'DBP691A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP691A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP693A', name: 'DBP693A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP693A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP697A', name: 'DBP697A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP697A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP702A', name: 'DBP702A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP702A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP703A', name: 'DBP703A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP703A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP704A', name: 'DBP704A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP704A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP705A', name: 'DBP705A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP705A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP706A', name: 'DBP706A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP706A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP707A', name: 'DBP707A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP707A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP737A', name: 'DBP737A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP737A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP738A', name: 'DBP738A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP738A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP739A', name: 'DBP739A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP739A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP740A', name: 'DBP740A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP740A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP741A', name: 'DBP741A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP741A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP748A', name: 'DBP748A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP748A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP751A', name: 'DBP751A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP751A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP752A', name: 'DBP752A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP752A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP753A', name: 'DBP753A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP753A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP754A', name: 'DBP754A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP754A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP942A', name: 'DBP942A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP942A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBP947A', name: 'DBP947A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP947A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ567A', name: 'DBQ567A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ567A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ568A', name: 'DBQ568A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ568A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ569A', name: 'DBQ569A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ569A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ662A', name: 'DBQ662A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ662A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ786A', name: 'DBQ786A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ786A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ787A', name: 'DBQ787A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ787A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ788A', name: 'DBQ788A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ788A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ789A', name: 'DBQ789A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ789A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ790A', name: 'DBQ790A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ790A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ791A', name: 'DBQ791A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ791A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ793A', name: 'DBQ793A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ793A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ794A', name: 'DBQ794A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ794A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ795A', name: 'DBQ795A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ795A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ796A', name: 'DBQ796A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ796A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ797A', name: 'DBQ797A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ797A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ801A', name: 'DBQ801A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ801A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ803A', name: 'DBQ803A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ803A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ810A', name: 'DBQ810A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ810A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ811A', name: 'DBQ811A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ811A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ812A', name: 'DBQ812A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ812A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ813A', name: 'DBQ813A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ813A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ815A', name: 'DBQ815A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ815A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ817A', name: 'DBQ817A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ817A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBQ818A', name: 'DBQ818A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ818A.jpg'), price: 1200, company: '' },

]

 fabric_AllSeasons2 = [
    { optType: "Fabric", key: 'DBR109A', name: 'DBR109A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR109A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR110A', name: 'DBR110A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR110A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR111A', name: 'DBR111A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR111A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR112A', name: 'DBR112A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR112A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR113A', name: 'DBR113A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR113A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR115A', name: 'DBR115A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR115A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR116A', name: 'DBR116A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR116A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR117A', name: 'DBR117A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR117A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR118A', name: 'DBR118A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR118A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR119A', name: 'DBR119A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR119A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR120A', name: 'DBR120A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR120A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR128A', name: 'DBR128A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR128A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR136A', name: 'DBR136A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR136A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR137A', name: 'DBR137A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR137A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR140A', name: 'DBR140A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR140A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR179A', name: 'DBR179A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR179A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR180A', name: 'DBR180A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR180A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR181A', name: 'DBR181A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR181A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR182A', name: 'DBR182A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR182A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR183A', name: 'DBR183A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR183A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR186A', name: 'DBR186A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR186A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR191A', name: 'DBR191A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR191A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR192A', name: 'DBR192A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR192A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR204A', name: 'DBR204A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR204A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR205A', name: 'DBR205A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR205A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR207A', name: 'DBR207A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR207A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR210A', name: 'DBR210A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR210A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR219A', name: 'DBR219A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR219A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR220A', name: 'DBR220A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR220A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR229A', name: 'DBR229A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR229A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR230A', name: 'DBR230A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR230A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR231A', name: 'DBR231A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR231A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR232A', name: 'DBR232A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR232A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR233A', name: 'DBR233A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR233A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR234A', name: 'DBR234A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR234A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR235A', name: 'DBR235A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR235A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR253A', name: 'DBR253A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR253A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS131A', name: 'DBS131A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS131A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS132A', name: 'DBS132A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS132A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS133A', name: 'DBS133A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS133A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS134A', name: 'DBS134A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS134A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS135A', name: 'DBS135A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS135A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS136A', name: 'DBS136A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS136A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS137A', name: 'DBS137A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS137A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS138A', name: 'DBS138A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS138A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS139A', name: 'DBS139A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS139A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS140A', name: 'DBS140A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS140A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS141A', name: 'DBS141A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS141A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS142A', name: 'DBS142A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS142A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS144A', name: 'DBS144A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS144A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS146A', name: 'DBS146A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS146A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS147A', name: 'DBS147A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS147A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS152A', name: 'DBS152A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS152A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS153A', name: 'DBS153A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS153A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS156A', name: 'DBS156A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS156A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS157A', name: 'DBS157A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS157A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS158A', name: 'DBS158A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS158A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS162A', name: 'DBS162A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS162A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS163A', name: 'DBS163A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS163A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS164A', name: 'DBS164A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS164A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS165A', name: 'DBS165A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS165A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS166A', name: 'DBS166A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS166A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS167A', name: 'DBS167A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS167A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS168A', name: 'DBS168A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS168A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS169A', name: 'DBS169A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS169A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS170A', name: 'DBS170A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS170A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS171A', name: 'DBS171A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS171A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS172A', name: 'DBS172A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS172A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS174A', name: 'DBS174A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS174A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS175A', name: 'DBS175A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS175A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS178A', name: 'DBS178A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS178A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS179A', name: 'DBS179A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS179A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS181A', name: 'DBS181A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS181A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS184A', name: 'DBS184A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS184A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS186A', name: 'DBS186A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS186A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS195A', name: 'DBS195A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS195A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS196A', name: 'DBS196A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS196A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS212A', name: 'DBS212A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS212A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS222A', name: 'DBS222A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS222A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS223A', name: 'DBS223A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS223A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS231A', name: 'DBS231A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS231A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS232A', name: 'DBS232A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS232A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS233A', name: 'DBS233A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS233A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS234A', name: 'DBS234A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS234A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS235A', name: 'DBS235A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS235A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS251A', name: 'DBS251A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS251A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS253A', name: 'DBS253A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS253A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT005A', name: 'DBT005A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT005A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT006A', name: 'DBT006A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT006A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT550A', name: 'DBT550A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT550A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT551A', name: 'DBT551A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT551A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT552A', name: 'DBT552A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT552A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT553A', name: 'DBT553A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT553A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT554A', name: 'DBT554A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT554A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT555A', name: 'DBT555A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT555A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT556A', name: 'DBT556A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT556A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT557A', name: 'DBT557A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT557A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT558A', name: 'DBT558A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT558A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT559A', name: 'DBT559A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT559A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT560A', name: 'DBT560A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT560A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT561A', name: 'DBT561A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT561A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT562A', name: 'DBT562A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT562A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT563A', name: 'DBT563A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT563A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT564A', name: 'DBT564A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT564A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT565A', name: 'DBT565A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT565A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT566A', name: 'DBT566A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT566A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT567A', name: 'DBT567A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT567A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT568A', name: 'DBT568A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT568A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT569A', name: 'DBT569A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT569A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT570A', name: 'DBT570A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT570A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT571A', name: 'DBT571A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT571A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT572A', name: 'DBT572A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT572A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT576A', name: 'DBT576A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT576A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT578A', name: 'DBT578A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT578A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT579A', name: 'DBT579A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT579A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT580A', name: 'DBT580A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT580A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT581A', name: 'DBT581A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT581A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT586A', name: 'DBT586A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT586A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT587A', name: 'DBT587A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT587A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT589A', name: 'DBT589A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT589A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT590A', name: 'DBT590A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT590A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT591A', name: 'DBT591A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT591A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT592A', name: 'DBT592A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT592A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT593A', name: 'DBT593A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT593A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT594A', name: 'DBT594A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT594A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT603A', name: 'DBT603A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT603A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT604A', name: 'DBT604A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT604A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT605A', name: 'DBT605A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT605A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT606A', name: 'DBT606A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT606A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT607A', name: 'DBT607A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT607A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT609A', name: 'DBT609A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT609A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT610A', name: 'DBT610A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT610A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT611A', name: 'DBT611A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT611A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT612A', name: 'DBT612A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT612A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT613A', name: 'DBT613A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT613A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT614A', name: 'DBT614A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT614A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT616A', name: 'DBT616A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT616A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT617A', name: 'DBT617A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT617A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT618A', name: 'DBT618A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT618A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT621A', name: 'DBT621A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT621A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT1503', name: 'DBT1503', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT1503.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT1506', name: 'DBT1506', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT1506.jpg'), price: 1200, company: '' },
]

 fabric_AllSeasons3 = [
    { optType: "Fabric", key: 'DBU018A', name: 'DBU018A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU018A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU019A', name: 'DBU019A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU019A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU020A', name: 'DBU020A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU020A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU021A', name: 'DBU021A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU021A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU022A', name: 'DBU022A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU022A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU023A', name: 'DBU023A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU023A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU025A', name: 'DBU025A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU025A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU026A', name: 'DBU026A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU026A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU027A', name: 'DBU027A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU027A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU028A', name: 'DBU028A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU028A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU029A', name: 'DBU029A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU029A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU030A', name: 'DBU030A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU030A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU031A', name: 'DBU031A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU031A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU032A', name: 'DBU032A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU032A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU033A', name: 'DBU033A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU033A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU034A', name: 'DBU034A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU034A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU035A', name: 'DBU035A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU035A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU036A', name: 'DBU036A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU036A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU037A', name: 'DBU037A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU037A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU038A', name: 'DBU038A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU038A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU039A', name: 'DBU039A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU039A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU040A', name: 'DBU040A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU040A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU041A', name: 'DBU041A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU041A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU042A', name: 'DBU042A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU042A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU043A', name: 'DBU043A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU043A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU054A', name: 'DBU054A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU054A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU059A', name: 'DBU059A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU059A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU060A', name: 'DBU060A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU060A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU061A', name: 'DBU061A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU061A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU062A', name: 'DBU062A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU062A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU063A', name: 'DBU063A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU063A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU064A', name: 'DBU064A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU064A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU065A', name: 'DBU065A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU065A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU066A', name: 'DBU066A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU066A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU067A', name: 'DBU067A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU067A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU068A', name: 'DBU068A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU068A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU069A', name: 'DBU069A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU069A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU070A', name: 'DBU070A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU070A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU071A', name: 'DBU071A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU071A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU072A', name: 'DBU072A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU072A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU073A', name: 'DBU073A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU073A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU074A', name: 'DBU074A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU074A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU075A', name: 'DBU075A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU075A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU076A', name: 'DBU076A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU076A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU077A', name: 'DBU077A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU077A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU078A', name: 'DBU078A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU078A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU079A', name: 'DBU079A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU079A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU080A', name: 'DBU080A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU080A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU081A', name: 'DBU081A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU081A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU082A', name: 'DBU082A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU082A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU083A', name: 'DBU083A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU083A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU084A', name: 'DBU084A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU084A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU085A', name: 'DBU085A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU085A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU086A', name: 'DBU086A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU086A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU087A', name: 'DBU087A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU087A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU088A', name: 'DBU088A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU088A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU089A', name: 'DBU089A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU089A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU098A', name: 'DBU098A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU098A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU099A', name: 'DBU099A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU099A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU100A', name: 'DBU100A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU100A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU101A', name: 'DBU101A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU101A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU102A', name: 'DBU102A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU102A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU103A', name: 'DBU103A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU103A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU104A', name: 'DBU104A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU104A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU105A', name: 'DBU105A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU105A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU106A', name: 'DBU106A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU106A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU107A', name: 'DBU107A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU107A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU108A', name: 'DBU108A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU108A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU109A', name: 'DBU109A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU109A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU110A', name: 'DBU110A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU110A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU111A', name: 'DBU111A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU111A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU112A', name: 'DBU112A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU112A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU113A', name: 'DBU113A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU113A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU114A', name: 'DBU114A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU114A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU115A', name: 'DBU115A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU115A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU116A', name: 'DBU116A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU116A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU117A', name: 'DBU117A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU117A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU118A', name: 'DBU118A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU118A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU119A', name: 'DBU119A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU119A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU120A', name: 'DBU120A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU120A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU121A', name: 'DBU121A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU121A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU122A', name: 'DBU122A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU122A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU123A', name: 'DBU123A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU123A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU124A', name: 'DBU124A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU124A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU125A', name: 'DBU125A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU125A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU126A', name: 'DBU126A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU126A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU127A', name: 'DBU127A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU127A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU128A', name: 'DBU128A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU128A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU129A', name: 'DBU129A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU129A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU130A', name: 'DBU130A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU130A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU131A', name: 'DBU131A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU131A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU132A', name: 'DBU132A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU132A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU133A', name: 'DBU133A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU133A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU134A', name: 'DBU134A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU134A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU135A', name: 'DBU135A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU135A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU136A', name: 'DBU136A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU136A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU137A', name: 'DBU137A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU137A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU138A', name: 'DBU138A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU138A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU139A', name: 'DBU139A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU139A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU150A', name: 'DBU150A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU150A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU151A', name: 'DBU151A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU151A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU152A', name: 'DBU152A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU152A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU153A', name: 'DBU153A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU153A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU155A', name: 'DBU155A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU155A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0001', name: 'DHH0001', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0001.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0002', name: 'DHH0002', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0002.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0003', name: 'DHH0003', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0003.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0004', name: 'DHH0004', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0004.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0005', name: 'DHH0005', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0005.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0006', name: 'DHH0006', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0006.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0007', name: 'DHH0007', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0007.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0008', name: 'DHH0008', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0008.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0009', name: 'DHH0009', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0009.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0010', name: 'DHH0010', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0010.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0011', name: 'DHH0011', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0011.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0012', name: 'DHH0012', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0012.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0013', name: 'DHH0013', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0013.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0014', name: 'DHH0014', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0014.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0015', name: 'DHH0015', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0015.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0016', name: 'DHH0016', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0016.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0017', name: 'DHH0017', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0017.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DHH0018', name: 'DHH0018', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0018.jpg'), price: 1200, company: '' },
]

 fabric_AllSeasons4 = [
    { optType: "Fabric", key: 'DBN342A', name: 'DBN342A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBN342A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN530A', name: 'DBN530A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBN530A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN890A', name: 'DBN890A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBN890A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBN910A', name: 'DBN910A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBN910A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR229A', name: 'DBR229A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBR229A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR230A', name: 'DBR230A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBR230A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR231A', name: 'DBR231A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBR231A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR232A', name: 'DBR232A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBR232A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR233A', name: 'DBR233A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBR233A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR234A', name: 'DBR234A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBR234A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBR235A', name: 'DBR235A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBR235A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS178A', name: 'DBS178A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBS178A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS179A', name: 'DBS179A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBS179A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS231A', name: 'DBS231A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBS231A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS232A', name: 'DBS232A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBS232A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS233A', name: 'DBS233A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBS233A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT557A', name: 'DBT557A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT557A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT558A', name: 'DBT558A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT558A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT570A', name: 'DBT570A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT570A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT571A', name: 'DBT571A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT571A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT586A', name: 'DBT586A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT586A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT587A', name: 'DBT587A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT587A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT589A', name: 'DBT589A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT589A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT590A', name: 'DBT590A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT590A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT1503', name: 'DBT1503', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT1503.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT1506', name: 'DBT1506', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT1506.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU037A', name: 'DBU037A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU037A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU038A', name: 'DBU038A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU038A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU039A', name: 'DBU039A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU039A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU040A', name: 'DBU040A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU040A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU041A', name: 'DBU041A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU041A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU042A', name: 'DBU042A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU042A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU043A', name: 'DBU043A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU043A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU054A', name: 'DBU054A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU054A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU055A', name: 'DBU055A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU055A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU058A', name: 'DBU058A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU058A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU059A', name: 'DBU059A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU059A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU072A', name: 'DBU072A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU072A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU098A', name: 'DBU098A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU098A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU099A', name: 'DBU099A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU099A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU100A', name: 'DBU100A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU100A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU101A', name: 'DBU101A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU101A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU102A', name: 'DBU102A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU102A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU103A', name: 'DBU103A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU103A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU104A', name: 'DBU104A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU104A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU105A', name: 'DBU105A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU105A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU106A', name: 'DBU106A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU106A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU107A', name: 'DBU107A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU107A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU108A', name: 'DBU108A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU108A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV013A', name: 'DBV013A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV013A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV015A', name: 'DBV015A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV015A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV016A', name: 'DBV016A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV016A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV017A', name: 'DBV017A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV017A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV018A', name: 'DBV018A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV018A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV020A', name: 'DBV020A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV020A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV021A', name: 'DBV021A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV021A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV031A', name: 'DBV031A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV031A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV032A', name: 'DBV032A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV032A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV033A', name: 'DBV033A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV033A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV034A', name: 'DBV034A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV034A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV035A', name: 'DBV035A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV035A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV036A', name: 'DBV036A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV036A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV037A', name: 'DBV037A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV037A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV074A', name: 'DBV074A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV074A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV075A', name: 'DBV075A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV075A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV076A', name: 'DBV076A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV076A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV077A', name: 'DBV077A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV077A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV0219A', name: 'DBV0219A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV0219A.jpg'), price: 1200, company: '' },


]

 fabric_FallWinter = [
    { optType: "Fabric", key: 'DBQ662A', name: 'DBQ662A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBQ662A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS234A', name: 'DBS234A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBS234A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBS235A', name: 'DBS235A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBS235A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT559A', name: 'DBT559A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT559A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT560A', name: 'DBT560A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT560A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT561A', name: 'DBT561A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT561A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT591A', name: 'DBT591A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT591A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT592A', name: 'DBT592A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT592A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT593A', name: 'DBT593A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT593A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT594A', name: 'DBT594A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT594A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT605A', name: 'DBT605A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT605A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT606A', name: 'DBT606A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT606A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT607A', name: 'DBT607A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT607A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT610A', name: 'DBT610A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT610A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT611A', name: 'DBT611A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT611A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT612A', name: 'DBT612A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT612A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT616A', name: 'DBT616A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT616A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBT618A', name: 'DBT618A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT618A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU008A', name: 'DBU008A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU008A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU009A', name: 'DBU009A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU009A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU010A', name: 'DBU010A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU010A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU011A', name: 'DBU011A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU011A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU012A', name: 'DBU012A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU012A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU013A', name: 'DBU013A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU013A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU014A', name: 'DBU014A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU014A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU015A', name: 'DBU015A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU015A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU016A', name: 'DBU016A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU016A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU017A', name: 'DBU017A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU017A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU090A', name: 'DBU090A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU090A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU091A', name: 'DBU091A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU091A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU092A', name: 'DBU092A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU092A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBU093A', name: 'DBU093A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU093A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV008A', name: 'DBV008A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV008A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV009A', name: 'DBV009A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV009A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV011A', name: 'DBV011A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV011A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV012A', name: 'DBV012A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV012A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV038A', name: 'DBV038A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV038A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV039A', name: 'DBV039A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV039A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV050A', name: 'DBV050A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV050A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV051A', name: 'DBV051A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV051A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV052A', name: 'DBV052A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV052A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV053A', name: 'DBV053A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV053A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV054A', name: 'DBV054A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV054A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV055A', name: 'DBV055A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV055A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV056A', name: 'DBV056A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV056A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV057A', name: 'DBV057A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV057A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV058A', name: 'DBV058A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV058A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV080A', name: 'DBV080A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV080A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV081A', name: 'DBV081A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV081A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV082A', name: 'DBV082A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV082A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV083A', name: 'DBV083A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV083A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV084A', name: 'DBV084A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV084A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV085A', name: 'DBV085A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV085A.jpg'), price: 1200, company: '' },
    { optType: "Fabric", key: 'DBV086A', name: 'DBV086A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV086A.jpg'), price: 1200, company: '' },
]
 fabric_SpringSummer = [
    { optType: "Fabric", key: '2299-1', name: '2299-1', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-1.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-2', name: '2299-2', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-2.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-3', name: '2299-3', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-3.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-4', name: '2299-4', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-4.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-5', name: '2299-5', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-5.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-6', name: '2299-6', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-6.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-7', name: '2299-7', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-7.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-8', name: '2299-8', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-8.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-9', name: '2299-9', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-9.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-10', name: '2299-10', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-10.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-11', name: '2299-11', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-11.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-13', name: '2299-13', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-13.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-101', name: '2299-101', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-101.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-102', name: '2299-102', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-102.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-103', name: '2299-103', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-103.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-104', name: '2299-104', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-104.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-107', name: '2299-107', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-107.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-108', name: '2299-108', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-108.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2299-109', name: '2299-109', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-109.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-1', name: '2899-1', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-1.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-2', name: '2899-2', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-2.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-3', name: '2899-3', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-3.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-4', name: '2899-4', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-4.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-5', name: '2899-5', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-5.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-8', name: '2899-8', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-8.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-9', name: '2899-9', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-9.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-10', name: '2899-10', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-10.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-11', name: '2899-11', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-11.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-12', name: '2899-12', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-12.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-13', name: '2899-13', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-13.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-101', name: '2899-101', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-101.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-102', name: '2899-102', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-102.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-103', name: '2899-103', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-103.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-105', name: '2899-105', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-105.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-106', name: '2899-106', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-106.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-107', name: '2899-107', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-107.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '2899-108', name: '2899-108', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-108.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: 'Y01', name: 'Y01', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y01.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y10', name: 'Y10', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y10.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y12', name: 'Y12', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y12.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y13', name: 'Y13', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y13.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y14', name: 'Y14', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y14.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y23', name: 'Y23', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y23.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y26', name: 'Y26', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y26.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y27', name: 'Y27', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y27.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y33', name: 'Y33', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y33.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y40', name: 'Y40', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y40.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y41', name: 'Y41', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y41.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y46', name: 'Y46', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y46.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y48', name: 'Y48', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y48.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y54', name: 'Y54', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y54.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y62', name: 'Y62', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y62.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y65', name: 'Y65', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y65.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y68', name: 'Y68', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y68.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y70', name: 'Y70', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y70.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y71', name: 'Y71', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y71.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
    { optType: "Fabric", key: 'Y72', name: 'Y72', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y72.jpg'), price: 1000, company: 'Gresham Bloke Linen' },
]

 fabric_JacquardFabrics = [
    { optType: "Fabric", key: 'JF1001', name: 'JF1001', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1001.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1002', name: 'JF1002', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1002.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1003', name: 'JF1003', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1003.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1004', name: 'JF1004', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1004.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1005', name: 'JF1005', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1005.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1006', name: 'JF1006', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1006.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1007', name: 'JF1007', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1007.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1008', name: 'JF1008', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1008.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1009', name: 'JF1009', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1009.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1010', name: 'JF1010', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1010.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1011', name: 'JF1011', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1011.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1012', name: 'JF1012', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1012.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1013', name: 'JF1013', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1013.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1014', name: 'JF1014', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1014.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1015', name: 'JF1015', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1015.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1016', name: 'JF1016', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1016.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1017', name: 'JF1017', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1017.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1018', name: 'JF1018', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1018.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1019', name: 'JF1019', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1019.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1021', name: 'JF1021', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1021.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1022', name: 'JF1022', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1022.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1023', name: 'JF1023', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1023.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1024', name: 'JF1024', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1024.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1025', name: 'JF1025', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1025.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1026', name: 'JF1026', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1026.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1027', name: 'JF1027', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1027.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1028', name: 'JF1028', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1028.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1029', name: 'JF1029', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1029.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1030', name: 'JF1030', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1030.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1031', name: 'JF1031', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1031.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1032', name: 'JF1032', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1032.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1033', name: 'JF1033', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1033.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1034', name: 'JF1034', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1034.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1035', name: 'JF1035', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1035.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1036', name: 'JF1036', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1036.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1037', name: 'JF1037', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1037.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1038', name: 'JF1038', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1038.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1039', name: 'JF1039', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1039.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1040', name: 'JF1040', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1040.jpg'), price: 950, company: '' },
    { optType: "Fabric", key: 'JF1041', name: 'JF1041', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1041.jpg'), price: 950, company: '' },
]
 fabric_WaterResistant = [
    { optType: "Fabric", key: '280101', name: '280101', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280101.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280102', name: '280102', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280102.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280103', name: '280103', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280103.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280104', name: '280104', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280104.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280105', name: '280105', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280105.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280106', name: '280106', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280106.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280107', name: '280107', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280107.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280108', name: '280108', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280108.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280109', name: '280109', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280109.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280110', name: '280110', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280110.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280111', name: '280111', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280111.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280112', name: '280112', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280112.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280131', name: '280131', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280131.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280132', name: '280132', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280132.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280135', name: '280135', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280135.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280136', name: '280136', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280136.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280137', name: '280137', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280137.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280138', name: '280138', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280138.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280139', name: '280139', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280139.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280140', name: '280140', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280140.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280141', name: '280141', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280141.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280142', name: '280142', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280142.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280143', name: '280143', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280143.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280146', name: '280146', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280146.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280147', name: '280147', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280147.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280148', name: '280148', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280148.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280150', name: '280150', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280150.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280154', name: '280154', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280154.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280155', name: '280155', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280155.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280156', name: '280156', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280156.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280159', name: '280159', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280159.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280160', name: '280160', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280160.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280161', name: '280161', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280161.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280165', name: '280165', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280165.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280166', name: '280166', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280166.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280170', name: '280170', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280170.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280180', name: '280180', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280180.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280181', name: '280181', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280181.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280182', name: '280182', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280182.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280183', name: '280183', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280183.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280184', name: '280184', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280184.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280212', name: '280212', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280212.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280213', name: '280213', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280213.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280214', name: '280214', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280214.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280215', name: '280215', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280215.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280216', name: '280216', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280216.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280218', name: '280218', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280218.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280219', name: '280219', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280219.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280220', name: '280220', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280220.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280321', name: '280321', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280321.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280322', name: '280322', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280322.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280323', name: '280323', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280323.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280324', name: '280324', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280324.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280325', name: '280325', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280325.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280326', name: '280326', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280326.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280327', name: '280327', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280327.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280328', name: '280328', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280328.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280329', name: '280329', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280329.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280330', name: '280330', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280330.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280431', name: '280431', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280431.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280432', name: '280432', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280432.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280433', name: '280433', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280433.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280434', name: '280434', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280434.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280435', name: '280435', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280435.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280436', name: '280436', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280436.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280437', name: '280437', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280437.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280438', name: '280438', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280438.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280541', name: '280541', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280541.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280542', name: '280542', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280542.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280543', name: '280543', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280543.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280544', name: '280544', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280544.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280545', name: '280545', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280545.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280546', name: '280546', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280546.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280547', name: '280547', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280547.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280548', name: '280548', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280548.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280549', name: '280549', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280549.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280550', name: '280541', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280541.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280651', name: '280651', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280651.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280653', name: '280653', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280653.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280654', name: '280654', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280654.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280655', name: '280655', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280655.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280656', name: '280656', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280656.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280657', name: '280657', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280657.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280658', name: '280658', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280658.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280659', name: '280659', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280659.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280762', name: '280762', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280762.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280764', name: '280764', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280764.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280765', name: '280765', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280765.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280766', name: '280766', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280766.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280767', name: '280767', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280767.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280768', name: '280768', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280768.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280769', name: '280769', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280769.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280871', name: '280871', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280871.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280872', name: '280872', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280872.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280873', name: '280873', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280873.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280874', name: '280874', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280874.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280875', name: '280875', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280875.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280876', name: '280876', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280876.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280877', name: '280877', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280877.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280878', name: '280878', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280878.jpg'), price: 900, company: '' },
    { optType: "Fabric", key: '280880', name: '280880', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280880.jpg'), price: 900, company: '' },
]

 fabric_Buttons = [
    { optType: "Buttons", key: 'KB001', name: 'KB001', imageSrc: webImageNamed('Fabrics/SuitButtons/KB001.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB002', name: 'KB002', imageSrc: webImageNamed('Fabrics/SuitButtons/KB002.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB008', name: 'KB008', imageSrc: webImageNamed('Fabrics/SuitButtons/KB008.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB010', name: 'KB010', imageSrc: webImageNamed('Fabrics/SuitButtons/KB010.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB011', name: 'KB011', imageSrc: webImageNamed('Fabrics/SuitButtons/KB011.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB016', name: 'KB016', imageSrc: webImageNamed('Fabrics/SuitButtons/KB016.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB020', name: 'KB020', imageSrc: webImageNamed('Fabrics/SuitButtons/KB020.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB021', name: 'KB021', imageSrc: webImageNamed('Fabrics/SuitButtons/KB021.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB022', name: 'KB022', imageSrc: webImageNamed('Fabrics/SuitButtons/KB022.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB023', name: 'KB023', imageSrc: webImageNamed('Fabrics/SuitButtons/KB023.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB025', name: 'KB025', imageSrc: webImageNamed('Fabrics/SuitButtons/KB025.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB026', name: 'KB026', imageSrc: webImageNamed('Fabrics/SuitButtons/KB026.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB027', name: 'KB027', imageSrc: webImageNamed('Fabrics/SuitButtons/KB027.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB028', name: 'KB028', imageSrc: webImageNamed('Fabrics/SuitButtons/KB028.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB029', name: 'KB029', imageSrc: webImageNamed('Fabrics/SuitButtons/KB029.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB030', name: 'KB030', imageSrc: webImageNamed('Fabrics/SuitButtons/KB030.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG024', name: 'KG024', imageSrc: webImageNamed('Fabrics/SuitButtons/KG024.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG027', name: 'KG027', imageSrc: webImageNamed('Fabrics/SuitButtons/KG027.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG032', name: 'KG032', imageSrc: webImageNamed('Fabrics/SuitButtons/KG032.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG053', name: 'KG053', imageSrc: webImageNamed('Fabrics/SuitButtons/KG053.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG054', name: 'KG054', imageSrc: webImageNamed('Fabrics/SuitButtons/KG054.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG055', name: 'KG055', imageSrc: webImageNamed('Fabrics/SuitButtons/KG055.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG056', name: 'KG056', imageSrc: webImageNamed('Fabrics/SuitButtons/KG056.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG057', name: 'KG057', imageSrc: webImageNamed('Fabrics/SuitButtons/KG057.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG062', name: 'KG062', imageSrc: webImageNamed('Fabrics/SuitButtons/KG062.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG064', name: 'KG064', imageSrc: webImageNamed('Fabrics/SuitButtons/KG064.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG068', name: 'KG068', imageSrc: webImageNamed('Fabrics/SuitButtons/KG068.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG069', name: 'KG069', imageSrc: webImageNamed('Fabrics/SuitButtons/KG069.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG157', name: 'KG157', imageSrc: webImageNamed('Fabrics/SuitButtons/KG157.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG168', name: 'KG168', imageSrc: webImageNamed('Fabrics/SuitButtons/KG168.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG178', name: 'KG178', imageSrc: webImageNamed('Fabrics/SuitButtons/KG178.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG190', name: 'KG190', imageSrc: webImageNamed('Fabrics/SuitButtons/KG190.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG191', name: 'KG191', imageSrc: webImageNamed('Fabrics/SuitButtons/KG191.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG192', name: 'KG192', imageSrc: webImageNamed('Fabrics/SuitButtons/KG192.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG195', name: 'KG195', imageSrc: webImageNamed('Fabrics/SuitButtons/KG195.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG196', name: 'KG196', imageSrc: webImageNamed('Fabrics/SuitButtons/KG196.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG197', name: 'KG197', imageSrc: webImageNamed('Fabrics/SuitButtons/KG197.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG198', name: 'KG198', imageSrc: webImageNamed('Fabrics/SuitButtons/KG198.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG199', name: 'KG199', imageSrc: webImageNamed('Fabrics/SuitButtons/KG199.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG202', name: 'KG202', imageSrc: webImageNamed('Fabrics/SuitButtons/KG202.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG203', name: 'KG203', imageSrc: webImageNamed('Fabrics/SuitButtons/KG203.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG204', name: 'KG204', imageSrc: webImageNamed('Fabrics/SuitButtons/KG204.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG206', name: 'KG206', imageSrc: webImageNamed('Fabrics/SuitButtons/KG206.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG212', name: 'KG212', imageSrc: webImageNamed('Fabrics/SuitButtons/KG212.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ011', name: 'KNJ011', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ011.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ012', name: 'KNJ012', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ012.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ013', name: 'KNJ013', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ013.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ014', name: 'KNJ014', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ014.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ023', name: 'KNJ023', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ023.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ024', name: 'KNJ024', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ024.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ030', name: 'KNJ030', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ030.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ031', name: 'KNJ031', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ031.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ033', name: 'KNJ033', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ033.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ036', name: 'KNJ036', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ036.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ039', name: 'KNJ039', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ039.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KQ022', name: 'KQ022', imageSrc: webImageNamed('Fabrics/SuitButtons/KQ022.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ133', name: 'KSZ133', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ133.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ134', name: 'KSZ134', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ134.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ137', name: 'KSZ137', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ137.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ138', name: 'KSZ138', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ138.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ139', name: 'KSZ139', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ139.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ140', name: 'KSZ140', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ140.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ150', name: 'KSZ150', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ150.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ151', name: 'KSZ151', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ151.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ152', name: 'KSZ152', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ152.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ153', name: 'KSZ153', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ153.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ154', name: 'KSZ154', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ154.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ155', name: 'KSZ155', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ155.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ161', name: 'KSZ161', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ161.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ162', name: 'KSZ162', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ162.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ165', name: 'KSZ165', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ165.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ175', name: 'KSZ175', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ175.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ176', name: 'KSZ176', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ176.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ177', name: 'KSZ177', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ177.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ191', name: 'KSZ191', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ191.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ192', name: 'KSZ192', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ192.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ193', name: 'KSZ193', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ193.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ195', name: 'KSZ195', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ195.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ196', name: 'KSZ196', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ196.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ197', name: 'KSZ197', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ197.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ198', name: 'KSZ198', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ198.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ199', name: 'KSZ199', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ199.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ200', name: 'KSZ200', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ200.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ201', name: 'KSZ201', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ201.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ205', name: 'KSZ205', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ205.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ206', name: 'KSZ206', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ206.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ207', name: 'KSZ207', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ207.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ220', name: 'KSZ220', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ220.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ221', name: 'KSZ221', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ221.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW001', name: 'KW001', imageSrc: webImageNamed('Fabrics/SuitButtons/KW001.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW002', name: 'KW002', imageSrc: webImageNamed('Fabrics/SuitButtons/KW002.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW003', name: 'KW003', imageSrc: webImageNamed('Fabrics/SuitButtons/KW003.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW004', name: 'KW004', imageSrc: webImageNamed('Fabrics/SuitButtons/KW004.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW005', name: 'KW005', imageSrc: webImageNamed('Fabrics/SuitButtons/KW005.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW006', name: 'KW006', imageSrc: webImageNamed('Fabrics/SuitButtons/KW006.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW017', name: 'KW017', imageSrc: webImageNamed('Fabrics/SuitButtons/KW017.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW042', name: 'KW042', imageSrc: webImageNamed('Fabrics/SuitButtons/KW042.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW043', name: 'KW043', imageSrc: webImageNamed('Fabrics/SuitButtons/KW043.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW044', name: 'KW044', imageSrc: webImageNamed('Fabrics/SuitButtons/KW044.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW045', name: 'KW045', imageSrc: webImageNamed('Fabrics/SuitButtons/KW045.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW063', name: 'KW063', imageSrc: webImageNamed('Fabrics/SuitButtons/KW063.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW065', name: 'KW065', imageSrc: webImageNamed('Fabrics/SuitButtons/KW065.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW066', name: 'KW066', imageSrc: webImageNamed('Fabrics/SuitButtons/KW066.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW067', name: 'KW067', imageSrc: webImageNamed('Fabrics/SuitButtons/KW067.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW068', name: 'KW068', imageSrc: webImageNamed('Fabrics/SuitButtons/KW068.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW069', name: 'KW069', imageSrc: webImageNamed('Fabrics/SuitButtons/KW069.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW070', name: 'KW070', imageSrc: webImageNamed('Fabrics/SuitButtons/KW070.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW071', name: 'KW071', imageSrc: webImageNamed('Fabrics/SuitButtons/KW071.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'QMJQZ03', name: 'QMJQZ03', imageSrc: webImageNamed('Fabrics/SuitButtons/QMJQZ03.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'QZMAB01', name: 'QZMAB01', imageSrc: webImageNamed('Fabrics/SuitButtons/QZMAB01.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'QZMAB02', name: 'QZMAB02', imageSrc: webImageNamed('Fabrics/SuitButtons/QZMAB02.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'QZMAB03', name: 'QZMAB03', imageSrc: webImageNamed('Fabrics/SuitButtons/QZMAB03.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'QZMAB08', name: 'QZMAB08', imageSrc: webImageNamed('Fabrics/SuitButtons/QZMAB08.jpg'), price: null, company: '' },
]
 fabric_Linings = [
    { optType: "Lining", key: '4', name: '4', imageSrc: webImageNamed('Fabrics/LiningFabrics/4.jpg'), price: null, company: '' },
    { optType: "Lining", key: '5', name: '5', imageSrc: webImageNamed('Fabrics/LiningFabrics/5.jpg'), price: null, company: '' },
    { optType: "Lining", key: '11', name: '11', imageSrc: webImageNamed('Fabrics/LiningFabrics/11.jpg'), price: null, company: '' },
    { optType: "Lining", key: '12', name: '12', imageSrc: webImageNamed('Fabrics/LiningFabrics/12.jpg'), price: null, company: '' },
    { optType: "Lining", key: '13', name: '13', imageSrc: webImageNamed('Fabrics/LiningFabrics/13.jpg'), price: null, company: '' },
    { optType: "Lining", key: '19', name: '19', imageSrc: webImageNamed('Fabrics/LiningFabrics/19.jpg'), price: null, company: '' },
    { optType: "Lining", key: '20', name: '20', imageSrc: webImageNamed('Fabrics/LiningFabrics/20.jpg'), price: null, company: '' },
    { optType: "Lining", key: '21', name: '21', imageSrc: webImageNamed('Fabrics/LiningFabrics/21.jpg'), price: null, company: '' },
    { optType: "Lining", key: '22', name: '22', imageSrc: webImageNamed('Fabrics/LiningFabrics/22.jpg'), price: null, company: '' },
    { optType: "Lining", key: '23', name: '23', imageSrc: webImageNamed('Fabrics/LiningFabrics/23.jpg'), price: null, company: '' },
    { optType: "Lining", key: '24', name: '24', imageSrc: webImageNamed('Fabrics/LiningFabrics/24.jpg'), price: null, company: '' },
    { optType: "Lining", key: '25', name: '25', imageSrc: webImageNamed('Fabrics/LiningFabrics/25.jpg'), price: null, company: '' },
    { optType: "Lining", key: '26', name: '26', imageSrc: webImageNamed('Fabrics/LiningFabrics/26.jpg'), price: null, company: '' },
    { optType: "Lining", key: '29', name: '29', imageSrc: webImageNamed('Fabrics/LiningFabrics/29.jpg'), price: null, company: '' },
    { optType: "Lining", key: '30', name: '30', imageSrc: webImageNamed('Fabrics/LiningFabrics/30.jpg'), price: null, company: '' },
    { optType: "Lining", key: '31', name: '31', imageSrc: webImageNamed('Fabrics/LiningFabrics/31.jpg'), price: null, company: '' },
    { optType: "Lining", key: '32', name: '32', imageSrc: webImageNamed('Fabrics/LiningFabrics/32.jpg'), price: null, company: '' },
    { optType: "Lining", key: '33', name: '33', imageSrc: webImageNamed('Fabrics/LiningFabrics/33.jpg'), price: null, company: '' },
    { optType: "Lining", key: '34', name: '34', imageSrc: webImageNamed('Fabrics/LiningFabrics/34.jpg'), price: null, company: '' },
    { optType: "Lining", key: '36', name: '36', imageSrc: webImageNamed('Fabrics/LiningFabrics/36.jpg'), price: null, company: '' },
    { optType: "Lining", key: '39', name: '39', imageSrc: webImageNamed('Fabrics/LiningFabrics/39.jpg'), price: null, company: '' },
    { optType: "Lining", key: '40', name: '40', imageSrc: webImageNamed('Fabrics/LiningFabrics/40.jpg'), price: null, company: '' },
    { optType: "Lining", key: '44', name: '44', imageSrc: webImageNamed('Fabrics/LiningFabrics/44.jpg'), price: null, company: '' },
    { optType: "Lining", key: '45', name: '45', imageSrc: webImageNamed('Fabrics/LiningFabrics/45.jpg'), price: null, company: '' },
    { optType: "Lining", key: '47', name: '47', imageSrc: webImageNamed('Fabrics/LiningFabrics/47.jpg'), price: null, company: '' },
    { optType: "Lining", key: '48', name: '48', imageSrc: webImageNamed('Fabrics/LiningFabrics/48.jpg'), price: null, company: '' },
    { optType: "Lining", key: '49', name: '49', imageSrc: webImageNamed('Fabrics/LiningFabrics/49.jpg'), price: null, company: '' },
    { optType: "Lining", key: '50', name: '50', imageSrc: webImageNamed('Fabrics/LiningFabrics/50.jpg'), price: null, company: '' },
    { optType: "Lining", key: '53', name: '53', imageSrc: webImageNamed('Fabrics/LiningFabrics/53.jpg'), price: null, company: '' },
    { optType: "Lining", key: '54', name: '54', imageSrc: webImageNamed('Fabrics/LiningFabrics/54.jpg'), price: null, company: '' },
    { optType: "Lining", key: '56', name: '56', imageSrc: webImageNamed('Fabrics/LiningFabrics/56.jpg'), price: null, company: '' },
    { optType: "Lining", key: '59', name: '59', imageSrc: webImageNamed('Fabrics/LiningFabrics/59.jpg'), price: null, company: '' },
    { optType: "Lining", key: '60', name: '60', imageSrc: webImageNamed('Fabrics/LiningFabrics/60.jpg'), price: null, company: '' },
    { optType: "Lining", key: '63', name: '63', imageSrc: webImageNamed('Fabrics/LiningFabrics/63.jpg'), price: null, company: '' },
    { optType: "Lining", key: '64', name: '64', imageSrc: webImageNamed('Fabrics/LiningFabrics/64.jpg'), price: null, company: '' },
    { optType: "Lining", key: '67', name: '67', imageSrc: webImageNamed('Fabrics/LiningFabrics/67.jpg'), price: null, company: '' },
    { optType: "Lining", key: '68', name: '68', imageSrc: webImageNamed('Fabrics/LiningFabrics/68.jpg'), price: null, company: '' },
    { optType: "Lining", key: '70', name: '70', imageSrc: webImageNamed('Fabrics/LiningFabrics/70.jpg'), price: null, company: '' },
    { optType: "Lining", key: '71', name: '71', imageSrc: webImageNamed('Fabrics/LiningFabrics/71.jpg'), price: null, company: '' },
    { optType: "Lining", key: '75', name: '75', imageSrc: webImageNamed('Fabrics/LiningFabrics/75.jpg'), price: null, company: '' },
    { optType: "Lining", key: '76', name: '76', imageSrc: webImageNamed('Fabrics/LiningFabrics/76.jpg'), price: null, company: '' },
    { optType: "Lining", key: '78', name: '78', imageSrc: webImageNamed('Fabrics/LiningFabrics/78.jpg'), price: null, company: '' },
    { optType: "Lining", key: '81', name: '81', imageSrc: webImageNamed('Fabrics/LiningFabrics/81.jpg'), price: null, company: '' },
    { optType: "Lining", key: '83', name: '83', imageSrc: webImageNamed('Fabrics/LiningFabrics/83.jpg'), price: null, company: '' },
    { optType: "Lining", key: '97', name: '97', imageSrc: webImageNamed('Fabrics/LiningFabrics/97.jpg'), price: null, company: '' },
    { optType: "Lining", key: '101', name: '101', imageSrc: webImageNamed('Fabrics/LiningFabrics/101.jpg'), price: null, company: '' },
    { optType: "Lining", key: '102', name: '102', imageSrc: webImageNamed('Fabrics/LiningFabrics/102.jpg'), price: null, company: '' },
    { optType: "Lining", key: '103', name: '103', imageSrc: webImageNamed('Fabrics/LiningFabrics/103.jpg'), price: null, company: '' },
    { optType: "Lining", key: '106', name: '106', imageSrc: webImageNamed('Fabrics/LiningFabrics/106.jpg'), price: null, company: '' },
    { optType: "Lining", key: '108', name: '108', imageSrc: webImageNamed('Fabrics/LiningFabrics/108.jpg'), price: null, company: '' },
    { optType: "Lining", key: '109', name: '109', imageSrc: webImageNamed('Fabrics/LiningFabrics/109.jpg'), price: null, company: '' },
    { optType: "Lining", key: '111', name: '111', imageSrc: webImageNamed('Fabrics/LiningFabrics/111.jpg'), price: null, company: '' },
    { optType: "Lining", key: '112', name: '112', imageSrc: webImageNamed('Fabrics/LiningFabrics/112.jpg'), price: null, company: '' },
    { optType: "Lining", key: '114', name: '114', imageSrc: webImageNamed('Fabrics/LiningFabrics/114.jpg'), price: null, company: '' },
    { optType: "Lining", key: '122', name: '122', imageSrc: webImageNamed('Fabrics/LiningFabrics/122.jpg'), price: null, company: '' },
    { optType: "Lining", key: '125', name: '125', imageSrc: webImageNamed('Fabrics/LiningFabrics/125.jpg'), price: null, company: '' },
    { optType: "Lining", key: '128', name: '128', imageSrc: webImageNamed('Fabrics/LiningFabrics/128.jpg'), price: null, company: '' },
    { optType: "Lining", key: '129', name: '129', imageSrc: webImageNamed('Fabrics/LiningFabrics/129.jpg'), price: null, company: '' },
    { optType: "Lining", key: '130', name: '130', imageSrc: webImageNamed('Fabrics/LiningFabrics/130.jpg'), price: null, company: '' },
    { optType: "Lining", key: '131', name: '131', imageSrc: webImageNamed('Fabrics/LiningFabrics/131.jpg'), price: null, company: '' },
    { optType: "Lining", key: '133', name: '133', imageSrc: webImageNamed('Fabrics/LiningFabrics/133.jpg'), price: null, company: '' },
    { optType: "Lining", key: '134', name: '134', imageSrc: webImageNamed('Fabrics/LiningFabrics/134.jpg'), price: null, company: '' },
    { optType: "Lining", key: '139', name: '139', imageSrc: webImageNamed('Fabrics/LiningFabrics/139.jpg'), price: null, company: '' },
    { optType: "Lining", key: '143', name: '143', imageSrc: webImageNamed('Fabrics/LiningFabrics/143.jpg'), price: null, company: '' },
    { optType: "Lining", key: '144', name: '144', imageSrc: webImageNamed('Fabrics/LiningFabrics/144.jpg'), price: null, company: '' },
    { optType: "Lining", key: '145', name: '145', imageSrc: webImageNamed('Fabrics/LiningFabrics/145.jpg'), price: null, company: '' },
    { optType: "Lining", key: '146', name: '146', imageSrc: webImageNamed('Fabrics/LiningFabrics/146.jpg'), price: null, company: '' },
    { optType: "Lining", key: '147', name: '147', imageSrc: webImageNamed('Fabrics/LiningFabrics/147.jpg'), price: null, company: '' },
    { optType: "Lining", key: '148', name: '148', imageSrc: webImageNamed('Fabrics/LiningFabrics/148.jpg'), price: null, company: '' },
    { optType: "Lining", key: '151', name: '151', imageSrc: webImageNamed('Fabrics/LiningFabrics/151.jpg'), price: null, company: '' },
    { optType: "Lining", key: '154', name: '154', imageSrc: webImageNamed('Fabrics/LiningFabrics/154.jpg'), price: null, company: '' },
    { optType: "Lining", key: '157', name: '157', imageSrc: webImageNamed('Fabrics/LiningFabrics/157.jpg'), price: null, company: '' },
    { optType: "Lining", key: '181', name: '181', imageSrc: webImageNamed('Fabrics/LiningFabrics/181.jpg'), price: null, company: '' },
    { optType: "Lining", key: '182', name: '182', imageSrc: webImageNamed('Fabrics/LiningFabrics/182.jpg'), price: null, company: '' },
    { optType: "Lining", key: '187', name: '187', imageSrc: webImageNamed('Fabrics/LiningFabrics/187.jpg'), price: null, company: '' },
    { optType: "Lining", key: '190', name: '190', imageSrc: webImageNamed('Fabrics/LiningFabrics/190.jpg'), price: null, company: '' },
    { optType: "Lining", key: '192', name: '192', imageSrc: webImageNamed('Fabrics/LiningFabrics/192.jpg'), price: null, company: '' },
    { optType: "Lining", key: '201', name: '201', imageSrc: webImageNamed('Fabrics/LiningFabrics/201.jpg'), price: null, company: '' },
    { optType: "Lining", key: '202', name: '202', imageSrc: webImageNamed('Fabrics/LiningFabrics/202.jpg'), price: null, company: '' },
    { optType: "Lining", key: '212', name: '212', imageSrc: webImageNamed('Fabrics/LiningFabrics/212.jpg'), price: null, company: '' },
    { optType: "Lining", key: '219', name: '219', imageSrc: webImageNamed('Fabrics/LiningFabrics/219.jpg'), price: null, company: '' },
    { optType: "Lining", key: '220', name: '220', imageSrc: webImageNamed('Fabrics/LiningFabrics/220.jpg'), price: null, company: '' },
    { optType: "Lining", key: '225', name: '225', imageSrc: webImageNamed('Fabrics/LiningFabrics/225.jpg'), price: null, company: '' },
    { optType: "Lining", key: '226', name: '226', imageSrc: webImageNamed('Fabrics/LiningFabrics/226.jpg'), price: null, company: '' },
    { optType: "Lining", key: '227', name: '227', imageSrc: webImageNamed('Fabrics/LiningFabrics/227.jpg'), price: null, company: '' },
    { optType: "Lining", key: '228', name: '228', imageSrc: webImageNamed('Fabrics/LiningFabrics/228.jpg'), price: null, company: '' },
    { optType: "Lining", key: '231', name: '231', imageSrc: webImageNamed('Fabrics/LiningFabrics/231.jpg'), price: null, company: '' },
    { optType: "Lining", key: '232', name: '232', imageSrc: webImageNamed('Fabrics/LiningFabrics/232.jpg'), price: null, company: '' },
    { optType: "Lining", key: '234', name: '234', imageSrc: webImageNamed('Fabrics/LiningFabrics/234.jpg'), price: null, company: '' },
    { optType: "Lining", key: '237', name: '237', imageSrc: webImageNamed('Fabrics/LiningFabrics/237.jpg'), price: null, company: '' },
    { optType: "Lining", key: '238', name: '238', imageSrc: webImageNamed('Fabrics/LiningFabrics/238.jpg'), price: null, company: '' },
    { optType: "Lining", key: '239', name: '239', imageSrc: webImageNamed('Fabrics/LiningFabrics/239.jpg'), price: null, company: '' },
    { optType: "Lining", key: '240', name: '240', imageSrc: webImageNamed('Fabrics/LiningFabrics/240.jpg'), price: null, company: '' },
    { optType: "Lining", key: '241', name: '241', imageSrc: webImageNamed('Fabrics/LiningFabrics/241.jpg'), price: null, company: '' },
    { optType: "Lining", key: '243', name: '243', imageSrc: webImageNamed('Fabrics/LiningFabrics/243.jpg'), price: null, company: '' },
    { optType: "Lining", key: '245', name: '245', imageSrc: webImageNamed('Fabrics/LiningFabrics/245.jpg'), price: null, company: '' },
    { optType: "Lining", key: '246', name: '246', imageSrc: webImageNamed('Fabrics/LiningFabrics/246.jpg'), price: null, company: '' },
    { optType: "Lining", key: '247', name: '247', imageSrc: webImageNamed('Fabrics/LiningFabrics/247.jpg'), price: null, company: '' },
    { optType: "Lining", key: '248', name: '248', imageSrc: webImageNamed('Fabrics/LiningFabrics/248.jpg'), price: null, company: '' },
    { optType: "Lining", key: '254', name: '254', imageSrc: webImageNamed('Fabrics/LiningFabrics/254.jpg'), price: null, company: '' },
    { optType: "Lining", key: '255', name: '255', imageSrc: webImageNamed('Fabrics/LiningFabrics/255.jpg'), price: null, company: '' },
    { optType: "Lining", key: '257', name: '257', imageSrc: webImageNamed('Fabrics/LiningFabrics/257.jpg'), price: null, company: '' },
    { optType: "Lining", key: '259', name: '259', imageSrc: webImageNamed('Fabrics/LiningFabrics/259.jpg'), price: null, company: '' },
    { optType: "Lining", key: '261', name: '261', imageSrc: webImageNamed('Fabrics/LiningFabrics/261.jpg'), price: null, company: '' },
    { optType: "Lining", key: '262', name: '262', imageSrc: webImageNamed('Fabrics/LiningFabrics/262.jpg'), price: null, company: '' },
    { optType: "Lining", key: '267', name: '267', imageSrc: webImageNamed('Fabrics/LiningFabrics/267.jpg'), price: null, company: '' },
    { optType: "Lining", key: '268', name: '268', imageSrc: webImageNamed('Fabrics/LiningFabrics/268.jpg'), price: null, company: '' },
    { optType: "Lining", key: '269', name: '269', imageSrc: webImageNamed('Fabrics/LiningFabrics/269.jpg'), price: null, company: '' },
    { optType: "Lining", key: '270', name: '270', imageSrc: webImageNamed('Fabrics/LiningFabrics/270.jpg'), price: null, company: '' },
    { optType: "Lining", key: '271', name: '271', imageSrc: webImageNamed('Fabrics/LiningFabrics/271.jpg'), price: null, company: '' },
    { optType: "Lining", key: '272', name: '272', imageSrc: webImageNamed('Fabrics/LiningFabrics/272.jpg'), price: null, company: '' },
    { optType: "Lining", key: '273', name: '273', imageSrc: webImageNamed('Fabrics/LiningFabrics/273.jpg'), price: null, company: '' },
    { optType: "Lining", key: '274', name: '274', imageSrc: webImageNamed('Fabrics/LiningFabrics/274.jpg'), price: null, company: '' },
    { optType: "Lining", key: '275', name: '275', imageSrc: webImageNamed('Fabrics/LiningFabrics/275.jpg'), price: null, company: '' },
    { optType: "Lining", key: '276', name: '276', imageSrc: webImageNamed('Fabrics/LiningFabrics/276.jpg'), price: null, company: '' },
    { optType: "Lining", key: '277', name: '277', imageSrc: webImageNamed('Fabrics/LiningFabrics/277.jpg'), price: null, company: '' },
    { optType: "Lining", key: '278', name: '278', imageSrc: webImageNamed('Fabrics/LiningFabrics/278.jpg'), price: null, company: '' },
    { optType: "Lining", key: '289', name: '289', imageSrc: webImageNamed('Fabrics/LiningFabrics/289.jpg'), price: null, company: '' },
    { optType: "Lining", key: '290', name: '290', imageSrc: webImageNamed('Fabrics/LiningFabrics/290.jpg'), price: null, company: '' },
    { optType: "Lining", key: '291', name: '291', imageSrc: webImageNamed('Fabrics/LiningFabrics/291.jpg'), price: null, company: '' },
    { optType: "Lining", key: '292', name: '292', imageSrc: webImageNamed('Fabrics/LiningFabrics/292.jpg'), price: null, company: '' },
    { optType: "Lining", key: '293', name: '293', imageSrc: webImageNamed('Fabrics/LiningFabrics/293.jpg'), price: null, company: '' },
    { optType: "Lining", key: '294', name: '294', imageSrc: webImageNamed('Fabrics/LiningFabrics/294.jpg'), price: null, company: '' },
    { optType: "Lining", key: '295', name: '295', imageSrc: webImageNamed('Fabrics/LiningFabrics/295.jpg'), price: null, company: '' },
    { optType: "Lining", key: '296', name: '296', imageSrc: webImageNamed('Fabrics/LiningFabrics/296.jpg'), price: null, company: '' },
    { optType: "Lining", key: '297', name: '297', imageSrc: webImageNamed('Fabrics/LiningFabrics/297.jpg'), price: null, company: '' },
    { optType: "Lining", key: '298', name: '298', imageSrc: webImageNamed('Fabrics/LiningFabrics/298.jpg'), price: null, company: '' },
    { optType: "Lining", key: '299', name: '299', imageSrc: webImageNamed('Fabrics/LiningFabrics/299.jpg'), price: null, company: '' },
    { optType: "Lining", key: '300', name: '300', imageSrc: webImageNamed('Fabrics/LiningFabrics/300.jpg'), price: null, company: '' },
    { optType: "Lining", key: '301', name: '301', imageSrc: webImageNamed('Fabrics/LiningFabrics/301.jpg'), price: null, company: '' },
    { optType: "Lining", key: '302', name: '302', imageSrc: webImageNamed('Fabrics/LiningFabrics/302.jpg'), price: null, company: '' },
    { optType: "Lining", key: '303', name: '303', imageSrc: webImageNamed('Fabrics/LiningFabrics/303.jpg'), price: null, company: '' },
]



 fabricImageForSelectedKey = (tone, key) => {
    let opts = null
    switch (tone) {

        // AllSeasons: 'fabric_AllSeasons',
        // FallWinter: 'fabric_FallWinter',
        // SpringSummer: 'fabric_SpringSummer',
        // JacquardFabrics: 'fabric_JacquardFabrics',
        // WaterResistant: 'fabric_WaterResistant',


        case this.fabric_AllSeasons1: opts = this.fabric_AllSeasons1; break;
        case this.fabric_AllSeasons2: opts = this.fabric_AllSeasons2; break;
        case this.fabric_AllSeasons3: opts = this.fabric_AllSeasons3; break;
        case this.fabric_AllSeasons4: opts = this.fabric_AllSeasons4; break;

        case this.fabric_FallWinter: opts = this.fabric_FallWinter; break;
        case this.fabric_SpringSummer: opts = this.fabric_SpringSummer; break;
        case this.fabric_JacquardFabrics: opts = this.fabric_JacquardFabrics; break;
        case this.fabric_WaterResistant: opts = this.fabric_WaterResistant; break;
        default: opts = this.fabric_AllSeasons1; break;
    }
    let thisObj = opts.filter((obj) => {
        return obj.key === key
    })
    return webImageNamed(thisObj[0].imageSrc)
}


 optsForTone = (tone) => {
    switch (tone) {
        case this.TONE_KEYS.AllSeasons1: return this.fabric_AllSeasons1; break;
        case this.TONE_KEYS.AllSeasons2: return this.fabric_AllSeasons2; break;
        case this.TONE_KEYS.AllSeasons3: return this.fabric_AllSeasons3; break;
        case this.TONE_KEYS.AllSeasons4: return this.fabric_AllSeasons4; break;

        case this.TONE_KEYS.FallWinter: return this.fabric_FallWinter; break;
        case this.TONE_KEYS.SpringSummer: return this.fabric_SpringSummer; break;
        case this.TONE_KEYS.JacquardFabrics: return this.fabric_JacquardFabrics; break;
        case this.TONE_KEYS.WaterResistant: return this.fabric_WaterResistant; break;
        default: return this.fabric_AllSeasons1;
    }
}

 fabricStep = () => {
    // Change images here with respect to button choices

    // console.log('Calculating Fabric again. SelectedTone =', this.state.selected_Tone.key)

    let thisTone = this.state.selected_Tone ? this.state.selected_Tone.key : this.TONE_KEYS.blues

    let opts = this.optsForTone(thisTone)


    console.log('OPTS NOW =', opts)
    console.log(opts)


    return {
        name: 'Pick a Fabric',
        key: this.STEP_KEYS.fabric,
        opts: opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1'
    }
}


scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(this.scrollToTop);
        window.scrollTo(0, c - c / 8);
    }
}


onChangeHandler = (direction) => {
    console.log('CHANGE HAPPEND -', direction)
    this.scrollToTop()
    if (direction === 'done') {
        this.donePressed()
    }
    if (direction === 'cancelled') {
        // Cancel Pressed
    }
}





donePressed = () => {

    // Create And Save Style Here first


    if (this._mounted) {
        let ns = { ...this.state }
        ns.isComplete = true
        this.setState(ns)


        // CHECK 3
        // const frontImageObj = this.fro.filter((obj) => {
        //     return obj.key === this.state.selected_Collar
        // })
        let mainImage = imageNamed('singalButton')

        if (this.state.selected_SuitStyle.imageSrc) {
            mainImage = this.state.selected_SuitStyle.imageSrc
        }

        let fabricImage = null

        if (this.state.selected_Fabric.imageSrc) {
            fabricImage = this.state.selected_Fabric.imageSrc
        }
        let styleName = this.state.productType + "-" + makeid(3)

        if (this.state.editingProduct) {
            uniqId = this.state.editingProduct.key
            styleName = this.state.editingProduct.styleName
        }


        // 1. Suit Style
        // 2. Construction
        // 3. Fit Preference
        // 4. Lapel
        // 5. Lapel Width
        // 6. Lapel Button Hole
        // 7. Pocket Style
        // 8. Vent
        // 9. Sleeve Button
        // 10. Sleeve Button Orientation
        // 11. Lining Option
        // 12. Monogram
        // 13. Contrast Option
        // 14. Pant Fit Preference
        // 15. Pant Pleat
        // 16. Pant Side Pockets
        // 17. Pant Back Pockets
        // 18. Waist Band Type
        // 19. Pant Bottom
        // 20. Pant Monogram


        console.log("--------SELELCTED FABRIC IS ---------");
        console.log(this.state.selected_Fabric);



        let allSelelected = [
            this.state.selected_SuitStyle,
            this.state.selected_Construction,
            this.state.selected_FitPreference,
            this.state.selected_Lapel,
            this.state.selected_LapelWidth,
            this.state.selected_LapelButtonHole,
            this.state.selected_LapelButtonHoleThread,
            this.state.selected_PocketStyle,
            this.state.selected_Vent,
            this.state.selected_SleeveButton,
            this.state.selected_SleeveButtonOrientation,
            this.state.selected_SleeveButtonHoleContrast,
            this.state.selected_SleeveButtonHoleContrastThread,
            this.state.selected_LiningOption,
            this.state.selected_Monogram,
            this.state.selected_MonogramThreadColor,
            this.state.selected_PantFitPreference,
            this.state.selected_PantPleat,
            this.state.selected_PantSidePockets,
            this.state.selected_PantBackPockets,
            this.state.selected_WaistBandType,
            this.state.selected_PantBottom,
            this.state.selected_PantMonogram,
            this.state.selected_PantMonogramThreadColor,
            this.state.selected_Tone,
            this.state.selected_Fabric,
            this.state.selected_FabricLining,
            this.state.selected_SuitButtons,
            this.state.selected_ContrastOption,
            this.state.selected_ContrastFabric
        ]


        let finalPrice = this.basePrice
        let addedPriceObjs = []
        let addOnPrice = 0

        allSelelected.forEach(asel => {
            console.log("ASEL");
            console.log(asel);
            if (asel != null) {
                if (asel.price != null) {
                    finalPrice += asel.price
                    addOnPrice += asel.price
                    addedPriceObjs.push({ type: asel.optType, name: asel.name, price: asel.price })
                }
            }

        })



        // allSelelected.forEach( selectedObj  => {
        //     if (selelectedObj.price) {
        //         finalPrice += selectedObj.price
        //         addedPriceObjs.push( { type: selectedObj.optType, name: selectedObj.name, price: selectedObj.price })
        //     }
        // })

        console.log("this.state.selected_Collar = ")
        console.log(this.state.selected_Collar)

        // 1. Suit Style
        // 2. Construction
        // 3. Fit Preference
        // 4. Lapel
        // 5. Lapel Width
        // 6. Lapel Button Hole
        // 7. Pocket Style
        // 8. Vent
        // 9. Sleeve Button
        // 10. Sleeve Button Orientation
        // 11. Lining Option
        // 12. Monogram
        // 13. Contrast Option
        // 14. Pant Fit Preference
        // 15. Pant Pleat
        // 16. Pant Side Pockets
        // 17. Pant Back Pockets
        // 18. Waist Band Type
        // 19. Pant Bottom
        // 20. Pant Monogram




        let style = {
            suitStyle: this.state.selected_SuitStyle,
            construction: this.state.selected_Construction,
            suitStyle: this.state.selected_SuitStyle,
            construction: this.state.selected_Construction,
            fitPreference: this.state.selected_FitPreference,
            lapel: this.state.selected_Lapel,
            lapelWidth: this.state.selected_LapelWidth,
            lapelButtonHole: this.state.selected_LapelButtonHole,
            lapelButtonHoleThread: this.state.selected_LapelButtonHoleThread,
            pocketStyle: this.state.selected_PocketStyle,
            vent: this.state.selected_Vent,
            sleeveButton: this.state.selected_SleeveButton,
            sleeveButtonOrientation: this.state.selected_SleeveButtonOrientation,
            sleeveButtonHoleContrast: this.state.selected_SleeveButtonHoleContrast,
            sleeveButtonHoleContrastThread: this.state.selected_SleeveButtonHoleContrastThread,
            liningOption: this.state.selected_LiningOption,
            monogram: this.state.selected_Monogram,
            monogramThreadColor: this.state.selected_MonogramThreadColor,
            pantFitPreference: this.state.selected_PantFitPreference,
            pantPleat: this.state.selected_PantPleat,
            pantSidePockets: this.state.selected_PantSidePockets,
            pantBackPockets: this.state.selected_PantBackPockets,
            waistBandType: this.state.selected_WaistBandType,
            pantBottom: this.state.selected_PantBottom,
            pantMonogram: this.state.selected_PantMonogram,
            pantMonogramThreadColor: this.state.selected_PantMonogramThreadColor,
            tone: this.state.selected_Tone,
            fabric: this.state.selected_Fabric,
            fabricLining: this.state.selected_FabricLining,
            suitButtons: this.state.selected_SuitButtons,
            contrastOption: this.state.selected_ContrastOption,
            contrastFabric: this.state.selected_ContrastFabric
        }


        let styleArr = Object.keys(style).map(function (key) {

            return [key, style[key]];
        });

        let withoutNull = styleArr.filter(ar => {
            return !ar.includes(null)
        })


        let description = ' '
        withoutNull.forEach((ms, index) => {
            let type = ms[0]
            let val = ms[1].name


            description += ' ' + type + ':' + val
            if (ms[1].customInput) {
                description += `<${ms[1].customInput}> `
            }
            if (index == (withoutNull.length - 1)) {
                description += ""
            } else {
                description += ","
            }


        })


        // let prodObj = new C_Product(null,true,this.state.productType, uniqId,"",null, null,[], [], style, "", description, false, null, true, false, null, true, [], currencySymbol ,this.basePrice,addOnPrice, 0,0, finalPrice, addedPriceObjs, styleName, fabricImage, mainImage)

        // prodObj.key = 'id' + (new Date()).getTime();


        // // let prodObj = {
        // //     key: uniqId,
        // //     styleName:  styleName,
        // //     frontImage: mainImage,
        // //     fabricImage: fabricImage,
        // //     description: description,
        // //     type: this.state.productType,
        // //     basePrice: this.basePrice,
        // //     price: finalPrice,
        // //     addedPriceObjs: addedPriceObjs,
        // //     selectedSelections: style
        // // }

        let prod = Product.init()
        prod.type = this.state.productType
        prod.conciergeSelections = [style]

        let fabricAndToneMaterials = []

        let concToTwSelections = Object.values(style).map((obj) => {
            if (obj === null) {
                return null
            }

            if (`${obj.optType}`.toLowerCase() === 'fabric') {
                fabricAndToneMaterials.push("Fabric - " + obj.name + (obj.customInput ? `<${obj.customInput}>` : ""))
                return null
            }

            if (`${obj.optType}`.toLowerCase() === 'tone') {
                return null
                // fabricAndToneMaterials.push("Fabric - " + obj.name  + (obj.customInput ?  `<${obj.customInput}>` : "")  )
            }

            return {
                "key": `${obj.optType}`,
                "value": obj.name + (obj.customInput ? `<${obj.customInput}>` : ""),
                "styleSelectionId": obj.styleSelectionId ?? "",
                "imageSrc": obj.imageSrc ?? null,
                "isConciergeSelection": true
            }
        })

        concToTwSelections = concToTwSelections.filter((obj) => {
            return (obj !== null)
        })

        prod.materialToSource = fabricAndToneMaterials


        prod.styleSelections = concToTwSelections

        prod.basePrice = this.basePrice
        prod.addOnPrice = addOnPrice
        prod.name = styleName
        prod.taxOnPrice = 0
        prod.shippingPrice = 0
        prod.taxDescription = ""
        prod.shippingDescription = ""
        prod.price = finalPrice
        prod.addedPriceObjs = addedPriceObjs
        prod.styleName = styleName
        prod.imageUrl = mainImage
        prod.fabricImage = fabricImage
        prod.imagesUrls = [mainImage, fabricImage]
        prod.description = description
        prod.isBespoke = true
        prod.isViaConcierge = true

        console.log('Sending Style Object -', prod)

        this.props.isDone('created', prod)

    }
}

stepItemSelected = (stepKey, itemKey, customInput) => {
    console.log('Step selection happend in StepKey =' + stepKey + ', ItemKey= ', itemKey)

    if (this._mounted) {
        console.log('IS MOUNTED')
        let ns = { ...this.state }
        console.log("GOT Custom INPUT " + customInput)

        // 1. Suit Style
        // 2. Construction
        // 3. Fit Preference
        // 4. Lapel
        // 5. Lapel Width
        // 6. Lapel Button Hole
        // 7. Pocket Style
        // 8. Vent
        // 9. Sleeve Button
        // 10. Sleeve Button Orientation
        // 11. Lining Option
        // 12. Monogram
        // 13. Contrast Option
        // 14. Pant Fit Preference
        // 15. Pant Pleat
        // 16. Pant Side Pockets
        // 17. Pant Back Pockets
        // 18. Waist Band Type
        // 19. Pant Bottom
        // 20. Pant Monogram




        switch (stepKey) {
            case this.STEP_KEYS.suitStyle: ns.selected_SuitStyle = this.suitStyle_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.construction: ns.selected_Construction = this.construction_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.fitPreference: ns.selected_FitPreference = this.fitPreference_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.lapel: ns.selected_Lapel = this.lapel_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.lapelWidth: ns.selected_LapelWidth = this.lapelWidth_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.lapelButtonHole: ns.selected_LapelButtonHole = this.lapelButtonHole_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.lapelButtonHoleThread: ns.selected_LapelButtonHoleThread = this.lapelButtonHoleThread_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.pocketStyle: ns.selected_PocketStyle = this.pocketStyle_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.vent: ns.selected_Vent = this.vent_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.sleeveButton: ns.selected_SleeveButton = this.sleeveButton_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.sleeveButtonOrientation: ns.selected_SleeveButtonOrientation = this.sleeveButtonOrientation_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.sleeveButtonHoleContrast: ns.selected_SleeveButtonHoleContrast = this.sleeveButtonHoleContrast_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.sleeveButtonHoleContrastThread: ns.selected_SleeveButtonHoleContrastThread = this.sleeveButtonHoleContrastThread_Opts.filter(opt => { return opt.key === itemKey })[0]; break;

            case this.STEP_KEYS.liningOption: ns.selected_LiningOption = this.liningOption_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.monogram: ns.selected_Monogram = this.monogram_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.monogramThreadColor: ns.selected_MonogramThreadColor = this.monogramThreadColor_Opts.filter(opt => { return opt.key === itemKey })[0]; break;

            case this.STEP_KEYS.pantFitPreference: ns.selected_PantFitPreference = this.pantFitPreference_Opts.filter(opt => { return opt.key === itemKey })[0]; if (customInput && customInput !== "") { ns.selected_Monogram["customInput"] = customInput }; break;
            case this.STEP_KEYS.pantPleat: ns.selected_PantPleat = this.pantPleat_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.pantSidePockets: ns.selected_PantSidePockets = this.pantSidePockets_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.pantBackPockets: ns.selected_PantBackPockets = this.pantBackPockets_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.waistBandType: ns.selected_WaistBandType = this.waistBandType_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.pantBottom: ns.selected_PantBottom = this.pantBottom_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.pantMonogram: ns.selected_PantMonogram = this.pantMonogram_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.pantMonogramThreadColor: ns.selected_PantMonogramThreadColor = this.pantMonogramThreadColor_Opts.filter(opt => { return opt.key === itemKey })[0]; break;

            case this.STEP_KEYS.tone: ns.selected_Tone = this.toneOpts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.suitButtons: ns.selected_SuitButtons = this.suitButtons_Step().opts.filter(opt => { return opt.key === itemKey })[0]; break;

            case this.STEP_KEYS.contrastOption: ns.selected_ContrastOption = this.contrast_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.contrastFabric: ns.selected_ContrastFabric = this.contrastFabricOpts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.fabric: ns.selected_Fabric = this.fabricStep().opts.filter(opt => { return opt.key === itemKey })[0]; break;
            case this.STEP_KEYS.fabricLining: ns.selected_FabricLining = this.fabricLining_Step().opts.filter(opt => { return opt.key === itemKey })[0]; break;

            default: break;
        }

        // if (stepKey === this.STEP_KEYS.fabricLining && stepKey.length === this.STEP_KEYS.fabricLining.length ) {
        //     console.log("--------GOT FABRIC LINING STEP -------");
        //     console.log("STEPKEY = " + stepKey);
        //     console.log("ITEMKEY = " + itemKey);
        //     ns.selected_FabricLining = this.fabricLining_Step().opts.filter(opt => { return opt.key === itemKey })[0];
        // }

        this.setState(ns)
    }

}

createUploadChoiceMade = (name, code) => {
    if (this._mounted) {
        let ns = { ...this.state }
        ns.selectedCreateOrUpload = code
        this.setState(ns)
    }
}

createUploadChooser = () => {
    // return <div> <Box className='is-radiusless is-shadowless layerGreyBG  minHeight300 myFlexContainer '>
    return <div> <Box className='is-radiusless is-shadowless  minHeight300 myFlexContainer '>
        <ChooseBox choices={[{ name: 'Create Style', code: this.STYLE_START.create }, { name: 'Upload Style', code: this.STYLE_START.upload }]} choiceMade={this.createUploadChoiceMade} />
        {/* <ChooseBox choices={[{ name: 'Choose Style' }, { name: 'Upload Style' }]} choiceMade={this.choiceMadeInChooseBox} /> */}
    </Box>
        <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button>
    </div>
}




uploadImageStarted = () => {
    console.log('UPLOAD IMAGE STARTED IN WIZARD')
}






jacketWizard = () => {

    // 1. Suit Style
    // 2. Construction
    // 3. Fit Preference
    // 4. Lapel
    // 5. Lapel Width
    // 6. Lapel Button Hole
    // 7. Pocket Style
    // 8. Vent
    // 9. Sleeve Button
    // 10. Sleeve Button Orientation
    // 11. Lining Option
    // 12. Monogram
    // 13. Contrast Option
    // 14. Pant Fit Preference
    // 15. Pant Pleat
    // 16. Pant Side Pockets
    // 17. Pant Back Pockets
    // 18. Waist Band Type
    // 19. Pant Bottom
    // 20. Pant Monogram




    let suitStyle_Step = this.suitStyle_Step()
    let construction_Step = this.construction_Step()
    let fitPreference_Step = this.fitPreference_Step()

    let lapel_Step = this.lapel_Step()
    let lapelWidth_Step = this.lapelWidth_Step()
    let lapelButtonHole_Step = this.lapelButtonHole_Step() 
    let lapelButtonHoleThread_Step = this.lapelButtonHoleThread_Step()  // Show on first 2 options

    let pocketStyle_Step = this.pocketStyle_Step()
    let vent_Step = this.vent_Step()
    let sleeveButton_Step = this.sleeveButton_Step()
    let sleeveButtonOrientation_Step = this.sleeveButtonOrientation_Step()

    let sleeveButtonHoleContrast_Step = this.sleeveButtonHoleContrast_Step()
    let sleeveButtonHoleContrastThread_Step = this.sleeveButtonHoleContrastThread_Step() // dont show on first 

    let liningOption_Step = this.liningOption_Step()
    let monogram_Step = this.monogram_Step() // add no monogram option
    let monogramThreadColor_Step = this.monogramThreadColor_Step() // dont show on no monogram
    let pantFitPreference_Step = this.pantFitPreference_Step()

    let pantPleat_Step = this.pantPleat_Step()
    let pantSidePockets_Step = this.pantSidePockets_Step()
    let pantBackPockets_Step = this.pantBackPockets_Step()
    let waistBandType_Step = this.waistBandType_Step()
    let pantBottom_Step = this.pantBottom_Step()
    let pantMonogram_Step = this.pantMonogram_Step()
    let pantMonogramThreadColor_Step = this.pantMonogramThreadColor_Step()


    let toneStep = this.toneStep()
    let fabricStep = this.fabricStep()
    let fabricLining_Step = this.fabricLining_Step()
    let suitButtons_Step = this.suitButtons_Step()

    let contrast_Step = this.contrast_Step()
    let contrastFabric_Step = this.contrastFabricStep()

    let isContrastFabricNeeded = false
    if (this.state.selected_ContrastOption) {
        if (this.state.selected_ContrastOption !== this.contrast_Opts[0])  {
            isContrastFabricNeeded = true
        }
    } 


    // lapelButtonHoleThread conditioned on lapelButtonHole
    let is_lapelButtonHoleThread_Needed = false
    if (this.state.selected_LapelButtonHole) {
        if (this.state.selected_LapelButtonHole !== this.lapelButtonHole_Opts[0])  {
            // show on first 2 options.. not on third
            is_lapelButtonHoleThread_Needed = true
        }
    } 

    // sleeveButtonHoleContrastThread conditioned on sleeveButtonHoleContrast
    let is_sleeveButtonHoleContrastThread_Needed = false
    if (this.state.selected_SleeveButtonHoleContrast) {
        if (this.state.selected_SleeveButtonHoleContrast !== this.sleeveButtonHoleContrast_Opts[0])  {
            is_sleeveButtonHoleContrastThread_Needed = true
        }
    } 

    // monogramThreadColor conditioned on monogram
    let is_monogramThreadColor_Needed = false
    if (this.state.selected_Monogram) {
        if (this.state.selected_Monogram !== this.monogram_Opts[0])  {
            is_monogramThreadColor_Needed = true
        }
    } 

    // pantMonogramThreadColor conditioned on pantMonogram
    let is_pantMonogramThreadColor_Needed = false
    if (this.state.selected_PantMonogram) {
        if (this.state.selected_PantMonogram !== this.pantMonogram_Opts[0])  {
            is_pantMonogramThreadColor_Needed = true
        }
    } 

    // // contrastFabric conditioned on contrastOption
    // let is_contrastFabric_Needed = false
    // if (this.state.selected_ContrastOption {
    //     if (this.state.selected_ContrastOption !== this.contrast_Opts[0])  {
    //         is_contrastFabric_Needed = true
    //     }
    // } 





    let title = "Let's Create a Suit Style"
    let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
    let points = [
        'To make it easy we pick the cuts and tones before finding the fabric.',
        'We will measure you right after we have created all the needed styles.'
    ]




    let stepArr = [
        suitStyle_Step,
        construction_Step,
        fitPreference_Step,
        lapel_Step,
        lapelWidth_Step,
        lapelButtonHoleThread_Step,
        lapelButtonHole_Step,
        pocketStyle_Step,
        vent_Step,
        sleeveButton_Step,
        sleeveButtonOrientation_Step,
        sleeveButtonHoleContrast_Step,
        sleeveButtonHoleContrastThread_Step,
        liningOption_Step,
        monogram_Step,
        monogramThreadColor_Step,
        pantFitPreference_Step,
        pantPleat_Step,
        pantSidePockets_Step,
        pantBackPockets_Step,
        waistBandType_Step,
        pantBottom_Step,
        pantMonogram_Step,
        pantMonogramThreadColor_Step,
        toneStep,
        fabricStep,
        fabricLining_Step,
        suitButtons_Step,
        contrast_Step,
        contrastFabric_Step
    ]

    return (<div >
        {/* <div ref={this.mainStepperDiv}></div> */}
        
        {(this.state.isComplete || this.state.isCancelled) ?
            <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
            :
            <StepWizard className=' fadeIn'>
                {/* {showUploader ? <Step type='uploader' skipable={true} isFirst={true} existingUploadImage={this.state.uploadImage} uploadImagePressed={this.uploadImageStarted} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  ></Step> : null} */}
                {this.state.editingProduct ? null : <Step name="Welcome" imageSrc={imageNamed('IconBg-Style')} overrideNext='Start' isMessageScreen={true} title={title} subtitle={subtitle} points={points} isFirst={true} skipable={false} stepKey={"12wjkbwj3"} exitingSelectedKey={null} opts={null} isPhotoBox={false} photoRatio={'16by9'} onChange={this.onChangeHandler} itemSelected={null}  ></Step>}
                {/* {
                    stepArr.map((thisStep, index) => {
                        <Step name={thisStep.name} skipable={thisStep.skipable ?? false} stepKey={thisStep.key} exitingSelectedKey={thisStep.key} opts={thisStep.opts} isFirst={index === 0} isPhotoBox={thisStep.isPhotoBox} photoRatio={thisStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={index === (stepArr.length - 1)} paddingLess={stepArr.boldText ?? false} boldText={stepArr.boldText ?? false} ></Step>
                    })
                } */}




                <Step name={suitStyle_Step.name} skipable={suitStyle_Step.skipable ?? false} stepKey={suitStyle_Step.key} exitingSelectedKey={this.state.selected_SuitStyle ? this.state.selected_SuitStyle.key : null} opts={suitStyle_Step.opts} isFirst={true} isPhotoBox={suitStyle_Step.isPhotoBox} photoRatio={suitStyle_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={suitStyle_Step.boldText ?? false} boldText={suitStyle_Step.boldText ?? false} ></Step>
                <Step name={construction_Step.name} skipable={construction_Step.skipable ?? false} stepKey={construction_Step.key} exitingSelectedKey={this.state.selected_Construction ? this.state.selected_Construction.key : null} opts={construction_Step.opts} isFirst={false} isPhotoBox={construction_Step.isPhotoBox} photoRatio={construction_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={construction_Step.boldText ?? false} boldText={construction_Step.boldText ?? false} ></Step>
                <Step name={fitPreference_Step.name} skipable={fitPreference_Step.skipable ?? false} stepKey={fitPreference_Step.key} exitingSelectedKey={this.state.selected_FitPreference ? this.state.selected_FitPreference.key : null} opts={fitPreference_Step.opts} isFirst={false} isPhotoBox={fitPreference_Step.isPhotoBox} photoRatio={fitPreference_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={fitPreference_Step.boldText ?? false} boldText={fitPreference_Step.boldText ?? false} ></Step>
                <Step name={lapel_Step.name} skipable={lapel_Step.skipable ?? false} stepKey={lapel_Step.key} exitingSelectedKey={this.state.selected_Lapel ? this.state.selected_Lapel.key : null} opts={lapel_Step.opts} isFirst={false} isPhotoBox={lapel_Step.isPhotoBox} photoRatio={lapel_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={lapel_Step.boldText ?? false} boldText={lapel_Step.boldText ?? false} ></Step>
                <Step name={lapelWidth_Step.name} skipable={lapelWidth_Step.skipable ?? false} stepKey={lapelWidth_Step.key} exitingSelectedKey={this.state.selected_LapelWidth ? this.state.selected_LapelWidth.key : null} opts={lapelWidth_Step.opts} isFirst={false} isPhotoBox={lapelWidth_Step.isPhotoBox} photoRatio={lapelWidth_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={lapelWidth_Step.boldText ?? false} boldText={lapelWidth_Step.boldText ?? false} ></Step>
                <Step name={lapelButtonHole_Step.name} skipable={lapelButtonHole_Step.skipable ?? false} stepKey={lapelButtonHole_Step.key} exitingSelectedKey={this.state.selected_LapelButtonHole ? this.state.selected_LapelButtonHole.key : null} opts={lapelButtonHole_Step.opts} isFirst={false} isPhotoBox={lapelButtonHole_Step.isPhotoBox} photoRatio={lapelButtonHole_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={lapelButtonHole_Step.boldText ?? false} boldText={lapelButtonHole_Step.boldText ?? false} ></Step>

                {
                    is_lapelButtonHoleThread_Needed ?
                        <Step name={lapelButtonHoleThread_Step.name} skipable={lapelButtonHoleThread_Step.skipable ?? false} stepKey={lapelButtonHoleThread_Step.key} exitingSelectedKey={this.state.selected_LapelButtonHoleThread ? this.state.selected_LapelButtonHoleThread.key : null} opts={lapelButtonHoleThread_Step.opts} isFirst={false} isPhotoBox={lapelButtonHoleThread_Step.isPhotoBox} photoRatio={lapelButtonHoleThread_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={lapelButtonHoleThread_Step.boldText ?? false} boldText={lapelButtonHoleThread_Step.boldText ?? false} ></Step>
                    : null
                }  

                <Step name={pocketStyle_Step.name} skipable={pocketStyle_Step.skipable ?? false} stepKey={pocketStyle_Step.key} exitingSelectedKey={this.state.selected_PocketStyle ? this.state.selected_PocketStyle.key : null} opts={pocketStyle_Step.opts} isFirst={false} isPhotoBox={pocketStyle_Step.isPhotoBox} photoRatio={pocketStyle_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={pocketStyle_Step.boldText ?? false} boldText={pocketStyle_Step.boldText ?? false} ></Step>
                <Step name={vent_Step.name} skipable={vent_Step.skipable ?? false} stepKey={vent_Step.key} exitingSelectedKey={this.state.selected_Vent ? this.state.selected_Vent.key : null} opts={vent_Step.opts} isFirst={false} isPhotoBox={vent_Step.isPhotoBox} photoRatio={vent_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={vent_Step.boldText ?? false} boldText={vent_Step.boldText ?? false} ></Step>
                <Step name={sleeveButton_Step.name} skipable={sleeveButton_Step.skipable ?? false} stepKey={sleeveButton_Step.key} exitingSelectedKey={this.state.selected_SleeveButtonOrientation ? this.state.selected_SleeveButtonOrientation.key : null} opts={sleeveButton_Step.opts} isFirst={false} isPhotoBox={sleeveButton_Step.isPhotoBox} photoRatio={sleeveButton_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={sleeveButton_Step.boldText ?? false} boldText={sleeveButton_Step.boldText ?? false} ></Step>
                <Step name={sleeveButtonOrientation_Step.name} skipable={sleeveButtonOrientation_Step.skipable ?? false} stepKey={sleeveButtonOrientation_Step.key} exitingSelectedKey={this.state.selected_SleeveButtonOrientation ? this.state.selected_SleeveButtonOrientation.key : null} opts={sleeveButtonOrientation_Step.opts} isFirst={false} isPhotoBox={sleeveButtonOrientation_Step.isPhotoBox} photoRatio={sleeveButtonOrientation_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={sleeveButtonOrientation_Step.boldText ?? false} boldText={sleeveButtonOrientation_Step.boldText ?? false} ></Step>
                
                <Step name={sleeveButtonHoleContrast_Step.name} skipable={sleeveButtonHoleContrast_Step.skipable ?? false} stepKey={sleeveButtonHoleContrast_Step.key} exitingSelectedKey={this.state.selected_SleeveButtonHoleContrast ? this.state.selected_SleeveButtonHoleContrast.key : null} opts={sleeveButtonHoleContrast_Step.opts} isFirst={false} isPhotoBox={sleeveButtonHoleContrast_Step.isPhotoBox} photoRatio={sleeveButtonHoleContrast_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={sleeveButtonHoleContrast_Step.boldText ?? false} boldText={sleeveButtonHoleContrast_Step.boldText ?? false} ></Step>
                
                {
                    is_sleeveButtonHoleContrastThread_Needed ? 
                        <Step name={sleeveButtonHoleContrastThread_Step.name} skipable={sleeveButtonHoleContrastThread_Step.skipable ?? false} stepKey={sleeveButtonHoleContrastThread_Step.key} exitingSelectedKey={this.state.selected_SleeveButtonHoleContrastThread ? this.state.selected_SleeveButtonHoleContrastThread.key : null} opts={sleeveButtonHoleContrastThread_Step.opts} isFirst={false} isPhotoBox={sleeveButtonHoleContrastThread_Step.isPhotoBox} photoRatio={sleeveButtonHoleContrastThread_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={sleeveButtonHoleContrastThread_Step.boldText ?? false} boldText={sleeveButtonHoleContrastThread_Step.boldText ?? false} ></Step>
                    : null
                }

                
                <Step name={liningOption_Step.name} skipable={liningOption_Step.skipable ?? false} stepKey={liningOption_Step.key} exitingSelectedKey={this.state.selected_LiningOption ? this.state.selected_LiningOption.key : null} opts={liningOption_Step.opts} isFirst={false} isPhotoBox={liningOption_Step.isPhotoBox} photoRatio={liningOption_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={liningOption_Step.boldText ?? false} boldText={liningOption_Step.boldText ?? false} ></Step>

                <Step name={monogram_Step.name} needsCustomInput={true} skipable={monogram_Step.skipable ?? false} stepKey={monogram_Step.key} exitingSelectedKey={this.state.selected_Monogram ? this.state.selected_Monogram.key : null} opts={monogram_Step.opts} isFirst={false} isPhotoBox={monogram_Step.isPhotoBox} photoRatio={monogram_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={monogram_Step.boldText ?? false} boldText={monogram_Step.boldText ?? false} ></Step>
                
                {
                    is_monogramThreadColor_Needed ?
                        <Step name={monogramThreadColor_Step.name} needsCustomInput={false} skipable={monogramThreadColor_Step.skipable ?? false} stepKey={monogramThreadColor_Step.key} exitingSelectedKey={this.state.selected_MonogramThreadColor ? this.state.selected_MonogramThreadColor.key : null} opts={monogramThreadColor_Step.opts} isFirst={false} isPhotoBox={monogramThreadColor_Step.isPhotoBox} photoRatio={monogramThreadColor_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={monogramThreadColor_Step.boldText ?? false} boldText={monogramThreadColor_Step.boldText ?? false} ></Step>
                    : null
                }


                
                <Step name={pantFitPreference_Step.name} needsCustomInput={false} skipable={pantFitPreference_Step.skipable ?? false} stepKey={pantFitPreference_Step.key} exitingSelectedKey={this.state.selected_PantFitPreference ? this.state.selected_PantFitPreference.key : null} opts={pantFitPreference_Step.opts} isFirst={false} isPhotoBox={pantFitPreference_Step.isPhotoBox} photoRatio={pantFitPreference_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={pantFitPreference_Step.boldText ?? false} boldText={pantFitPreference_Step.boldText ?? false} ></Step>
                <Step name={pantPleat_Step.name} needsCustomInput={false} skipable={pantPleat_Step.skipable ?? false} stepKey={pantPleat_Step.key} exitingSelectedKey={this.state.selected_PantPleat ? this.state.selected_PantPleat.key : null} opts={pantPleat_Step.opts} isFirst={false} isPhotoBox={pantPleat_Step.isPhotoBox} photoRatio={pantPleat_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={pantPleat_Step.boldText ?? false} boldText={pantPleat_Step.boldText ?? false} ></Step>
                <Step name={pantSidePockets_Step.name} needsCustomInput={false} skipable={pantSidePockets_Step.skipable ?? false} stepKey={pantSidePockets_Step.key} exitingSelectedKey={this.state.selected_PantSidePockets ? this.state.selected_PantSidePockets.key : null} opts={pantSidePockets_Step.opts} isFirst={false} isPhotoBox={pantSidePockets_Step.isPhotoBox} photoRatio={pantSidePockets_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={pantSidePockets_Step.boldText ?? false} boldText={pantSidePockets_Step.boldText ?? false} ></Step>
                <Step name={pantBackPockets_Step.name} needsCustomInput={false} skipable={pantBackPockets_Step.skipable ?? false} stepKey={pantBackPockets_Step.key} exitingSelectedKey={this.state.selected_PantBackPockets ? this.state.selected_PantBackPockets.key : null} opts={pantBackPockets_Step.opts} isFirst={false} isPhotoBox={pantBackPockets_Step.isPhotoBox} photoRatio={pantBackPockets_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={pantBackPockets_Step.boldText ?? false} boldText={pantBackPockets_Step.boldText ?? false} ></Step>
                <Step name={waistBandType_Step.name} needsCustomInput={false} skipable={waistBandType_Step.skipable ?? false} stepKey={waistBandType_Step.key} exitingSelectedKey={this.state.selected_WaistBandType ? this.state.selected_WaistBandType.key : null} opts={waistBandType_Step.opts} isFirst={false} isPhotoBox={waistBandType_Step.isPhotoBox} photoRatio={waistBandType_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={waistBandType_Step.boldText ?? false} boldText={waistBandType_Step.boldText ?? false} ></Step>
                <Step name={pantBottom_Step.name} needsCustomInput={false} skipable={pantBottom_Step.skipable ?? false} stepKey={pantBottom_Step.key} exitingSelectedKey={this.state.selected_PantBottom ? this.state.selected_PantBottom.key : null} opts={pantBottom_Step.opts} isFirst={false} isPhotoBox={pantBottom_Step.isPhotoBox} photoRatio={pantBottom_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={pantBottom_Step.boldText ?? false} boldText={pantBottom_Step.boldText ?? false} ></Step>
                <Step name={pantMonogram_Step.name} needsCustomInput={true} skipable={pantMonogram_Step.skipable ?? false} stepKey={pantMonogram_Step.key} exitingSelectedKey={this.state.selected_PantMonogram ? this.state.selected_PantMonogram.key : null} opts={pantMonogram_Step.opts} isFirst={false} isPhotoBox={pantMonogram_Step.isPhotoBox} photoRatio={pantMonogram_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={pantMonogram_Step.boldText ?? false} boldText={pantMonogram_Step.boldText ?? false} ></Step>
               
               {
                   is_pantMonogramThreadColor_Needed ?
                        <Step name={pantMonogramThreadColor_Step.name} needsCustomInput={false} skipable={pantMonogramThreadColor_Step.skipable ?? false} stepKey={pantMonogramThreadColor_Step.key} exitingSelectedKey={this.state.selected_PantMonogramThreadColor ? this.state.selected_PantMonogramThreadColor.key : null} opts={pantMonogramThreadColor_Step.opts} isFirst={false} isPhotoBox={pantMonogramThreadColor_Step.isPhotoBox} photoRatio={pantMonogramThreadColor_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={pantMonogramThreadColor_Step.boldText ?? false} boldText={pantMonogramThreadColor_Step.boldText ?? false} ></Step>
                   : null
               }


                <Step name={toneStep.name} skipable={toneStep.skipable ?? false} stepKey={toneStep.key} exitingSelectedKey={this.state.selected_Tone ? this.state.selected_Tone.key : null} opts={toneStep.opts} isFirst={false} isPhotoBox={toneStep.isPhotoBox} photoRatio={toneStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={toneStep.boldText ?? false} boldText={toneStep.boldText ?? false} ></Step>
                <Step name={fabricStep.name} skipable={fabricStep.skipable ?? false} stepKey={fabricStep.key} exitingSelectedKey={this.state.selected_Fabric ? this.state.selected_Fabric.key : null} opts={fabricStep.opts} isFirst={false} isPhotoBox={fabricStep.isPhotoBox} photoRatio={fabricStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={fabricStep.boldText ?? false} boldText={fabricStep.boldText ?? false} ></Step>
                
                <Step name={fabricLining_Step.name} skipable={fabricLining_Step.skipable ?? false} stepKey={fabricLining_Step.key} exitingSelectedKey={this.state.selected_FabricLining ? this.state.selected_FabricLining.key : null} opts={fabricLining_Step.opts} isFirst={false} isPhotoBox={fabricLining_Step.isPhotoBox} photoRatio={fabricLining_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={fabricLining_Step.boldText ?? false} boldText={fabricLining_Step.boldText ?? false} ></Step>

                <Step name={suitButtons_Step.name} skipable={suitButtons_Step.skipable ?? false} stepKey={suitButtons_Step.key} exitingSelectedKey={this.state.selected_SuitButtons ? this.state.selected_SuitButtons.key : null} opts={suitButtons_Step.opts} isFirst={false} isPhotoBox={suitButtons_Step.isPhotoBox} photoRatio={suitButtons_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={suitButtons_Step.boldText ?? false} boldText={suitButtons_Step.boldText ?? false} ></Step>


                <Step name={contrast_Step.name} needsCustomInput={false} skipable={contrast_Step.skipable ?? false} stepKey={contrast_Step.key} exitingSelectedKey={this.state.selected_ContrastOption ? this.state.selected_ContrastOption.key : null} opts={contrast_Step.opts} isFirst={false} isPhotoBox={contrast_Step.isPhotoBox} photoRatio={contrast_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={!isContrastFabricNeeded} paddingLess={contrast_Step.boldText ?? false} boldText={contrast_Step.boldText ?? false} ></Step>

                {
                    isContrastFabricNeeded  ?

                    <Step name={contrastFabric_Step.name} needsCustomInput={false} skipable={contrastFabric_Step.skipable ?? false} stepKey={contrastFabric_Step.key} exitingSelectedKey={this.state.selected_ContrastFabric ? this.state.selected_ContrastFabric.key : null} opts={contrastFabric_Step.opts} isFirst={false} isPhotoBox={contrastFabric_Step.isPhotoBox} photoRatio={contrastFabric_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={true} paddingLess={contrastFabric_Step.boldText ?? false} boldText={contrastFabric_Step.boldText ?? false} ></Step>
                    :
                    null
                }

            </StepWizard>
        }
        {/* <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button> */}
    </div>
    )
}








render() {
    return (
        this.jacketWizard()
    )
}

}


export default WizardForSuit
