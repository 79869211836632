import React from 'react'
import { Box, Columns, Button, Tag, Image } from 'react-bulma-components/dist';
import LoadingAux from './LoadingAux'
import { imageNamed, PRODUCT_TYPE } from '../utilities/utils'
import {dateToDateString} from '../utilities/helper'

const CustomerBox = (props) => {

    const editPressed = () => {
        props.editPressed(props.order.objectId)
    }

    const makeDefaultPressed = () => {
        props.makeDefaultPressed(props.order.objectId)
    }

    const orderDescription = (ord) => {
        let isPaid = ord.isPaid ? 'Paid' : 'Pending Payment'
        let note = ord.conciergeDisplayNote ?? "NOTE:"
        let description = isPaid + '. \n' + note
        return description
    }


    const viewPressed = (order) => {
        props.viewPressed(order)
    }

    const customerBox = (customer) => {

        //  id, orderNo, productsCount, bookingD, trialD, deliveryD, status

        return (
            <Box key={customer.objectId} className='hoverShine noBreakWords generalCardBG'>
                <Columns className='is-mobile is-size-7'>
                    <Columns.Column className=''>
                        <p className='has-text-grey-light has-text-weight-medium  marginBottom5'> Customer </p>
                        <p className='maxWrap165 has-text-weight-medium'>{customer.name} </p> 
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Email </p>
                        <p>{ customer.email1 ?  customer.email1 : null}</p>

                        {/* {order.isPaid ? <Tag className="is-info is-light" >Paid</Tag> :  <Tag className="is-warning is-light" >Pending</Tag>}   */}
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Phone </p>
                        <p>{ customer.phone1 ?  customer.phone1 : null}</p>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Country </p>
                        <p>{ customer.country ?  customer.country + ` (${ customer.state ? customer.state : null})` : null}</p>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Measures </p>
                        <p>{ customer.conciergeMeasurements ?  'Added' : '-'}</p>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <Button className='is-small is-fullwidth is-outlined' onClick={() => { viewPressed(customer) }}> View </Button>
                    </Columns.Column>
                </Columns>
            </Box>
        )
    }






    const page = () => {

        return customerBox(props.customer)
    }

    return (
        page()
    )
}

export default CustomerBox
