import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Webshop from './Webshop'


class Home extends Component {


    webshop = () => {
        return (
                <Webshop />
        )
    }


    render() {
        return (
            this.webshop()
        )
    }

}

export default withRouter(Home)