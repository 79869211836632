import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faCheck, faArrowRight, faArrowAltCircleRight, faArrowCircleDown, faForward, faPlus, faPlusCircle, faPlusSquare, faPhone, faPhoneAlt, faSms, faMailBulk, faEnvelope, faSurprise, faSmileBeam, faSmile, faRibbon, faRainbow, faHatCowboy, faAirFreshener, faBirthdayCake, faHatCowboySide } from '@fortawesome/free-solid-svg-icons'
import Logo from '../assets/tw-logo-140x30.svg'
// import Logo from '../assets/logoClient.png'
// import Logo from '../assets/logoClientNav.png'

import SignIn from './SignIn'
import SignUp from './SignUp'


import LoadingAux from './LoadingAux'
import ColumnAux from './ColumnAux'
import ChooseBox from './ChooseBox'
import StyleChooserWizard from './StyleChooserWizard'
import MeasurementsWizard from './MeasurementsWizard'
import StyleBox from './StyleBox'
import StyleCard from './StyleCard'
import OrderBox from './OrderBox'
import OrderModal from './OrderModal'


import ModalFullPage from './ModalFullPage'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal, Tag, Pagination, Dropdown
} from 'react-bulma-components/dist'

import { Order, Product, Customer } from '../utilities/classes'
import { imageNamed, PRODUCT_TYPE, uuidv4 } from '../utilities/utils'
import cogoToast from 'cogo-toast'
import { getCurrentAdmin, saveCurrentAdmin, removeCurrentAdmin } from '../utilities/Login'



const AdminOrders = (props) => {

    const OrderFetchType = {
        all: 'All',
        pending: 'PENDING',
        delivered: 'DELIVERED',
        orderNo: 'orderNo'
    }

    const displayLimit = 8


    const [orders, setOrders] = useState([])
    const [orderFetchType, setOrderFetchType] = useState(OrderFetchType.all)
    const [ordersCurrentPage, setOrdersCurrentPage] = useState(1)
    const [fetchingOrders, setFetchingOrders] = useState(true)
    const [noOrders, setNoOrders] = useState(false)

    const [selectedOrder, setSelectedOrder] = useState(null)
    const [searchOrderNo, setSearchOrderNo] = useState("")

    const [currentTypeTab, setCurrentTypeTab] = useState(OrderFetchType.all)



    useEffect(() => {
        // cogoToast.loading('Loading Orders');
        Order.getRecentOrders("", "", (succ, orders, errMsg) => {
            setFetchingOrders(false)
            if (succ) {
                setOrders(orders)

                console.log(orders)

                console.log('TOTAL ORDERS = ', orders.length)

                if (orders.length === 0) {
                    setNoOrders(true)
                }
            } else {
                cogoToast.error('Could not fetch orders.')
                console.log(errMsg)
            }

            console.log('USE EFFECT IS EFFECTING')

        })
    }, [])



    const refetchOrders = (status, orderNo) => {

        if (currentTypeTab === OrderFetchType.orderNo && orderNo === "") {
            return
        }

        setOrders([])
        setFetchingOrders(true)
        Order.getRecentOrders(status ?? "", orderNo ?? "", (succ, orders, errMsg) => {
            setFetchingOrders(false)
            if (succ) {
                setOrders(orders)

                // console.log(orders)

                // console.log('TOTAL ORDERS = ', orders.length)

                if (orders.length === 0) {
                    setNoOrders(true)
                }
            } else {
                cogoToast.error('Could not fetch orders.')
                console.log(errMsg)
            }


        })

    }

    const paginationChanged = (e) => {
        console.log('PAGINATION CHANGED')
        console.log(e)
        setOrdersCurrentPage(e)
    }



    const closeSelectedEntities = () => {
        setSelectedOrder(null)
    }


    const viewOrderPressed = (order) => {
        setSelectedOrder(order)
    }

    const ordersForThisPage = (page) => {

        console.log('PAGE = ', page)
        let skip = (displayLimit * (page - 1))

        let fromIndex = displayLimit * (page - 1)
        let toIndex = displayLimit

        console.log(`Check. OrdersLength ${orders.length} >=  FromIndex ${fromIndex} + ${displayLimit}`)

        if (orders.length >= (fromIndex + displayLimit)) {

            toIndex = fromIndex + displayLimit
        } else {
            toIndex = null
        }

        console.log(`From Index = ${fromIndex}`)
        console.log(`To Index = ${toIndex}`)



        // console.log(`SLICING = (${skip},${displayLimit}) `)

        let ordersCopy = [...orders]
        let theseOrders = ordersCopy.slice(fromIndex, toIndex)

        if (toIndex === null) {
            theseOrders = ordersCopy.slice(fromIndex)
        }

        console.log(`THESE ORDERS `)
        console.log(theseOrders)

        return theseOrders
    }

    const orderList = () => {
        console.log('rerendering Order list')
        return (
            <ColumnAux className=' fadeIn' isMiddle={true} size={6}>
                <br />
                { noOrders ? <Heading size={6} className='has-text-centered has-text-grey-light'> No Orders </Heading> : null}
                {/* <h4 className="has-text-centered"> Awaiting Order Confirmation.. </h4> */}
                <LoadingAux isLoading={fetchingOrders} loaderHeight={400} size={'medium'} isMain={false} >
                    {
                        ordersForThisPage(ordersCurrentPage).map((ord) => {
                            return <OrderBox key={ord.objectId} order={ord} viewPressed={viewOrderPressed} />
                        })
                    }
                </LoadingAux>
                {
                    <Pagination className='is-small' current={ordersCurrentPage} total={Math.ceil(orders.length / displayLimit)} delta={2} autoHide={true} onChange={paginationChanged} />
                }
            </ColumnAux>
        )
    }


    const changeTypeTab = (val) => {
        setCurrentTypeTab(val)
        setNoOrders(false)

        if (val === OrderFetchType.all && currentTypeTab !== OrderFetchType.all) {
            refetchOrders()
        }

        if (val === OrderFetchType.pending && currentTypeTab !== OrderFetchType.pending) {
            refetchOrders(OrderFetchType.pending, null)
        }

        if (val === OrderFetchType.delivered && currentTypeTab !== OrderFetchType.delivered) {
            refetchOrders(OrderFetchType.delivered, null)
        }

        if (val === OrderFetchType.orderNo && currentTypeTab !== OrderFetchType.orderNo) {
            setOrders([])
            // refetchOrders(null,searchOrderNo)
        }


    }

    const mainTabs = () => {
        return (<div className=' fadeIn '>
            <Tabs align='centered' type='toggle' className='is-mobile is-small    '>
                <Tabs.Tab active={currentTypeTab === OrderFetchType.all} onClick={() => changeTypeTab(OrderFetchType.all)}>
                    Recent
                </Tabs.Tab>
                <Tabs.Tab active={currentTypeTab === OrderFetchType.pending} onClick={() => changeTypeTab(OrderFetchType.pending)}>
                    Pending
                </Tabs.Tab>
                <Tabs.Tab active={currentTypeTab === OrderFetchType.delivered} onClick={() => changeTypeTab(OrderFetchType.delivered)}>
                    Delivered
                </Tabs.Tab>
                <Tabs.Tab active={currentTypeTab === OrderFetchType.orderNo} onClick={() => changeTypeTab(OrderFetchType.orderNo)}>
                    Search
                </Tabs.Tab>
            </Tabs>
        </div>)
    }


    const orderUpdated = (updatedOrder) => {
        let ords = [...orders]
        let objIndex = ords.findIndex(obj =>
            obj.objectId === updatedOrder.objectId
        )
        ords[objIndex] = updatedOrder
        setOrders(ords)
    }


    const orderModal = () => {
        return <OrderModal order={selectedOrder} closed={closeSelectedEntities} orderUpdated={orderUpdated} isAdmin={true} />
    }

    const searchOrderNoChanged = (e) => {
        setSearchOrderNo(e.target.value)
    }

    const searchBox = () => {
        return (
            <ColumnAux className=' fadeIn' isMiddle={true} size={4}>
                <br />
                <Box className=" marginTop20 has-background-grey-lighter">
                    <div className='field'>
                        <label className="label type60 marginTop10" >Order No</label>
                        <input name='orderNo' type='input' className='input has-text-weight-medium type65' value={searchOrderNo} onChange={(e) => { searchOrderNoChanged(e) }} />
                    </div>

                    <div className='control is-expanded marginTop20'>
                        <Button disabled={fetchingOrders} loading={fetchingOrders} className='button is-black is-fullwidth marginTop10' onClick={() => { refetchOrders(null, searchOrderNo) }} > Search </Button>
                    </div>
                </Box>
            </ColumnAux>
        )
    }

    const thisPage = () => {
        return (
            <div className="pad20">
                { selectedOrder ? orderModal() : null}

                {mainTabs()}
                {currentTypeTab === OrderFetchType.orderNo ? searchBox() : null}
                {orderList()}

            </div>
        )
    }




    return thisPage()

}

export default AdminOrders