import React, { Component } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal
} from 'react-bulma-components/dist'

import ModalAux from './ModalAux'
import { imageNamed, PRODUCT_TYPE } from '../utilities/utils'
import Aux from './Aux'
import BillingForm from './BillingForm'
import ColumnAux from './ColumnAux'
import OrderBox from './OrderBox'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faCheck, faArrowRight, faArrowAltCircleRight, faArrowCircleDown, faForward, faPlus, faPlusCircle, faPlusSquare, faOtter } from '@fortawesome/free-solid-svg-icons'
import { getCurrentCustomer } from '../utilities/Login'

import { currencySymbol, clientName, clientEmail, standardDeliveryTime, freeShippingThreshold, standardPaidShippingPrice } from '../clientInfo/clientInfo'

import { sendMail } from '../utilities/classes'


class OrderSection extends Component {


    state = {
        showPaymentModal: false,
        order: this.props.order,
        totalAmount: 0,
        pendingOrders: []
    }

    _mounted = false
    _total = 0
    _shipping = 0

    _noShippingForTest = false


    componentDidMount = () => {
        this._mounted = true
    }

    componentWillUnmount = () => {
        this._mounted = false
    }

    showPaymentModal = () => {
        console.log('SHOW MODAL PRESSED')
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showPaymentModal = true
            this.setState(ns)
        }
    }

    closePaymentModal = () => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showPaymentModal = false
            this.setState(ns)
        }
    }

    paymentModal = () => {
        return (<Modal closeOnBlur={true} showClose={true} show={this.state.showPaymentModal} >
            <h1> Modal Content </h1>
        </Modal>)
    }

    cartRows = () => {
        let i = 0
        this._total = 0
        


        if (this.props.styles) {


            let rows = this.props.styles.map(st => {
                i = i++
                this._total += st.price

                if (st.name ==='TEST') {
                    this._noShippingForTest = true
                }

                return <tr key={i + 'cartrow'}>
                    
                    <td>{st.type} <div className='type50'> {st.isBespoke ? 'BESPOKE ·' : 'MADE TO ORDER ·'} {st.description} </div>
                        <div className='cartItemContainer'>
                            {st.imageUrl ?
                                <div className='  is-radiusless pad0 is-shadowless is-clipped styleBox80'>
                                    <Image className='is-clipped ' src={st.imageUrl} size='1by1' />
                                </div>
                                : null}

                            {st.fabricImage ?
                                <div className='  is-radiusless pad0 is-shadowless is-clipped styleBox80'>
                                    <Image className='is-clipped ' src={st.fabricImage} size='1by1' />
                                </div>
                                : null}
                        </div>
                    </td>
                    {/* <td>1</td> */}
                    <td className='has-text-right'>{currencySymbol}{st.price}</td>
                </tr>
            })            

            this.props.updateFinalPrice(this._total)
            return rows
        }

        this.props.updateFinalPrice(this._total)
        return null

    }


    shippingRows = () => {

        if (this.props.styles.length === 0) {
            return null
        }

        let totalProductPrice = 0
        this.props.styles.forEach(pt => {
            totalProductPrice += pt.price
        })

        // console.log('TOTAL PRICE =', totalProductPrice)
        // console.log('freeShippingThreshold PRICE =', freeShippingThreshold)

        if (totalProductPrice < freeShippingThreshold && this._noShippingForTest === false) {


            this._total += standardPaidShippingPrice
            this._shipping = standardPaidShippingPrice

            
            this.props.updateFinalPrice(this._total)


            return (
                <tr key={'shippingRow2526'}>
                    <td> Shipping Charges <p className="type50 has-text-grey-light"> Not applicable on order amount more than {freeShippingThreshold} </p> </td>
                    <td className='has-text-right'>{currencySymbol}{standardPaidShippingPrice}</td>
                </tr>
            )

        } else {
            this._shipping = 0
        }

        this.props.updateFinalPrice(this._total)


        return null
    }

    cartDetails = (
        // <Container>
        <Columns>
            <Columns.Column>
                <Heading className='fadeIn' size={6}>Product Details</Heading>
                <Table className='is-hoverable type74 fadeIn '>
                    <thead >
                        <tr >
                            <td className='has-text-grey-light is-size-7'>Product</td>
                            {/* <td className='has-text-grey-light is-size-7'>Count</td> */}
                            <td className='has-text-grey-light has-text-right is-size-7'>Price</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.cartRows()}
                        {this.shippingRows()}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th></th>
                            {/* <th></th> */}
                            <th className='has-text-right'> {currencySymbol}{this._total}</th>
                        </tr>
                    </tfoot>
                </Table>
                {this.props.styles.length > 0 ?
                    <div>
                        <Button className='is-small is-light' onClick={() => { this.props.showConcierge() }}> Edit Products </Button>
                        <br />
                    </div>
                    : null}
                {this.props.measures.length > 0 ?
                    <Box className=' is-shadowless'>
                        <div className='is-size-7  has-text-semibold fadeIn'>  <FontAwesomeIcon className='has-text-info' icon={faCheck} /> &nbsp; &nbsp; Measurements Added. Current Measurent will be attached to order. </div>
                    </Box>
                    : null
                }

                <br />
                <div>
                    <p className='text-grey-light is-size-7'> Prices are inclusive of taxes. </p>
                </div>
                <div>
                    <p className='text-grey-light is-size-7'> {standardDeliveryTime} </p>
                </div>

                <br />
                <Box className='fadeIn'>
                    <Heading className='' size={6}>Shop With Confidence</Heading>
                    <div className='type60 '>Every order is carefuly reviewed at {clientName}. We will study the style and the measurements provided by you. If we have any suggestions or queries we will reach out to you to make sure your garments are just perfect.</div>
                </Box>


            </Columns.Column>
            <Columns.Column size={1}>
            </Columns.Column>
        </Columns>
        // </Container>
    )

    // successAtCheckout = (data) => {
    //     console.log('SUCCESS AT CHECKOUT = ', data)
    //     this.props.paymentStart(true)
    // }

    errorAtCheckout = (data) => {
        console.log('ERRROR AT CHECKOUT = ', data)
    }



    orderAdded = (order, products) => {
        if (this._mounted) {
            // let ns = { ...this.state }
            // ns.order = order
            // this.setState(ns)
            this.props.orderAdded(order, products)
        }
    }


    billingAddress = (cust) => {
        return <BillingForm customer={cust} products={this.props.styles} totalAmount={this._total} shippingAmount={this._shipping} measurements={this.props.measures} error={this.errorAtCheckout} order={this.order} orderAdded={this.orderAdded}   signInViaModal={this.props.signInViaModal}/>
    }

    modalClosed = () => {
        console.log('Modal has been closed. Make any extra required steps')
    }

    orderPage = () => {
        return (
            <ColumnAux isMiddle={true} size={9} >
                                    <ColumnAux size={10} isMiddle={true} >
                                        {(getCurrentCustomer() != null) ?   <div className="has-text-centered is-centered marginTop20"> <span className="has-text-grey-light type74 "> Your orders are in your account. &nbsp; </span> <Button className='is-small is-light' onClick={ () => { this.props.showMyAccount(true) } }> View My Account</Button>   </div>  : null }
                                    </ColumnAux>

                <Section>




                    {/* {this.props.order ?  
                        this.props.order.orderNo ?  <Heading size={5}> Order is created. Payment Pending. </Heading>  : null 
                    : null} */}


                    {/* <Heading size={3}> Checkout </Heading> */}
                    {/* {this.state.showModalProgress ? <ModalProgress height={200} title='Payment Authentication'  centerText='Do Not close any window' showDoNotClose={false}  onClose={this.closeModal} /> : null} */}
                    <Columns>
                        <Columns.Column size={7}>
                            {/* <br /> */}
                            {/* <Heading size={7}>DESCRIPTION</Heading>
                        <div className='is-size-7'>Petierunt uti sibi concilium totius Galliae in diem certam indicere. Unam incolunt Belgae, aliam Aquitani, tertiam. Morbi fringilla convallis sapien, id pulvinar odio volutpat. Quid securi etiam tamquam eu fugiat nulla pariatur. Nihil hic munitissimus habendi senatus locus, nihil horum? At nos hinc posthac, sitientis piros Afros.</div> */}

                            {this.cartDetails}


                        </Columns.Column>
                        <Columns.Column size={5}>
                            {/* <h4 className='subtitle strong'> Make Payment Via </h4> */}

                            {this.billingAddress(this.props.customer)}



                        </Columns.Column>
                    </Columns>


                </Section>

            </ColumnAux>
        )
    }



    // pendingOrderList = () => {
    //     return (
    //         <ColumnAux isMiddle={true} size={9} >
    //             <Section>
    //                 <Box >
    //                  <OrderBox key={this.props.order.objectId} order={this.props.order} viewPressed={()=> {this.props.showMyAccount(true)}} />
    //                 </Box>
    //             </Section>

    //         </ColumnAux>
    //     )
    // }

    render() {

        return (
            <div>
                <Modal closeOnBlur={true} showClose={true} show={this.state.showPaymentModal} onClose={this.modalClosed} > <h1> Modal Content </h1>  </Modal>
                {this.orderPage()}
            </div>

        )

    }

}

export default OrderSection