
import Parse from 'parse'
import { Customer, Order, Product, Location, Position, User  } from './classes'
import cogoToast from 'cogo-toast'

const CustomerClass = Parse.Object.extend("Customer");
const UsersClass = Parse.Object.extend("Users");



export const signUpCustomer = (name, email, password, callback) => {

    // console.log('TRYING Customer LOGIN')


    // Search if this email user exists, if yes. Fetch their username, pass, email1, & 2


    Customer.searchCustomers(email, (succ, customs, errMsg) => {
        if (succ) {
            if (customs.length === 0) {
                signUpThisCut(name, email, password, callback)
            }else{

                // cogoToast.warn('User already exists.')
                // Check if username and password already exists in this product

                let thisCust = customs[0]

                if (thisCust.username !== null && thisCust.username !== "" && thisCust.password !== null && thisCust.password !== ""  && thisCust.password !== undefined){
                    // Both username and password already exist. Ask user to sign in.
                    console.log("USERNAME = ", thisCust.username)
                    console.log("PASSWORD = ", thisCust.password)
                    cogoToast.warn('This email is already registered. Try Signing in.')
                    callback(false, thisCust, '')
                }else if (thisCust.username !== null && thisCust.username !== "" && thisCust.isOnConcierge === true) {
                    // username exists but not password. THIS WAS CREATED VIA CONCIERGE
                    cogoToast.warn('This email is already registered. Try SignIn or Forget Password.')
                    callback(false, thisCust, '')
                }else{
                    // MERGE THIS USER WITH. MEANS SET USERNAME & PASSWORD FOR THIS USER. 
                    thisCust.username = email
                    thisCust.password = password
                    thisCust.isOnConcierge = true
                    // Update Customer

                    thisCust.update((succ, errMsg) => {
                        if (succ === true) {
                            // currentCust = thisCust
                            saveCurrentCustomer(thisCust)
                            cogoToast.success("Registered with in-person visit profile. ")
                            // saveCurrentCustomer(thisCust)
                            callback(true, thisCust, '')

            
                        } else {
                            cogoToast.error("Found existing info. But could not sign up." )
                            // callback({ success: false, message: 'Could not update address. ' + errMsg })
                            callback(false, thisCust, '')

                        }
            
                    })

                }


            }
        } else {
            cogoToast.error('Could not validate customers while signing up. Check Internet.')
            console.log(errMsg)
            callback(false, null, '')

        }
    })





}

const signUpThisCut = (name, email, password, callback) => {
    let newCust = new Customer(email, password, true, name, null,null, null, null, null, null, null, email, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null )

    newCust.add( (succ, errMsg) => {
        if (succ === true) {
            saveCurrentCustomer(newCust)
            callback(true, newCust, '')

        }else{
            cogoToast.warn("Could not SignUp.  Check valid entries, internet connection and try again.")
            callback(false, null , 'Could not validate Customers')
        }
    })
}

export const tryAdminLogin = (email, password, callback) => {
    const usersQuery = new Parse.Query(UsersClass);
    usersQuery.equalTo("conciergeAdminEmail", email);
    usersQuery.equalTo("conciergePass", password);
    // customerQuery.includeAll()

    console.log('STARTING QUERY')

    usersQuery.first().then((object) => {
            if (object != null) {
                let adminObj = { email: object.conciergeAdminEmail}
                saveCurrentAdmin(adminObj)
                setTimeout(() => {
                    callback(true, object, '')
                }, 1000);
            } else {
                callback(false, null, 'Could not validate Admin')
            }

        }, (error) => {
            console.log('Error while trying to login Admin ', error.message)
            callback(false, null, error.message)
    })
}

export const tryCustomerLogin = (email, password, callback) => {

    console.log('Trying to login Customer.')


    const customerQuery = new Parse.Query(CustomerClass);
    customerQuery.equalTo("username", email);
    customerQuery.equalTo("password", password);
    // customerQuery.includeAll()

    console.log('STARTING QUERY')


    customerQuery.first().then((object) => {
        console.log('All Customers =')
        console.log(object)


        if (object != null) {

            let thisCustomer = Customer.initFromPFObject(object, false)

            // TODO - Save User Login and all associated info with it.
            // let isMobile = false;
            // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            //     isMobile = true            
            // }

            if (thisCustomer != null) {
                saveCurrentCustomer(thisCustomer)

                setTimeout(() => {
                    callback(true, thisCustomer, '')
                }, 1000);
            } else {
                callback(false, null, 'Could not validate Customers')

            }

        } else {
            callback(false, null, 'Invalid')
        }

    }, (error) => {
        console.log('Error while getting Customers ', error.message)
        callback(false, null, error.message)
    })
}


export const logoutCurrentCustomer = () => {
    removeCurrentCustomer()
}


export const saveCurrentAdmin = (admin) => {
    console.log('SAVING CURRENT ADMIN')
    localStorage.setItem('adminAuthenticated', true)
    localStorage.setItem('currentAdmin', JSON.stringify(admin))
    localStorage.setItem('loginTime', new Date())
}
export const removeCurrentAdmin = (admin) => {
    localStorage.setItem('adminAuthenticated', false)
    localStorage.setItem('currentAdmin', null)
    localStorage.setItem('loginTime', null)
}

export const getCurrentAdmin = () => {
    let userObj = localStorage.getItem('currentAdmin');
    if (userObj == null){
        return null
    }
    let user = JSON.parse(userObj)
    return user 
}



export const saveCurrentCustomer = (user) => {

    console.log('SAVING CURRENT USER INFO')

    localStorage.setItem('customerAuthenticated', true)
    localStorage.setItem('currentCustomer', JSON.stringify(user))

    // let isAdmin = user.get('isAdmin')
    // let isDeleted = user.get('isDeleted')
    // let imageUrl = user.get('imageUrl')
    // let phone = user.get('phone')
    // let position = user.get('phone')
    // let location = user.get('phone')

    localStorage.setItem('loginTime', new Date())

    // Save Position, Location, isDeleted, imageUrl and Phone as well
}


export const getCurrentCustomerInfo = () => {
    let user = localStorage.getItem('currentCustomer');

    if (user != null) {
        user = JSON.parse(user)
    } else {
        return null
    }

    const loginTimeSTR = localStorage.getItem('loginTime')
    const loginTime = new Date(loginTimeSTR)
    let difference_In_Time = new Date().getTime() - loginTime.getTime();
    let difference_In_Hours = difference_In_Time / (1000 * 3600);
    let difference_In_Minutes = difference_In_Time / (1000 * 60);

    let locationStr = 'Central Location'
    let positionStr = user.position.name ?? ''
    let loginSinceStr = ''

    if (difference_In_Hours > 1) {
        loginSinceStr = 'Logged in since ' + difference_In_Hours.toFixed(2) + ' hrs'
    } else {
        loginSinceStr = 'Logged in since ' + difference_In_Minutes.toFixed(2) + ' mins'
    }

    user.posAtLocInfo = positionStr + ' at ' + locationStr
    user.loginInfo = loginSinceStr

    // let isAdmin = user.get('isAdmin')
    // let isDeleted = user.get('isDeleted')
    // let imageUrl = user.get('imageUrl')
    // let phone = user.get('phone')
    // let position = user.get('phone')
    // let location = user.get('phone')

    return user
}


export const removeCurrentCustomer = (user) => {
    localStorage.setItem('customerAuthenticated', false)
    localStorage.setItem('currentCustomer', null)
    localStorage.setItem('loginTime', null)
}


export const isCurrentCustomer = () => {

    const customerAuthenticated = localStorage.getItem('customerAuthenticated') === 'true';
    const currentCustomer = customerAuthenticated ? localStorage.getItem('currentCustomer') : '';

    if (customerAuthenticated && currentCustomer) {

        // TODO 
        // Remove User if logged in more than 48 hours 
        const loginTimeSTR = localStorage.getItem('loginTime')
        const loginTime = new Date(loginTimeSTR)

        // To calculate the time difference of two dates 
        let difference_In_Time = new Date().getTime() - loginTime.getTime();

        // To calculate the no. of days between two dates 
        let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);


        let difference_In_Hours = difference_In_Time / (1000 * 3600);

        let difference_In_Minutes = difference_In_Time / (1000 * 60);

        // let conf = getTwConfig()

        let conf = {
            logoutAfterHours : 48
        }

        if (conf){
            if (conf.logoutAfterHours) {
                if (difference_In_Hours >= Number(conf.logoutAfterHours)){
                    removeCurrentCustomer()
                    return false
                }
            }
        }else
        if (difference_In_Hours >= 6) {
            removeCurrentCustomer()
            return false
        } else {
            // alert('Last Login Was within ' + (difference_In_Minutes).toFixed(2) + ' Minutes Ago')
        }

        return true
    }

    return false

    // localStorage.setItem('customerAuthenticated', true)
    // localStorage.setItem('currentCustomer', user.get('email'))
    // localStorage.setItem('loginTime', new Date())
}






export const getCurrentCustomer = () => {
    let userObj = localStorage.getItem('currentCustomer');
    if (userObj == null){
        return null
    }
    let user = JSON.parse(userObj)
    return user 
}



export const getAccessAssets = () => {

    console.log('FETCHING LOCATIONS')


    // let need = areAssetsNeeded()
    let need = true


    if (need === false) {
        console.log('DO NOT NEED ASSETS')
        return
    } else {
        console.log('ASSETS ARE NEEDED')

    }




    Location.getAll((success, locs, errMsg) => {
        if (success) {
            // console.log('LOCATIONS ARE')
            // console.log(locs)
            saveLocations(locs)


            console.log('FETCHING Positions')
            Position.getAll((success, positions, errMsg) => {
                if (success) {
                    // console.log('Positions ARE')
                    // console.log(positions)
                    savePositions(positions)

                    // console.log('GETTING Positions')
                    // getPositions()

                    console.log('FETCHING Users')
                    User.getAll((success, users, errMsg) => {
                        if (success) {
                            // console.log('Users ARE')
                            // console.log(users)

                            let usersWOpass = users.map((us) => {
                                us.password = '••••••••'
                                return us
                            })

                            // saving Users without password
                            saveUsers(usersWOpass)

                            localStorage.setItem('assetsSavedOn', new Date())
                            localStorage.setItem('needAssets', false)



                            // TWConfig.getConfig((success, conf, errMsg) => {
                            //     console.log('FETCHING CONFIG')

                            //     if (success) {
                            //         // save Config
                            //         console.log('SAVING CONFIG')
                            //         saveTwConfig(conf)
                            //     } else {
                            //         console.log('Could not fetch config. ' + errMsg)
                            //     }
                            // })


                            // console.log('GETTING Users')
                            // getUsers()

                        } else {
                            console.log('Users could not be fetched')
                            console.log('ERROR = ' + errMsg)
                        }
                    })



                } else {
                    console.log('Positions could not be fetched')
                    console.log('ERROR = ' + errMsg)
                }
            })

            // console.log('GETTING LOCATIONS')
            // getLocations()
        } else {
            console.log('Locations could not be fetched')
            console.log('ERROR = ' + errMsg)
        }
    })

}





export const saveTwConfig = (conf) => {
    let jsons = JSON.stringify(conf)
    localStorage.setItem('twConfig', jsons)
}

export const getTwConfig = () => {
    const twConf = localStorage.getItem('twConfig');
    return JSON.parse(twConf)
}

export const saveLocations = (locs) => {
    let jsons = JSON.stringify(locs)
    localStorage.setItem('locations', jsons)
}

export const getLocations = () => {
    const locs = localStorage.getItem('locations');
    return JSON.parse(locs)
}

export const savePositions = (positions) => {
    let jsons = JSON.stringify(positions)
    localStorage.setItem('positions', jsons)
}

export const getPositions = () => {
    const positions = localStorage.getItem('positions');

    // console.log('GOT Positions FROM STORE')
    console.log(JSON.parse(positions))

    return JSON.parse(positions)
}


export const saveUsers = (users) => {
    let jsons = JSON.stringify(users)
    localStorage.setItem('users', jsons)
}


export const getUsers = () => {
    const users = localStorage.getItem('users');

    // console.log('GOT Users FROM STORE')
    // console.log(JSON.parse(users))
    return JSON.parse(users)
}
