import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Button, Box, Heading } from 'react-bulma-components/dist'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faCreditCard, faCashRegister, faCheck, faPersonBooth } from '@fortawesome/free-solid-svg-icons'
import { CardElement, useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js'
import { waitForElement } from '@testing-library/react'
import { findAllInRenderedTree } from 'react-dom/test-utils'
import CountrySelect from './CountrySelect'
import { getCurrentCustomer } from '../utilities/Login'
import { currencySymbol, currencyCode } from '../clientInfo/clientInfo'
import PayPal from './PayPal'




const backendBaseURL = process.env.REACT_APP_BACKEND_BASE_URL
const backendHeaders = new Headers();
backendHeaders.append("X-Parse-Application-Id", process.env.REACT_APP_BACKEND_APPID);
backendHeaders.append("X-Parse-REST-API-Key", process.env.REACT_APP_BACKEND_RESTKEY);
backendHeaders.append("Content-Type", "application/json");



let requestParams = {}

const backendRequestOptions = (params) => {
  return ({
    method: 'POST',
    headers: backendHeaders,
    redirect: 'follow',
    body: JSON.stringify(params)
  }
  )
}

// fetch("https://parseapi.back4app.com/functions/createPaymentIntent?amount=600", backendRequestOptions)





// PENDING 
// 1. CAPTURE COUNTRY = ATleast THAT BEFORE PAYMENT ( BETTER TO PUT COUNTRY SELECTION ON PAYMENT Page, so the user smostly selects the country of their card. which is what we want. )
// 2. If the chosen country in both billing and on Payment is in INDIA, send inr and countryFullConfirmIN.... int this case the inr amount is paid and the address is kept same. 
// 3. IF the chosen county is INR at any one place, put the pre -entered Country into state field, and change biling country to INR... send on backedn,  send inr and counrtyMightBeIN
// 4. If still there is an error with type: "invalid_request_error" and message: container Non-INR or INR ... tell user, that you should select the country of your card issue. 

// NOTE ... DO NOT CHANGE $ sign or amount on front end ,,, as the exact amount might be dirrent. 

// ON BACKEND
// USe OPen currency APi. Make a job to get the currency after every 30 minutes and update the current INR - USD rate... we can use this rate in our calls.



const PaymentPage = (props) => {


  let [amount, setAmount] = useState(props.finalPrice)
  const [payStatus, setPayStatus] = useState(null);
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState()
  const [country, setCountry] = useState('GB')
  const [cardComplete, setCardComplete] = useState()

  const [paypalCheckout, setPayPalCheckout] = useState(false)




  let currency = currencyCode

  const countryChanged = (val) => {
    // setTimeout(() => {
    // showOrderSuccess()
    // }, 6000);
    setCountry(val)
  }


  let thisCust = getCurrentCustomer()

  let billingDetails = {
    name: thisCust.name,
    email: thisCust.email1,
    address: {
      line1: thisCust.address,
      city: thisCust.state,
      state: thisCust.state,
      postal_code: thisCust.pincode,
      country: country
    }
  }


  const stripe = useStripe()
  const elements = useElements()

  let paymentSecret = null



  // APPLE PAY TRIAL
  // const [canMakePayment, setCanMakePayment] = useState(null);
  // const paymentRequest = stripe.paymentRequest({
  //   country: 'US',
  //   currency: 'usd',
  //   total: {
  //     label: 'Demo total',
  //     amount: 1000,
  //   },
  // });

  // paymentRequest.on('token', ({complete, token, ...data}) => {
  //   console.log('Received Stripe token: ', token);
  //   console.log('Received customer information: ', data);
  //   complete('succeeded');
  // });

  // paymentRequest.canMakePayment().then((result) => {
  //   setCanMakePayment(!!result)
  // });



  const changeToINR = () => {
    // if (country === 'IN') {
    //   return true
    // }
    return false
  }


  const handleSubmit = async (event) => {
    console.log('FORM WAS SUBMITTED')

    // Block native form submission.
    event.preventDefault();

    setProcessing(true)

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.

      // HANDLE AND SHOW ERROR

      setError('Could not connect wirh Payment Gateway.')
      setProcessing(false)

      // console.log('Stripe Or Elements are not there')

      return;
    }



    // STEPS TO PROCESS PAYMENT with Stripe


    // SEND THESE PARAS = requestId, amount, currency, description, changeToINR
    // if IN in country or Address, or an Non-INR error occured earlier. 

    let twMerchantId = 'twMC_102678'
    let reqId = twMerchantId + '-' + Date.now() + '-' + amount


    // amount, currency, description   - ALL ESSENTIAL
    requestParams = {
      requestId: reqId,
      changeToINR: changeToINR(),
      amount: amount * 100,    // The amount is in lowest dinomination. 100 cents of $, same for Rupees
      currency: currency,   // If the customer if from india, charge in rupees, set this to 'inr' and send counry 'IN' in billing address
      description: 'Bespoke Purchase via Concierge. OrderNo=' + props.order.orderNo + '(devId=' + props.order.objectId + '). CustomerName=' + props.order.customerName + '. CustomerPhone=' + props.order.customerPhone + '. CustomerEmail=' + props.order.customerEmail
    }

    // MAKE CLOUD FUCNTIN CALL 
    //1. Create a payment intent on the server 
    //   Server returns us client secret of that payment intent
    fetch(backendBaseURL, backendRequestOptions(requestParams))
      .then(response => response.json())
      .then(jsoned => {
        // console.log(jsoned);
        paymentSecret = jsoned.result.secret

        amount = jsoned.result.amount
        currency = jsoned.result.currency

        // console.log('ATTAINED PAYMENT SECRET =', jsoned.result.secret)
        // console.log('IsINR =', jsoned.result.isINR)
        // console.log('PriceVerified =', jsoned.result.priceVerified)


        //2. Clreate Payment method on frontEnd. using Card Element and stripe.js 
        createPaymentMethod(paymentSecret)
        // 3. ^ We will confirm payment by using secret and id in CreatePaymentMethodFucntion 

      })
      .catch(error => {
        console.log('Error Connecting Backend.', error.message)
        console.log(error.message)
        console.log(error)


        setError('Error Connecting Backend. Check internet. ReEnter Card and try again.')
        setProcessing(false)
      });

  }


  // const showOrderSuccess = () => {

  //   setTimeout(() => {
  //     props.orderSuccess()
  //   }, 1000);

  // }



  const createPaymentMethod = async (secret) => {
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    if (changeToINR()) {
      let bd = { ...billingDetails }
      bd.address.country = country
      billingDetails = bd
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: billingDetails
    });


    if (error) {
      console.log('Error Creating Payment Method', error.message);
      setError('Error Creating Payment Method. Check internet. ReEnter Card and try again.')
      setProcessing(false)
    } else {
      // setProcessing(false)
      // setPayStatus('succeeded')
      // console.log('[PaymentMethod]', paymentMethod);

      console.log('Payment Method Id =', paymentMethod.id);


      // 3rd. Using the attained secret and the paymentMethod Id. We will confirm if the payment happened.
      // confirm payment

      try {
        console.log('TRYING TO CONFIRM')
        const confirmResult = await stripe.confirmCardPayment(secret, {
          payment_method: paymentMethod.id
        })

        console.log('TRYING TO CONFIRM 2')

        // console.log('CONFIRM RESULT = ', confirmResult)

        if (error) {
          throw error
        }

        if (confirmResult) {
          // Note Payment and staus if you want to
          console.log('CONFIRM RESULT - ')
          console.log(confirmResult)

          let intent = confirmResult.paymentIntent 
          let paymentId = intent.id  
          let status = intent.status 

          // console.log('Payment was confirmed - ', intent)
          console.log('Payment ID - ', paymentId)
          console.log('Currenct - ', intent.currency)
          console.log('Amount - ', intent.amount)
          console.log('Payment Status -', status)

          setProcessing(false)
          setError(null)
          if (status === 'succeeded' || status === 'success') {
            setPayStatus('succeeded')

            props.paymentDone(paymentId, status, amount, currency, "STRIPE")


            // showOrderSuccess()
          }
        }

      }
      catch (error) {
        setProcessing(false)
        setError('Error. Could not confirm payment. Check internet ReEnter Card and try again.')
        console.log('Error. Could not confirm payment.', error)
      }

      // { confirmError, confirmCardPayment } = result


    }
  }


  const changeHandler = (e) => {
    if (e.complete) {
      setCardComplete(true)
    } else {
      setCardComplete(false)
    }
    // console.log('CARD CHANGE DETAILS = ', e)
    setError(null)
  }

  const blurHandler = (e) => {
    // console.log('BLUR HAPPENED')
  }

  const CARD_OPTIONS = {
    // iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: '#fff',
        fontWeight: 500,
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#87BBFD',
        },
      },
      invalid: {
        iconColor: '#FFC7EE',
        color: '#FFC7EE',
      },
    },
    hidePostalCode: true
  }


  const ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: '15px',
        fontWeight: 600,
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
    hidePostalCode: true
  };



const payWithPayPalPressed = () => {
    setPayPalCheckout(true)
}


  const form = () => {

    return (

      <div>

        {
          paypalCheckout ?
            <PayPal  order={props.order} finalPrice={props.finalPrice} paymentDone={props.paymentDone} />

          :

          <div>
        <label className="label avenir type55 has-text-grey-light  is-uppercase">Enter Card Details</label>

        <form onSubmit={handleSubmit}>

          <p className='help is-danger marginBottom10 marginTop20 '> {error} </p>
          <Box>

            <CountrySelect countryChanged={countryChanged} />


            <CardElement
              className='marginTop40'
              options={ELEMENT_OPTIONS}
              onReady={() => {
                console.log("CardElement [ready]");
              }}
              onChange={event => {
                // console.log("CardElement [change]", event);
                changeHandler(event)
              }}
              onBlur={() => {
                blurHandler()
              }}
              onFocus={() => {
                console.log("CardElement [focus]");
              }}
            />
          </Box>



          {/* <PaymentRequestButtonElement
            paymentRequest={paymentRequest}
            className="PaymentRequestButton"
            style={{
              paymentRequestButton: {
                theme: 'light',
                height: '64px',
              },
            }}
          /> */}

        {payStatus !== 'succeeded' ? <Button renderAs='button' className='is-fullwidth marginTop20 is-black has-text-weight-medium avenir' type='submit' disabled={isProcessing || (error != null) || country === "" || !cardComplete} loading={isProcessing} > Pay&nbsp; {currencySymbol}{amount} </Button> : null}
          {payStatus === 'succeeded' ? <p className=" marginTop10 has-text-success has-text-weight-medium"> <FontAwesomeIcon className='' icon={faCheck} /> &nbsp; Success </p> : null}

          <br />
          {payStatus !== 'succeeded' ? <Button renderAs='button' className='is-fullwidth marginTop20 is-black has-text-weight-medium avenir' onClick={payWithPayPalPressed}  > Pay&nbsp; {currencySymbol}{amount} via PayPal </Button> : null}


          <br />
          <p className=" avenir marginTop10 type60 has-text-grey has-text-weight-medium"> <FontAwesomeIcon className='' icon={faLock} /> &nbsp; PCI Compliant. Secured Payments. </p>

          <br />
        </form>
      </div>


        }



      </div>



    )
  }



  return (
    form()
  )

}

export default PaymentPage