import React, { Component, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import Logo from '../assets/tw-logo-140x30.svg'

import LoadingAux from './LoadingAux'
import ColumnAux from './ColumnAux'

import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal, Dropdown
} from 'react-bulma-components/dist'

import { imageNamed, PRODUCT_TYPE, webImageNamed, uuidv4, newOrderNo } from '../utilities/utils'
import { C_Product } from '../utilities/classes'
import { currencySymbol, standardDeliveryTime } from '../clientInfo/clientInfo'

import OrderBox from './OrderBox'
import MyAddressForm from './MyAddressForm'

import Service from './Service'
import Slider from 'infinite-react-carousel';
import cogoToast from 'cogo-toast'
import { allProds } from './hardcodeProducts'



const Shop = (props) => {

    let sampleProds = allProds

    const [products, setProducts] = useState(sampleProds)
    const [addedProducts, setAddedProducts] = useState(props.addedProducts ?? [])
    const [showProductModal, setShowProductModal] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null)

    const [prodAddedToOrder, setProdAddedToOrder] = useState(false)

    const [selectedCategories, setSelectedCategories] = useState(null)
    const [selectedTags, setSelectedTags] = useState([])



    const catChanged = (val) => {
        setSelectedCategories(val)
    }


    const toggleProdAddedToOrder = (prod) => {
        // setProdAddedToOrder(!prodAddedToOrder)
        cogoToast.success("Product Added to Concierge")

        props.productAdded(prod)
        let existingAdded = addedProducts

        existingAdded.push(prod)
        setAddedProducts(existingAdded)

        setTimeout(() => {
            setSelectedProduct(null)
        }, 500)

    }

    const filterRow = () => {

        return (
            <div>
                <Columns className='  ' >
                    <Columns.Column onChange={(e) => { catChanged(e.target.value) }} lav size={4} className=' marginLeft40   bottomPadding0'>

                        <Button className="is-aligned-right"> Create My Style </Button>


                        {/* <Dropdown  >
                            <Dropdown.Item value="item" >
                                Dropdown item
                                </Dropdown.Item>
                            <Dropdown.Item value="other">
                                Other Dropdown item
                            </Dropdown.Item>
                            <Dropdown.Item value="active">
                                Active Dropdown item
                            </Dropdown.Item>
                            <Dropdown.Item value="other 2">
                                Other Dropdown item
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item value="divider">
                                With divider
                            </Dropdown.Item>
                        </Dropdown> */}

                    </Columns.Column>

                </Columns>
            </div>
        )

    }

    const toggleProductModal = (prod) => {
        setSelectedProduct(prod ?? null)
        // setShowProductModal(!showProductModal)
    }

    const productModal = (prod) => {

        let exists = false

        addedProducts.map(ar => {
            if (ar.objectId === prod.objectId) {
                exists = true
            }
            return null
        })



        return (
            <Modal className='' show={selectedProduct != null} closeOnBlur={true} showClose={true} onClose={() => { toggleProductModal() }} >
                <Modal.Content className='is-padingless '>
                        <Box className='is-radiusless is-padingless fadeIn ' >
                            <div className=' is-clipped'>

                            <Columns>
                                <Columns.Column size={7} >
                                    {carouselImages(prod.imagesUrls)}
                                    {/* <Image className='is-clipped ' src={prod.imageUrl} size={"2by3"} /> */}
                                </Columns.Column>
                                <Columns.Column size={5} >
                                    <div className="margin20 pad20">
                                        <Heading size={6}>
                                            {prod.name}
                                        </Heading>
                                        <p className="type60 show-white-space ">
                                            {prod.description}
                                        </p>
                                        <br />
                                        <p className="type70 is-bold">
                                            {prod.currency + prod.price}
                                        </p>
                                        <br />
                                        <Button onClick={() => { toggleProdAddedToOrder(prod) }} color={exists ? "primary" : "black"} className={"is-black is-radiusless"}> {exists ? "Add Another" : "Add to Order"}  </Button>
                                        <br />
                                        <p className=' marginTop20 is-size-7 has-text-grey-light'> {standardDeliveryTime} </p>
                                        {exists ?
                                            <p className=' marginTop20 is-size-7 has-text-info'> Choose 'Concierge' in Menu to view added products. </p>
                                            : null}
                                    </div>
                                </Columns.Column>
                            </Columns>


                            </div>


                        </Box>
                </Modal.Content>
            </Modal>
        )
    }




    const header = (childElement) => {
        return (
            <div>
                {selectedProduct ? productModal(selectedProduct) : null}
                <Container className=''>
                    <Heading size={4} className='has-text-centered'> Welcome to our Shop</Heading>
                    <ColumnAux isMiddle={true} size={11}>


                        <div className=" marginLeft20 pad10">
                            {/* <div className="select is-pulled-left  ">
                                <select>
                                    <option>View All</option>
                                    <option>View Jackets</option>
                                    <option>View WaistCoats</option>
                                    <option>View OverCoats</option>
                                    <option>View Shirts</option>
                                    <option>View Pants</option>
                                </select>
                            </div> */}
                        </div>
                        <div className=" is-centered has-text-centered">
                            <Button className="is-light" onClick={props.showConcierge} > Create Custom Style with Concierge </Button>

                        </div>
                    </ColumnAux>
                    <Section>
                        <Heading className="has-text-grey has-text-centered" size={6} > Ready Styles Made to Order </Heading>

                        <div>
                            {productsCollection()}

                        </div>
                        {/* {childElement} */}

                    </Section>
                </Container>
            </div>
        )
    }


    const productsCollection = () => {

        return photoBox()

    }


    const productSelected = (prod) => {

        toggleProductModal(prod)

    }



    const carouselImages = (images) => {

        return (<Slider dots>

            {
                images.map((img) => {
                    return <img alt="productImages" src={img} className="myCarouselImage" ></img>

                    // return  < Image className='is-clipped ' src={img} />
                })
            }

        </Slider>)

    }

    const imageBoxWithText = (prod) => {

        let textClasses = ' marginTop10 type55 has-text-centered '
        // if (props.boldText) {
        //     textClasses = ' pad10 has-items-vertical-center  has-text-centered has-text-weight-medium is-size-6'
        // }
        if (props.imageUrl === null) {
            textClasses += ' verticalCenter pad10'
        }

        return <Box key={prod.objectId} selected className={"myFlexItem  stylesBox is-radiusless  longBox-360-480"} onClick={() => { productSelected(prod) }} >
            {prod.imageUrl ?

                // <LazyLoadImage alt="productImage"  src={prod.imageUrl} className="myImage" />


                <img alt="productImage" src={prod.imageUrl} className="myImage" ></img>

                //    <figure className="is-square" size={"1by1"}>  < Image className='is-clipped myImage' src={prod.imageUrl} size={""} /> </figure> 
                :
                null
            }

            <div className={textClasses} >{prod.name}</div>

            <div>
                {prod.price ?
                    <div className=' type45 has-text-centered  has-text-weight-semibold has-text-grey' >{currencySymbol}{prod.price}</div>
                    : null
                }

            </div>

        </Box>
    }

    const photoBox = () => {

        const steps = products.map((opt) => {
            return imageBoxWithText(opt)
        })

        return (
            <div className='myFlexContainer '>
                {steps}
            </div>
        )
    }






    const getCurrentPage = () => {



        return header()


    }

    return (
        getCurrentPage()
    )

}

export default Shop