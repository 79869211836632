import React from 'react'
import { Button, Loader, Heading, Box, Image, Columns, Card, Table } from 'react-bulma-components/dist'
import { imageNamed, scrollToRef } from '../utilities/utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCross, faCrosshairs, faRemoveFormat } from '@fortawesome/free-solid-svg-icons'


const MeasurementBox = (props) => {


    // console.log('IMAGE NAMED fourButton =', imageNamed('fourButton'))

    // description: "Some Description"

    // key: "id1591113207703"

    // measures: Object

    //     age: null

    //     arms: { value: "14", unit: "Inchs" }

    //     biceps: { value: "21", unit: "Inchs" }

    //     chest: { value: "40", unit: "Inchs" }

    //     height: { value: "5.11", unit: "Fts" }

    //     inseem: { value: "12", unit: "Inchs" }

    //     neck: { value: "14", unit: "Inchs" }

    //     pantLength: { value: "70", unit: "Inchs" }

    //     shirtLength: { value: "54", unit: "Inchs" }

    //     shoulder: { value: "40", unit: "Inchs" }

    //     suitLength: { value: "78", unit: "Inchs" }

    //     waiste: { value: "32", unit: "Inchs" }

    //     weight: { value: "70", unit: "Kg" }

    //     wrist: { value: "11", unit: "Inchs" }

    // productType: "SUIT"

    console.log('Incoming Measure =', props.measure)

    const result = Object.keys(props.measure.measures).map(function (key) {
        return [key, props.measure.measures[key]];
    });

    let withoutNull = result.filter(ar => {
        return !ar.includes(null)
    })

    withoutNull = result.filter(ar => {
        return !ar.includes('Age')
    })


    // let removeUnwanted = withoutNull.filter( ar => {
    //     return (ar !== 'age')
    // })


    const infoRows = withoutNull.map(ar => {
        let name = ar[0]
        let valObj = ar[1]

        if (name === '0'){
            return null
        }

        // return  <li key={name} className=' type60 list-item'> {name} : {valObj.value} {valObj.unit} </li>
        return <tr key={name}><td>{name}</td><td className='has-text-right'>{valObj.value}&nbsp;{valObj.unit}</td></tr>
    })
    // console.log('Without Measure Array =', withoutNull)





    return (
        <Card className= { props.isAtProfile ? ' selectedMeasurementBox  is-radiusless pad0 stylesBox'  : ' selectedMeasurementBox selectedMeasurementBoxCentered margin10 is-radiusless pad0 stylesBox'   }>
            <Card.Header className=' has-background-light'>
                {/* {props.isAtProfile ? null :
                        <Button onClick={() => { props.removePressed(props.measure.key) }} className='  marginTop4 is-shadowless is-borderless has-background-light has-text-grey  type50 is-small'> Clear
                        </Button>
                } */}


                <p className= { props.isAtProfile ? 'is-size-7 width246 marginLeft10 has-text-weight-medium' : 'is-size-7 width246 marginLeft10 has-text-centered   has-text-weight-medium'  } > ALL MEASUREMENTS </p>


                {props.isAtProfile ? 
                null :
                    <Button onClick={() => { props.editPressed(props.measure.key) }} className='  marginTop4 is-radiusless is-shadowless has-background-light is-borderless type55 is-small'>EDIT</Button>
                }

            </Card.Header>
            <Card.Content className='  is-marginless '>
                {props.isAtProfile ? <p className='type65 has-text-weight-medium has-text-grey has-text-centered'> Editable in Concierge </p> : null}

                <p className='type55 marginTop10 has-text-centered'> {props.measure.description} </p>
                <div className=' marginHorizontal10'>
                    <Table className='is-hoverable is-size-7'>
                        <tbody>
                            {infoRows}
                        </tbody>
                    </Table>
                    {/* <ul className=' list is-shadowless is-borderless'> */}
                    {/* </ul> */}
                </div>


            </Card.Content>

        </Card>
    )

}

export default MeasurementBox