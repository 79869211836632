import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Button, 
    Box, 
} from 'react-bulma-components/dist'
import StyleBox from './StyleBox'
import { PRODUCT_TYPE, randomAlphabets, imageNamed } from '../utilities/utils'
import StyleChooserWizard from './StyleChooserWizard2'


class StylesSection extends Component {



    state = {
        products: [],
        editingProduct: false,
        showProductWizard: false
    }

    _mounted = false 

    componentDidMount =() => {
        this._mounted = true

        if (this.props.products){
            if (this._mounted){
                let ns = {...this.state}
                ns.products = this.props.products
                this.setState(ns)
            }
        }

    }

    componentWillUnmount = () => {
        this._mounted = false
    }


    choiceMadeInChooseBox = (choice) => {
        console.log('CHOICE MADE IS -', choice)
        if (this._mounted) {
            let ns = { ...this.state }
            ns.currentWizard = 'styleChooser'
            this.setState(ns)
        }
    }

    productWizardComplete = (endpoint, style) => {
        console.log('Style Chooser Completed with endpoint = ' + endpoint + '. And Style =', style)
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showProductWizard = false
            let existingProducts = [...ns.products]

            if (this.state.editingProduct) {
                console.log('Old Style =', ns.editingProduct)
                ns.editingProduct = null
                let objIndex = existingProducts.findIndex(obj =>
                    obj.key === style.key
                )
                existingProducts[objIndex] = style
                console.log('CHANGED SELECTED OBJECT')
                console.log('New Style =', style)

            } else {
                style.key = Date.now()
                existingProducts.push(style)
            }

            ns.products = existingProducts
            this.setState(ns)

            console.log('**SENDING STYLES CHANGES TO')
            console.log(existingProducts)

            this.props.stylesChanged(existingProducts)
        }

    }

    addProductPressed = () => {
        // Add Option to select wizard here, Suit, Shirt, Pant, Sherwani etce
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showProductWizard = true
            this.setState(ns)
        }
    }

    productRemovePressed = (key) => {
        console.log(' Remove Pressed for Style With Key -', key)

        console.log('ALL PRODUCTS')
        console.log(this.state.products)



        if (this._mounted) {
            let ns = { ...this.state }
            ns.showProductWizard = false
            let existingProducts = [...ns.products]
            existingProducts = existingProducts.filter((obj) => {
                return obj.key !== key
            })
            ns.products = existingProducts
            this.setState(ns)

            console.log('**SENDING STYLES CHANGES TO')
            console.log(existingProducts)

            this.props.stylesChanged(existingProducts)
        }

    }

    productEditPressed = (key) => {
        console.log(' Edit Pressed for Style With Key -', key)

        if (this._mounted) {
            const products = [...this.state.products]
            let thisProduct = products.filter((obj) => {
                return obj.key === key
            })[0]
            console.log('EDITING This Style =', thisProduct)
            let ns = { ...this.state }
            ns.showProductWizard = true
            ns.editingProduct = thisProduct
            this.setState(ns)
        }
    }

    cancelPressedInWizard = () => {
        // clear any imaages of keys 
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showProductWizard = false
            ns.editingProduct = false
            this.setState(ns)
        }
    }



    showProductsOrWizard = () => {

        if (this.state.showProductWizard) {
            // CHECK THE SELECTED WIZARD HERE
            // return <Box className='is-radiusless is-shadowless layerGreyBG  minHeight60vh '>
            return <Box className='is-radiusless is-shadowless   fadeIn'>
                <StyleChooserWizard  editingProduct={this.state.editingProduct} cancelPressed={this.cancelPressedInWizard} isDone={this.productWizardComplete} />
            </Box>
        }

        // console.log("ALL PRODUCTS")
        // console.log(this.state.products)

        let products = this.state.products.map((style,index) => {
            // console.log('THIS STYLE  = ',style)

            // console.log('THIS STYLE KEY = ',style.key)
            // return <div className='marginTop30'> <StyleCard key={style.key} style={style} removePressed={this.productRemovePressed} editPressed={this.productEditPressed} /> </div>
            return <div className='marginTop30'> <StyleBox key={style.key} index={index} style={style} removePressed={this.productRemovePressed} editPressed={this.productEditPressed} /> </div>
        })

        return (<div> <div className=' marginTop20 fadeIn '> {products} </div>
            <br />
            <Box className=' is-shadowless  myFlexContainer boxLast0 pad0 fadeIn'>
                <div className='has-items-vertical-center selectable '>
                    <Button className='is-radiusless  addStyleBox   no-decoration' onClick={this.addProductPressed} >
                        <i className=" has-texh-weight-thin marginRight10"> <FontAwesomeIcon className='' icon={faPlus} /></i>
                        Create Bespoke Style
                        </Button>
                </div>
            </Box>

            {products.length > 0 ?
                <Box className=' is-marginless is-radiusless is-shadowless  myFlexContainer fadeIn  '>
                    {/* <Button className='is-black  doneButton is-radiusless '> Done. Start Measure. </Button> */}
                    <Button className=' doneButton is-radiusless is-pulled-right ' onClick={ () => { this.props.doneLetsMeasure(this.state.products) } }>&nbsp; Done. Lets Measure
                     <i className=" has-texh-weight-thin marginLeft10"> <FontAwesomeIcon className='padRight20' icon={faArrowRight} /></i>
                    </Button>
                </Box>
                :

                <Box className=' is-marginless is-borderless is-radiusless is-shadowless  myFlexContainer fadeIn  '>
                    <img src={imageNamed('welcomeOnConcierge')} alt="Tailorwise: Streamlined Bespoke Tailoring" width="400" height="600" />
                </Box>


            }
            </div>
        )

    }



    render() {
        return this.showProductsOrWizard()
    }
}

export default StylesSection