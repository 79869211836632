import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {  Columns } from 'react-bulma-components/dist'

const ColumnAux = (props) => {
        // console.log('COLUMN AUX PROPS = ',props)
        if (props.isMiddle){
           return     <Columns className={props.className}> <Columns.Column className={ props.noPaddingSides  ? ' pad0' : '' }></Columns.Column> <Columns.Column className={props.className} size={props.size}> {props.children} </Columns.Column>  <Columns.Column className={ props.noPaddingSides ? 'pad0' : '' } ></Columns.Column> </Columns>
        }
        return <Columns> <Columns.Column size={props.size}> {props.children} </Columns.Column> </Columns>
}

export default ColumnAux