/* eslint-disable */

import React, { Component } from 'react'
import StepWizard from 'react-step-wizard'
import Step from '../containers/Step'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal
} from 'react-bulma-components/dist'

import ColumnAux from '../containers/ColumnAux'
import LoadingAux from '../containers/LoadingAux'
import ChooseBox from '../containers/ChooseBox'

import { imageNamed, webImageNamed, PRODUCT_TYPE, makeid } from '../utilities/utils'
import { C_Product, Product } from '../utilities/classes'
import { currencySymbol } from './clientInfo'







// console.log('Images Folder =', allImages)
// console.log('Images with ````` =', imageNamed('`````'))



class WizardForShirt extends Component {

    basePrice = 0


    // 1. FitPreference
    // 2. Collar
    // 3. CollarWingLength
    // 4. PlacketStyle
    // 5. Pocket
    // 6. SleeveStyle
    // 7. Cuff
    // 8. BackPleats
    // 9. ShirtLength
    // 10. Monogram
    // 11. CollarContrast
    // 12. CuffContrast
    // 13. ElbowPatch




    STEP_KEYS = {
        productType: '',
        fitPreference: 'FIT PREFERENCE',
        collar: 'COLLAR',
        collarWingLength: 'COLLAR WING LENGTH',
        placketStyle: 'PLACKET STYLE',
        pocket: 'POCKET',
        sleeveStyle: 'SLEEVE STYLE',
        cuff: 'CUFF',
        backPleats: 'BACK PLEATS',
        shirtLength: 'SHIRT LENGTH',
        monogram: 'MONOGRAM',
        monogramThreadColor: 'MONOGRAM THREAD COLOR', // NEW (conditional) - dont show on no monogram
        tone: 'TONE',
        fabric: 'FABRIC',
        collarContrast: 'COLLAR CONTRAST',
        collarContrastFabric: 'COLLAR CONTRAST FABRIC',
        cuffContrast: 'CUFF CONTRAST',
        cuffContrastFabric: 'CUFF CONTRAST FABRIC',
        elbowPatch: 'ELBOW PATCH',
        elbowPatchFabric: 'ELBOW PATCH FABRIC',
        buttons: 'BUTTONS', //NEW

    }

    TONE_KEYS = {
        greys: 'greys',
        blues: 'blues',
        blacks: 'blacks',
        playful: 'playfuls'
    }

    STYLE_START = {
        create: 'Create Style',
        upload: 'Upload Style',
        select: 'Select Style'
    }






    state = {
        uploadImage: null,
        selected_FitPreference: this.props.editingProduct ? this.props.editingProduct.selectedSelections.fitPreference : null,
        selected_Collar: this.props.editingProduct ? this.props.editingProduct.selectedSelections.collar : null,
        selected_CollarWingLength: this.props.editingProduct ? this.props.editingProduct.selectedSelections.collarWingLength : null,
        selected_PlacketStyle: this.props.editingProduct ? this.props.editingProduct.selectedSelections.placketStyle : null,
        selected_Pocket: this.props.editingProduct ? this.props.editingProduct.selectedSelections.pocket : null,
        selected_SleeveStyle: this.props.editingProduct ? this.props.editingProduct.selectedSelections.sleeveStyle : null,
        selected_Cuff: this.props.editingProduct ? this.props.editingProduct.selectedSelections.cuff : null,

        selected_BackPleats: this.props.editingProduct ? this.props.editingProduct.selectedSelections.backPleats : null,
        selected_ShirtLength: this.props.editingProduct ? this.props.editingProduct.selectedSelections.shirtLength : null,


        // selected_LowerPockets: this.props.editingProduct ? this.props.editingProduct.selectedSelections.lowerPockets : null,
        // selected_Vent: this.props.editingProduct ? this.props.editingProduct.selectedSelections.vent : null,
        // selected_SleeveButtons: this.props.editingProduct ? this.props.editingProduct.selectedSelections.sleeveButtons : null,
        // selected_Lining: this.props.editingProduct ? this.props.editingProduct.selectedSelections.lining : null,
        selected_Monogram: this.props.editingProduct ? this.props.editingProduct.selectedSelections.monogram : null,
        selected_MonogramThreadColor: this.props.editingProduct ? this.props.editingProduct.selectedSelections.monogramThreadColor : null,

        // selected_Tone: this.props.editingProduct ? this.props.editingProduct.selectedSelections.tone : null,
        selected_Fabric: this.props.editingProduct ? this.props.editingProduct.selectedSelections.fabric : null,
        selected_Buttons: this.props.editingProduct ? this.props.editingProduct.selectedSelections.selected_Buttons : null,

        selected_CollarContrast: this.props.editingProduct ? this.props.editingProduct.selectedSelections.collarContrast : null,
        selected_CollarContrastFabric: this.props.editingProduct ? this.props.editingProduct.selectedSelections.collarContrastFabric : null,

        selected_CuffContrast: this.props.editingProduct ? this.props.editingProduct.selectedSelections.cuffContrast : null,
        selected_CuffContrastFabric: this.props.editingProduct ? this.props.editingProduct.selectedSelections.cuffContrastFabric : null,

        selected_ElbowPatch: this.props.editingProduct ? this.props.editingProduct.selectedSelections.elbowPatch : null,
        selected_ElbowPatchFabric: this.props.editingProduct ? this.props.editingProduct.selectedSelections.elbowPatchFabric : null,

        isComplete: false,
        isCancelled: false,
        selectedCreateOrUpload: this.STYLE_START.create,
        editingProduct: this.props.editingProduct ?? null,
        productType: PRODUCT_TYPE.jacket
    }



    _mounted = false


    componentDidMount = () => {
        this._mounted = true

        let ns = { ...this.state }
        if (this.props.editingProduct) {
            ns.editingProduct = this.props.editingProduct
        }
        if (this.props.productType) {
            ns.productType = this.props.productType
        }




        if (this.props.editingProduct) {
            let existingStyle = { ...this.props.editingProduct }
            ns.editingProduct = existingStyle
            // console.log("EDITING WIZRAD OF JACKET")
            // console.log(existingStyle)

                // monogramThreadColor
    // buttons

            ns.selected_FitPreference = existingStyle.selectedSelections.fitPreference
            ns.selected_Collar = existingStyle.selectedSelections.collar
            ns.selected_CollarWingLength = existingStyle.selectedSelections.collarWingLength
            ns.selected_PlacketStyle = existingStyle.selectedSelections.placketStyle
            ns.selected_Pocket = existingStyle.selectedSelections.pocket
            ns.selected_SleeveStyle = existingStyle.selectedSelections.sleeveStyle
            ns.selected_Cuff = existingStyle.selectedSelections.cuff
            ns.selected_BackPleats = existingStyle.selectedSelections.backPleats
            ns.selected_ShirtLength = existingStyle.selectedSelections.shirtLength
            ns.selected_Monogram = existingStyle.selectedSelections.monogram
            ns.selected_MonogramThreadColor = existingStyle.selectedSelections.monogramThreadColor

            // ns.selected_LowerPockets = existingStyle.selectedSelections.lowerPockets
            // ns.selected_Vent = existingStyle.selectedSelections.vent
            // ns.selected_SleeveButtons = existingStyle.selectedSelections.sleeveButtons
            // ns.selected_Lining = existingStyle.selectedSelections.lining
            ns.selected_Monogram = existingStyle.selectedSelections.monogram
            // ns.selected_Tone = existingStyle.selectedSelections.tone
            ns.selected_Fabric = existingStyle.selectedSelections.fabric
            ns.selected_Buttons = existingStyle.selectedSelections.buttons

            ns.selected_CollarContrast = existingStyle.selectedSelections.collarContrast
            ns.selected_CollarContrastFabric = existingStyle.selectedSelections.collarContrastFabric

            ns.selected_CuffContrast = existingStyle.selectedSelections.cuffContrast
            ns.selected_CuffContrastFabric = existingStyle.selectedSelections.cuffContrastFabric

            ns.selected_ElbowPatch = existingStyle.selectedSelections.elbowPatch
            ns.selected_ElbowPatchFabric = existingStyle.selectedSelections.elbowPatchFabric

        }


        this.setState(ns)

    }

    componentWillUnmount = () => {
        this._mounted = false
    }







    // FIT PREFERENCE
    fitPreference_Opts = [
        { optType: "FIT PREFERENCE", styleSelectionId: "", key: 'SLIM', name: 'SLIM', imageSrc: webImageNamed('StyleSelections/Shirt/Fit/Slim.jpg') },
        { optType: "FIT PREFERENCE", styleSelectionId: "", key: 'COMFORT', name: 'COMFORT', imageSrc: webImageNamed('StyleSelections/Shirt/Fit/Comfort.jpg') },
        { optType: "FIT PREFERENCE", styleSelectionId: "", key: 'LOOSE', name: 'LOOSE', imageSrc: webImageNamed('StyleSelections/Shirt/Fit/Loose.jpg') },
    ]
    fitPreference_Step = () => {
        return {
            name: 'Choose Fit Preference',
            key: this.STEP_KEYS.fitPreference,
            opts: this.fitPreference_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // COLLAR
    collar_Opts = [
        { optType: "COLLAR", styleSelectionId: "", key: 'POINTED COLLAR (STANDARD)', name: 'POINTED COLLAR (STANDARD)', imageSrc: webImageNamed('StyleSelections/Shirt/CollarStyle/POINTEDstandard.jpg') },
        { optType: "COLLAR", styleSelectionId: "", key: 'POINTED WITH BUTTON DOWN', name: 'POINTED WITH BUTTON DOWN', imageSrc: webImageNamed('StyleSelections/Shirt/CollarStyle/STANDARDwithBUTTONdown.jpg') },
        { optType: "COLLAR", styleSelectionId: "", key: 'POINTED WITH HIDDEN BUTTON DOWN', name: 'POINTED WITH HIDDEN BUTTON DOWN', imageSrc: webImageNamed('StyleSelections/Shirt/CollarStyle/POINTEDwithHIDDENbuttonDOWN.jpg') },
        { optType: "COLLAR", styleSelectionId: "", key: 'BAND COLLAR', name: 'BAND COLLAR', imageSrc: webImageNamed('StyleSelections/Shirt/CollarStyle/BandCollar.jpg') },
        { optType: "COLLAR", styleSelectionId: "", key: 'CLUB COLLAR', name: 'CLUB COLLAR', imageSrc: webImageNamed('StyleSelections/Shirt/CollarStyle/ClubCollar.jpg') },
        { optType: "COLLAR", styleSelectionId: "", key: 'TUXEDO COLLAR', name: 'TUXEDO COLLAR', imageSrc: webImageNamed('StyleSelections/Shirt/CollarStyle/TuxedoCollar.jpg') },
        { optType: "COLLAR", styleSelectionId: "", key: 'CUTAWAY COLLAR', name: 'CUTAWAY COLLAR', imageSrc: webImageNamed('StyleSelections/Shirt/CollarStyle/CUTAWAY.jpg') },
        { optType: "COLLAR", styleSelectionId: "", key: 'WIDE CUTAWAY', name: 'WIDE CUTAWAY', imageSrc: webImageNamed('StyleSelections/Shirt/CollarStyle/WIDEcutaway.jpg') },
    ]
    collar_Step = () => {
        return {
            name: 'How would you like the Collar?',
            key: this.STEP_KEYS.collar,
            opts: this.collar_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }



    // COLLAR WING LENGTH
    collarWingLength_Opts = [
        { optType: "COLLAR WING LENGTH", styleSelectionId: "", key: 'STANDARD (1.5 INCH)', name: 'STANDARD (1.5 INCH)', imageSrc: webImageNamed('StyleSelections/Shirt/CollarWingLength/Standard.jpg') },
        { optType: "COLLAR WING LENGTH", styleSelectionId: "", key: 'WIDE (2 INCH)', name: 'WIDE (2 INCH)', imageSrc: webImageNamed('StyleSelections/Shirt/CollarWingLength/Wide.jpg') },
    ]
    collarWingLength_Step = () => {
        return {
            name: 'Choose Collar Wing Length',
            key: this.STEP_KEYS.collarWingLength,
            opts: this.collarWingLength_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // Placket Style  
    placketStyle_Opts = [
        { optType: "PLACKET STYLE", styleSelectionId: "", key: 'STANDARD', name: 'STANDARD', imageSrc: webImageNamed('StyleSelections/Shirt/Placket/Standard.jpg') },
        { optType: "PLACKET STYLE", styleSelectionId: "", key: 'COVERED', name: 'COVERED', imageSrc: webImageNamed('StyleSelections/Shirt/Placket/Covered.jpg') },
        { optType: "PLACKET STYLE", styleSelectionId: "", key: 'TUXEDO STUD', name: 'TUXEDO STUD', imageSrc: webImageNamed('StyleSelections/Shirt/Placket/TuxedoStud.jpg') },
    ]
    placketStyle_Step = () => {
        return {
            name: 'How would you like the Placket Style?',
            key: this.STEP_KEYS.placketStyle,
            opts: this.placketStyle_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // Pocket  
    pocket_Opts = [
        { optType: "POCKET", styleSelectionId: "", key: 'NO POCKET (STANDARD)', name: 'NO POCKET (STANDARD)', imageSrc: webImageNamed('StyleSelections/Shirt/PocketStyle/NoPocket.jpg') },
        { optType: "POCKET", styleSelectionId: "", key: 'SINGLE POCKET', name: 'SINGLE POCKET', imageSrc: webImageNamed('StyleSelections/Shirt/PocketStyle/Single.jpg') },
        { optType: "POCKET", styleSelectionId: "", key: 'DOUBLE POCKETS', name: 'DOUBLE POCKETS', imageSrc: webImageNamed('StyleSelections/Shirt/PocketStyle/Double.jpg') },
    ]
    pocket_Step = () => {
        return {
            name: 'How would you like the POCKET?',
            key: this.STEP_KEYS.pocket,
            opts: this.pocket_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // SleeveStyle  
    sleeveStyle_Opts = [        
        { optType: "SLEEVE STYLE", styleSelectionId: "", key: 'SHORT', name: 'SHORT', imageSrc: webImageNamed('StyleSelections/Shirt/SleeveStyle/Short.jpg') },
        { optType: "SLEEVE STYLE", styleSelectionId: "", key: 'LONG', name: 'LONG', imageSrc: webImageNamed('StyleSelections/Shirt/SleeveStyle/Long.jpg') },
    ]
    sleeveStyle_Step = () => {
        return {
            name: 'Sleeve Style',
            key: this.STEP_KEYS.sleeveStyle,
            opts: this.sleeveStyle_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }




    // CUFF
    cuff_Opts = [
        { optType: "CUFF", styleSelectionId: "", key: 'SQUARE', name: 'SQUARE', imageSrc: webImageNamed('StyleSelections/Shirt/CuffStyle/Square.jpg') },
        { optType: "CUFF", styleSelectionId: "", key: 'ROUND', name: 'ROUND', imageSrc: webImageNamed('StyleSelections/Shirt/CuffStyle/Round.jpg') },
        { optType: "CUFF", styleSelectionId: "", key: 'MITERED', name: 'MITERED', imageSrc: webImageNamed('StyleSelections/Shirt/CuffStyle/Mitered.jpg') },
        { optType: "CUFF", styleSelectionId: "", key: 'FRENCH SQUARE', name: 'FRENCH SQUARE', imageSrc: webImageNamed('StyleSelections/Shirt/CuffStyle/FrenchSquare.jpg') },
        { optType: "CUFF", styleSelectionId: "", key: 'FRENCH MITERED', name: 'FRENCH MITERED', imageSrc: webImageNamed('StyleSelections/Shirt/CuffStyle/FrenchMitered.jpg') },
        { optType: "CUFF", styleSelectionId: "", key: 'CONVERTIBLE SQUARE', name: 'CONVERTIBLE SQUARE', imageSrc: webImageNamed('StyleSelections/Shirt/CuffStyle/ConvertibleSquare.jpg') },
        { optType: "CUFF", styleSelectionId: "", key: 'CONVERTIBLE ROUND', name: 'CONVERTIBLE ROUND', imageSrc: webImageNamed('StyleSelections/Shirt/CuffStyle/ConvertibleRound.jpg') },
        { optType: "CUFF", styleSelectionId: "", key: 'CONVERTIBLE MITERED', name: 'CONVERTIBLE MITERED', imageSrc: webImageNamed('StyleSelections/Shirt/CuffStyle/ConvertibleMitered.jpg') },
        { optType: "CUFF", styleSelectionId: "", key: 'DOUBLE BUTTON SQUARE', name: 'DOUBLE BUTTON SQUARE', imageSrc: webImageNamed('StyleSelections/Shirt/CuffStyle/DoubleButtonSquare.jpg') },
        { optType: "CUFF", styleSelectionId: "", key: 'DOUBLE BUTTON ROUND', name: 'DOUBLE BUTTON ROUND', imageSrc: webImageNamed('StyleSelections/Shirt/CuffStyle/DoubleButtonRound.jpg') },
        { optType: "CUFF", styleSelectionId: "", key: 'DOUBLE BUTTON MITERED', name: 'DOUBLE BUTTON MITERED', imageSrc: webImageNamed('StyleSelections/Shirt/CuffStyle/DoubleButtonMitered.jpg') },
        { optType: "CUFF", styleSelectionId: "", key: 'COCKTAIL CUFF', name: 'COCKTAIL CUFF', imageSrc: webImageNamed('StyleSelections/Shirt/CuffStyle/CockTailCuff.jpg') },

    ]
    cuff_Step = () => {
        return {
            name: 'What Cuffs would suit you the best?',
            key: this.STEP_KEYS.cuff,
            opts: this.cuff_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }




    //BACK PLEATS
    backPleats_Opts = [
        { optType: "BACK PLEATS", styleSelectionId: "", key: 'NO PLEATS', name: 'NO PLEATS', imageSrc: webImageNamed('StyleSelections/Shirt/BackPleats/NoPleats.jpg') },
        { optType: "BACK PLEATS", styleSelectionId: "", key: 'BOX PLEATS', name: 'BOX PLEATS', imageSrc: webImageNamed('StyleSelections/Shirt/BackPleats/BoxPleat.jpg') },
        { optType: "BACK PLEATS", styleSelectionId: "", key: 'SIDE PLEATS', name: 'SIDE PLEATS', imageSrc: webImageNamed('StyleSelections/Shirt/BackPleats/SidePleats.jpg') }
    ]
    backPleats_Step = () => {
        return {
            name: 'Back Pleats',
            key: this.STEP_KEYS.backPleats,
            opts: this.backPleats_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }



    // ShirtLength
    shirtLength_Opts = [
        { optType: "SHIRT LENGTH", styleSelectionId: "", key: 'LONG (TUCKED)', name: 'LONG (TUCKED)', imageSrc: webImageNamed('StyleSelections/Shirt/Length/TUCKEDlong.jpg') },
        { optType: "SHIRT LENGTH", styleSelectionId: "", key: 'STANDARD (UN-TUCKED)', name: 'STANDARD (UN-TUCKED)', imageSrc: webImageNamed('StyleSelections/Shirt/Length/UNTUCKEDstandard.jpg') },
    ]
    shirtLength_Step = () => {
        return {
            name: 'Shirt Length Preference',
            key: this.STEP_KEYS.shirtLength,
            opts: this.shirtLength_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // CollarContrast
    collarContrast_Opts = [
        { optType: "COLLAR CONTRAST", styleSelectionId: "", key: 'NO CONTRAST', name: 'NO CONTRAST', imageSrc: webImageNamed('StyleSelections/Shirt/CollarContrast/NoContrast.jpg') },
        { optType: "COLLAR CONTRAST", styleSelectionId: "", key: 'COLLAR BAND', name: 'COLLAR BAND', imageSrc: webImageNamed('StyleSelections/Shirt/CollarContrast/CollarBand.jpg') },
        { optType: "COLLAR CONTRAST", styleSelectionId: "", key: 'INSIDE COLLAR', name: 'INSIDE COLLAR', imageSrc: webImageNamed('StyleSelections/Shirt/CollarContrast/InsideCollar.jpg') },
        { optType: "COLLAR CONTRAST", styleSelectionId: "", key: 'OUTER COLLAR', name: 'OUTER COLLAR', imageSrc: webImageNamed('StyleSelections/Shirt/CollarContrast/OuterCollar.jpg') },
        { optType: "COLLAR CONTRAST", styleSelectionId: "", key: 'WHOLE COLLAR', name: 'WHOLE COLLAR', imageSrc: webImageNamed('StyleSelections/Shirt/CollarContrast/WholeCollar.jpg') },

    ]
    collarContrast_Step = () => {
        return {
            name: 'Collar Contrast Preference',
            key: this.STEP_KEYS.collarContrast,
            opts: this.collarContrast_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }

    // CollarContrastFabric
    collarContrastFabricOpts = () => {
        let allFabricOpts = [...this.fabric_greyOpts.map((thisFab) => {
            thisFab.optType = "COLLAR CONTRAST FABRIC"
            return thisFab
        })]

        allFabricOpts.unshift(
            { optType: "COLLAR CONTRAST FABRIC", styleSelectionId: "", key: 'CHOOSE FOR ME', name: 'CHOOSE FOR ME', imageSrc: null }
        )

        allFabricOpts = allFabricOpts.map((thisFb) => {
            let fbCopy = {...thisFb}
            delete fbCopy["price"]
            return fbCopy
        })

        return allFabricOpts
    }
    collarContrastFabricStep = () => {
        return {
            name: 'Choose Collar Contrast Fabric',
            key: this.STEP_KEYS.collarContrastFabric,
            opts: this.collarContrastFabricOpts(),
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }




    // CuffContrast
    cuffContrast_Opts = [
        { optType: "CUFF CONTRAST", styleSelectionId: "", key: 'NO CONTRAST', name: 'NO CONTRAST', imageSrc: webImageNamed('StyleSelections/Shirt/CuffContrast/NoContrast.jpg') },
        { optType: "CUFF CONTRAST", styleSelectionId: "", key: 'INSIDE CUFF', name: 'INSIDE CUFF', imageSrc: webImageNamed('StyleSelections/Shirt/CuffContrast/InsideCuff.jpg') },
        { optType: "CUFF CONTRAST", styleSelectionId: "", key: 'OUTSIDE CUFF', name: 'OUTSIDE CUFF', imageSrc: webImageNamed('StyleSelections/Shirt/CuffContrast/OutsideCuff.jpg') },
        { optType: "CUFF CONTRAST", styleSelectionId: "", key: 'WHOLE CUFF', name: 'WHOLE CUFF', imageSrc: webImageNamed('StyleSelections/Shirt/CuffContrast/WholeCuff.jpg') },

    ]
    cuffContrast_Step = () => {
        return {
            name: 'Cuff Contrast Preference',
            key: this.STEP_KEYS.cuffContrast,
            opts: this.cuffContrast_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // CuffContrast Fabric
    cuffContrastFabricOpts = () => {
        let allFabricOpts = [...this.fabric_greyOpts.map((thisFab) => {
            thisFab.optType = "CUFF CONTRAST FABRIC"
            return thisFab
        })]

        allFabricOpts.unshift(
            { optType: "CUFF CONTRAST FABRIC", styleSelectionId: "", key: 'SAME AS COLLAR', name: 'SAME AS COLLAR', imageSrc: null }
        )

        allFabricOpts.unshift(
            { optType: "CHOOSE FOR ME", styleSelectionId: "", key: 'CHOOSE FOR ME', name: 'CHOOSE FOR ME', imageSrc: null }
        )

        allFabricOpts = allFabricOpts.map((thisFb) => {
            let fbCopy = {...thisFb}
            delete fbCopy["price"]
            return fbCopy
        })

        return allFabricOpts
    }
    cuffContrastFabricStep = () => {
        return {
            name: 'Choose Cuff Contrast Fabric',
            key: this.STEP_KEYS.cuffContrastFabric,
            opts: this.cuffContrastFabricOpts(),
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }



    // ElbowPatch
    elbowPatch_Opts = [
        { optType: "ELBOW PATCH FABRIC", styleSelectionId: "", key: 'NO', name: 'NO', imageSrc: webImageNamed('StyleSelections/Shirt/ElbowPatch/NoPatch.jpg') },
        { optType: "ELBOW PATCH FABRIC", styleSelectionId: "", key: 'YES', name: 'YES', imageSrc: webImageNamed('StyleSelections/Shirt/ElbowPatch/YES.jpg') },
    ]

    elbowPatch_Step = () => {
        return {
            name: 'Elbow Patch ?',
            key: this.STEP_KEYS.elbowPatch,
            opts: this.elbowPatch_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }



    // ElbowPatch Fabric
    elbowPatchFabricOpts = () => {
        let allFabricOpts = [...this.fabric_greyOpts.map((thisFab) => {
            thisFab.optType = "ELBOW PATCH FABRIC"
            return thisFab
        })]

        allFabricOpts.unshift(
            { optType: "ELBOW PATCH FABRIC", styleSelectionId: "", key: 'SAME AS COLLAR', name: 'SAME AS COLLAR', imageSrc: null }
        )

        allFabricOpts.unshift(
            { optType: "ELBOW PATCH FABRIC", styleSelectionId: "", key: 'SAME AS CUFF', name: 'SAME AS CUFF', imageSrc: null }
        )

        return allFabricOpts
    }

    elbowPatchFabric_Step = () => {
        return {
            name: 'Choose Elbow Patch Fabric',
            key: this.STEP_KEYS.elbowPatchFabric,
            opts: this.elbowPatchFabricOpts(),
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }




    // MONOGRAM, ["SHIRT"], ["LEFT CUFF", "RIGHT CUFF"]
    // MONOGRAM
    monogram_Opts = [
        { optType: "MONOGRAM",  key: 'NO MONOGRAM', name: 'NO MONOGRAM', imageSrc: null, customInput: "" },
        { optType: "MONOGRAM", styleSelectionId: "", key: 'LEFT CUFF', name: 'LEFT CUFF', imageSrc: webImageNamed('StyleSelections/Shirt/Monogram/LEFTcuff.jpg'), customInput: "" },
        { optType: "MONOGRAM", styleSelectionId: "", key: 'RIGHT CUFF', name: 'RIGHT CUFF', imageSrc: webImageNamed('StyleSelections/Shirt/Monogram/RIGHTcuff.jpg'), customInput: "" },
        { optType: "MONOGRAM", styleSelectionId: "", key: 'CHEST POCKET', name: 'CHEST POCKET', imageSrc: webImageNamed('StyleSelections/Shirt/Monogram/ChestPocket.jpg'), customInput: "" },

    ]
    monogram_Step = () => {
        return {
            name: 'If and where you would like to have monogram',
            key: this.STEP_KEYS.monogram,
            opts: this.monogram_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: true,
            paddingLess: false,
            boldText: false,
            needsCustomInput: true
        }
    }

        // NEW ENTRY 
        monogramThreadColor_Opts = [
            // { optType: "Monogram Thread Color", key: '101', name: '101', imageSrc: webImageNamed('Fabrics/MonogramThread/101.jpg'), price: null, company: '' },

            { optType: "Monogram Thread Color", key: 'C1172', name: 'C1172', imageSrc: webImageNamed('Fabrics/SewingThread/C1172.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C1202', name: 'C1202', imageSrc: webImageNamed('Fabrics/SewingThread/C1202.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C1231', name: 'C1231', imageSrc: webImageNamed('Fabrics/SewingThread/C1231.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C1257', name: 'C1257', imageSrc: webImageNamed('Fabrics/SewingThread/C1257.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C1424', name: 'C1424', imageSrc: webImageNamed('Fabrics/SewingThread/C1424.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C1740', name: 'C1740', imageSrc: webImageNamed('Fabrics/SewingThread/C1740.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C2376', name: 'C2376', imageSrc: webImageNamed('Fabrics/SewingThread/C2376.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C2427', name: 'C2427', imageSrc: webImageNamed('Fabrics/SewingThread/C2427.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C2740', name: 'C2740', imageSrc: webImageNamed('Fabrics/SewingThread/C2740.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C3156', name: 'C3156', imageSrc: webImageNamed('Fabrics/SewingThread/C3156.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C3166', name: 'C3166', imageSrc: webImageNamed('Fabrics/SewingThread/C3166.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C3646', name: 'C3646', imageSrc: webImageNamed('Fabrics/SewingThread/C3646.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C3853', name: 'C3853', imageSrc: webImageNamed('Fabrics/SewingThread/C3853.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C3867', name: 'C3867', imageSrc: webImageNamed('Fabrics/SewingThread/C3867.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C3952', name: 'C3952', imageSrc: webImageNamed('Fabrics/SewingThread/C3952.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C3966', name: 'C3966', imageSrc: webImageNamed('Fabrics/SewingThread/C3966.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C3993', name: 'C3993', imageSrc: webImageNamed('Fabrics/SewingThread/C3993.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C4106', name: 'C4106', imageSrc: webImageNamed('Fabrics/SewingThread/C4106.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C4300', name: 'C4300', imageSrc: webImageNamed('Fabrics/SewingThread/C4300.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C4328', name: 'C4328', imageSrc: webImageNamed('Fabrics/SewingThread/C4328.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C4351', name: 'C4351', imageSrc: webImageNamed('Fabrics/SewingThread/C4351.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C4983', name: 'C4983', imageSrc: webImageNamed('Fabrics/SewingThread/C4983.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C4987', name: 'C4987', imageSrc: webImageNamed('Fabrics/SewingThread/C4987.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C5140', name: 'C5140', imageSrc: webImageNamed('Fabrics/SewingThread/C5140.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C5229', name: 'C5229', imageSrc: webImageNamed('Fabrics/SewingThread/C5229.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C5337', name: 'C5337', imageSrc: webImageNamed('Fabrics/SewingThread/C5337.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C5345', name: 'C5345', imageSrc: webImageNamed('Fabrics/SewingThread/C5345.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C5744', name: 'C5744', imageSrc: webImageNamed('Fabrics/SewingThread/C5744.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C5943', name: 'C5943', imageSrc: webImageNamed('Fabrics/SewingThread/C5943.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C5987', name: 'C5987', imageSrc: webImageNamed('Fabrics/SewingThread/C5987.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C6178', name: 'C6178', imageSrc: webImageNamed('Fabrics/SewingThread/C6178.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C6935', name: 'C6935', imageSrc: webImageNamed('Fabrics/SewingThread/C6935.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C7201', name: 'C7201', imageSrc: webImageNamed('Fabrics/SewingThread/C7201.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C7279', name: 'C7279', imageSrc: webImageNamed('Fabrics/SewingThread/C7279.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C7305', name: 'C7305', imageSrc: webImageNamed('Fabrics/SewingThread/C7305.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C7930', name: 'C7930', imageSrc: webImageNamed('Fabrics/SewingThread/C7930.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C7978', name: 'C7978', imageSrc: webImageNamed('Fabrics/SewingThread/C7978.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C8501', name: 'C8501', imageSrc: webImageNamed('Fabrics/SewingThread/C8501.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C8587', name: 'C8587', imageSrc: webImageNamed('Fabrics/SewingThread/C8587.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C8989', name: 'C8989', imageSrc: webImageNamed('Fabrics/SewingThread/C8989.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C9114', name: 'C9114', imageSrc: webImageNamed('Fabrics/SewingThread/C9114.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C9313', name: 'C9313', imageSrc: webImageNamed('Fabrics/SewingThread/C9313.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C9623', name: 'C9623', imageSrc: webImageNamed('Fabrics/SewingThread/C9623.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C9631', name: 'C9631', imageSrc: webImageNamed('Fabrics/SewingThread/C9631.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C9666', name: 'C9666', imageSrc: webImageNamed('Fabrics/SewingThread/C9666.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C9770', name: 'C9770', imageSrc: webImageNamed('Fabrics/SewingThread/C9770.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C9913', name: 'C9913', imageSrc: webImageNamed('Fabrics/SewingThread/C9913.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'C9949', name: 'C9949', imageSrc: webImageNamed('Fabrics/SewingThread/C9949.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'WCG001', name: 'WCG001', imageSrc: webImageNamed('Fabrics/SewingThread/WCG001.jpg'), price: null, company: '' },
            { optType: "Monogram Thread Color", key: 'WCG007', name: 'WCG007', imageSrc: webImageNamed('Fabrics/SewingThread/WCG007.jpg'), price: null, company: '' },
        ]
        monogramThreadColor_Step = () => {
            return {
                name: 'Select Monogram Thread Color',
                key: this.STEP_KEYS.monogramThreadColor,
                opts: this.monogramThreadColor_Opts,
                selectedOptKey: null,
                isPhotoBox: true,
                photoRatio: '1by1',
                skipable: false,
                paddingLess: false,
                boldText: false
            }
        }


         // SUIT BUTTONS
    buttons_Opts = [
        { optType: "Buttons", key: 'KB220', name: 'KB220', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB220.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB221', name: 'KB221', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB221.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB222', name: 'KB222', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB222.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB223', name: 'KB223', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB223.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB225', name: 'KB225', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB225.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB226', name: 'KB226', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB226.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB227', name: 'KB227', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB227.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB228', name: 'KB228', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB228.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB229', name: 'KB229', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB229.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB230', name: 'KB230', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB230.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB231', name: 'KB231', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB231.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB232', name: 'KB232', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB232.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB233', name: 'KB233', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB233.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB235', name: 'KB235', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB235.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB236', name: 'KB236', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB236.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB237', name: 'KB237', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB237.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB238', name: 'KB238', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB238.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB239', name: 'KB239', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB239.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB240', name: 'KB240', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB240.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB241', name: 'KB241', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB241.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB242', name: 'KB242', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB242.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB243', name: 'KB243', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB243.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB245', name: 'KB245', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB245.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KJ006', name: 'KJ006', imageSrc: webImageNamed('Fabrics/ShirtButtons/KJ006.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KJ007', name: 'KJ007', imageSrc: webImageNamed('Fabrics/ShirtButtons/KJ007.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KJ013', name: 'KJ013', imageSrc: webImageNamed('Fabrics/ShirtButtons/KJ013.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KJ016', name: 'KJ016', imageSrc: webImageNamed('Fabrics/ShirtButtons/KJ016.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KJ017', name: 'KJ017', imageSrc: webImageNamed('Fabrics/ShirtButtons/KJ017.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KJ018', name: 'KJ018', imageSrc: webImageNamed('Fabrics/ShirtButtons/KJ018.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KJ020', name: 'KJ020', imageSrc: webImageNamed('Fabrics/ShirtButtons/KJ020.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KJ021', name: 'KJ021', imageSrc: webImageNamed('Fabrics/ShirtButtons/KJ021.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS172', name: 'KS172', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS172.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS178', name: 'KS178', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS178.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS180', name: 'KS180', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS180.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS182', name: 'KS182', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS182.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS184', name: 'KS184', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS184.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS187', name: 'KS187', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS187.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS189', name: 'KS189', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS189.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS191', name: 'KS191', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS191.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS240', name: 'KS240', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS240.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS243', name: 'KS243', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS243.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS244', name: 'KS244', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS244.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS298', name: 'KS298', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS298.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS299', name: 'KS299', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS299.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS300', name: 'KS300', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS300.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS303', name: 'KS303', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS303.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS304', name: 'KS304', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS304.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS308', name: 'KS308', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS308.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS309', name: 'KS309', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS309.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS310', name: 'KS310', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS310.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS311', name: 'KS311', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS311.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS318', name: 'KS318', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS318.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS319', name: 'KS319', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS319.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS320', name: 'KS320', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS320.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS322', name: 'KS322', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS322.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS361', name: 'KS361', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS361.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS363', name: 'KS363', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS363.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS364', name: 'KS364', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS364.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS389', name: 'KS389', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS389.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS392', name: 'KS392', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS392.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS397', name: 'KS397', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS397.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS398', name: 'KS398', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS398.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS410', name: 'KS410', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS410.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS411', name: 'KS411', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS411.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS414', name: 'KS414', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS414.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS417', name: 'KS417', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS417.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS421', name: 'KS421', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS421.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS422', name: 'KS422', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS422.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS503', name: 'KS503', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS503.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS505', name: 'KS505', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS505.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS506', name: 'KS506', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS506.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS507', name: 'KS507', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS507.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS508', name: 'KS508', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS508.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS509', name: 'KS509', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS509.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS510', name: 'KS510', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS510.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS511', name: 'KS511', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS511.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS512', name: 'KS512', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS512.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS513', name: 'KS513', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS513.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS516', name: 'KS516', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS516.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS556', name: 'KS556', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS556.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS557', name: 'KS557', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS557.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS558', name: 'KS558', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS558.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS559', name: 'KS559', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS559.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS566', name: 'KS566', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS566.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS567', name: 'KS567', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS567.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS568', name: 'KS568', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS568.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KS3569', name: 'KS3569', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS3569.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KTC003', name: 'KTC003', imageSrc: webImageNamed('Fabrics/ShirtButtons/KTC003.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KTC005', name: 'KTC005', imageSrc: webImageNamed('Fabrics/ShirtButtons/KTC005.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KTC008', name: 'KTC008', imageSrc: webImageNamed('Fabrics/ShirtButtons/KTC008.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KTC0009', name: 'KTC0009', imageSrc: webImageNamed('Fabrics/ShirtButtons/KTC0009.jpg'), price: null, company: '' },
    ]
    buttons_Step = () => {
        // Change images here with respect to button choices
        return {
            name: 'Choose Buttons',
            key: this.STEP_KEYS.buttons,
            opts: this.buttons_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }
  


    

    // TONE, ["SHIRT"], []
    // TONE
    toneOpts = [
        { optType: "TONE", styleSelectionId: "", key: this.TONE_KEYS.blues, name: 'Blues', imageSrc: webImageNamed('blues') },
        { optType: "TONE", styleSelectionId: "", key: this.TONE_KEYS.blacks, name: 'Blacks', imageSrc: webImageNamed('blacks') },
        { optType: "TONE", styleSelectionId: "", key: this.TONE_KEYS.greys, name: 'Greys', imageSrc: webImageNamed('greys') },
        { optType: "TONE", styleSelectionId: "", key: this.TONE_KEYS.playful, name: 'Playful', imageSrc: webImageNamed('playful') },
        // { key: 'other', name: 'Other', imageSrc: null },
    ]
    toneStep = () => {
        // Change images here with respect to button choices
        return {
            name: 'Choose Fabric Collection',
            key: this.STEP_KEYS.tone,
            opts: this.toneOpts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }

    // FABRIC, ["SHIRT"], []
    fabric_greyOpts = [
        { optType: "Fabric", key: '1', name: '1', imageSrc: webImageNamed('Fabrics/ShirtFabrics/1.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '2', name: '2', imageSrc: webImageNamed('Fabrics/ShirtFabrics/2.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '3', name: '3', imageSrc: webImageNamed('Fabrics/ShirtFabrics/3.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '4', name: '4', imageSrc: webImageNamed('Fabrics/ShirtFabrics/4.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '5', name: '5', imageSrc: webImageNamed('Fabrics/ShirtFabrics/5.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '6', name: '6', imageSrc: webImageNamed('Fabrics/ShirtFabrics/6.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '7', name: '7', imageSrc: webImageNamed('Fabrics/ShirtFabrics/7.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '8', name: '8', imageSrc: webImageNamed('Fabrics/ShirtFabrics/8.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '9', name: '9', imageSrc: webImageNamed('Fabrics/ShirtFabrics/9.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '10', name: '10', imageSrc: webImageNamed('Fabrics/ShirtFabrics/10.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '11', name: '11', imageSrc: webImageNamed('Fabrics/ShirtFabrics/11.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '12', name: '12', imageSrc: webImageNamed('Fabrics/ShirtFabrics/12.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '13', name: '13', imageSrc: webImageNamed('Fabrics/ShirtFabrics/13.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '14', name: '14', imageSrc: webImageNamed('Fabrics/ShirtFabrics/14.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '15', name: '15', imageSrc: webImageNamed('Fabrics/ShirtFabrics/15.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '16', name: '16', imageSrc: webImageNamed('Fabrics/ShirtFabrics/16.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '17', name: '17', imageSrc: webImageNamed('Fabrics/ShirtFabrics/17.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '18', name: '18', imageSrc: webImageNamed('Fabrics/ShirtFabrics/18.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '19', name: '19', imageSrc: webImageNamed('Fabrics/ShirtFabrics/19.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '20', name: '20', imageSrc: webImageNamed('Fabrics/ShirtFabrics/20.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '21', name: '21', imageSrc: webImageNamed('Fabrics/ShirtFabrics/21.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '22', name: '22', imageSrc: webImageNamed('Fabrics/ShirtFabrics/22.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '23', name: '23', imageSrc: webImageNamed('Fabrics/ShirtFabrics/23.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '24', name: '24', imageSrc: webImageNamed('Fabrics/ShirtFabrics/24.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '25', name: '25', imageSrc: webImageNamed('Fabrics/ShirtFabrics/25.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '26', name: '26', imageSrc: webImageNamed('Fabrics/ShirtFabrics/26.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '27', name: '27', imageSrc: webImageNamed('Fabrics/ShirtFabrics/27.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '28', name: '28', imageSrc: webImageNamed('Fabrics/ShirtFabrics/28.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '29', name: '29', imageSrc: webImageNamed('Fabrics/ShirtFabrics/29.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '30', name: '30', imageSrc: webImageNamed('Fabrics/ShirtFabrics/30.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '31', name: '31', imageSrc: webImageNamed('Fabrics/ShirtFabrics/31.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '32', name: '32', imageSrc: webImageNamed('Fabrics/ShirtFabrics/32.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '33', name: '33', imageSrc: webImageNamed('Fabrics/ShirtFabrics/33.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '34', name: '34', imageSrc: webImageNamed('Fabrics/ShirtFabrics/34.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '35', name: '35', imageSrc: webImageNamed('Fabrics/ShirtFabrics/35.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '36', name: '36', imageSrc: webImageNamed('Fabrics/ShirtFabrics/36.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '37', name: '37', imageSrc: webImageNamed('Fabrics/ShirtFabrics/37.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '38', name: '38', imageSrc: webImageNamed('Fabrics/ShirtFabrics/38.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '39', name: '39', imageSrc: webImageNamed('Fabrics/ShirtFabrics/39.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '40', name: '40', imageSrc: webImageNamed('Fabrics/ShirtFabrics/40.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '41', name: '41', imageSrc: webImageNamed('Fabrics/ShirtFabrics/41.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '42', name: '42', imageSrc: webImageNamed('Fabrics/ShirtFabrics/42.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '43', name: '43', imageSrc: webImageNamed('Fabrics/ShirtFabrics/43.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '44', name: '44', imageSrc: webImageNamed('Fabrics/ShirtFabrics/44.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '45', name: '45', imageSrc: webImageNamed('Fabrics/ShirtFabrics/45.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '46', name: '46', imageSrc: webImageNamed('Fabrics/ShirtFabrics/46.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '47', name: '47', imageSrc: webImageNamed('Fabrics/ShirtFabrics/47.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '48', name: '48', imageSrc: webImageNamed('Fabrics/ShirtFabrics/48.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '49', name: '49', imageSrc: webImageNamed('Fabrics/ShirtFabrics/49.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '50', name: '50', imageSrc: webImageNamed('Fabrics/ShirtFabrics/50.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '51', name: '51', imageSrc: webImageNamed('Fabrics/ShirtFabrics/51.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '52', name: '52', imageSrc: webImageNamed('Fabrics/ShirtFabrics/52.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '53', name: '53', imageSrc: webImageNamed('Fabrics/ShirtFabrics/53.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '54', name: '54', imageSrc: webImageNamed('Fabrics/ShirtFabrics/54.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '55', name: '55', imageSrc: webImageNamed('Fabrics/ShirtFabrics/55.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '56', name: '56', imageSrc: webImageNamed('Fabrics/ShirtFabrics/56.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '57', name: '57', imageSrc: webImageNamed('Fabrics/ShirtFabrics/57.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '58', name: '58', imageSrc: webImageNamed('Fabrics/ShirtFabrics/58.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '59', name: '59', imageSrc: webImageNamed('Fabrics/ShirtFabrics/59.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '60', name: '60', imageSrc: webImageNamed('Fabrics/ShirtFabrics/60.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '61', name: '61', imageSrc: webImageNamed('Fabrics/ShirtFabrics/61.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '62', name: '62', imageSrc: webImageNamed('Fabrics/ShirtFabrics/62.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '63', name: '63', imageSrc: webImageNamed('Fabrics/ShirtFabrics/63.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '64', name: '64', imageSrc: webImageNamed('Fabrics/ShirtFabrics/64.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '65', name: '65', imageSrc: webImageNamed('Fabrics/ShirtFabrics/65.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '66', name: '66', imageSrc: webImageNamed('Fabrics/ShirtFabrics/66.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '67', name: '67', imageSrc: webImageNamed('Fabrics/ShirtFabrics/67.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '68', name: '68', imageSrc: webImageNamed('Fabrics/ShirtFabrics/68.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '69', name: '69', imageSrc: webImageNamed('Fabrics/ShirtFabrics/69.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '70', name: '70', imageSrc: webImageNamed('Fabrics/ShirtFabrics/70.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '71', name: '71', imageSrc: webImageNamed('Fabrics/ShirtFabrics/71.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '72', name: '72', imageSrc: webImageNamed('Fabrics/ShirtFabrics/72.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '73', name: '73', imageSrc: webImageNamed('Fabrics/ShirtFabrics/73.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '74', name: '74', imageSrc: webImageNamed('Fabrics/ShirtFabrics/74.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '75', name: '75', imageSrc: webImageNamed('Fabrics/ShirtFabrics/75.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '76', name: '76', imageSrc: webImageNamed('Fabrics/ShirtFabrics/76.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '77', name: '77', imageSrc: webImageNamed('Fabrics/ShirtFabrics/77.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '78', name: '78', imageSrc: webImageNamed('Fabrics/ShirtFabrics/78.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '79', name: '79', imageSrc: webImageNamed('Fabrics/ShirtFabrics/79.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '80', name: '80', imageSrc: webImageNamed('Fabrics/ShirtFabrics/80.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '81', name: '81', imageSrc: webImageNamed('Fabrics/ShirtFabrics/81.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '82', name: '82', imageSrc: webImageNamed('Fabrics/ShirtFabrics/82.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '83', name: '83', imageSrc: webImageNamed('Fabrics/ShirtFabrics/83.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '84', name: '84', imageSrc: webImageNamed('Fabrics/ShirtFabrics/84.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '85', name: '85', imageSrc: webImageNamed('Fabrics/ShirtFabrics/85.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '86', name: '86', imageSrc: webImageNamed('Fabrics/ShirtFabrics/86.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '87', name: '87', imageSrc: webImageNamed('Fabrics/ShirtFabrics/87.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '88', name: '88', imageSrc: webImageNamed('Fabrics/ShirtFabrics/88.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '89', name: '89', imageSrc: webImageNamed('Fabrics/ShirtFabrics/89.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '90', name: '90', imageSrc: webImageNamed('Fabrics/ShirtFabrics/90.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '91', name: '91', imageSrc: webImageNamed('Fabrics/ShirtFabrics/91.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '92', name: '92', imageSrc: webImageNamed('Fabrics/ShirtFabrics/92.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '93', name: '93', imageSrc: webImageNamed('Fabrics/ShirtFabrics/93.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '94', name: '94', imageSrc: webImageNamed('Fabrics/ShirtFabrics/94.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '95', name: '95', imageSrc: webImageNamed('Fabrics/ShirtFabrics/95.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '96', name: '96', imageSrc: webImageNamed('Fabrics/ShirtFabrics/96.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '97', name: '97', imageSrc: webImageNamed('Fabrics/ShirtFabrics/97.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '98', name: '98', imageSrc: webImageNamed('Fabrics/ShirtFabrics/98.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '99', name: '99', imageSrc: webImageNamed('Fabrics/ShirtFabrics/99.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '100', name: '100', imageSrc: webImageNamed('Fabrics/ShirtFabrics/100.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '101', name: '101', imageSrc: webImageNamed('Fabrics/ShirtFabrics/101.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '102', name: '102', imageSrc: webImageNamed('Fabrics/ShirtFabrics/102.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '103', name: '103', imageSrc: webImageNamed('Fabrics/ShirtFabrics/103.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '104', name: '104', imageSrc: webImageNamed('Fabrics/ShirtFabrics/104.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '105', name: '105', imageSrc: webImageNamed('Fabrics/ShirtFabrics/105.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '106', name: '106', imageSrc: webImageNamed('Fabrics/ShirtFabrics/106.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '107', name: '107', imageSrc: webImageNamed('Fabrics/ShirtFabrics/107.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '108', name: '108', imageSrc: webImageNamed('Fabrics/ShirtFabrics/108.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '109', name: '109', imageSrc: webImageNamed('Fabrics/ShirtFabrics/109.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '110', name: '110', imageSrc: webImageNamed('Fabrics/ShirtFabrics/110.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '111', name: '111', imageSrc: webImageNamed('Fabrics/ShirtFabrics/111.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '112', name: '112', imageSrc: webImageNamed('Fabrics/ShirtFabrics/112.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '113', name: '113', imageSrc: webImageNamed('Fabrics/ShirtFabrics/113.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '114', name: '114', imageSrc: webImageNamed('Fabrics/ShirtFabrics/114.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '115', name: '115', imageSrc: webImageNamed('Fabrics/ShirtFabrics/115.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '116', name: '116', imageSrc: webImageNamed('Fabrics/ShirtFabrics/116.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '117', name: '117', imageSrc: webImageNamed('Fabrics/ShirtFabrics/117.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '118', name: '118', imageSrc: webImageNamed('Fabrics/ShirtFabrics/118.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '119', name: '119', imageSrc: webImageNamed('Fabrics/ShirtFabrics/119.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '120', name: '120', imageSrc: webImageNamed('Fabrics/ShirtFabrics/120.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '121', name: '121', imageSrc: webImageNamed('Fabrics/ShirtFabrics/121.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '122', name: '122', imageSrc: webImageNamed('Fabrics/ShirtFabrics/122.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '123', name: '123', imageSrc: webImageNamed('Fabrics/ShirtFabrics/123.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '124', name: '124', imageSrc: webImageNamed('Fabrics/ShirtFabrics/124.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '125', name: '125', imageSrc: webImageNamed('Fabrics/ShirtFabrics/125.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '126', name: '126', imageSrc: webImageNamed('Fabrics/ShirtFabrics/126.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '127', name: '127', imageSrc: webImageNamed('Fabrics/ShirtFabrics/127.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '128', name: '128', imageSrc: webImageNamed('Fabrics/ShirtFabrics/128.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '129', name: '129', imageSrc: webImageNamed('Fabrics/ShirtFabrics/129.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '130', name: '130', imageSrc: webImageNamed('Fabrics/ShirtFabrics/130.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '131', name: '131', imageSrc: webImageNamed('Fabrics/ShirtFabrics/131.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '132', name: '132', imageSrc: webImageNamed('Fabrics/ShirtFabrics/132.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '133', name: '133', imageSrc: webImageNamed('Fabrics/ShirtFabrics/133.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '134', name: '134', imageSrc: webImageNamed('Fabrics/ShirtFabrics/134.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '135', name: '135', imageSrc: webImageNamed('Fabrics/ShirtFabrics/135.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '136', name: '136', imageSrc: webImageNamed('Fabrics/ShirtFabrics/136.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '137', name: '137', imageSrc: webImageNamed('Fabrics/ShirtFabrics/137.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '138', name: '138', imageSrc: webImageNamed('Fabrics/ShirtFabrics/138.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '139', name: '139', imageSrc: webImageNamed('Fabrics/ShirtFabrics/139.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '140', name: '140', imageSrc: webImageNamed('Fabrics/ShirtFabrics/140.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '141', name: '141', imageSrc: webImageNamed('Fabrics/ShirtFabrics/141.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '142', name: '142', imageSrc: webImageNamed('Fabrics/ShirtFabrics/142.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '143', name: '143', imageSrc: webImageNamed('Fabrics/ShirtFabrics/143.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '144', name: '144', imageSrc: webImageNamed('Fabrics/ShirtFabrics/144.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '145', name: '145', imageSrc: webImageNamed('Fabrics/ShirtFabrics/145.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '146', name: '146', imageSrc: webImageNamed('Fabrics/ShirtFabrics/146.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '147', name: '147', imageSrc: webImageNamed('Fabrics/ShirtFabrics/147.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '148', name: '148', imageSrc: webImageNamed('Fabrics/ShirtFabrics/148.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '149', name: '149', imageSrc: webImageNamed('Fabrics/ShirtFabrics/149.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '150', name: '150', imageSrc: webImageNamed('Fabrics/ShirtFabrics/150.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '151', name: '151', imageSrc: webImageNamed('Fabrics/ShirtFabrics/151.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '152', name: '152', imageSrc: webImageNamed('Fabrics/ShirtFabrics/152.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '153', name: '153', imageSrc: webImageNamed('Fabrics/ShirtFabrics/153.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '154', name: '154', imageSrc: webImageNamed('Fabrics/ShirtFabrics/154.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '155', name: '155', imageSrc: webImageNamed('Fabrics/ShirtFabrics/155.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '156', name: '156', imageSrc: webImageNamed('Fabrics/ShirtFabrics/156.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '157', name: '157', imageSrc: webImageNamed('Fabrics/ShirtFabrics/157.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '158', name: '158', imageSrc: webImageNamed('Fabrics/ShirtFabrics/158.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '159', name: '159', imageSrc: webImageNamed('Fabrics/ShirtFabrics/159.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '160', name: '160', imageSrc: webImageNamed('Fabrics/ShirtFabrics/160.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '161', name: '161', imageSrc: webImageNamed('Fabrics/ShirtFabrics/161.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '162', name: '162', imageSrc: webImageNamed('Fabrics/ShirtFabrics/162.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '163', name: '163', imageSrc: webImageNamed('Fabrics/ShirtFabrics/163.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '164', name: '164', imageSrc: webImageNamed('Fabrics/ShirtFabrics/164.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '165', name: '165', imageSrc: webImageNamed('Fabrics/ShirtFabrics/165.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '166', name: '166', imageSrc: webImageNamed('Fabrics/ShirtFabrics/166.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '167', name: '167', imageSrc: webImageNamed('Fabrics/ShirtFabrics/167.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '168', name: '168', imageSrc: webImageNamed('Fabrics/ShirtFabrics/168.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '169', name: '169', imageSrc: webImageNamed('Fabrics/ShirtFabrics/169.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '170', name: '170', imageSrc: webImageNamed('Fabrics/ShirtFabrics/170.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '171', name: '171', imageSrc: webImageNamed('Fabrics/ShirtFabrics/171.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '172', name: '172', imageSrc: webImageNamed('Fabrics/ShirtFabrics/172.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '173', name: '173', imageSrc: webImageNamed('Fabrics/ShirtFabrics/173.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '174', name: '174', imageSrc: webImageNamed('Fabrics/ShirtFabrics/174.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '175', name: '175', imageSrc: webImageNamed('Fabrics/ShirtFabrics/175.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '176', name: '176', imageSrc: webImageNamed('Fabrics/ShirtFabrics/176.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '177', name: '177', imageSrc: webImageNamed('Fabrics/ShirtFabrics/177.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '178', name: '178', imageSrc: webImageNamed('Fabrics/ShirtFabrics/178.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '179', name: '179', imageSrc: webImageNamed('Fabrics/ShirtFabrics/179.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '180', name: '180', imageSrc: webImageNamed('Fabrics/ShirtFabrics/180.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '181', name: '181', imageSrc: webImageNamed('Fabrics/ShirtFabrics/181.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '182', name: '182', imageSrc: webImageNamed('Fabrics/ShirtFabrics/182.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '183', name: '183', imageSrc: webImageNamed('Fabrics/ShirtFabrics/183.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '184', name: '184', imageSrc: webImageNamed('Fabrics/ShirtFabrics/184.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '185', name: '185', imageSrc: webImageNamed('Fabrics/ShirtFabrics/185.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '186', name: '186', imageSrc: webImageNamed('Fabrics/ShirtFabrics/186.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '187', name: '187', imageSrc: webImageNamed('Fabrics/ShirtFabrics/187.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '188', name: '188', imageSrc: webImageNamed('Fabrics/ShirtFabrics/188.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '189', name: '189', imageSrc: webImageNamed('Fabrics/ShirtFabrics/189.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '190', name: '190', imageSrc: webImageNamed('Fabrics/ShirtFabrics/190.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '191', name: '191', imageSrc: webImageNamed('Fabrics/ShirtFabrics/191.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '192', name: '192', imageSrc: webImageNamed('Fabrics/ShirtFabrics/192.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '193', name: '193', imageSrc: webImageNamed('Fabrics/ShirtFabrics/193.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '194', name: '194', imageSrc: webImageNamed('Fabrics/ShirtFabrics/194.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '195', name: '195', imageSrc: webImageNamed('Fabrics/ShirtFabrics/195.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '196', name: '196', imageSrc: webImageNamed('Fabrics/ShirtFabrics/196.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '197', name: '197', imageSrc: webImageNamed('Fabrics/ShirtFabrics/197.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '198', name: '198', imageSrc: webImageNamed('Fabrics/ShirtFabrics/198.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '199', name: '199', imageSrc: webImageNamed('Fabrics/ShirtFabrics/199.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '200', name: '200', imageSrc: webImageNamed('Fabrics/ShirtFabrics/200.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '201', name: '201', imageSrc: webImageNamed('Fabrics/ShirtFabrics/201.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '202', name: '202', imageSrc: webImageNamed('Fabrics/ShirtFabrics/202.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '203', name: '203', imageSrc: webImageNamed('Fabrics/ShirtFabrics/203.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '204', name: '204', imageSrc: webImageNamed('Fabrics/ShirtFabrics/204.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '205', name: '205', imageSrc: webImageNamed('Fabrics/ShirtFabrics/205.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '206', name: '206', imageSrc: webImageNamed('Fabrics/ShirtFabrics/206.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '207', name: '207', imageSrc: webImageNamed('Fabrics/ShirtFabrics/207.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '208', name: '208', imageSrc: webImageNamed('Fabrics/ShirtFabrics/208.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '209', name: '209', imageSrc: webImageNamed('Fabrics/ShirtFabrics/209.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '210', name: '210', imageSrc: webImageNamed('Fabrics/ShirtFabrics/210.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '211', name: '211', imageSrc: webImageNamed('Fabrics/ShirtFabrics/211.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '212', name: '212', imageSrc: webImageNamed('Fabrics/ShirtFabrics/212.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '213', name: '213', imageSrc: webImageNamed('Fabrics/ShirtFabrics/213.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '214', name: '214', imageSrc: webImageNamed('Fabrics/ShirtFabrics/214.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '215', name: '215', imageSrc: webImageNamed('Fabrics/ShirtFabrics/215.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '216', name: '216', imageSrc: webImageNamed('Fabrics/ShirtFabrics/216.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '217', name: '217', imageSrc: webImageNamed('Fabrics/ShirtFabrics/217.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '218', name: '218', imageSrc: webImageNamed('Fabrics/ShirtFabrics/218.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '219', name: '219', imageSrc: webImageNamed('Fabrics/ShirtFabrics/219.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '220', name: '220', imageSrc: webImageNamed('Fabrics/ShirtFabrics/220.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '221', name: '221', imageSrc: webImageNamed('Fabrics/ShirtFabrics/221.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '222', name: '222', imageSrc: webImageNamed('Fabrics/ShirtFabrics/222.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '223', name: '223', imageSrc: webImageNamed('Fabrics/ShirtFabrics/223.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '224', name: '224', imageSrc: webImageNamed('Fabrics/ShirtFabrics/224.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '225', name: '225', imageSrc: webImageNamed('Fabrics/ShirtFabrics/225.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '226', name: '226', imageSrc: webImageNamed('Fabrics/ShirtFabrics/226.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '227', name: '227', imageSrc: webImageNamed('Fabrics/ShirtFabrics/227.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '228', name: '228', imageSrc: webImageNamed('Fabrics/ShirtFabrics/228.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '229', name: '229', imageSrc: webImageNamed('Fabrics/ShirtFabrics/229.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '230', name: '230', imageSrc: webImageNamed('Fabrics/ShirtFabrics/230.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '231', name: '231', imageSrc: webImageNamed('Fabrics/ShirtFabrics/231.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '232', name: '232', imageSrc: webImageNamed('Fabrics/ShirtFabrics/232.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '233', name: '233', imageSrc: webImageNamed('Fabrics/ShirtFabrics/233.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '234', name: '234', imageSrc: webImageNamed('Fabrics/ShirtFabrics/234.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '235', name: '235', imageSrc: webImageNamed('Fabrics/ShirtFabrics/235.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '236', name: '236', imageSrc: webImageNamed('Fabrics/ShirtFabrics/236.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '237', name: '237', imageSrc: webImageNamed('Fabrics/ShirtFabrics/237.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '238', name: '238', imageSrc: webImageNamed('Fabrics/ShirtFabrics/238.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '239', name: '239', imageSrc: webImageNamed('Fabrics/ShirtFabrics/239.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '240', name: '240', imageSrc: webImageNamed('Fabrics/ShirtFabrics/240.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '241', name: '241', imageSrc: webImageNamed('Fabrics/ShirtFabrics/241.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '242', name: '242', imageSrc: webImageNamed('Fabrics/ShirtFabrics/242.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '243', name: '243', imageSrc: webImageNamed('Fabrics/ShirtFabrics/243.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '244', name: '244', imageSrc: webImageNamed('Fabrics/ShirtFabrics/244.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '245', name: '245', imageSrc: webImageNamed('Fabrics/ShirtFabrics/245.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '246', name: '246', imageSrc: webImageNamed('Fabrics/ShirtFabrics/246.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '247', name: '247', imageSrc: webImageNamed('Fabrics/ShirtFabrics/247.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '248', name: '248', imageSrc: webImageNamed('Fabrics/ShirtFabrics/248.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '249', name: '249', imageSrc: webImageNamed('Fabrics/ShirtFabrics/249.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '250', name: '250', imageSrc: webImageNamed('Fabrics/ShirtFabrics/250.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '251', name: '251', imageSrc: webImageNamed('Fabrics/ShirtFabrics/251.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '252', name: '252', imageSrc: webImageNamed('Fabrics/ShirtFabrics/252.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '253', name: '253', imageSrc: webImageNamed('Fabrics/ShirtFabrics/253.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '254', name: '254', imageSrc: webImageNamed('Fabrics/ShirtFabrics/254.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '255', name: '255', imageSrc: webImageNamed('Fabrics/ShirtFabrics/255.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '256', name: '256', imageSrc: webImageNamed('Fabrics/ShirtFabrics/256.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '257', name: '257', imageSrc: webImageNamed('Fabrics/ShirtFabrics/257.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '258', name: '258', imageSrc: webImageNamed('Fabrics/ShirtFabrics/258.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '259', name: '259', imageSrc: webImageNamed('Fabrics/ShirtFabrics/259.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '260', name: '260', imageSrc: webImageNamed('Fabrics/ShirtFabrics/260.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '261', name: '261', imageSrc: webImageNamed('Fabrics/ShirtFabrics/261.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '262', name: '262', imageSrc: webImageNamed('Fabrics/ShirtFabrics/262.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '263', name: '263', imageSrc: webImageNamed('Fabrics/ShirtFabrics/263.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '264', name: '264', imageSrc: webImageNamed('Fabrics/ShirtFabrics/264.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '265', name: '265', imageSrc: webImageNamed('Fabrics/ShirtFabrics/265.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '266', name: '266', imageSrc: webImageNamed('Fabrics/ShirtFabrics/266.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '267', name: '267', imageSrc: webImageNamed('Fabrics/ShirtFabrics/267.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '268', name: '268', imageSrc: webImageNamed('Fabrics/ShirtFabrics/268.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '269', name: '269', imageSrc: webImageNamed('Fabrics/ShirtFabrics/269.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '270', name: '270', imageSrc: webImageNamed('Fabrics/ShirtFabrics/270.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '271', name: '271', imageSrc: webImageNamed('Fabrics/ShirtFabrics/271.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '272', name: '272', imageSrc: webImageNamed('Fabrics/ShirtFabrics/272.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '273', name: '273', imageSrc: webImageNamed('Fabrics/ShirtFabrics/273.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '274', name: '274', imageSrc: webImageNamed('Fabrics/ShirtFabrics/274.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '275', name: '275', imageSrc: webImageNamed('Fabrics/ShirtFabrics/275.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '276', name: '276', imageSrc: webImageNamed('Fabrics/ShirtFabrics/276.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '277', name: '277', imageSrc: webImageNamed('Fabrics/ShirtFabrics/277.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '278', name: '278', imageSrc: webImageNamed('Fabrics/ShirtFabrics/278.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '279', name: '279', imageSrc: webImageNamed('Fabrics/ShirtFabrics/279.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '280', name: '280', imageSrc: webImageNamed('Fabrics/ShirtFabrics/280.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '281', name: '281', imageSrc: webImageNamed('Fabrics/ShirtFabrics/281.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '282', name: '282', imageSrc: webImageNamed('Fabrics/ShirtFabrics/282.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '283', name: '283', imageSrc: webImageNamed('Fabrics/ShirtFabrics/283.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '284', name: '284', imageSrc: webImageNamed('Fabrics/ShirtFabrics/284.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '285', name: '285', imageSrc: webImageNamed('Fabrics/ShirtFabrics/285.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '286', name: '286', imageSrc: webImageNamed('Fabrics/ShirtFabrics/286.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '287', name: '287', imageSrc: webImageNamed('Fabrics/ShirtFabrics/287.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '288', name: '288', imageSrc: webImageNamed('Fabrics/ShirtFabrics/288.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '289', name: '289', imageSrc: webImageNamed('Fabrics/ShirtFabrics/289.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '290', name: '290', imageSrc: webImageNamed('Fabrics/ShirtFabrics/290.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '291', name: '291', imageSrc: webImageNamed('Fabrics/ShirtFabrics/291.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '292', name: '292', imageSrc: webImageNamed('Fabrics/ShirtFabrics/292.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '293', name: '293', imageSrc: webImageNamed('Fabrics/ShirtFabrics/293.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '294', name: '294', imageSrc: webImageNamed('Fabrics/ShirtFabrics/294.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '295', name: '295', imageSrc: webImageNamed('Fabrics/ShirtFabrics/295.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '296', name: '296', imageSrc: webImageNamed('Fabrics/ShirtFabrics/296.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '297', name: '297', imageSrc: webImageNamed('Fabrics/ShirtFabrics/297.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '298', name: '298', imageSrc: webImageNamed('Fabrics/ShirtFabrics/298.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '299', name: '299', imageSrc: webImageNamed('Fabrics/ShirtFabrics/299.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '300', name: '300', imageSrc: webImageNamed('Fabrics/ShirtFabrics/300.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '301', name: '301', imageSrc: webImageNamed('Fabrics/ShirtFabrics/301.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '302', name: '302', imageSrc: webImageNamed('Fabrics/ShirtFabrics/302.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '303', name: '303', imageSrc: webImageNamed('Fabrics/ShirtFabrics/303.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '304', name: '304', imageSrc: webImageNamed('Fabrics/ShirtFabrics/304.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '305', name: '305', imageSrc: webImageNamed('Fabrics/ShirtFabrics/305.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '306', name: '306', imageSrc: webImageNamed('Fabrics/ShirtFabrics/306.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '307', name: '307', imageSrc: webImageNamed('Fabrics/ShirtFabrics/307.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '308', name: '308', imageSrc: webImageNamed('Fabrics/ShirtFabrics/308.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '309', name: '309', imageSrc: webImageNamed('Fabrics/ShirtFabrics/309.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '310', name: '310', imageSrc: webImageNamed('Fabrics/ShirtFabrics/310.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '311', name: '311', imageSrc: webImageNamed('Fabrics/ShirtFabrics/311.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '312', name: '312', imageSrc: webImageNamed('Fabrics/ShirtFabrics/312.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '313', name: '313', imageSrc: webImageNamed('Fabrics/ShirtFabrics/313.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '314', name: '314', imageSrc: webImageNamed('Fabrics/ShirtFabrics/314.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '315', name: '315', imageSrc: webImageNamed('Fabrics/ShirtFabrics/315.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '316', name: '316', imageSrc: webImageNamed('Fabrics/ShirtFabrics/316.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '317', name: '317', imageSrc: webImageNamed('Fabrics/ShirtFabrics/317.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '318', name: '318', imageSrc: webImageNamed('Fabrics/ShirtFabrics/318.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '319', name: '319', imageSrc: webImageNamed('Fabrics/ShirtFabrics/319.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '320', name: '320', imageSrc: webImageNamed('Fabrics/ShirtFabrics/320.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '321', name: '321', imageSrc: webImageNamed('Fabrics/ShirtFabrics/321.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '322', name: '322', imageSrc: webImageNamed('Fabrics/ShirtFabrics/322.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '323', name: '323', imageSrc: webImageNamed('Fabrics/ShirtFabrics/323.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '324', name: '324', imageSrc: webImageNamed('Fabrics/ShirtFabrics/324.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '325', name: '325', imageSrc: webImageNamed('Fabrics/ShirtFabrics/325.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '326', name: '326', imageSrc: webImageNamed('Fabrics/ShirtFabrics/326.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '327', name: '327', imageSrc: webImageNamed('Fabrics/ShirtFabrics/327.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '328', name: '328', imageSrc: webImageNamed('Fabrics/ShirtFabrics/328.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '329', name: '329', imageSrc: webImageNamed('Fabrics/ShirtFabrics/329.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '330', name: '330', imageSrc: webImageNamed('Fabrics/ShirtFabrics/330.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '331', name: '331', imageSrc: webImageNamed('Fabrics/ShirtFabrics/331.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '332', name: '332', imageSrc: webImageNamed('Fabrics/ShirtFabrics/332.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '333', name: '333', imageSrc: webImageNamed('Fabrics/ShirtFabrics/333.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '334', name: '334', imageSrc: webImageNamed('Fabrics/ShirtFabrics/334.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '335', name: '335', imageSrc: webImageNamed('Fabrics/ShirtFabrics/335.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '336', name: '336', imageSrc: webImageNamed('Fabrics/ShirtFabrics/336.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '337', name: '337', imageSrc: webImageNamed('Fabrics/ShirtFabrics/337.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '338', name: '338', imageSrc: webImageNamed('Fabrics/ShirtFabrics/338.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '339', name: '339', imageSrc: webImageNamed('Fabrics/ShirtFabrics/339.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '340', name: '340', imageSrc: webImageNamed('Fabrics/ShirtFabrics/340.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '341', name: '341', imageSrc: webImageNamed('Fabrics/ShirtFabrics/341.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '342', name: '342', imageSrc: webImageNamed('Fabrics/ShirtFabrics/342.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '343', name: '343', imageSrc: webImageNamed('Fabrics/ShirtFabrics/343.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '344', name: '344', imageSrc: webImageNamed('Fabrics/ShirtFabrics/344.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '345', name: '345', imageSrc: webImageNamed('Fabrics/ShirtFabrics/345.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '346', name: '346', imageSrc: webImageNamed('Fabrics/ShirtFabrics/346.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '347', name: '347', imageSrc: webImageNamed('Fabrics/ShirtFabrics/347.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '348', name: '348', imageSrc: webImageNamed('Fabrics/ShirtFabrics/348.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '349', name: '349', imageSrc: webImageNamed('Fabrics/ShirtFabrics/349.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '350', name: '350', imageSrc: webImageNamed('Fabrics/ShirtFabrics/350.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '351', name: '351', imageSrc: webImageNamed('Fabrics/ShirtFabrics/351.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '352', name: '352', imageSrc: webImageNamed('Fabrics/ShirtFabrics/352.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '353', name: '353', imageSrc: webImageNamed('Fabrics/ShirtFabrics/353.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '354', name: '354', imageSrc: webImageNamed('Fabrics/ShirtFabrics/354.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '355', name: '355', imageSrc: webImageNamed('Fabrics/ShirtFabrics/355.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '356', name: '356', imageSrc: webImageNamed('Fabrics/ShirtFabrics/356.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '357', name: '357', imageSrc: webImageNamed('Fabrics/ShirtFabrics/357.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '358', name: '358', imageSrc: webImageNamed('Fabrics/ShirtFabrics/358.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '359', name: '359', imageSrc: webImageNamed('Fabrics/ShirtFabrics/359.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '360', name: '360', imageSrc: webImageNamed('Fabrics/ShirtFabrics/360.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '361', name: '361', imageSrc: webImageNamed('Fabrics/ShirtFabrics/361.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '362', name: '362', imageSrc: webImageNamed('Fabrics/ShirtFabrics/362.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '363', name: '363', imageSrc: webImageNamed('Fabrics/ShirtFabrics/363.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '364', name: '364', imageSrc: webImageNamed('Fabrics/ShirtFabrics/364.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '365', name: '365', imageSrc: webImageNamed('Fabrics/ShirtFabrics/365.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '366', name: '366', imageSrc: webImageNamed('Fabrics/ShirtFabrics/366.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '367', name: '367', imageSrc: webImageNamed('Fabrics/ShirtFabrics/367.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '368', name: '368', imageSrc: webImageNamed('Fabrics/ShirtFabrics/368.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '369', name: '369', imageSrc: webImageNamed('Fabrics/ShirtFabrics/369.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '370', name: '370', imageSrc: webImageNamed('Fabrics/ShirtFabrics/370.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '371', name: '371', imageSrc: webImageNamed('Fabrics/ShirtFabrics/371.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '372', name: '372', imageSrc: webImageNamed('Fabrics/ShirtFabrics/372.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '373', name: '373', imageSrc: webImageNamed('Fabrics/ShirtFabrics/373.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '374', name: '374', imageSrc: webImageNamed('Fabrics/ShirtFabrics/374.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '375', name: '375', imageSrc: webImageNamed('Fabrics/ShirtFabrics/375.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '376', name: '376', imageSrc: webImageNamed('Fabrics/ShirtFabrics/376.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '377', name: '377', imageSrc: webImageNamed('Fabrics/ShirtFabrics/377.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '378', name: '378', imageSrc: webImageNamed('Fabrics/ShirtFabrics/378.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '379', name: '379', imageSrc: webImageNamed('Fabrics/ShirtFabrics/379.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '380', name: '380', imageSrc: webImageNamed('Fabrics/ShirtFabrics/380.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '381', name: '381', imageSrc: webImageNamed('Fabrics/ShirtFabrics/381.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '382', name: '382', imageSrc: webImageNamed('Fabrics/ShirtFabrics/382.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '383', name: '383', imageSrc: webImageNamed('Fabrics/ShirtFabrics/383.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '384', name: '384', imageSrc: webImageNamed('Fabrics/ShirtFabrics/384.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '385', name: '385', imageSrc: webImageNamed('Fabrics/ShirtFabrics/385.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '386', name: '386', imageSrc: webImageNamed('Fabrics/ShirtFabrics/386.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '387', name: '387', imageSrc: webImageNamed('Fabrics/ShirtFabrics/387.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '388', name: '388', imageSrc: webImageNamed('Fabrics/ShirtFabrics/388.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '389', name: '389', imageSrc: webImageNamed('Fabrics/ShirtFabrics/389.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '390', name: '390', imageSrc: webImageNamed('Fabrics/ShirtFabrics/390.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '391', name: '391', imageSrc: webImageNamed('Fabrics/ShirtFabrics/391.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '392', name: '392', imageSrc: webImageNamed('Fabrics/ShirtFabrics/392.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '393', name: '393', imageSrc: webImageNamed('Fabrics/ShirtFabrics/393.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '394', name: '394', imageSrc: webImageNamed('Fabrics/ShirtFabrics/394.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '395', name: '395', imageSrc: webImageNamed('Fabrics/ShirtFabrics/395.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '396', name: '396', imageSrc: webImageNamed('Fabrics/ShirtFabrics/396.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '397', name: '397', imageSrc: webImageNamed('Fabrics/ShirtFabrics/397.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '398', name: '398', imageSrc: webImageNamed('Fabrics/ShirtFabrics/398.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '399', name: '399', imageSrc: webImageNamed('Fabrics/ShirtFabrics/399.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '400', name: '400', imageSrc: webImageNamed('Fabrics/ShirtFabrics/400.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '401', name: '401', imageSrc: webImageNamed('Fabrics/ShirtFabrics/401.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '402', name: '402', imageSrc: webImageNamed('Fabrics/ShirtFabrics/402.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '403', name: '403', imageSrc: webImageNamed('Fabrics/ShirtFabrics/403.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '404', name: '404', imageSrc: webImageNamed('Fabrics/ShirtFabrics/404.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '405', name: '405', imageSrc: webImageNamed('Fabrics/ShirtFabrics/405.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '406', name: '406', imageSrc: webImageNamed('Fabrics/ShirtFabrics/406.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '407', name: '407', imageSrc: webImageNamed('Fabrics/ShirtFabrics/407.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '408', name: '408', imageSrc: webImageNamed('Fabrics/ShirtFabrics/408.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '409', name: '409', imageSrc: webImageNamed('Fabrics/ShirtFabrics/409.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '410', name: '410', imageSrc: webImageNamed('Fabrics/ShirtFabrics/410.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '411', name: '411', imageSrc: webImageNamed('Fabrics/ShirtFabrics/411.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '412', name: '412', imageSrc: webImageNamed('Fabrics/ShirtFabrics/412.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '413', name: '413', imageSrc: webImageNamed('Fabrics/ShirtFabrics/413.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '414', name: '414', imageSrc: webImageNamed('Fabrics/ShirtFabrics/414.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '415', name: '415', imageSrc: webImageNamed('Fabrics/ShirtFabrics/415.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '416', name: '416', imageSrc: webImageNamed('Fabrics/ShirtFabrics/416.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '417', name: '417', imageSrc: webImageNamed('Fabrics/ShirtFabrics/417.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '418', name: '418', imageSrc: webImageNamed('Fabrics/ShirtFabrics/418.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '419', name: '419', imageSrc: webImageNamed('Fabrics/ShirtFabrics/419.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '420', name: '420', imageSrc: webImageNamed('Fabrics/ShirtFabrics/420.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '421', name: '421', imageSrc: webImageNamed('Fabrics/ShirtFabrics/421.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '422', name: '422', imageSrc: webImageNamed('Fabrics/ShirtFabrics/422.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '423', name: '423', imageSrc: webImageNamed('Fabrics/ShirtFabrics/423.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '424', name: '424', imageSrc: webImageNamed('Fabrics/ShirtFabrics/424.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '425', name: '425', imageSrc: webImageNamed('Fabrics/ShirtFabrics/425.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '426', name: '426', imageSrc: webImageNamed('Fabrics/ShirtFabrics/426.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '427', name: '427', imageSrc: webImageNamed('Fabrics/ShirtFabrics/427.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '428', name: '428', imageSrc: webImageNamed('Fabrics/ShirtFabrics/428.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '429', name: '429', imageSrc: webImageNamed('Fabrics/ShirtFabrics/429.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '430', name: '430', imageSrc: webImageNamed('Fabrics/ShirtFabrics/430.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '431', name: '431', imageSrc: webImageNamed('Fabrics/ShirtFabrics/431.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '432', name: '432', imageSrc: webImageNamed('Fabrics/ShirtFabrics/432.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '433', name: '433', imageSrc: webImageNamed('Fabrics/ShirtFabrics/433.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '434', name: '434', imageSrc: webImageNamed('Fabrics/ShirtFabrics/434.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '435', name: '435', imageSrc: webImageNamed('Fabrics/ShirtFabrics/435.jpg'), price: 200, company: '' },
        { optType: "Fabric", key: '436', name: '436', imageSrc: webImageNamed('Fabrics/ShirtFabrics/436.jpg'), price: 200, company: '' },
    ]

    fabric_buttons = [
        { optType: "BUTTON", key: 'KB220', name: 'KB220', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB220.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB221', name: 'KB221', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB221.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB222', name: 'KB222', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB222.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB223', name: 'KB223', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB223.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB225', name: 'KB225', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB225.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB226', name: 'KB226', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB226.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB227', name: 'KB227', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB227.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB228', name: 'KB228', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB228.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB229', name: 'KB229', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB229.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB230', name: 'KB230', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB230.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB231', name: 'KB231', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB231.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB232', name: 'KB232', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB232.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB233', name: 'KB233', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB233.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB235', name: 'KB235', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB235.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB236', name: 'KB236', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB236.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB237', name: 'KB237', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB237.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB238', name: 'KB238', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB238.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB239', name: 'KB239', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB239.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB240', name: 'KB240', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB240.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB241', name: 'KB241', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB241.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB242', name: 'KB242', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB242.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB243', name: 'KB243', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB243.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KB245', name: 'KB245', imageSrc: webImageNamed('Fabrics/ShirtButtons/KB245.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KJ006', name: 'KJ006', imageSrc: webImageNamed('Fabrics/ShirtButtons/KJ006.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KJ007', name: 'KJ007', imageSrc: webImageNamed('Fabrics/ShirtButtons/KJ007.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KJ013', name: 'KJ013', imageSrc: webImageNamed('Fabrics/ShirtButtons/KJ013.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KJ016', name: 'KJ016', imageSrc: webImageNamed('Fabrics/ShirtButtons/KJ016.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KJ017', name: 'KJ017', imageSrc: webImageNamed('Fabrics/ShirtButtons/KJ017.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KJ018', name: 'KJ018', imageSrc: webImageNamed('Fabrics/ShirtButtons/KJ018.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KJ020', name: 'KJ020', imageSrc: webImageNamed('Fabrics/ShirtButtons/KJ020.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KJ021', name: 'KJ021', imageSrc: webImageNamed('Fabrics/ShirtButtons/KJ021.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS172', name: 'KS172', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS172.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS178', name: 'KS178', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS178.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS180', name: 'KS180', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS180.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS182', name: 'KS182', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS182.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS184', name: 'KS184', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS184.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS187', name: 'KS187', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS187.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS189', name: 'KS189', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS189.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS191', name: 'KS191', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS191.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS240', name: 'KS240', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS240.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS243', name: 'KS243', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS243.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS244', name: 'KS244', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS244.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS298', name: 'KS298', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS298.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS299', name: 'KS299', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS299.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS300', name: 'KS300', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS300.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS303', name: 'KS303', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS303.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS304', name: 'KS304', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS304.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS308', name: 'KS308', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS308.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS309', name: 'KS309', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS309.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS310', name: 'KS310', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS310.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS311', name: 'KS311', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS311.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS318', name: 'KS318', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS318.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS319', name: 'KS319', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS319.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS320', name: 'KS320', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS320.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS322', name: 'KS322', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS322.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS361', name: 'KS361', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS361.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS363', name: 'KS363', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS363.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS364', name: 'KS364', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS364.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS389', name: 'KS389', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS389.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS392', name: 'KS392', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS392.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS397', name: 'KS397', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS397.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS398', name: 'KS398', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS398.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS410', name: 'KS410', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS410.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS410', name: 'KS410', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS410.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS411', name: 'KS411', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS411.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS414', name: 'KS414', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS414.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS417', name: 'KS417', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS417.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS421', name: 'KS421', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS421.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS422', name: 'KS422', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS422.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS503', name: 'KS503', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS503.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS505', name: 'KS505', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS505.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS506', name: 'KS506', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS506.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS507', name: 'KS507', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS507.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS508', name: 'KS508', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS508.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS509', name: 'KS509', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS509.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS510', name: 'KS510', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS510.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS511', name: 'KS511', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS511.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS512', name: 'KS512', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS512.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS513', name: 'KS513', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS513.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS516', name: 'KS516', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS516.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS556', name: 'KS556', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS556.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS557', name: 'KS557', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS557.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS558', name: 'KS558', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS558.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS559', name: 'KS559', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS559.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS566', name: 'KS566', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS566.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS567', name: 'KS567', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS567.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS568', name: 'KS568', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS568.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KS3569', name: 'KS3569', imageSrc: webImageNamed('Fabrics/ShirtButtons/KS3569.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KTC003', name: 'KTC003', imageSrc: webImageNamed('Fabrics/ShirtButtons/KTC003.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KTC005', name: 'KTC005', imageSrc: webImageNamed('Fabrics/ShirtButtons/KTC005.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KTC008', name: 'KTC008', imageSrc: webImageNamed('Fabrics/ShirtButtons/KTC008.jpg'), price: null, company: '' },
        { optType: "BUTTON", key: 'KTC0009', name: 'KTC0009', imageSrc: webImageNamed('Fabrics/ShirtButtons/KTC0009.jpg'), price: null, company: '' },
       ]





    fabricImageForSelectedKey = (tone, key) => {
        let opts = null
        switch (tone) {
            case this.TONE_KEYS.blacks: opts = this.fabric_blackOpts; break;
            case this.TONE_KEYS.blues: opts = this.fabric_blueOpts; break;
            case this.TONE_KEYS.playful: opts = this.fabric_playOpts; break;
            default: opts = this.fabric_greyOpts; break;
        }
        let thisObj = opts.filter((obj) => {
            return obj.key === key
        })
        return webImageNamed(thisObj[0].imageSrc)
    }


    optsForTone = (tone) => {
        switch (tone) {
            case this.TONE_KEYS.blacks: return this.fabric_blackOpts;
            case this.TONE_KEYS.blues: return this.fabric_blueOpts;
            case this.TONE_KEYS.playful: return this.fabric_playOpts;
            default: return this.fabric_greyOpts;
        }
    }

    fabricStep = () => {
        // Change images here with respect to button choices

        // console.log('Calculating Fabric again. SelectedTone =', this.state.selected_Tone.key)

        // let thisTone = this.state.selected_Tone ? this.state.selected_Tone.key : this.TONE_KEYS.blues

        // let opts = this.optsForTone(thisTone)


        // console.log('OPTS NOW =', opts)
        // console.log(opts)


        return {
            name: 'Pick a Fabric',
            key: this.STEP_KEYS.fabric,
            opts: this.fabric_greyOpts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1'
        }
    }


    scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(this.scrollToTop);
            window.scrollTo(0, c - c / 8);
        }
    }


    onChangeHandler = (direction) => {
        console.log('CHANGE HAPPEND -', direction)
        this.scrollToTop()
        if (direction === 'done') {
            this.donePressed()
        }
        if (direction === 'cancelled') {
            // Cancel Pressed
        }
    }





    donePressed = () => {

        // Create And Save Style Here first


        if (this._mounted) {
            let ns = { ...this.state }
            ns.isComplete = true
            this.setState(ns)


            // CHECK 3
            // const frontImageObj = this.fro.filter((obj) => {
            //     return obj.key === this.state.selected_Collar
            // })
            let mainImage = imageNamed('singalButton')

            if (this.state.selected_Collar.imageSrc) {
                mainImage = this.state.selected_Collar.imageSrc
            }

            let fabricImage = null

            if (this.state.selected_Fabric.imageSrc) {
                fabricImage = this.state.selected_Fabric.imageSrc
            }
            let styleName = this.state.productType + "-" + makeid(3)

            if (this.state.editingProduct) {
                uniqId = this.state.editingProduct.key
                styleName = this.state.editingProduct.styleName
            }





            let allSelelected = [
                this.state.selected_FitPreference,
                this.state.selected_Collar,
                this.state.selected_CollarWingLength,
                this.state.selected_PlacketStyle,
                this.state.selected_Pocket,
                this.state.selected_SleeveStyle,
                this.state.selected_Cuff,
                this.state.selected_BackPleats,
                this.state.selected_ShirtLength,
                this.state.selected_Monogram,
                this.state.selected_MonogramThreadColor,
                this.state.selected_CollarContrast,
                this.state.selected_CollarContrastFabric,

                this.state.selected_CuffContrast,
                this.state.selected_CuffContrastFabric,

                this.state.selected_ElbowPatch,
                this.state.selected_ElbowPatchFabric,

                // this.state.selected_Tone,
                this.state.selected_Fabric,
                this.state.selected_Buttons,
            ]


            let finalPrice = this.basePrice
            let addedPriceObjs = []
            let addOnPrice = 0

            allSelelected.forEach(asel => {
                if (asel != null) {
                    if (asel.price) {
                        finalPrice += asel.price
                        addOnPrice += asel.price
                        addedPriceObjs.push({ type: asel.optType, name: asel.name, price: asel.price })
                    }
                }

            })



            // allSelelected.forEach( selectedObj  => {
            //     if (selelectedObj.price) {
            //         finalPrice += selectedObj.price
            //         addedPriceObjs.push( { type: selectedObj.optType, name: selectedObj.name, price: selectedObj.price })
            //     }
            // })

            console.log("this.state.selected_Collar = ")
            console.log(this.state.selected_Collar)




            let style = {
                fitPreference: this.state.selected_FitPreference,
                collar: this.state.selected_Collar,
                collarWingLength: this.state.selected_CollarWingLength,
                placketStyle: this.state.selected_PlacketStyle,
                pocket: this.state.selected_Pocket,
                sleeveStyle: this.state.selected_SleeveStyle,
                cuff: this.state.selected_Cuff,
                backPleats: this.state.selected_BackPleats,
                shirtLength: this.state.selected_ShirtLength,
                monogram: this.state.selected_Monogram,
                monogramThreadColor: this.state.selected_MonogramThreadColor,

                // tone: this.state.selected_Tone,
                fabric: this.state.selected_Fabric,

                collarContrast: this.state.selected_CollarContrast,
                collarContrastFabric: this.state.selected_CollarContrastFabric,

                cuffContrast: this.state.selected_CuffContrast,
                cuffContrastFabric: this.state.selected_CuffContrastFabric,

                elbowPatch: this.state.selected_ElbowPatch,
                elbowPatchFabric: this.state.selected_ElbowPatchFabric,
                buttons: this.state.selected_Buttons,

            }


            let styleArr = Object.keys(style).map(function (key) {

                return [key, style[key]];
            });

            let withoutNull = styleArr.filter(ar => {
                return !ar.includes(null)
            })


            let description = ' '
            withoutNull.forEach((ms, index) => {
                let type = ms[0]
                let val = ms[1].name


                description += ' ' + type + ':' + val
                if (ms[1].customInput) {
                    description += `<${ms[1].customInput}> `
                }
                if (index == (withoutNull.length - 1)) {
                    description += ""
                } else {
                    description += ","
                }


            })


            // let prodObj = new C_Product(null,true,this.state.productType, uniqId,"",null, null,[], [], style, "", description, false, null, true, false, null, true, [], currencySymbol ,this.basePrice,addOnPrice, 0,0, finalPrice, addedPriceObjs, styleName, fabricImage, mainImage)

            // prodObj.key = 'id' + (new Date()).getTime();


            // // let prodObj = {
            // //     key: uniqId,
            // //     styleName:  styleName,
            // //     frontImage: mainImage,
            // //     fabricImage: fabricImage,
            // //     description: description,
            // //     type: this.state.productType,
            // //     basePrice: this.basePrice,
            // //     price: finalPrice,
            // //     addedPriceObjs: addedPriceObjs,
            // //     selectedSelections: style
            // // }

            let prod = Product.init()
            prod.type = this.state.productType
            prod.conciergeSelections = [style]

            let fabricAndToneMaterials = []

            let concToTwSelections = Object.values(style).map((obj) => {
                if (obj === null) {
                    return null
                }

                if (obj.optType.toLowerCase() === 'fabric') {
                    fabricAndToneMaterials.push("Fabric - " + obj.name + (obj.customInput ? `<${obj.customInput}>` : ""))
                    return null
                }

                // if (obj.optType.toLowerCase() === 'tone') {
                //     return null
                //     // fabricAndToneMaterials.push("Fabric - " + obj.name  + (obj.customInput ?  `<${obj.customInput}>` : "")  )
                // }

                return {
                    "key": obj.optType,
                    "value": obj.name + (obj.customInput ? `<${obj.customInput}>` : ""),
                    "styleSelectionId": obj.styleSelectionId ?? "",
                    "imageSrc": obj.imageSrc ?? null,
                    "isConciergeSelection": true
                }
            })

            concToTwSelections = concToTwSelections.filter((obj) => {
                return (obj !== null)
            })

            prod.materialToSource = fabricAndToneMaterials


            prod.styleSelections = concToTwSelections

            prod.basePrice = this.basePrice
            prod.addOnPrice = addOnPrice
            prod.name = styleName
            prod.taxOnPrice = 0
            prod.shippingPrice = 0
            prod.taxDescription = ""
            prod.shippingDescription = ""
            prod.price = finalPrice
            prod.addedPriceObjs = addedPriceObjs
            prod.styleName = styleName
            prod.imageUrl = mainImage
            prod.fabricImage = fabricImage
            prod.imagesUrls = [mainImage, fabricImage]
            prod.description = description
            prod.isBespoke = true
            prod.isViaConcierge = true

            console.log('Sending Style Object -', prod)

            this.props.isDone('created', prod)

        }
    }

    stepItemSelected = (stepKey, itemKey, customInput) => {
        console.log('Step selection happend in StepKey =' + stepKey + ', ItemKey= ', itemKey)

        if (this._mounted) {
            console.log('IS MOUNTED')
            let ns = { ...this.state }
            console.log("GOT Custom INPUT " + customInput)

            // this.state.selected_FitPreference,
            // this.state.selected_Collar,
            // this.state.selected_CollarWingLength,
            // this.state.selected_PlacketStyle,
            // this.state.selected_Pocket,
            // this.state.selected_SleeveStyle,
            // this.state.selected_Cuff,
            // this.state.selected_BackPleats,
            // this.state.selected_ShirtLength,
            // this.state.selected_Monogram,
            // this.state.selected_CollarContrast,
            // this.state.selected_CuffContrast,
            // this.state.selected_ElbowPatch,
            // this.state.selected_Tone,
            // this.state.selected_Fabric


            // selected_MonogramThreadColor
            // selected_Buttons

            switch (stepKey) {
                case this.STEP_KEYS.fitPreference: ns.selected_FitPreference = this.fitPreference_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.collar: ns.selected_Collar = this.collar_Opts.filter(opt => { return opt.key === itemKey })[0]; break;

                case this.STEP_KEYS.collarWingLength: ns.selected_CollarWingLength = this.collarWingLength_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.placketStyle: ns.selected_PlacketStyle = this.placketStyle_Opts.filter(opt => { return opt.key === itemKey })[0]; break;

                case this.STEP_KEYS.pocket: ns.selected_Pocket = this.pocket_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.sleeveStyle: ns.selected_SleeveStyle = this.sleeveStyle_Opts.filter(opt => { return opt.key === itemKey })[0]; break;


                case this.STEP_KEYS.cuff: ns.selected_Cuff = this.cuff_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.backPleats: ns.selected_BackPleats = this.backPleats_Opts.filter(opt => { return opt.key === itemKey })[0]; break;

                case this.STEP_KEYS.shirtLength: ns.selected_ShirtLength = this.shirtLength_Opts.filter(opt => { return opt.key === itemKey })[0]; break;

                case this.STEP_KEYS.monogram: ns.selected_Monogram = this.monogram_Opts.filter(opt => { return opt.key === itemKey })[0]; if (customInput && customInput !== "") { ns.selected_Monogram["customInput"] = customInput }; break;
                case this.STEP_KEYS.monogramThreadColor: ns.selected_MonogramThreadColor = this.monogramThreadColor_Opts.filter(opt => { return opt.key === itemKey })[0]; break;

                case this.STEP_KEYS.collarContrast: ns.selected_CollarContrast = this.collarContrast_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.collarContrastFabric: ns.selected_CollarContrastFabric = this.collarContrastFabricOpts().filter(opt => { return opt.key === itemKey })[0]; break;

                case this.STEP_KEYS.cuffContrast: ns.selected_CuffContrast = this.cuffContrast_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.cuffContrastFabric: ns.selected_CuffContrastFabric = this.cuffContrastFabricOpts().filter(opt => { return opt.key === itemKey })[0]; break;

                case this.STEP_KEYS.elbowPatch: ns.selected_ElbowPatch = this.elbowPatch_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.elbowPatchFabric: ns.selected_ElbowPatchFabric = this.elbowPatchFabricOpts().filter(opt => { return opt.key === itemKey })[0]; break;


                // case this.STEP_KEYS.tone: ns.selected_Tone = this.toneOpts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.fabric: ns.selected_Fabric = this.fabricStep().opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.buttons: ns.selected_Buttons = this.buttons_Step().opts.filter(opt => { return opt.key === itemKey })[0]; break;

                default: break;
            }
            this.setState(ns)
        }

    }

    createUploadChoiceMade = (name, code) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.selectedCreateOrUpload = code
            this.setState(ns)
        }
    }

    createUploadChooser = () => {
        // return <div> <Box className='is-radiusless is-shadowless layerGreyBG  minHeight300 myFlexContainer '>
        return <div> <Box className='is-radiusless is-shadowless  minHeight300 myFlexContainer '>
            <ChooseBox choices={[{ name: 'Create Style', code: this.STYLE_START.create }, { name: 'Upload Style', code: this.STYLE_START.upload }]} choiceMade={this.createUploadChoiceMade} />
            {/* <ChooseBox choices={[{ name: 'Choose Style' }, { name: 'Upload Style' }]} choiceMade={this.choiceMadeInChooseBox} /> */}
        </Box>
            <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button>
        </div>
    }




    uploadImageStarted = () => {
        console.log('UPLOAD IMAGE STARTED IN WIZARD')
    }






    jacketWizard = () => {



        let fitPreference_Step = this.fitPreference_Step()
        let collar_Step = this.collar_Step()
        let collarWingLength_Step = this.collarWingLength_Step()
        let placketStyle_Step = this.placketStyle_Step()
        let pocket_Step = this.pocket_Step()
        let sleeveStyle_Step = this.sleeveStyle_Step()
        let cuff_Step = this.cuff_Step()

        let isCuffStepNeeded = false

        if (this.state.selected_SleeveStyle) {
            if (this.state.selected_SleeveStyle.key !== sleeveStyle_Step.opts[0].key) {
                isCuffStepNeeded = true
            }
        } 


        let backPleats_Step = this.backPleats_Step()
        let shirtLength_Step = this.shirtLength_Step()

        // let isCollarContrastFabricLast = false
        // let isCuffContrastFabricLast = false
        // let isElbowContrastFabricLast = false

        let collarContrast_Step = this.collarContrast_Step()
        let collarContrastFabric_Step = this.collarContrastFabricStep()
        let isCollarContrastFabricNeeded = false

        if (this.state.selected_CollarContrast) {
            if (this.state.selected_CollarContrast.key !== collarContrast_Step.opts[0].key) {
                isCollarContrastFabricNeeded = true
            }
        }

        let cuffContrast_Step = this.cuffContrast_Step()
        let cuffContrastFabric_Step = this.cuffContrastFabricStep()
        let isCuffContrastFabricNeeded = false

        if (this.state.selected_CuffContrast) {
            if (this.state.selected_CuffContrast.key !== cuffContrast_Step.opts[0].key) {
                isCuffContrastFabricNeeded = true
            }
        }

        let elbowPatch_Step = this.elbowPatch_Step()
        let elbowPatchFabric_Step = this.elbowPatchFabric_Step()
        let isElbowPatchFabricNeeded = false


        if (this.state.selected_ElbowPatch) {
            if (this.state.selected_ElbowPatch.key !== elbowPatch_Step.opts[0].key) {
                isElbowPatchFabricNeeded = true
            }
        }


        let monogram_Step = this.monogram_Step()
        let monogramThreadColor_Step = this.monogramThreadColor_Step() // dont show on no monogram
        let buttons_Step = this.buttons_Step()

        // let toneStep = this.toneStep()
        let fabricStep = this.fabricStep()

        let is_monogramThreadColor_Needed = false
        if (this.state.selected_Monogram) {
            if (this.state.selected_Monogram !== this.monogram_Opts[0])  {
                is_monogramThreadColor_Needed = true
            }
        } 


        let title = "Let's Create a Shirt Style"
        let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
        let points = [
            'To make it easy we pick the cuts and tones before finding the fabric.',
            'We will measure you right after we have created all the needed styles.'
        ]



        // 1. FitPreference
        // 2. Collar
        // 3. CollarWingLength
        // 4. PlacketStyle
        // 5. Pocket
        // 6. SleeveStyle
        // 7. Cuff
        // 8. BackPleats
        // 9. ShirtLength
        // 10. Monogram
        // 11. CollarContrast
        // 11.5. CollarContrastFabric

        // 12. CuffContrast
        // 12.5. CuffContrastFabric

        // 13. ElbowPatch
        // 13.5. ElbowPatchFabric


        return (<div >
            {/* <div ref={this.mainStepperDiv}></div> */}
            {(this.state.isComplete || this.state.isCancelled) ?
                <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
                :
                <StepWizard className=' fadeIn'>
                    {/* {showUploader ? <Step type='uploader' skipable={true} isFirst={true} existingUploadImage={this.state.uploadImage} uploadImagePressed={this.uploadImageStarted} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  ></Step> : null} */}
                    {this.state.editingProduct ? null : <Step name="Welcome" imageSrc={imageNamed('IconBg-Style')} overrideNext='Start' isMessageScreen={true} title={title} subtitle={subtitle} points={points} isFirst={true} skipable={false} stepKey={"12wjkbwj3"} exitingSelectedKey={null} opts={null} isPhotoBox={false} photoRatio={'16by9'} onChange={this.onChangeHandler} itemSelected={null}  ></Step>}

                    <Step name={fitPreference_Step.name} skipable={fitPreference_Step.skipable ?? false} stepKey={fitPreference_Step.key} exitingSelectedKey={this.state.selected_FitPreference ? this.state.selected_FitPreference.key : null} opts={fitPreference_Step.opts} isFirst={true} isPhotoBox={fitPreference_Step.isPhotoBox} photoRatio={fitPreference_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={fitPreference_Step.boldText ?? false} boldText={fitPreference_Step.boldText ?? false} ></Step>
                    <Step name={collar_Step.name} skipable={collar_Step.skipable ?? false} stepKey={collar_Step.key} exitingSelectedKey={this.state.selected_Collar ? this.state.selected_Collar.key : null} opts={collar_Step.opts} isFirst={false} isPhotoBox={collar_Step.isPhotoBox} photoRatio={collar_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={collar_Step.boldText ?? false} boldText={collar_Step.boldText ?? false} ></Step>
                    {/* <Step name={collarWingLength_Step.name} skipable={collarWingLength_Step.skipable ?? false} stepKey={collarWingLength_Step.key} exitingSelectedKey={this.state.selected_CollarWingLength ? this.state.selected_CollarWingLength.key : null} opts={collarWingLength_Step.opts} isFirst={false} isPhotoBox={collarWingLength_Step.isPhotoBox} photoRatio={collarWingLength_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={collarWingLength_Step.boldText ?? false} boldText={collarWingLength_Step.boldText ?? false} ></Step> */}
                    <Step name={placketStyle_Step.name} skipable={placketStyle_Step.skipable ?? false} stepKey={placketStyle_Step.key} exitingSelectedKey={this.state.selected_PlacketStyle ? this.state.selected_PlacketStyle.key : null} opts={placketStyle_Step.opts} isFirst={false} isPhotoBox={placketStyle_Step.isPhotoBox} photoRatio={placketStyle_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={placketStyle_Step.boldText ?? false} boldText={placketStyle_Step.boldText ?? false} ></Step>

                    <Step name={pocket_Step.name} skipable={pocket_Step.skipable ?? false} stepKey={pocket_Step.key} exitingSelectedKey={this.state.selected_Pocket ? this.state.selected_Pocket.key : null} opts={pocket_Step.opts} isFirst={false} isPhotoBox={pocket_Step.isPhotoBox} photoRatio={pocket_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={pocket_Step.boldText ?? false} boldText={pocket_Step.boldText ?? false} ></Step>
                    <Step name={sleeveStyle_Step.name} skipable={sleeveStyle_Step.skipable ?? false} stepKey={sleeveStyle_Step.key} exitingSelectedKey={this.state.selected_SleeveStyle ? this.state.selected_SleeveStyle.key : null} opts={sleeveStyle_Step.opts} isFirst={false} isPhotoBox={sleeveStyle_Step.isPhotoBox} photoRatio={sleeveStyle_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={sleeveStyle_Step.boldText ?? false} boldText={sleeveStyle_Step.boldText ?? false} ></Step>
                    {
                        isCuffStepNeeded ?
                        <Step name={cuff_Step.name} skipable={cuff_Step.skipable ?? false} stepKey={cuff_Step.key} exitingSelectedKey={this.state.selected_Cuff ? this.state.selected_Cuff.key : null} opts={cuff_Step.opts} isFirst={false} isPhotoBox={cuff_Step.isPhotoBox} photoRatio={cuff_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={cuff_Step.boldText ?? false} boldText={cuff_Step.boldText ?? false} ></Step>
                        : null
                    }
                    
                    <Step name={backPleats_Step.name} skipable={backPleats_Step.skipable ?? false} stepKey={backPleats_Step.key} exitingSelectedKey={this.state.selected_BackPleats ? this.state.selected_BackPleats.key : null} opts={backPleats_Step.opts} isFirst={false} isPhotoBox={backPleats_Step.isPhotoBox} photoRatio={backPleats_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={backPleats_Step.boldText ?? false} boldText={backPleats_Step.boldText ?? false} ></Step>
                    <Step name={shirtLength_Step.name} skipable={shirtLength_Step.skipable ?? false} stepKey={shirtLength_Step.key} exitingSelectedKey={this.state.selected_ShirtLength ? this.state.selected_ShirtLength.key : null} opts={shirtLength_Step.opts} isFirst={false} isPhotoBox={shirtLength_Step.isPhotoBox} photoRatio={shirtLength_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={shirtLength_Step.boldText ?? false} boldText={shirtLength_Step.boldText ?? false} ></Step>

                    <Step name={monogram_Step.name} needsCustomInput={true} skipable={monogram_Step.skipable ?? false} stepKey={monogram_Step.key} exitingSelectedKey={this.state.selected_Monogram ? this.state.selected_Monogram.key : null} opts={monogram_Step.opts} isFirst={false} isPhotoBox={monogram_Step.isPhotoBox} photoRatio={monogram_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={monogram_Step.boldText ?? false} boldText={monogram_Step.boldText ?? false} ></Step>
                    {
                        is_monogramThreadColor_Needed ?
                            <Step name={monogramThreadColor_Step.name} needsCustomInput={false} skipable={monogramThreadColor_Step.skipable ?? false} stepKey={monogramThreadColor_Step.key} exitingSelectedKey={this.state.selected_MonogramThreadColor ? this.state.selected_MonogramThreadColor.key : null} opts={monogramThreadColor_Step.opts} isFirst={false} isPhotoBox={monogramThreadColor_Step.isPhotoBox} photoRatio={monogramThreadColor_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={monogramThreadColor_Step.boldText ?? false} boldText={monogramThreadColor_Step.boldText ?? false} ></Step>
                        : null
                    }
                    <Step name={fabricStep.name} skipable={fabricStep.skipable ?? false} stepKey={fabricStep.key} exitingSelectedKey={this.state.selected_Fabric ? this.state.selected_Fabric.key : null} opts={fabricStep.opts} isFirst={false} isPhotoBox={fabricStep.isPhotoBox} photoRatio={fabricStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={fabricStep.boldText ?? false} boldText={fabricStep.boldText ?? false} ></Step>
                   
                    <Step name={buttons_Step.name} skipable={buttons_Step.skipable ?? false} stepKey={buttons_Step.key} exitingSelectedKey={this.state.selected_Buttons ? this.state.selected_Buttons.key : null} opts={buttons_Step.opts} isFirst={false} isPhotoBox={buttons_Step.isPhotoBox} photoRatio={buttons_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={buttons_Step.boldText ?? false} boldText={buttons_Step.boldText ?? false} ></Step>

                   
                    <Step name={collarContrast_Step.name} skipable={collarContrast_Step.skipable ?? false} stepKey={collarContrast_Step.key} exitingSelectedKey={this.state.selected_CollarContrast ? this.state.selected_CollarContrast.key : null} opts={collarContrast_Step.opts} isFirst={false} isPhotoBox={collarContrast_Step.isPhotoBox} photoRatio={collarContrast_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={collarContrast_Step.boldText ?? false} boldText={collarContrast_Step.boldText ?? false} ></Step>
                    {
                        isCollarContrastFabricNeeded ?
                            <Step name={collarContrastFabric_Step.name} needsCustomInput={false} skipable={collarContrastFabric_Step.skipable ?? false} stepKey={collarContrastFabric_Step.key} exitingSelectedKey={this.state.selected_CollarContrastFabric ? this.state.selected_CollarContrastFabric.key : null} opts={collarContrastFabric_Step.opts} isFirst={false} isPhotoBox={collarContrastFabric_Step.isPhotoBox} photoRatio={collarContrastFabric_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={collarContrastFabric_Step.boldText ?? false} boldText={collarContrastFabric_Step.boldText ?? false} ></Step>
                            :
                            null
                    }

                    <Step name={cuffContrast_Step.name} skipable={cuffContrast_Step.skipable ?? false} stepKey={cuffContrast_Step.key} exitingSelectedKey={this.state.selected_CuffContrast ? this.state.selected_CuffContrast.key : null} opts={cuffContrast_Step.opts} isFirst={false} isPhotoBox={cuffContrast_Step.isPhotoBox} photoRatio={cuffContrast_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={!isCuffContrastFabricNeeded} paddingLess={cuffContrast_Step.boldText ?? false} boldText={cuffContrast_Step.boldText ?? false} ></Step>
                    {
                        isCuffContrastFabricNeeded ?
                            <Step name={cuffContrastFabric_Step.name} needsCustomInput={false} skipable={cuffContrastFabric_Step.skipable ?? false} stepKey={cuffContrastFabric_Step.key} exitingSelectedKey={this.state.selected_CuffContrastFabric ? this.state.selected_CuffContrastFabric.key : null} opts={cuffContrastFabric_Step.opts} isFirst={false} isPhotoBox={cuffContrastFabric_Step.isPhotoBox} photoRatio={cuffContrastFabric_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={true} paddingLess={cuffContrastFabric_Step.boldText ?? false} boldText={cuffContrastFabric_Step.boldText ?? false} ></Step>
                            :
                            null
                    }

                    {/* <Step name={elbowPatch_Step.name} skipable={elbowPatch_Step.skipable ?? false} stepKey={elbowPatch_Step.key} exitingSelectedKey={this.state.selected_ElbowPatch ? this.state.selected_ElbowPatch.key : null} opts={elbowPatch_Step.opts} isFirst={false} isPhotoBox={elbowPatch_Step.isPhotoBox} photoRatio={elbowPatch_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={!isElbowPatchFabricNeeded} paddingLess={elbowPatch_Step.boldText ?? false} boldText={elbowPatch_Step.boldText ?? false} ></Step>
                    {
                        isElbowPatchFabricNeeded ?
                            <Step name={elbowPatchFabric_Step.name} needsCustomInput={false} skipable={elbowPatchFabric_Step.skipable ?? false} stepKey={elbowPatchFabric_Step.key} exitingSelectedKey={this.state.selected_ElbowPatchFabric ? this.state.selected_ElbowPatchFabric.key : null} opts={elbowPatchFabric_Step.opts} isFirst={false} isPhotoBox={elbowPatchFabric_Step.isPhotoBox} photoRatio={elbowPatchFabric_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={true} paddingLess={elbowPatchFabric_Step.boldText ?? false} boldText={elbowPatchFabric_Step.boldText ?? false} ></Step>
                            :
                            null
                    } */}


                </StepWizard>
            }
            {/* <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button> */}
        </div>
        )
    }








    render() {
        return (
            this.jacketWizard()
        )
    }

}


export default WizardForShirt
