import React, { Component } from 'react'
import StepWizard from 'react-step-wizard'
import Step from './Step'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal
} from 'react-bulma-components/dist'

import ColumnAux from './ColumnAux'
import LoadingAux from './LoadingAux'
import ChooseBox from './ChooseBox'

import { imageNamed, scrollToRef, PRODUCT_TYPE } from '../utilities/utils'





// console.log('Images Folder =', allImages)
// console.log('Images with ````` =', imageNamed('`````'))



class StyleChooseWizard extends Component {

    STEP_KEYS = {
        productType: '',
        frontType: 'fronType',
        tone: 'tone',
        fabric: 'fabric',
        buttonDesign: 'buttonDesign',
        collar: 'collar',
        back: 'back',
        pant: 'pant',
        fitness: 'fitness',
    }

    TONE_KEYS = {
        greys: 'greys',
        blues: 'blues',
        blacks: 'blacks',
        playful: 'playfuls'
    }

    STYLE_START = {
        create: 'Create Style',
        upload: 'Upload Style',
        select: 'Select Style'
    }

    state = {
        productType: null,
        uploadImage: null,
        selected_Product: null,
        selected_Front: null,
        selected_Tone: null,
        selected_Fabric: null,
        selected_ButtonDesign: null,
        selected_Collar: null,
        selected_Back: null,
        selected_PantStyle: null,
        selected_Fitting: null,

        selected_StyleName: null,
        selected_Description: null,
        isComplete: false,
        isCancelled: false,
        selectedCreateOrUpload: this.STYLE_START.create,
        selectedProduct: null,
        editingStyle: null
    }



    _mounted = false


    componentDidMount = () => {
        this._mounted = true

        let ns = { ...this.state }
        if (this.props.editingStyle) {
            ns.editingStyle = this.props.editingStyle
        }
        if (this.props.productType) {
            ns.productType = this.props.productType
        }

        if (this.props.editingStyle) {
            let existingStyle = { ...this.props.editingStyle }
            ns.editingStyle = existingStyle
            if (this.props.productType === PRODUCT_TYPE.suit) {
                ns.selected_Front = existingStyle.style.front
                ns.selected_Tone = existingStyle.style.tone
                ns.selected_Fabric = existingStyle.style.fabric
                ns.selected_ButtonDesign = existingStyle.style.buttonDesign
                ns.selected_Collar = existingStyle.style.collar
                ns.selected_Back = existingStyle.style.back
                ns.selected_PantStyle = existingStyle.style.pantStyle
                ns.selected_Fitting = existingStyle.style.fitting
            }

            ns.selected_StyleName = existingStyle.style.styleName
            ns.selected_Description = existingStyle.style.description
            if (this.props.productType) {
                ns.productType = this.props.productType
            }
        }
        this.setState(ns)

    }

    componentWillUnmount = () => {
        this._mounted = false
    }


    productOpts = [
        { key: PRODUCT_TYPE.suit , name: 'Suit', imageSrc: null},
        { key: PRODUCT_TYPE.shirt, name: 'Shirt', imageSrc: null },
        { key: PRODUCT_TYPE.pant, name: 'Pant', imageSrc: null },
        { key: PRODUCT_TYPE.shoe, name: 'Shoe', imageSrc: null },
    ]

    frontOpts = [
        { key: 'oneButton', name: 'One Button', imageSrc: imageNamed('singalButton') },
        { key: 'twoButton', name: 'Two Button', imageSrc: imageNamed('twoButtons') },
        { key: 'fourButton', name: 'Four Button', imageSrc: imageNamed('double4Buttons') },
        { key: 'sixButton', name: 'Six Button', imageSrc: imageNamed('double6Buttons') },
        { key: 'fullButton', name: 'Full Button', imageSrc: imageNamed('fullButtons') },
    ]

    toneOpts = [
        { key: this.TONE_KEYS.blues, name: 'Blues', imageSrc: imageNamed('blues') },
        { key: this.TONE_KEYS.blacks, name: 'Blacks', imageSrc: imageNamed('blacks') },
        { key: this.TONE_KEYS.greys, name: 'Greys', imageSrc: imageNamed('greys') },
        { key: this.TONE_KEYS.playful, name: 'Playful', imageSrc: imageNamed('playful') },
        // { key: 'other', name: 'Other', imageSrc: null },
    ]

    buttonOpts = [
        { key: 'buttonClassic', name: 'Classic', imageSrc: imageNamed('button_classic') },
        { key: 'buttonFancy', name: 'Fancy', imageSrc: imageNamed('button_fancy') },
    ]

    collarOpts = [
        { key: 'collarSlim', name: 'Slim', imageSrc: imageNamed('collar_slim') },
        { key: 'collarStandard', name: 'Standard', imageSrc: imageNamed('collar_standard') },
        { key: 'collarWide', name: 'Wide', imageSrc: imageNamed('collar_wide') },
    ]

    fitOpts = [
        { key: 'slim', name: 'Slim', imageSrc: null },
        { key: 'regular', name: 'Standard', imageSrc: null },
        { key: 'comfortable', name: 'Comforting', imageSrc: null },
    ]

    backOpts = [
        { key: 'centerCut', name: 'Center Cut', imageSrc: imageNamed('back_centerCut') },
        { key: 'sideCut', name: 'Side Cut', imageSrc: imageNamed('back_sideCut') },
    ]

    pantOpts = [
        { key: 'plain', name: 'Plain', imageSrc: imageNamed('pant_plain') },
        { key: 'pleated', name: 'Pleated', imageSrc: imageNamed('pant_pleated') },
    ]

    fabric_greyOpts = [
        { key: '1', name: 'Grey 1', imageSrc: imageNamed('fab_grey1') , price:210 , company:'Scabal'},
        { key: '2', name: 'Grey 2', imageSrc: imageNamed('fab_grey2') , price:130 , company:'Zegna'},
        { key: '3', name: 'Grey 3', imageSrc: imageNamed('fab_grey3') , price:320 , company:'Freggi'},
        { key: '4', name: 'Grey 4', imageSrc: imageNamed('fab_grey4') , price:100 , company:'Loro Piano'},
        { key: '5', name: 'Grey 5', imageSrc: imageNamed('fab_grey1') , price:210 , company:'Scabal'},
        { key: '6', name: 'Grey 6', imageSrc: imageNamed('fab_grey2') , price:130 , company:'Zegna'},
        { key: '7', name: 'Grey 7', imageSrc: imageNamed('fab_grey3') , price:320 , company:'Freggi'},
        { key: '8', name: 'Grey 8', imageSrc: imageNamed('fab_grey4') , price:100 , company:'Loro Piano'},
        { key: '9', name: 'Grey 9', imageSrc: imageNamed('fab_grey1') , price:210 , company:'Scabal'},
        { key: '10', name: 'Grey 10', imageSrc: imageNamed('fab_grey2') , price:130 , company:'Zegna'},
        { key: '11', name: 'Grey 11', imageSrc: imageNamed('fab_grey3') , price:320 , company:'Freggi'},
        { key: '12', name: 'Grey 12', imageSrc: imageNamed('fab_grey4') , price:100 , company:'Loro Piano'},
        { key: '13', name: 'Grey 13', imageSrc: imageNamed('fab_grey1') , price:210 , company:'Scabal'},
        { key: '14', name: 'Grey 14', imageSrc: imageNamed('fab_grey2') , price:130 , company:'Zegna'},
        { key: '15', name: 'Grey 15', imageSrc: imageNamed('fab_grey3') , price:320 , company:'Freggi'}
    ]
    fabric_blueOpts = [
        { key: '1', name: 'Blue 1', imageSrc: imageNamed('fab_blue1')  , price:320 , company:'Armegna'},
        { key: '2', name: 'Blue 2', imageSrc: imageNamed('fab_blue2')  , price:90  , company:'Ferrocia'},
        { key: '3', name: 'Blue 3', imageSrc: imageNamed('fab_blue3')  , price:420 , company:'Muchos'},
        { key: '4', name: 'Blue 4', imageSrc: imageNamed('fab_blue4')  , price:120 , company:'Scabal'},
    ]
    fabric_blackOpts = [
        { key: '1', name: 'Black 1', imageSrc: imageNamed('fab_black1')  , price:145 , company:'Curruti'},
        { key: '2', name: 'Black 2', imageSrc: imageNamed('fab_black2')  , price:622 , company:'Formid'},
        { key: '3', name: 'Black 3', imageSrc: imageNamed('fab_black3')  , price:366 , company:'Scabal'},
        { key: '4', name: 'Black 4', imageSrc: imageNamed('fab_black4')  , price:445 , company:'Scabal'},
    ]
    fabric_playOpts = [
        { key: '1', name: 'Play 1', imageSrc: imageNamed('fab_play1')  , price:233 , company:'Zegna'},
        { key: '2', name: 'Play 2', imageSrc: imageNamed('fab_play2')  , price:365 , company:'Loro Piano'},
        { key: '3', name: 'Play 3', imageSrc: imageNamed('fab_play3')  , price:647 , company:'Scabal'},
        { key: '4', name: 'Play 4', imageSrc: imageNamed('fab_play4')  , price:442 , company:'Cerruti'},
        { key: '5', name: 'Not Sure! You can choose for me.', imageSrc: null , company:'Choice Fabric',  price:250}
    ]


    productStep = () => {
        return {
            name: 'Select Apparel to Begin',
            key: this.STEP_KEYS.productType,
            opts: this.productOpts,
            selectedOptKey: null,
            isPhotoBox: false,
            photoRatio: '1by1'
        }
    }

    frontStep = () => {
        return {
            name: 'How would you like the front?',
            key: this.STEP_KEYS.frontType,
            opts: this.frontOpts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1'
        }
    }

    toneStep = () => {
        // Change images here with respect to button choices
        return {
            name: 'Choose Fabric Collection',
            key: this.STEP_KEYS.tone,
            opts: this.toneOpts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4'
        }
    }

    fabricImageForSelectedKey = (tone, key) => {
        let opts = null
        switch (tone) {
            case this.TONE_KEYS.blacks: opts = this.fabric_blackOpts; break;
            case this.TONE_KEYS.blues: opts = this.fabric_blueOpts; break;
            case this.TONE_KEYS.playful: opts = this.fabric_playOpts; break;
            default: opts = this.fabric_greyOpts; break;
        }
        let thisObj = opts.filter((obj) => {
            return obj.key === key
        })
        return imageNamed(thisObj[0].imageSrc)
    }

    fabricStep = () => {
        // Change images here with respect to button choices

        // console.log('Calculating Fabric again. SelectedTone =', this.state.selected_Tone)

        let opts = null
        switch (this.state.selected_Tone) {
            case this.TONE_KEYS.blacks: opts = this.fabric_blackOpts; break;
            case this.TONE_KEYS.blues: opts = this.fabric_blueOpts; break;
            case this.TONE_KEYS.playful: opts = this.fabric_playOpts; break;
            default: opts = this.fabric_greyOpts; break;
        }

        return {
            name: 'Pick a Fabric',
            key: this.STEP_KEYS.fabric,
            opts: opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1'
        }
    }

    buttonStep = () => {
        return {
            name: 'Button Design ?',
            key: this.STEP_KEYS.buttonDesign,
            opts: this.buttonOpts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4'
        }
    }

    collarStep = () => {
        return {
            name: 'Any Specific Collar Width',
            key: this.STEP_KEYS.collar,
            opts: this.collarOpts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4'
        }
    }

    backStep = () => {
        return {
            name: 'Select Back Cuts',
            key: this.STEP_KEYS.back,
            opts: this.backOpts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4'
        }
    }

    pantStep = () => {
        return {
            name: 'Pick Pant Front',
            key: this.STEP_KEYS.pant,
            opts: this.pantOpts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4'
        }
    }

    fitStep = () => {
        return {
            name: 'Whats your fitness preference?',
            key: this.STEP_KEYS.fitness,
            opts: this.fitOpts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4'
        }
    }


    // Buttons
    // Color Tone
    // Fabric 
    // Buttons
    // Collar
    // Back
    // Pant

    PRODUCT_TYPE = {
        suit: 'SUIT',
        blazer: 'Blazer',
        jacket: 'Jacket',
        shirt: 'Shirt',
        pant: 'Pant'
    }


    scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(this.scrollToTop);
            window.scrollTo(0, c - c / 8);
        }
    };

    // scrollToTop = () => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: 'smooth',
    //       })
    // }


    onChangeHandler = (direction) => {
        console.log('CHANGE HAPPEND -', direction)
        this.scrollToTop()
        if (direction === 'done') {
            this.donePressed()
        }
        if (direction === 'cancelled') {
            // Cancel Pressed
        }
    }

    donePressed = () => {

        // Create And Save Style Here first


        if (this._mounted) {
            let ns = { ...this.state }
            ns.isComplete = true
            this.setState(ns)


            const frontImageObj = this.frontOpts.filter((obj) => {
                return obj.key === this.state.selected_Front
            })
            const frontImage = frontImageObj[0].imageSrc
            const fabricImage = this.fabricImageForSelectedKey(this.state.selected_Tone, this.state.selected_Fabric)

            let uniqId = 'id' + (new Date()).getTime();

            if (this.state.editingStyle) {
                uniqId = this.state.editingStyle.key
            }


            let style = {
                front: this.state.selected_Front,
                tone: this.state.selected_Tone,
                fabric: this.state.selected_Fabric,
                buttonDesign: this.state.selected_ButtonDesign,
                collar: this.state.selected_Collar,
                back: this.state.selected_Back,
                pantStyle: this.state.selected_PantStyle,
                fitting: this.state.selected_Fitting
            }

            let styleArr = Object.keys(style).map(function (key) {
                return [key, style[key]];
            });

            let withoutNull = styleArr.filter(ar => {
                return !ar.includes(null)
            })

            let description = ' '
            withoutNull.forEach(ms => {
                let type = ms[0]
                let val = ms[1]
                description += ' ' + type + ':' + val + '.'
            })

            let styleObj = {
                key: uniqId,
                productType: this.PRODUCT_TYPE.suit,
                styleName: this.state.selected_StyleName,
                frontImage: frontImage,
                fabricImage: fabricImage,
                description: description,
                type: 'Created',
                style: style
            }

            console.log('Sending Style Object -', styleObj)

            this.props.isDone('created', styleObj)

        }
    }

    stepItemSelected = (stepKey, itemKey) => {
        console.log('Step selection happend in StepKey =' + stepKey + ', ItemKey= ', itemKey)

        if (this._mounted) {
            console.log('IS MOUNTED')
            let ns = { ...this.state }
            switch (stepKey) {
                case this.STEP_KEYS.productType: ns.selected_Product = itemKey; break;
                case this.STEP_KEYS.frontType: ns.selected_Front = itemKey; break;
                case this.STEP_KEYS.tone: ns.selected_Tone = itemKey; break;
                case this.STEP_KEYS.fabric: ns.selected_Fabric = itemKey; break;
                case this.STEP_KEYS.buttonDesign: ns.selected_ButtonDesign = itemKey; break;
                case this.STEP_KEYS.collar: ns.selected_Collar = itemKey; break;
                case this.STEP_KEYS.back: ns.selected_Back = itemKey; break;
                case this.STEP_KEYS.pant: ns.selected_PantStyle = itemKey; break;
                case this.STEP_KEYS.fitness: ns.selected_Fitting = itemKey; break;

                default: break;
            }
            this.setState(ns)
        }

    }

    createUploadChoiceMade = (name, code) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.selectedCreateOrUpload = code
            this.setState(ns)
        }
    }

    createUploadChooser = () => {
        // return <div> <Box className='is-radiusless is-shadowless layerGreyBG  minHeight300 myFlexContainer '>
        return <div> <Box className='is-radiusless is-shadowless  minHeight300 myFlexContainer '>
            <ChooseBox choices={[{ name: 'Create Style', code: this.STYLE_START.create }, { name: 'Upload Style', code: this.STYLE_START.upload }]} choiceMade={this.createUploadChoiceMade} />
            {/* <ChooseBox choices={[{ name: 'Choose Style' }, { name: 'Upload Style' }]} choiceMade={this.choiceMadeInChooseBox} /> */}
        </Box>
            <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button>
        </div>
    }

    productChoiceMade = (name, code) => {
        console.log('Product Choice Made. Code =', code)
        if (this._mounted) {
            let ns = { ...this.state }
            ns.selectedProduct = code
            ns.productType = code
            this.setState(ns)
        }
    }

    productChooser = () => {
        return <div>
            <Button onClick={this.props.cancelPressed} className='delete type50 is-small has-text-light'></Button>

            {/* <Box className='is-radiusless is-shadowless layerGreyBG  minHeight300 myFlexContainer '> */}
            <Box className='is-radiusless is-shadowless   myFlexContainer '>
                <ChooseBox choices={[
                    { name: 'Suit (demo)', code: PRODUCT_TYPE.suit },
                    // { name: 'Blazer', code: PRODUCT_TYPE.blazer },
                    // { name: 'Jacket', code: PRODUCT_TYPE.jacket },
                    { name: 'Shirt', code: PRODUCT_TYPE.shirt },
                    { name: 'Pant', code: PRODUCT_TYPE.pant },
                    { name: 'Blazer', code: PRODUCT_TYPE.blazer },
                ]} choiceMade={this.productChoiceMade} />
            </Box>
        </div>
    }

    uploadImageStarted = () => {
        console.log('UPLOAD IMAGE STARTED IN WIZARD')
    }


    blazerWizard = () => {
        return <h2>Blazer Wizard</h2>
    }

    jacketWizard = () => {
        return <h2>Jacket Wizard</h2>

    }

    shirtWizard = () => {
        return <h2>Shirt Wizard</h2>

    }

    pantWizard = () => {
        return <h2>Pant Wizard</h2>

    }

    shoeWizard = () => {
        return <h2>Shoe Wizard</h2>

    }


        // productOpts = [
    //     { key: PRODUCT_TYPE.suit , name: 'Suit', imageSrc: null},
    //     { key: PRODUCT_TYPE.shirt, name: 'Shirt', imageSrc: null },
    //     { key: PRODUCT_TYPE.pant, name: 'Pant', imageSrc: null },
    //     { key: PRODUCT_TYPE.shoe, name: 'Shoe', imageSrc: null },
    // ]


    suitWizard = (showUploader = false) => {

        let productStep = this.productStep()
        let frontStep = this.frontStep()
        let toneStep = this.toneStep()
        let fabricStep = this.fabricStep()
        let buttonStep = this.buttonStep()
        let collarStep = this.collarStep()
        let backStep = this.backStep()
        let pantStep = this.pantStep()
        let fitStep = this.fitStep()

        // console.log('Name =',frontStep.name)
        // console.log('Key =',frontStep.key)
        // console.log('Opts =',frontStep.opts)

        // createUploadChooser

        let title = "Let's Create a Style"
        let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
        let points = [
            'To make it easy we pick the cuts and tones before finding the fabric.',
            'We will measure you right after we have created all the needed styles.'
        ]

        return (<div >
            {/* <div ref={this.mainStepperDiv}></div> */}
            <Button onClick={this.props.cancelPressed} className='delete type50 is-small has-text-light'></Button>
            {(this.state.isComplete || this.state.isCancelled) ?
                <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
                :
                <StepWizard className=' fadeIn'>
                    {/* {showUploader ? <Step type='uploader' skipable={true} isFirst={true} existingUploadImage={this.state.uploadImage} uploadImagePressed={this.uploadImageStarted} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  ></Step> : null} */}
                   {this.state.editingStyle ? null : <Step name="Welcome" imageSrc={imageNamed('IconBg-Style')} overrideNext='Start' isMessageScreen={true} title={title} subtitle={subtitle} points={points} isFirst={true} skipable={false} stepKey={frontStep.key} exitingSelectedKey={null} opts={null} isPhotoBox={false} photoRatio={'16by9'} onChange={this.onChangeHandler} itemSelected={null}  ></Step> }  
                    {/* <Step name={productStep.name} boldText={true} skipable={false} stepKey={productStep.key} exitingSelectedKey={this.state.selected_Product} opts={productStep.opts} isFirst={false} isPhotoBox={productStep.isPhotoBox} photoRatio={productStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  ></Step> */}
                    <Step name={frontStep.name} skipable={false} stepKey={frontStep.key} exitingSelectedKey={this.state.selected_Front} opts={frontStep.opts} isFirst={this.state.editingStyle} isPhotoBox={frontStep.isPhotoBox} photoRatio={frontStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  ></Step>
                    <Step name={toneStep.name} skipable={false} stepKey={toneStep.key} exitingSelectedKey={this.state.selected_Tone} opts={toneStep.opts} isPhotoBox={toneStep.isPhotoBox} photoRatio={toneStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} ></Step>
                    <Step name={fabricStep.name} isFabric={true} skipable={false} stepKey={fabricStep.key} exitingSelectedKey={this.state.selected_Fabric} opts={fabricStep.opts} isPhotoBox={fabricStep.isPhotoBox} photoRatio={fabricStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} ></Step>
                    <Step name={buttonStep.name} skipable={false} stepKey={buttonStep.key} exitingSelectedKey={this.state.selected_ButtonDesign} opts={buttonStep.opts} paddingLess={false} isPhotoBox={buttonStep.isPhotoBox} photoRatio={buttonStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} ></Step>
                    <Step name={collarStep.name} skipable={false} stepKey={collarStep.key} exitingSelectedKey={this.state.selected_Collar} opts={collarStep.opts} paddingLess={false} isPhotoBox={collarStep.isPhotoBox} photoRatio={collarStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} ></Step>
                    <Step name={backStep.name} skipable={false} stepKey={backStep.key} exitingSelectedKey={this.state.selected_Back} opts={backStep.opts} paddingLess={false} isPhotoBox={backStep.isPhotoBox} photoRatio={backStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} ></Step>
                    <Step name={pantStep.name} skipable={false} stepKey={pantStep.key} exitingSelectedKey={this.state.selected_PantStyle} opts={pantStep.opts} isLast={false} paddingLess={false} isPhotoBox={pantStep.isPhotoBox} photoRatio={pantStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} ></Step>
                    <Step name={fitStep.name} boldText={true} skipable={false} stepKey={fitStep.key} exitingSelectedKey={this.state.selected_Fitting} opts={fitStep.opts} isLast={true} paddingLess={false} isPhotoBox={fitStep.isPhotoBox} photoRatio={fitStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} ></Step>
                </StepWizard>
            }
            {/* <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button> */}
        </div>
        )
    }


    currentScreen = () => {

        if (this.state.editingStyle) {
            switch (this.state.productType) {
                case PRODUCT_TYPE.suit: return this.suitWizard(false);
                case PRODUCT_TYPE.blazer: return this.blazerWizard(false);
                case PRODUCT_TYPE.jacket: return this.jacketWizard(false);
                case PRODUCT_TYPE.shirt: return this.shirtWizard(false);
                case PRODUCT_TYPE.pant: return this.pantWizard(false);
                case PRODUCT_TYPE.shoe: return this.shoeWizard(false);
                default: break;
            }
        }


        if (this.state.selectedProduct) {

            switch (this.state.selectedProduct) {
                case PRODUCT_TYPE.suit: return this.suitWizard(false);
                // case PRODUCT_TYPE.blazer: return this.blazerWizard(false);
                // case PRODUCT_TYPE.jacket: return this.jacketWizard(false);
                // case PRODUCT_TYPE.shirt: return this.shirtWizard(false);
                // case PRODUCT_TYPE.pant: return this.pantWizard(false);
                // case PRODUCT_TYPE.shoe: return this.shoeWizard(false);
                default: break;
            }

            // if (this.state.selectedProduct === this.STYLE_START.suit) {
            //     return this.suitWizard()
            // } else if (this.state.selectedCreateOrUpload === this.STYLE_START.upload) {
            //     return this.suitWizard(true)
            // }
        }

        return this.productChooser()

        // if (this.state.selectedCreateOrUpload) {
        //     if (this.state.selectedCreateOrUpload === this.STYLE_START.create) {
        //         return this.suitWizard()
        //     } else if (this.state.selectedCreateOrUpload === this.STYLE_START.upload) {
        //         return this.suitWizard(true)
        //     }
        //     // SELECTION CASE REMAINING
        // }

        //SKiping Wizard Chooser Currently
        // return this.createUploadChooser()
    }

    render() {
        return (
            this.currentScreen()
        )
    }

}


export default StyleChooseWizard
