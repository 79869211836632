import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faCheck, faArrowRight, faArrowAltCircleRight, faArrowCircleDown, faForward, faPlus, faPlusCircle, faPlusSquare, faPhone, faPhoneAlt, faSms, faMailBulk, faEnvelope, faSurprise, faSmileBeam, faSmile, faRibbon, faRainbow, faHatCowboy, faAirFreshener, faBirthdayCake, faHatCowboySide } from '@fortawesome/free-solid-svg-icons'

import LoadingAux from './LoadingAux'
import ColumnAux from './ColumnAux'
import ChooseBox from './ChooseBox'
import StyleChooserWizard from './StyleChooserWizard'
import MeasurementsWizard from './MeasurementsWizard'
import StyleBox from './StyleBox'
import StyleCard from './StyleCard'
// import OrderBox from './OrderBox'
import CustomerBox from './CustomerBox'

import OrderModal from './OrderModal'
import CustomerModal from './CustomerModal'



import ModalFullPage from './ModalFullPage'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal, Tag, Pagination, Dropdown
} from 'react-bulma-components/dist'

import { Customer, Product } from '../utilities/classes'
import { imageNamed, PRODUCT_TYPE, uuidv4 } from '../utilities/utils'
import cogoToast from 'cogo-toast'
import { getCurrentAdmin, saveCurrentAdmin, removeCurrentAdmin } from '../utilities/Login'



const AdminOrders = (props) => {

    const CustomerFetchType = {
        all: 'All',
        search: 'search'
    }

    const displayLimit = 8


    const [customers, setCustomers] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [fetching, setFetching] = useState(true)
    const [noEntry, setNoEntry] = useState(false)

    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [searchValue, setSearchValue] = useState("")

    const [currentTypeTab, setCurrentTypeTab] = useState(CustomerFetchType.all)



    useEffect(() => {
        // cogoToast.loading('Loading Customers');
        Customer.getRecentCustomers("", "", (succ, customs, errMsg) => {
            setFetching(false)
            if (succ) {
                setCustomers(customs)

                console.log(customs)

                console.log('TOTAL Customers = ', customs.length)

                if (customs.length === 0) {
                    setNoEntry(true)
                }
            } else {
                cogoToast.error('Could not fetch customers.')
                console.log(errMsg)
            }

            console.log('USE EFFECT IS EFFECTING')

        })
    }, [])



    const refetchCustomers = (email, phone) => {

        if (currentTypeTab === CustomerFetchType.search && email === "" && phone === "") {
            return
        }

        setCustomers([])
        setFetching(true)
        Customer.getRecentCustomers(email ?? "", phone ?? "", (succ, customs, errMsg) => {
            setFetching(false)
            if (succ) {
                setCustomers(customs)

                // console.log(customs)

                // console.log('TOTAL customers = ', customs.length)

                if (customs.length === 0) {
                    setNoEntry(true)
                }
            } else {
                cogoToast.error('Could not fetch customers.')
                console.log(errMsg)
            }
        })

    }

    const paginationChanged = (e) => {
        console.log('PAGINATION CHANGED')
        console.log(e)
        setCurrentPage(e)
    }



    const closeSelectedEntities = () => {
        setSelectedCustomer(null)
    }


    const viewOrderPressed = (customer) => {
        setSelectedCustomer(customer)
    }

    const ordersForThisPage = (page) => {

        console.log('PAGE = ', page)

        let fromIndex = displayLimit * (page - 1)
        let toIndex = displayLimit

        console.log(`Check. OrdersLength ${customers.length} >=  FromIndex ${fromIndex} + ${displayLimit}`)

        if (customers.length >= (fromIndex + displayLimit)) {

            toIndex = fromIndex + displayLimit
        } else {
            toIndex = null
        }

        console.log(`From Index = ${fromIndex}`)
        console.log(`To Index = ${toIndex}`)



        // console.log(`SLICING = (${skip},${displayLimit}) `)

        let allCusts = [...customers]
        let theseCusts = allCusts.slice(fromIndex, toIndex)

        if (toIndex === null) {
            theseCusts = allCusts.slice(fromIndex)
        }

        console.log(`THESE CUSTOMERS `)
        console.log(theseCusts)

        return theseCusts
    }

    const orderList = () => {
        console.log('rerendering Customer list')
        return (
            <ColumnAux className=' fadeIn' isMiddle={true} size={6}>
                <br />
                { noEntry ? <Heading size={6} className='has-text-centered has-text-grey-light'> - </Heading> : null}
                {/* <h4 className="has-text-centered"> Awaiting Customer Confirmation.. </h4> */}
                <LoadingAux isLoading={fetching} loaderHeight={400} size={'medium'} isMain={false} >
                    {
                        ordersForThisPage(currentPage).map((ord) => {
                            return <CustomerBox key={ord.objectId} customer={ord} viewPressed={viewOrderPressed} />
                        })
                    }
                </LoadingAux>
                {
                    <Pagination className='is-small' current={currentPage} total={Math.ceil(customers.length / displayLimit)} delta={2} autoHide={true} onChange={paginationChanged} />
                }
            </ColumnAux>
        )
    }


    const changeTypeTab = (val) => {
        setCurrentTypeTab(val)

        if (val === CustomerFetchType.all && currentTypeTab !== CustomerFetchType.all) {
            refetchCustomers()
        }

        if (val === CustomerFetchType.search && currentTypeTab !== CustomerFetchType.search) {
            setCustomers([])
            // refetchCustomers(null,searchValue)
        }


    }

    const mainTabs = () => {
        return (<div className=' fadeIn '>
            <Tabs align='centered' type='toggle' className='is-mobile is-small    '>
                <Tabs.Tab active={currentTypeTab === CustomerFetchType.all} onClick={() => changeTypeTab(CustomerFetchType.all)}>
                    Recent
                </Tabs.Tab>
                <Tabs.Tab active={currentTypeTab === CustomerFetchType.search} onClick={() => changeTypeTab(CustomerFetchType.search)}>
                    Search
                </Tabs.Tab>
            </Tabs>
        </div>)
    }


    const orderUpdated = (updatedOrder) => {
        let ords = [...customers]
        let objIndex = ords.findIndex(obj =>
            obj.objectId === updatedOrder.objectId
        )
        ords[objIndex] = updatedOrder
        setCustomers(ords)
    }


    const orderModal = () => {
        return <CustomerModal customer={selectedCustomer} closed={closeSelectedEntities} />
        // return <OrderModal customer={selectedCustomer} closed={closeSelectedEntities} orderUpdated={orderUpdated} isAdmin={true} />
    }

    const searchOrderNoChanged = (e) => {
        setSearchValue(e.target.value)
    }

    const searchBox = () => {
        return (
            <ColumnAux className=' fadeIn' isMiddle={true} size={4}>
                <br />
                <Box className=" marginTop20 has-background-grey-lighter">
                    <div className='field'>
                        <label className="label type60 marginTop10" >Customer Email</label>
                        <input name='orderNo' type='input' className='input has-text-weight-medium type65' value={searchValue} onChange={(e) => { searchOrderNoChanged(e) }} />
                    </div>

                    <div className='control is-expanded marginTop20'>
                        <Button disabled={fetching} loading={fetching} className='button is-black is-fullwidth marginTop10' onClick={() => { refetchCustomers(searchValue, null) }} > Search </Button>
                    </div>
                </Box>
            </ColumnAux>
        )
    }

    const thisPage = () => {
        return (
            <div className="pad20">
                { selectedCustomer ? orderModal() : null}

                {mainTabs()}
                {currentTypeTab === CustomerFetchType.search ? searchBox() : null}
                {orderList()}

            </div>
        )
    }




    return thisPage()

}

export default AdminOrders