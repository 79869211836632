
import {PRODUCT_TYPE} from '../utilities/utils'

//____CHANGE PROCESS______

//1. CHANGE LOGO 
// EVERYWHERE THERE IS assets/logoTailorwise.svg imported. Change to clientLogo or clientLogoNav or clientLogoMenu. Upload these first.

//2. CHANGE BACKEND APPID, JSKEY & RESTKEY 
// Change APPID, JSKEY & RESTKEY in .env.development & .env.production

//3. CHANGE STRIPE ACCOUNT ID 
// Change REACT_APP_STRIPE_PUBLISHABLE_KEY in .env.development & .env.production

//4. IF PAYPAL . CHANGE IMPORT LINE in /public/index.html 
// Change   <script src="https://www.paypal.com/sdk/js?client-id=<CLIENT-KEY>&components=buttons&currency=<CURRENCY-3LETTER-CODE DEFAULT USD>"></script>

//5. CHANGE CONFIG HERE & in WEBSHOP
// Change isOpenWithoutSignIn and hasShop Here.

//6. VALIDATE DOMAIN FOR EMAILS
// Validate client domain on Sendgrid to make sure emails work as expected.

//7. Upload & copy Assets on AWS
// Make folder for client, copy measurement images & videos from existing folders into it. And upload clients logo. Input urls underneath.

//8. Change All the info undersneath


export const hasShop = false

export const defaultStoreObjid = "FC237G0HJG"  // Main Store
export const defaultUserObjid = "hywLGNUCi6"  // ROY


export const availableProducts = [
    PRODUCT_TYPE.suit, 
    PRODUCT_TYPE.jacket, 
    PRODUCT_TYPE.overCoat, 
    PRODUCT_TYPE.waistCoat, 
    PRODUCT_TYPE.shirt, 
    PRODUCT_TYPE.pant, 
    PRODUCT_TYPE.tuxedo
]

// STRIPE
const REACT_APP_STRIPE_PUBLISHABLE_KEY= "pk_test_PdGMAl4hboCdCwJXrOmT9Ky900p4ztI4OW" // USING APPSQUARE ACCOUNT

// REAL TW-Dionicios Closet
const REACT_APP_BACKEND_APPID= "QMMOmnoNYjYx1yCcmow5RXB47bkLfyKIRJ05gClb"
const REACT_APP_BACKEND_JSKEY= "qsTecWF00MlJWev9qooaieJFLEMIGJmcxAkgMjUd"
const REACT_APP_BACKEND_RESTKEY= "sM0X7bGwWidLERSTemfdGEFDUVD7pjoLQbEUHHAX"

const homepage = "https://dionicioscloset.tailorwise.com"


export const standardDeliveryTime = "Standard Delivery Time of 4-5 weeks"
export const freeShippingThreshold = 300
export const standardPaidShippingPrice = 35

// https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/xavirow/StyleSelections/WaistCoat/Bottom/POINTED.jpg

// https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/dionicious/fabrics/MonogramThread/1017.jpg

export const awsBaseUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/dionicious/"
export const commonAwsBaseUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/common/"
export const clientName = "Dionicious Closet"

export const clientPhone = "" // missing
export const clientEmail = "info@dionicioscloset.com"
export const clientEmailFromForOrder = "no-reply@tailorwise.com"

// export const clientEmail = "appsquare.in@gmail.com"
// export const clientEmailFromForOrder = "appsquare.in@gmail.com"



export const clientWebsite = "https://dionicioscloset.com/"
export const clientTermsAndConditionsUrl = "https://dionicioscloset.com/terms/"

export const clientLogoUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/Dionicios+Closet/dionicios-140x30.png"
export const currencySymbol = "$"
export const currencyCode = "USD"
export const clientLogoNav = ""
export const clientLogoHero = ""
export const clientBGHome = ""
export const clientBGHero = ""


