/* eslint-disable */

import React, { Component } from 'react'
import StepWizard from 'react-step-wizard'
import Step from '../containers/Step'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal
} from 'react-bulma-components/dist'

import ColumnAux from '../containers/ColumnAux'
import LoadingAux from '../containers/LoadingAux'
import ChooseBox from '../containers/ChooseBox'

import { imageNamed, webImageNamed, PRODUCT_TYPE, makeid } from '../utilities/utils'
import { C_Product, Product } from '../utilities/classes'
import { currencySymbol } from './clientInfo'








// console.log('Images Folder =', allImages)
// console.log('Images with ````` =', imageNamed('`````'))



class WizardForOverCoat extends Component {
   
    basePrice = 0

    STEP_KEYS = {
        productType: '',
        frontButtons: 'FRONT BUTTONS',
        lapel: 'LAPEL',
        // lapelWidth: 'LAPEL WIDTH',
        lowerPockets: 'LOWER POCKETS',
        vent: 'VENT',
        sleeveButtons: 'SLEEVE BUTTONS',
        sleeveButtonOrientation: 'SLEEVE BUTTON ORIENTATION',
        sleeveButtonHoleContrast: 'SLEEVE BUTTON HOLE CONTRAST', // NEW
        sleeveButtonHoleContrastThread: 'SLEEVE BUTTON HOLE CONTRAST_THREAD',  // NEW (conditional) - dont show on first
        lining: 'LINING',
        monogram: 'MONOGRAM',
        monogramThreadColor: 'MONOGRAM THREAD COLOR', // NEW (conditional) - dont show on no monogram
        tone: 'TONE',
        fabric: 'FABRIC',
        fabricLining: 'FABRIC LINING', //NEW
        suitButtons: 'BUTTONS', //NEW
        contrastOption: 'CONTRAST OPTION', // NEW (add pricing)
        contrastFabric: 'CONTRAST FABRIC' // NEW (conditional)
    }

    TONE_KEYS = {
        AllSeasons: 'All Seasons',
        FallWinter: 'Fall Winter',
        WaterResistant: 'Water Resistant',
    }

    STYLE_START = {
        create: 'Create Style',
        upload: 'Upload Style',
        select: 'Select Style'
    }



    // selected_FrontButtons : frontButtons
    // selected_Lapel : lapel
    // selected_LapelWidth : lapelWidth
    // selected_LowerPockets : lowerPockets
    // selected_Vent : vent
    // selected_SleeveButtons : sleeveButtons
    // selected_Lining : lining
    // selected_Monogram : monogram
    // selected_Tone : tone
    // selected_Fabric : fabric



    state = {
        uploadImage: null,
        
        selected_FrontButtons: this.props.editingProduct ? this.props.editingProduct.selectedSelections.frontButtons : null,
        selected_Lapel: this.props.editingProduct ? this.props.editingProduct.selectedSelections.lapel : null,
        // selected_LapelWidth: this.props.editingProduct ? this.props.editingProduct.selectedSelections.lapelWidth : null,
        selected_LowerPockets: this.props.editingProduct ? this.props.editingProduct.selectedSelections.lowerPockets : null,
        selected_Vent: this.props.editingProduct ? this.props.editingProduct.selectedSelections.vent : null,
        selected_SleeveButtons: this.props.editingProduct ? this.props.editingProduct.selectedSelections.sleeveButtons : null,
        selected_SleeveButtonOrientation: this.props.sleeveButtonOrientation ? this.props.editingProduct.selectedSelections.sleeveButtonOrientation : null,
        selected_SleeveButtonHoleContrast: this.props.sleeveButtonHoleContrast ? this.props.editingProduct.selectedSelections.sleeveButtonHoleContrast : null,
        selected_SleeveButtonHoleContrastThread: this.props.sleeveButtonHoleContrastThread ? this.props.editingProduct.selectedSelections.sleeveButtonHoleContrastThread : null,
        selected_Lining: this.props.editingProduct ? this.props.editingProduct.selectedSelections.lining : null,
        selected_Monogram: this.props.editingProduct ? this.props.editingProduct.selectedSelections.monogram : null,
        selected_MonogramThreadColor: this.props.editingProduct ? this.props.editingProduct.selectedSelections.monogramThreadColor : null,

        selected_Tone: this.props.editingProduct ? this.props.editingProduct.selectedSelections.tone : null,
        selected_Fabric: this.props.editingProduct ? this.props.editingProduct.selectedSelections.fabric : null,

        selected_FabricLining: this.props.editingProduct ? this.props.editingProduct.selectedSelections.fabricLining : null,
        selected_SuitButtons: this.props.editingProduct ? this.props.editingProduct.selectedSelections.selected_SuitButtons : null,

        selected_ContrastOption: this.props.contrastOption ? this.props.editingProduct.selectedSelections.contrastOption : null,
        selected_ContrastFabric: this.props.editingProduct ? this.props.editingProduct.selectedSelections.contrastFabric : null,

        isComplete: false,
        isCancelled: false,
        selectedCreateOrUpload: this.STYLE_START.create,
        editingProduct: this.props.editingProduct ?? null,
        productType: PRODUCT_TYPE.jacket
    }



    _mounted = false


    componentDidMount = () => {
        this._mounted = true

        let ns = { ...this.state }
        if (this.props.editingProduct) {
            ns.editingProduct = this.props.editingProduct
        }
        if (this.props.productType) {
            ns.productType = this.props.productType
        }

        // if (this.props.editingProduct) {
        //     let existingStyle = { ...this.props.editingProduct }
        //     ns.editingProduct = existingStyle
        //     // console.log("EDITING WIZRAD OF JACKET")
        //     // console.log(existingStyle)

        //     ns.selected_FrontButtons = existingStyle.selectedSelections.frontButtons
        //     ns.selected_Lapel = existingStyle.selectedSelections.lapel
        //     ns.selected_LapelWidth = existingStyle.selectedSelections.lapelWidth
        //     ns.selected_LowerPockets = existingStyle.selectedSelections.lowerPockets
        //     ns.selected_Vent = existingStyle.selectedSelections.vent
        //     ns.selected_SleeveButtons = existingStyle.selectedSelections.sleeveButtons
        //     ns.selected_Lining = existingStyle.selectedSelections.lining
        //     ns.selected_Monogram = existingStyle.selectedSelections.monogram
        //     ns.selected_Tone = existingStyle.selectedSelections.tone
        //     ns.selected_Fabric = existingStyle.selectedSelections.fabric

        // }


        this.setState(ns)

    }

    componentWillUnmount = () => {
        this._mounted = false
    }




        // selected_FrontButtons : frontButtons
    // selected_Lapel : lapel
    // selected_LapelWidth : lapelWidth
    // selected_LowerPockets : lowerPockets
    // selected_Vent : vent
    // selected_SleeveButtons : sleeveButtons
    // selected_Lining : lining
    // selected_Monogram : monogram
    // selected_Tone : tone
    // selected_Fabric : fabric


    // FRONT        https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/xavirow/StyleSelections/Overcoat/frontbuttoning/2BUTTON.jpg
    frontButtons_Opts = [
        { optType:"FRONT BUTTON", styleSelectionId: "", key: 'TWO BUTTON', name: 'TWO BUTTON', imageSrc: webImageNamed('StyleSelections/Overcoat/frontbuttoning/2BUTTON.jpg') },
        { optType:"FRONT BUTTON", styleSelectionId: "", key: 'THREE BUTTON', name: 'THREE BUTTON', imageSrc: webImageNamed('StyleSelections/Overcoat/frontbuttoning/3BUTTON.jpg') },
        { optType:"FRONT BUTTON", styleSelectionId: "", key: 'FOUR BUTTON', name: 'FOUR BUTTON', imageSrc: webImageNamed('StyleSelections/Overcoat/frontbuttoning/4BUTTON.jpg') },
        { optType:"FRONT BUTTON", styleSelectionId: "", key: 'FOUR BUTTON WITH TWO FASTEN', name: 'FOUR BUTTON WITH TWO FASTEN', imageSrc: webImageNamed('StyleSelections/Overcoat/frontbuttoning/4BUTTONwith2FASTEN.jpg') },
        { optType:"FRONT BUTTON", styleSelectionId: "", key: 'SIX BUTTON WITH TWO FASTEN', name: 'SIX BUTTON WITH TWO FASTEN', imageSrc: webImageNamed('StyleSelections/Overcoat/frontbuttoning/6BUTTONwith2FASTEN.jpg') },
        { optType:"FRONT BUTTON", styleSelectionId: "", key: 'SIX BUTTON WITH THREE FASTEN', name: 'SIX BUTTON WITH THREE FASTEN', imageSrc: webImageNamed('StyleSelections/Overcoat/frontbuttoning/6BUTTONwith3FASTEN.jpg') },
    ]
    frontButtons_Step = () => {
        return {
            name: 'How would you like the front buttons?',
            key: this.STEP_KEYS.frontButtons,
            opts: this.frontButtons_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // LAPEL    https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/xavirow/StyleSelections/Overcoat/lapel/PEAK.jpg
    lapel_Opts = [
        {optType:"LAPEL", styleSelectionId: "", key: 'PEAK', name: 'PEAK', imageSrc: webImageNamed('StyleSelections/Overcoat/lapel/PEAK.jpg') },
        {optType:"LAPEL", styleSelectionId: "", key: 'NOTCH', name: 'NOTCH', imageSrc: webImageNamed('StyleSelections/Overcoat/lapel/NOTCH.jpg') },
        {optType:"Lapel", styleSelectionId: "", key: 'SHAWL', name: 'SHAWL', imageSrc: webImageNamed('StyleSelections/Overcoat/lapel/SHAWL.jpg') },
    ]
    lapel_Step = () => {
        return {
            name: 'How would you like the Lapel?',
            key: this.STEP_KEYS.lapel,
            opts: this.lapel_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }
    

    // LAPEL WIDTH, ["OVER COAT"], ["NARROW – 5.5CM", "STANDARD", "WIDE – 10CM"]
    // LAPEL WIDTH
    // lapelWidth_Opts = [
    //     {optType:"LAPEL WIDTH", styleSelectionId: "", key: 'NARROW', name: 'NARROW – 5.5CM', imageSrc: webImageNamed('NARROW') },
    //     {optType:"LAPEL WIDTH", styleSelectionId: "", key: 'STANDARD', name: 'STANDARD', imageSrc: webImageNamed('STANDARD') },
    //     {optType:"LAPEL WIDTH", styleSelectionId: "", key: 'WIDE', name: 'WIDE – 10CM', imageSrc: webImageNamed('WIDE') },
    // ]
    // lapelWidth_Step = () => {
    //     return {
    //         name: 'Width of the Lapel',
    //         key: this.STEP_KEYS.lapelWidth,
    //         opts: this.lapelWidth_Opts,
    //         selectedOptKey: null,
    //         isPhotoBox: true,
    //         photoRatio: '1by1',
    //         skipable: false,
    //         paddingLess: false,
    //         boldText: false
    //     }
    // }


    // LOWER POCKETS       concierge/xavirow/StyleSelections/Overcoat/lowerpockets/STRAIGHTwithFLAPS.jpg
    lowerPockets_Opts = [
        {optType:"LOWER POCKETS", styleSelectionId: "", key: 'STRAIGHT WITH FLAPS', name: 'STRAIGHT WITH FLAPS', imageSrc: webImageNamed('StyleSelections/Overcoat/lowerpockets/STRAIGHTwithFLAPS.jpg') },
        {optType:"LOWER POCKETS", styleSelectionId: "", key: 'STRAIGHT WITH TICKET POCKET (STANDARD)', name: 'STRAIGHT WITH TICKET POCKET (STANDARD)', imageSrc: webImageNamed('StyleSelections/Overcoat/lowerpockets/STRAIGHTFLAPwithTICKETPOCKET.jpg') },
        {optType:"LOWER POCKETS", styleSelectionId: "", key: 'SLANTED BESOM FLAPS', name: 'SLANTED BESOM FLAPS', imageSrc: webImageNamed('StyleSelections/Overcoat/lowerpockets/SLANTEDbesomPOCKET.jpg') },
    ]
    lowerPockets_Step = () => {
        return {
            name: 'And Lower Pockets',
            key: this.STEP_KEYS.lowerPockets,
            opts: this.lowerPockets_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // VENT     concierge/xavirow/StyleSelections/Overcoat/vent/NOVENT.jpg
    vent_Opts = [
        {optType:"VENT", styleSelectionId: "", key: 'SINGLE', name: 'SINGLE', imageSrc: webImageNamed('StyleSelections/Overcoat/vent/SINGLE.jpg') },
        {optType:"VENT", styleSelectionId: "", key: 'NO VENT', name: 'NO VENT', imageSrc: webImageNamed('StyleSelections/Overcoat/vent/NOVENT.jpg')  },
    ]
    vent_Step = () => {
        return {
            name: 'Choose Vents',
            key: this.STEP_KEYS.vent,
            opts: this.vent_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // SLEEVE BUTTONS   concierge/xavirow/StyleSelections/Overcoat/sleevebuttons/2BUTTON.jpg
    sleeveButtons_Opts = [
        {optType:"SLEEVE BUTTONS", styleSelectionId: "", key: 'TWO', name: 'TWO', imageSrc: webImageNamed('StyleSelections/Overcoat/sleevebuttons/2BUTTON.jpg') },
        {optType:"SLEEVE BUTTONS", styleSelectionId: "", key: 'THREE', name: 'THREE', imageSrc: webImageNamed('StyleSelections/Overcoat/sleevebuttons/3BUTTON.jpg') },
        {optType:"SLEEVE BUTTONS", styleSelectionId: "", key: 'FOUR', name: 'FOUR', imageSrc: webImageNamed('StyleSelections/Overcoat/sleevebuttons/4BUTTON.jpg') },
        // {optType:"SLEEVE BUTTONS", styleSelectionId: "", key: 'CUFF', name: 'CUFF', imageSrc: webImageNamed('overcoat/sleevebuttons/CUFF.jpg') }
    ]
    sleeveButtons_Step = () => {
        return {
            name: 'How many Sleeve Buttons are good',
            key: this.STEP_KEYS.sleeveButtons,
            opts: this.sleeveButtons_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    


      // SLEEVE BUTTON ORIENTATION
      sleeveButtonOrientation_Opts = [
        { optType: this.STEP_KEYS.sleeveButtonOrientation,  key: 'SLANTED', name: 'SLANTED', imageSrc: webImageNamed('StyleSelections/Suit/SleeveButtonOrientation/Slanted.jpg') },
        { optType: this.STEP_KEYS.sleeveButtonOrientation,  key: 'STRAIGHT', name: 'STRAIGHT', imageSrc: webImageNamed('StyleSelections/Suit/SleeveButtonOrientation/Straight.jpg') },
    ]
    sleeveButtonOrientation_Step = () => {
        return {
            name: 'Sleeve Button Orientation',
            key: this.STEP_KEYS.sleeveButtonOrientation,
            opts: this.sleeveButtonOrientation_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }




    // NEW ENTRY 
    sleeveButtonHoleContrast_Opts = [
        { optType: this.STEP_KEYS.sleeveButtonHoleContrast,  key: 'NO CONTRAST', name: 'NO CONTRAST', imageSrc: webImageNamed('StyleSelections/Suit/SleeveButtonHoleContrast/No+Contrast.jpg') },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrast,  key: 'ONE', name: 'ONE', imageSrc: webImageNamed('StyleSelections/Suit/SleeveButtonHoleContrast/ONE.jpg') },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrast,  key: 'TWO', name: 'TWO', imageSrc: webImageNamed('StyleSelections/Suit/SleeveButtonHoleContrast/TWO.jpg') },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrast,  key: 'ALL', name: 'ALL', imageSrc: webImageNamed('StyleSelections/Suit/SleeveButtonHoleContrast/All.jpg') },
    ]
    sleeveButtonHoleContrast_Step = () => {
        return {
            name: 'Sleeve Button Hole Contrast',
            key: this.STEP_KEYS.sleeveButtonHoleContrast,
            opts: this.sleeveButtonHoleContrast_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }
    
sleeveButtonHoleContrastThread_Opts = [
        // { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: '2187', name: '2187', imageSrc: webImageNamed('Threads/Buttonhole/2187.jpg'), price: null, company: '' },

        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1172', name: 'C1172', imageSrc: webImageNamed('Fabrics/SewingThread/C1172.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1202', name: 'C1202', imageSrc: webImageNamed('Fabrics/SewingThread/C1202.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1231', name: 'C1231', imageSrc: webImageNamed('Fabrics/SewingThread/C1231.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1257', name: 'C1257', imageSrc: webImageNamed('Fabrics/SewingThread/C1257.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1424', name: 'C1424', imageSrc: webImageNamed('Fabrics/SewingThread/C1424.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1740', name: 'C1740', imageSrc: webImageNamed('Fabrics/SewingThread/C1740.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C2376', name: 'C2376', imageSrc: webImageNamed('Fabrics/SewingThread/C2376.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C2427', name: 'C2427', imageSrc: webImageNamed('Fabrics/SewingThread/C2427.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C2740', name: 'C2740', imageSrc: webImageNamed('Fabrics/SewingThread/C2740.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3156', name: 'C3156', imageSrc: webImageNamed('Fabrics/SewingThread/C3156.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3166', name: 'C3166', imageSrc: webImageNamed('Fabrics/SewingThread/C3166.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3646', name: 'C3646', imageSrc: webImageNamed('Fabrics/SewingThread/C3646.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3853', name: 'C3853', imageSrc: webImageNamed('Fabrics/SewingThread/C3853.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3867', name: 'C3867', imageSrc: webImageNamed('Fabrics/SewingThread/C3867.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3952', name: 'C3952', imageSrc: webImageNamed('Fabrics/SewingThread/C3952.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3966', name: 'C3966', imageSrc: webImageNamed('Fabrics/SewingThread/C3966.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3993', name: 'C3993', imageSrc: webImageNamed('Fabrics/SewingThread/C3993.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4106', name: 'C4106', imageSrc: webImageNamed('Fabrics/SewingThread/C4106.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4300', name: 'C4300', imageSrc: webImageNamed('Fabrics/SewingThread/C4300.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4328', name: 'C4328', imageSrc: webImageNamed('Fabrics/SewingThread/C4328.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4351', name: 'C4351', imageSrc: webImageNamed('Fabrics/SewingThread/C4351.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4983', name: 'C4983', imageSrc: webImageNamed('Fabrics/SewingThread/C4983.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4987', name: 'C4987', imageSrc: webImageNamed('Fabrics/SewingThread/C4987.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5140', name: 'C5140', imageSrc: webImageNamed('Fabrics/SewingThread/C5140.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5229', name: 'C5229', imageSrc: webImageNamed('Fabrics/SewingThread/C5229.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5337', name: 'C5337', imageSrc: webImageNamed('Fabrics/SewingThread/C5337.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5345', name: 'C5345', imageSrc: webImageNamed('Fabrics/SewingThread/C5345.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5744', name: 'C5744', imageSrc: webImageNamed('Fabrics/SewingThread/C5744.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5943', name: 'C5943', imageSrc: webImageNamed('Fabrics/SewingThread/C5943.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5987', name: 'C5987', imageSrc: webImageNamed('Fabrics/SewingThread/C5987.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C6178', name: 'C6178', imageSrc: webImageNamed('Fabrics/SewingThread/C6178.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C6935', name: 'C6935', imageSrc: webImageNamed('Fabrics/SewingThread/C6935.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C7201', name: 'C7201', imageSrc: webImageNamed('Fabrics/SewingThread/C7201.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C7279', name: 'C7279', imageSrc: webImageNamed('Fabrics/SewingThread/C7279.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C7305', name: 'C7305', imageSrc: webImageNamed('Fabrics/SewingThread/C7305.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C7930', name: 'C7930', imageSrc: webImageNamed('Fabrics/SewingThread/C7930.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C7978', name: 'C7978', imageSrc: webImageNamed('Fabrics/SewingThread/C7978.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C8501', name: 'C8501', imageSrc: webImageNamed('Fabrics/SewingThread/C8501.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C8587', name: 'C8587', imageSrc: webImageNamed('Fabrics/SewingThread/C8587.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C8989', name: 'C8989', imageSrc: webImageNamed('Fabrics/SewingThread/C8989.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9114', name: 'C9114', imageSrc: webImageNamed('Fabrics/SewingThread/C9114.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9313', name: 'C9313', imageSrc: webImageNamed('Fabrics/SewingThread/C9313.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9623', name: 'C9623', imageSrc: webImageNamed('Fabrics/SewingThread/C9623.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9631', name: 'C9631', imageSrc: webImageNamed('Fabrics/SewingThread/C9631.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9666', name: 'C9666', imageSrc: webImageNamed('Fabrics/SewingThread/C9666.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9770', name: 'C9770', imageSrc: webImageNamed('Fabrics/SewingThread/C9770.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9913', name: 'C9913', imageSrc: webImageNamed('Fabrics/SewingThread/C9913.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9949', name: 'C9949', imageSrc: webImageNamed('Fabrics/SewingThread/C9949.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'WCG001', name: 'WCG001', imageSrc: webImageNamed('Fabrics/SewingThread/WCG001.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'WCG007', name: 'WCG007', imageSrc: webImageNamed('Fabrics/SewingThread/WCG007.jpg'), price: null, company: '' },
    ]
    
    // NEW ENTRY 
    sleeveButtonHoleContrastThread_Opts = [
        // { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: '2187', name: '2187', imageSrc: webImageNamed('Threads/Buttonhole/2187.jpg'), price: null, company: '' },

        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1172', name: 'C1172', imageSrc: webImageNamed('Fabrics/SewingThread/C1172.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1202', name: 'C1202', imageSrc: webImageNamed('Fabrics/SewingThread/C1202.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1231', name: 'C1231', imageSrc: webImageNamed('Fabrics/SewingThread/C1231.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1257', name: 'C1257', imageSrc: webImageNamed('Fabrics/SewingThread/C1257.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1424', name: 'C1424', imageSrc: webImageNamed('Fabrics/SewingThread/C1424.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C1740', name: 'C1740', imageSrc: webImageNamed('Fabrics/SewingThread/C1740.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C2376', name: 'C2376', imageSrc: webImageNamed('Fabrics/SewingThread/C2376.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C2427', name: 'C2427', imageSrc: webImageNamed('Fabrics/SewingThread/C2427.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C2740', name: 'C2740', imageSrc: webImageNamed('Fabrics/SewingThread/C2740.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3156', name: 'C3156', imageSrc: webImageNamed('Fabrics/SewingThread/C3156.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3166', name: 'C3166', imageSrc: webImageNamed('Fabrics/SewingThread/C3166.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3646', name: 'C3646', imageSrc: webImageNamed('Fabrics/SewingThread/C3646.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3853', name: 'C3853', imageSrc: webImageNamed('Fabrics/SewingThread/C3853.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3867', name: 'C3867', imageSrc: webImageNamed('Fabrics/SewingThread/C3867.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3952', name: 'C3952', imageSrc: webImageNamed('Fabrics/SewingThread/C3952.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3966', name: 'C3966', imageSrc: webImageNamed('Fabrics/SewingThread/C3966.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C3993', name: 'C3993', imageSrc: webImageNamed('Fabrics/SewingThread/C3993.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4106', name: 'C4106', imageSrc: webImageNamed('Fabrics/SewingThread/C4106.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4300', name: 'C4300', imageSrc: webImageNamed('Fabrics/SewingThread/C4300.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4328', name: 'C4328', imageSrc: webImageNamed('Fabrics/SewingThread/C4328.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4351', name: 'C4351', imageSrc: webImageNamed('Fabrics/SewingThread/C4351.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4983', name: 'C4983', imageSrc: webImageNamed('Fabrics/SewingThread/C4983.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C4987', name: 'C4987', imageSrc: webImageNamed('Fabrics/SewingThread/C4987.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5140', name: 'C5140', imageSrc: webImageNamed('Fabrics/SewingThread/C5140.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5229', name: 'C5229', imageSrc: webImageNamed('Fabrics/SewingThread/C5229.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5337', name: 'C5337', imageSrc: webImageNamed('Fabrics/SewingThread/C5337.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5345', name: 'C5345', imageSrc: webImageNamed('Fabrics/SewingThread/C5345.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5744', name: 'C5744', imageSrc: webImageNamed('Fabrics/SewingThread/C5744.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5943', name: 'C5943', imageSrc: webImageNamed('Fabrics/SewingThread/C5943.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C5987', name: 'C5987', imageSrc: webImageNamed('Fabrics/SewingThread/C5987.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C6178', name: 'C6178', imageSrc: webImageNamed('Fabrics/SewingThread/C6178.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C6935', name: 'C6935', imageSrc: webImageNamed('Fabrics/SewingThread/C6935.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C7201', name: 'C7201', imageSrc: webImageNamed('Fabrics/SewingThread/C7201.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C7279', name: 'C7279', imageSrc: webImageNamed('Fabrics/SewingThread/C7279.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C7305', name: 'C7305', imageSrc: webImageNamed('Fabrics/SewingThread/C7305.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C7930', name: 'C7930', imageSrc: webImageNamed('Fabrics/SewingThread/C7930.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C7978', name: 'C7978', imageSrc: webImageNamed('Fabrics/SewingThread/C7978.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C8501', name: 'C8501', imageSrc: webImageNamed('Fabrics/SewingThread/C8501.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C8587', name: 'C8587', imageSrc: webImageNamed('Fabrics/SewingThread/C8587.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C8989', name: 'C8989', imageSrc: webImageNamed('Fabrics/SewingThread/C8989.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9114', name: 'C9114', imageSrc: webImageNamed('Fabrics/SewingThread/C9114.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9313', name: 'C9313', imageSrc: webImageNamed('Fabrics/SewingThread/C9313.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9623', name: 'C9623', imageSrc: webImageNamed('Fabrics/SewingThread/C9623.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9631', name: 'C9631', imageSrc: webImageNamed('Fabrics/SewingThread/C9631.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9666', name: 'C9666', imageSrc: webImageNamed('Fabrics/SewingThread/C9666.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9770', name: 'C9770', imageSrc: webImageNamed('Fabrics/SewingThread/C9770.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9913', name: 'C9913', imageSrc: webImageNamed('Fabrics/SewingThread/C9913.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'C9949', name: 'C9949', imageSrc: webImageNamed('Fabrics/SewingThread/C9949.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'WCG001', name: 'WCG001', imageSrc: webImageNamed('Fabrics/SewingThread/WCG001.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.sleeveButtonHoleContrastThread, key: 'WCG007', name: 'WCG007', imageSrc: webImageNamed('Fabrics/SewingThread/WCG007.jpg'), price: null, company: '' },
    ]
    sleeveButtonHoleContrastThread_Step = () => {
        return {
            name: 'Sleeve Button Hole Contrast Thread',
            key: this.STEP_KEYS.sleeveButtonHoleContrastThread,
            opts: this.sleeveButtonHoleContrastThread_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }




    // LINING      concierge/xavirow/StyleSelections/Overcoat/lininigoption/FULL.jpg
    lining_Opts = [
        {optType:"LINING", styleSelectionId: "", key: 'FULL', name: 'FULL', imageSrc: webImageNamed('StyleSelections/Overcoat/lininigoption/FULL.jpg') },
        {optType:"LINING", styleSelectionId: "", key: 'HALF', name: 'HALF', imageSrc: webImageNamed('StyleSelections/Overcoat/lininigoption/HALF.jpg') },
        // {optType:"LINING", styleSelectionId: "", key: 'QUARTER', name: 'QUARTER', imageSrc: webImageNamed('overcoat/lininigoption/QUARTER.jpg') },
    ]
    lining_Step = () => {
        return {
            name: 'Do you like Full Lining',
            key: this.STEP_KEYS.lining,
            opts: this.lining_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // MONOGRAM     concierge/xavirow/StyleSelections/Overcoat/monogram/CollarFelt.jpg
    monogram_Opts = [
        { optType: "MONOGRAM",  key: 'NO MONOGRAM', name: 'NO MONOGRAM', imageSrc: null, customInput: "" },
        {optType:"MONOGRAM", styleSelectionId: "", key: 'COLLAR FELT', name: 'COLLAR FELT', imageSrc: webImageNamed('StyleSelections/Overcoat/monogram/CollarFelt.jpg'), customInput:"" },
        {optType:"MONOGRAM", styleSelectionId: "", key: 'ABOVE INNER LEFT BREAST POCKET', name: 'ABOVE INNER LEFT BREAST POCKET', imageSrc: webImageNamed('StyleSelections/Overcoat/monogram/AboveInnerPocket.jpg'), customInput:"" },
    ]
    monogram_Step = () => {
        return {
            name: 'If and where you would like to have monogram',
            key: this.STEP_KEYS.monogram,
            opts: this.monogram_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: true,
            paddingLess: false,
            boldText: false,
            needsCustomInput: true
        }
    }



    monogramThreadColor_Opts = [
        { optType: "Monogram Thread Color", key: '101', name: '101', imageSrc: webImageNamed('Fabrics/MonogramThread/101.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '102', name: '102', imageSrc: webImageNamed('Fabrics/MonogramThread/102.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '103', name: '103', imageSrc: webImageNamed('Fabrics/MonogramThread/103.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '138', name: '138', imageSrc: webImageNamed('Fabrics/MonogramThread/138.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '144', name: '144', imageSrc: webImageNamed('Fabrics/MonogramThread/144.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '145', name: '145', imageSrc: webImageNamed('Fabrics/MonogramThread/145.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '177', name: '177', imageSrc: webImageNamed('Fabrics/MonogramThread/177.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '189', name: '189', imageSrc: webImageNamed('Fabrics/MonogramThread/189.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '262', name: '262', imageSrc: webImageNamed('Fabrics/MonogramThread/262.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '312', name: '312', imageSrc: webImageNamed('Fabrics/MonogramThread/312.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '321', name: '321', imageSrc: webImageNamed('Fabrics/MonogramThread/321.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '352', name: '352', imageSrc: webImageNamed('Fabrics/MonogramThread/352.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '392', name: '392', imageSrc: webImageNamed('Fabrics/MonogramThread/392.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '432', name: '432', imageSrc: webImageNamed('Fabrics/MonogramThread/432.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '455', name: '455', imageSrc: webImageNamed('Fabrics/MonogramThread/455.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '489', name: '489', imageSrc: webImageNamed('Fabrics/MonogramThread/489.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '572', name: '572', imageSrc: webImageNamed('Fabrics/MonogramThread/572.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '598', name: '598', imageSrc: webImageNamed('Fabrics/MonogramThread/598.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '599', name: '599', imageSrc: webImageNamed('Fabrics/MonogramThread/599.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '624', name: '624', imageSrc: webImageNamed('Fabrics/MonogramThread/624.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '633', name: '633', imageSrc: webImageNamed('Fabrics/MonogramThread/633.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '714', name: '714', imageSrc: webImageNamed('Fabrics/MonogramThread/714.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '1017', name: '1017', imageSrc: webImageNamed('Fabrics/MonogramThread/1017.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '1034', name: '1034', imageSrc: webImageNamed('Fabrics/MonogramThread/1034.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '1059', name: '1059', imageSrc: webImageNamed('Fabrics/MonogramThread/1059.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '1093', name: '1093', imageSrc: webImageNamed('Fabrics/MonogramThread/1093.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '1116', name: '1116', imageSrc: webImageNamed('Fabrics/MonogramThread/1116.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '1161', name: '1161', imageSrc: webImageNamed('Fabrics/MonogramThread/1161.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '1162', name: '1162', imageSrc: webImageNamed('Fabrics/MonogramThread/1162.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '1173', name: '1173', imageSrc: webImageNamed('Fabrics/MonogramThread/1173.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '1196', name: '1196', imageSrc: webImageNamed('Fabrics/MonogramThread/1196.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '1203', name: '1203', imageSrc: webImageNamed('Fabrics/MonogramThread/1203.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '3077', name: '3077', imageSrc: webImageNamed('Fabrics/MonogramThread/3077.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '3099', name: '3099', imageSrc: webImageNamed('Fabrics/MonogramThread/3099.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '3172', name: '3172', imageSrc: webImageNamed('Fabrics/MonogramThread/3172.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '3334', name: '3334', imageSrc: webImageNamed('Fabrics/MonogramThread/3334.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '3601', name: '3601', imageSrc: webImageNamed('Fabrics/MonogramThread/3601.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '3618', name: '3618', imageSrc: webImageNamed('Fabrics/MonogramThread/3618.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '3655', name: '3655', imageSrc: webImageNamed('Fabrics/MonogramThread/3655.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '3687', name: '3687', imageSrc: webImageNamed('Fabrics/MonogramThread/3687.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '3694', name: '3694', imageSrc: webImageNamed('Fabrics/MonogramThread/3694.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '3701', name: '3701', imageSrc: webImageNamed('Fabrics/MonogramThread/3701.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '3712', name: '3712', imageSrc: webImageNamed('Fabrics/MonogramThread/3712.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '3720', name: '3720', imageSrc: webImageNamed('Fabrics/MonogramThread/3720.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '3742', name: '3742', imageSrc: webImageNamed('Fabrics/MonogramThread/3742.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '3747', name: '3747', imageSrc: webImageNamed('Fabrics/MonogramThread/3747.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '3750', name: '3750', imageSrc: webImageNamed('Fabrics/MonogramThread/3750.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: '3813', name: '3813', imageSrc: webImageNamed('Fabrics/MonogramThread/3813.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: 'MG2', name: 'MG2', imageSrc: webImageNamed('Fabrics/MonogramThread/MG2.jpg'), price: null, company: '' },
        { optType: "Monogram Thread Color", key: 'MS1', name: 'MS1', imageSrc: webImageNamed('Fabrics/MonogramThread/MS1.jpg'), price: null, company: '' },

    ]
    monogramThreadColor_Step = () => {
        return {
            name: 'Select Monogram Thread Color',
            key: this.STEP_KEYS.monogramThreadColor,
            opts: this.monogramThreadColor_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }

        // sleeveButtonOrientation
    // sleeveButtonHoleContrast
    // sleeveButtonHoleContrastThread
    // monogramThreadColor
    // fabricLining
    // suitButtons
    // contrastOption
    // contrastFabric


        // CONTRAST OPTION
        contrast_Opts = [
            { optType: "CONTRAST OPTION",  key: 'NO', name: 'NO', imageSrc: webImageNamed('StyleSelections/Suit/Contrast/No.jpg') },
            { optType: "CONTRAST OPTION",  key: 'LAPEL CONTRAST ONLY', name: 'LAPEL CONTRAST ONLY ', imageSrc: webImageNamed('StyleSelections/Suit/Contrast/LapelOnly.jpg'), price: 100 },
            { optType: "CONTRAST OPTION",  key: 'TUXEDO CONTRAST (Lapel, Pocket Lining, Button)', name: 'TUXEDO CONTRAST (Lapel, Pocket Lining, Button)', imageSrc: webImageNamed('StyleSelections/Suit/Contrast/TuxedoContrast.jpg'), price: 100 },
    
        ]
        contrast_Step = () => {
            // Change images here with respect to button choices
            return {
                name: 'Contrast ?',
                key: this.STEP_KEYS.contrastOption,
                opts: this.contrast_Opts,
                selectedOptKey: null,
                isPhotoBox: true,
                photoRatio: '3by4',
                skipable: false,
                paddingLess: false,
                boldText: false
            }
        }
    
    
        // CONTRAST FABRIC
        contrastFabricOpts = [
            // { optType: "CONTRAST FABRIC", key: 'BlackSatin', name: 'BlackSatin', imageSrc: webImageNamed('Fabrics/TuxedoContrast/BlackSatin.jpg'), price: 100, company: '' },
            // { optType: "CONTRAST FABRIC", key: 'IvorySatin', name: 'IvorySatin', imageSrc: webImageNamed('Fabrics/TuxedoContrast/IvorySatin.jpg'), price: 100, company: '' },
            // { optType: "CONTRAST FABRIC", key: 'NavySatin', name: 'NavySatin', imageSrc: webImageNamed('Fabrics/TuxedoContrast/NavySatin.jpeg'), price: 100, company: '' },
            // { optType: "CONTRAST FABRIC", key: 'WhiteSatin', name: 'WhiteSatin', imageSrc: webImageNamed('Fabrics/TuxedoContrast/WhiteSatin.jpg'), price: 100, company: '' },

            { optType: "CONTRAST FABRIC", key: 'DXN030A', name: 'DXN030A', imageSrc: webImageNamed('Fabrics/SatinFelt/DXN030A.jpg'), price: null , company: '' },
            { optType: "CONTRAST FABRIC", key: 'DXN031A', name: 'DXN031A', imageSrc: webImageNamed('Fabrics/SatinFelt/DXN031A.jpg'), price: null , company: '' },
            { optType: "CONTRAST FABRIC", key: 'DXN032A', name: 'DXN032A', imageSrc: webImageNamed('Fabrics/SatinFelt/DXN032A.jpg'), price: null , company: '' },
            { optType: "CONTRAST FABRIC", key: 'DXN044A', name: 'DXN044A', imageSrc: webImageNamed('Fabrics/SatinFelt/DXN044A.jpg'), price: null , company: '' },
            { optType: "CONTRAST FABRIC", key: 'DXN045A', name: 'DXN045A', imageSrc: webImageNamed('Fabrics/SatinFelt/DXN045A.jpg'), price: null , company: '' },
            { optType: "CONTRAST FABRIC", key: 'DXN047A', name: 'DXN047A', imageSrc: webImageNamed('Fabrics/SatinFelt/DXN047A.jpg'), price: null , company: '' },
            { optType: "CONTRAST FABRIC", key: 'DXN048A', name: 'DXN048A', imageSrc: webImageNamed('Fabrics/SatinFelt/DXN048A.jpg'), price: null , company: '' },

        ]
        contrastFabricStep = () => {
            // Change images here with respect to button choices
            return {
                name: 'Choose Fabric for Contrast',
                key: this.STEP_KEYS.contrastFabric,
                opts: this.contrastFabricOpts,
                selectedOptKey: null,
                isPhotoBox: true,
                photoRatio: '3by4',
                skipable: false,
                paddingLess: false,
                boldText: false
            }
        }
    
    
    
        // pantMonogramThreadColor
        // fabricLining
        // suitButtons
    
        // FABRIC -- written last
    
        // FABRIC LINING
        fabricLining_Opts = [
            // { optType: "Fabric Lining", key: 'A01', name: 'A01', imageSrc: webImageNamed('Fabrics/SuitLining/A01.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '4', name: '4', imageSrc: webImageNamed('Fabrics/LiningFabrics/4.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '5', name: '5', imageSrc: webImageNamed('Fabrics/LiningFabrics/5.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '11', name: '11', imageSrc: webImageNamed('Fabrics/LiningFabrics/11.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '12', name: '12', imageSrc: webImageNamed('Fabrics/LiningFabrics/12.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '13', name: '13', imageSrc: webImageNamed('Fabrics/LiningFabrics/13.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '19', name: '19', imageSrc: webImageNamed('Fabrics/LiningFabrics/19.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '20', name: '20', imageSrc: webImageNamed('Fabrics/LiningFabrics/20.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '21', name: '21', imageSrc: webImageNamed('Fabrics/LiningFabrics/21.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '22', name: '22', imageSrc: webImageNamed('Fabrics/LiningFabrics/22.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '23', name: '23', imageSrc: webImageNamed('Fabrics/LiningFabrics/23.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '24', name: '24', imageSrc: webImageNamed('Fabrics/LiningFabrics/24.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '25', name: '25', imageSrc: webImageNamed('Fabrics/LiningFabrics/25.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '26', name: '26', imageSrc: webImageNamed('Fabrics/LiningFabrics/26.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '29', name: '29', imageSrc: webImageNamed('Fabrics/LiningFabrics/29.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '30', name: '30', imageSrc: webImageNamed('Fabrics/LiningFabrics/30.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '31', name: '31', imageSrc: webImageNamed('Fabrics/LiningFabrics/31.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '32', name: '32', imageSrc: webImageNamed('Fabrics/LiningFabrics/32.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '33', name: '33', imageSrc: webImageNamed('Fabrics/LiningFabrics/33.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '34', name: '34', imageSrc: webImageNamed('Fabrics/LiningFabrics/34.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '36', name: '36', imageSrc: webImageNamed('Fabrics/LiningFabrics/36.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '39', name: '39', imageSrc: webImageNamed('Fabrics/LiningFabrics/39.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '40', name: '40', imageSrc: webImageNamed('Fabrics/LiningFabrics/40.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '44', name: '44', imageSrc: webImageNamed('Fabrics/LiningFabrics/44.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '45', name: '45', imageSrc: webImageNamed('Fabrics/LiningFabrics/45.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '47', name: '47', imageSrc: webImageNamed('Fabrics/LiningFabrics/47.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '48', name: '48', imageSrc: webImageNamed('Fabrics/LiningFabrics/48.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '49', name: '49', imageSrc: webImageNamed('Fabrics/LiningFabrics/49.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '50', name: '50', imageSrc: webImageNamed('Fabrics/LiningFabrics/50.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '53', name: '53', imageSrc: webImageNamed('Fabrics/LiningFabrics/53.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '54', name: '54', imageSrc: webImageNamed('Fabrics/LiningFabrics/54.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '56', name: '56', imageSrc: webImageNamed('Fabrics/LiningFabrics/56.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '59', name: '59', imageSrc: webImageNamed('Fabrics/LiningFabrics/59.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '60', name: '60', imageSrc: webImageNamed('Fabrics/LiningFabrics/60.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '63', name: '63', imageSrc: webImageNamed('Fabrics/LiningFabrics/63.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '64', name: '64', imageSrc: webImageNamed('Fabrics/LiningFabrics/64.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '67', name: '67', imageSrc: webImageNamed('Fabrics/LiningFabrics/67.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '68', name: '68', imageSrc: webImageNamed('Fabrics/LiningFabrics/68.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '70', name: '70', imageSrc: webImageNamed('Fabrics/LiningFabrics/70.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '71', name: '71', imageSrc: webImageNamed('Fabrics/LiningFabrics/71.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '75', name: '75', imageSrc: webImageNamed('Fabrics/LiningFabrics/75.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '76', name: '76', imageSrc: webImageNamed('Fabrics/LiningFabrics/76.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '78', name: '78', imageSrc: webImageNamed('Fabrics/LiningFabrics/78.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '81', name: '81', imageSrc: webImageNamed('Fabrics/LiningFabrics/81.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '83', name: '83', imageSrc: webImageNamed('Fabrics/LiningFabrics/83.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '97', name: '97', imageSrc: webImageNamed('Fabrics/LiningFabrics/97.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '101', name: '101', imageSrc: webImageNamed('Fabrics/LiningFabrics/101.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '102', name: '102', imageSrc: webImageNamed('Fabrics/LiningFabrics/102.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '103', name: '103', imageSrc: webImageNamed('Fabrics/LiningFabrics/103.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '106', name: '106', imageSrc: webImageNamed('Fabrics/LiningFabrics/106.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '108', name: '108', imageSrc: webImageNamed('Fabrics/LiningFabrics/108.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '109', name: '109', imageSrc: webImageNamed('Fabrics/LiningFabrics/109.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '111', name: '111', imageSrc: webImageNamed('Fabrics/LiningFabrics/111.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '112', name: '112', imageSrc: webImageNamed('Fabrics/LiningFabrics/112.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '114', name: '114', imageSrc: webImageNamed('Fabrics/LiningFabrics/114.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '122', name: '122', imageSrc: webImageNamed('Fabrics/LiningFabrics/122.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '125', name: '125', imageSrc: webImageNamed('Fabrics/LiningFabrics/125.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '128', name: '128', imageSrc: webImageNamed('Fabrics/LiningFabrics/128.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '129', name: '129', imageSrc: webImageNamed('Fabrics/LiningFabrics/129.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '130', name: '130', imageSrc: webImageNamed('Fabrics/LiningFabrics/130.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '131', name: '131', imageSrc: webImageNamed('Fabrics/LiningFabrics/131.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '133', name: '133', imageSrc: webImageNamed('Fabrics/LiningFabrics/133.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '134', name: '134', imageSrc: webImageNamed('Fabrics/LiningFabrics/134.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '139', name: '139', imageSrc: webImageNamed('Fabrics/LiningFabrics/139.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '143', name: '143', imageSrc: webImageNamed('Fabrics/LiningFabrics/143.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '144', name: '144', imageSrc: webImageNamed('Fabrics/LiningFabrics/144.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '145', name: '145', imageSrc: webImageNamed('Fabrics/LiningFabrics/145.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '146', name: '146', imageSrc: webImageNamed('Fabrics/LiningFabrics/146.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '147', name: '147', imageSrc: webImageNamed('Fabrics/LiningFabrics/147.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '148', name: '148', imageSrc: webImageNamed('Fabrics/LiningFabrics/148.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '151', name: '151', imageSrc: webImageNamed('Fabrics/LiningFabrics/151.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '154', name: '154', imageSrc: webImageNamed('Fabrics/LiningFabrics/154.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '157', name: '157', imageSrc: webImageNamed('Fabrics/LiningFabrics/157.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '181', name: '181', imageSrc: webImageNamed('Fabrics/LiningFabrics/181.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '182', name: '182', imageSrc: webImageNamed('Fabrics/LiningFabrics/182.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '187', name: '187', imageSrc: webImageNamed('Fabrics/LiningFabrics/187.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '190', name: '190', imageSrc: webImageNamed('Fabrics/LiningFabrics/190.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '192', name: '192', imageSrc: webImageNamed('Fabrics/LiningFabrics/192.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '201', name: '201', imageSrc: webImageNamed('Fabrics/LiningFabrics/201.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '202', name: '202', imageSrc: webImageNamed('Fabrics/LiningFabrics/202.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '212', name: '212', imageSrc: webImageNamed('Fabrics/LiningFabrics/212.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '219', name: '219', imageSrc: webImageNamed('Fabrics/LiningFabrics/219.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '220', name: '220', imageSrc: webImageNamed('Fabrics/LiningFabrics/220.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '225', name: '225', imageSrc: webImageNamed('Fabrics/LiningFabrics/225.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '226', name: '226', imageSrc: webImageNamed('Fabrics/LiningFabrics/226.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '227', name: '227', imageSrc: webImageNamed('Fabrics/LiningFabrics/227.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '228', name: '228', imageSrc: webImageNamed('Fabrics/LiningFabrics/228.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '231', name: '231', imageSrc: webImageNamed('Fabrics/LiningFabrics/231.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '232', name: '232', imageSrc: webImageNamed('Fabrics/LiningFabrics/232.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '234', name: '234', imageSrc: webImageNamed('Fabrics/LiningFabrics/234.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '237', name: '237', imageSrc: webImageNamed('Fabrics/LiningFabrics/237.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '238', name: '238', imageSrc: webImageNamed('Fabrics/LiningFabrics/238.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '239', name: '239', imageSrc: webImageNamed('Fabrics/LiningFabrics/239.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '240', name: '240', imageSrc: webImageNamed('Fabrics/LiningFabrics/240.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '241', name: '241', imageSrc: webImageNamed('Fabrics/LiningFabrics/241.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '243', name: '243', imageSrc: webImageNamed('Fabrics/LiningFabrics/243.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '245', name: '245', imageSrc: webImageNamed('Fabrics/LiningFabrics/245.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '246', name: '246', imageSrc: webImageNamed('Fabrics/LiningFabrics/246.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '247', name: '247', imageSrc: webImageNamed('Fabrics/LiningFabrics/247.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '248', name: '248', imageSrc: webImageNamed('Fabrics/LiningFabrics/248.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '254', name: '254', imageSrc: webImageNamed('Fabrics/LiningFabrics/254.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '255', name: '255', imageSrc: webImageNamed('Fabrics/LiningFabrics/255.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '257', name: '257', imageSrc: webImageNamed('Fabrics/LiningFabrics/257.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '259', name: '259', imageSrc: webImageNamed('Fabrics/LiningFabrics/259.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '261', name: '261', imageSrc: webImageNamed('Fabrics/LiningFabrics/261.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '262', name: '262', imageSrc: webImageNamed('Fabrics/LiningFabrics/262.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '267', name: '267', imageSrc: webImageNamed('Fabrics/LiningFabrics/267.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '268', name: '268', imageSrc: webImageNamed('Fabrics/LiningFabrics/268.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '269', name: '269', imageSrc: webImageNamed('Fabrics/LiningFabrics/269.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '270', name: '270', imageSrc: webImageNamed('Fabrics/LiningFabrics/270.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '271', name: '271', imageSrc: webImageNamed('Fabrics/LiningFabrics/271.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '272', name: '272', imageSrc: webImageNamed('Fabrics/LiningFabrics/272.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '273', name: '273', imageSrc: webImageNamed('Fabrics/LiningFabrics/273.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '274', name: '274', imageSrc: webImageNamed('Fabrics/LiningFabrics/274.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '275', name: '275', imageSrc: webImageNamed('Fabrics/LiningFabrics/275.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '276', name: '276', imageSrc: webImageNamed('Fabrics/LiningFabrics/276.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '277', name: '277', imageSrc: webImageNamed('Fabrics/LiningFabrics/277.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '278', name: '278', imageSrc: webImageNamed('Fabrics/LiningFabrics/278.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '289', name: '289', imageSrc: webImageNamed('Fabrics/LiningFabrics/289.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '290', name: '290', imageSrc: webImageNamed('Fabrics/LiningFabrics/290.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '291', name: '291', imageSrc: webImageNamed('Fabrics/LiningFabrics/291.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '292', name: '292', imageSrc: webImageNamed('Fabrics/LiningFabrics/292.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '293', name: '293', imageSrc: webImageNamed('Fabrics/LiningFabrics/293.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '294', name: '294', imageSrc: webImageNamed('Fabrics/LiningFabrics/294.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '295', name: '295', imageSrc: webImageNamed('Fabrics/LiningFabrics/295.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '296', name: '296', imageSrc: webImageNamed('Fabrics/LiningFabrics/296.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '297', name: '297', imageSrc: webImageNamed('Fabrics/LiningFabrics/297.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '298', name: '298', imageSrc: webImageNamed('Fabrics/LiningFabrics/298.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '299', name: '299', imageSrc: webImageNamed('Fabrics/LiningFabrics/299.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '300', name: '300', imageSrc: webImageNamed('Fabrics/LiningFabrics/300.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '301', name: '301', imageSrc: webImageNamed('Fabrics/LiningFabrics/301.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '302', name: '302', imageSrc: webImageNamed('Fabrics/LiningFabrics/302.jpg'), price: null, company: '' },
            { optType: "Fabric Lining", key: '303', name: '303', imageSrc: webImageNamed('Fabrics/LiningFabrics/303.jpg'), price: null, company: '' },
        ]
        fabricLining_Step = () => {
            // Change images here with respect to button choices
            return {
                name: 'Choose Fabric Lining',
                key: this.STEP_KEYS.fabricLining,
                opts: this.fabricLining_Opts,
                selectedOptKey: null,
                isPhotoBox: true,
                photoRatio: '3by4',
                skipable: false,
                paddingLess: false,
                boldText: false
            }
        }
    
        // SUIT BUTTONS
        suitButtons_Opts = [
            { optType: "Buttons", key: 'B01', name: 'B01', imageSrc: webImageNamed('Fabrics/SuitButtons/B01.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B02', name: 'B02', imageSrc: webImageNamed('Fabrics/SuitButtons/B02.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B03', name: 'B03', imageSrc: webImageNamed('Fabrics/SuitButtons/B03.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B04', name: 'B04', imageSrc: webImageNamed('Fabrics/SuitButtons/B04.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B05', name: 'B05', imageSrc: webImageNamed('Fabrics/SuitButtons/B05.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B06', name: 'B06', imageSrc: webImageNamed('Fabrics/SuitButtons/B06.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B07', name: 'B07', imageSrc: webImageNamed('Fabrics/SuitButtons/B07.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B08', name: 'B08', imageSrc: webImageNamed('Fabrics/SuitButtons/B08.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B09', name: 'B09', imageSrc: webImageNamed('Fabrics/SuitButtons/B09.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B10', name: 'B10', imageSrc: webImageNamed('Fabrics/SuitButtons/B10.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B11', name: 'B11', imageSrc: webImageNamed('Fabrics/SuitButtons/B11.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B12', name: 'B12', imageSrc: webImageNamed('Fabrics/SuitButtons/B12.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B13', name: 'B13', imageSrc: webImageNamed('Fabrics/SuitButtons/B13.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B14', name: 'B14', imageSrc: webImageNamed('Fabrics/SuitButtons/B14.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B15', name: 'B15', imageSrc: webImageNamed('Fabrics/SuitButtons/B15.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B16', name: 'B16', imageSrc: webImageNamed('Fabrics/SuitButtons/B16.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B17', name: 'B17', imageSrc: webImageNamed('Fabrics/SuitButtons/B17.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B18', name: 'B18', imageSrc: webImageNamed('Fabrics/SuitButtons/B18.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B19', name: 'B19', imageSrc: webImageNamed('Fabrics/SuitButtons/B19.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B20', name: 'B20', imageSrc: webImageNamed('Fabrics/SuitButtons/B20.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B21', name: 'B21', imageSrc: webImageNamed('Fabrics/SuitButtons/B21.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B22', name: 'B22', imageSrc: webImageNamed('Fabrics/SuitButtons/B22.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B23', name: 'B23', imageSrc: webImageNamed('Fabrics/SuitButtons/B23.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B24', name: 'B24', imageSrc: webImageNamed('Fabrics/SuitButtons/B24.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B25', name: 'B25', imageSrc: webImageNamed('Fabrics/SuitButtons/B25.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B26', name: 'B26', imageSrc: webImageNamed('Fabrics/SuitButtons/B26.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B27', name: 'B27', imageSrc: webImageNamed('Fabrics/SuitButtons/B27.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B28', name: 'B28', imageSrc: webImageNamed('Fabrics/SuitButtons/B28.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B29', name: 'B29', imageSrc: webImageNamed('Fabrics/SuitButtons/B29.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B30', name: 'B30', imageSrc: webImageNamed('Fabrics/SuitButtons/B30.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B31', name: 'B31', imageSrc: webImageNamed('Fabrics/SuitButtons/B31.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B32', name: 'B32', imageSrc: webImageNamed('Fabrics/SuitButtons/B32.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B33', name: 'B33', imageSrc: webImageNamed('Fabrics/SuitButtons/B33.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B34', name: 'B34', imageSrc: webImageNamed('Fabrics/SuitButtons/B34.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B35', name: 'B35', imageSrc: webImageNamed('Fabrics/SuitButtons/B35.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B36', name: 'B36', imageSrc: webImageNamed('Fabrics/SuitButtons/B36.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B37', name: 'B37', imageSrc: webImageNamed('Fabrics/SuitButtons/B37.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B38', name: 'B38', imageSrc: webImageNamed('Fabrics/SuitButtons/B38.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B39', name: 'B39', imageSrc: webImageNamed('Fabrics/SuitButtons/B39.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B40', name: 'B40', imageSrc: webImageNamed('Fabrics/SuitButtons/B40.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B41', name: 'B41', imageSrc: webImageNamed('Fabrics/SuitButtons/B41.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B42', name: 'B42', imageSrc: webImageNamed('Fabrics/SuitButtons/B42.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B43', name: 'B43', imageSrc: webImageNamed('Fabrics/SuitButtons/B43.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B44', name: 'B44', imageSrc: webImageNamed('Fabrics/SuitButtons/B44.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B45', name: 'B45', imageSrc: webImageNamed('Fabrics/SuitButtons/B45.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B46', name: 'B46', imageSrc: webImageNamed('Fabrics/SuitButtons/B46.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B47', name: 'B47', imageSrc: webImageNamed('Fabrics/SuitButtons/B47.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B48', name: 'B48', imageSrc: webImageNamed('Fabrics/SuitButtons/B48.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B49', name: 'B49', imageSrc: webImageNamed('Fabrics/SuitButtons/B49.jpg'), price: null, company: '' },
            { optType: "Buttons", key: 'B50', name: 'B50', imageSrc: webImageNamed('Fabrics/SuitButtons/B50.jpg'), price: null, company: '' },
        ]
        suitButtons_Step = () => {
            // Change images here with respect to button choices
            return {
                name: 'Choose Buttons',
                key: this.STEP_KEYS.suitButtons,
                opts: this.suitButtons_Opts,
                selectedOptKey: null,
                isPhotoBox: true,
                photoRatio: '3by4',
                skipable: false,
                paddingLess: false,
                boldText: false
            }
        }
      


    // TONE, ["OVER COAT"], ["Blues", "Blacks", "Greys", "Playful"]
    // TONE
    toneOpts = [
        {optType:"TONE", styleSelectionId: "", key: this.TONE_KEYS.AllSeasons, name: 'All Seasons', imageSrc: webImageNamed('Fabrics/FabricCategory/AllSeason.jpg') },
        // {optType:"TONE", styleSelectionId: "", key: this.TONE_KEYS.FallWinter, name: 'Fall Winter', imageSrc: webImageNamed('Fabrics/FabricCategory/Fall%26Winter.jpg') },
        // {optType:"TONE", styleSelectionId: "", key: this.TONE_KEYS.WaterResistant, name: 'Water Resistant (All Seasons)', imageSrc: webImageNamed('Fabrics/FabricCategory/WaterResistant.jpg') },
    ]
    toneStep = () => {
        // Change images here with respect to button choices
        return {
            name: 'Select Fabric Category',
            key: this.STEP_KEYS.tone,
            opts: this.toneOpts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }

    // FABRIC

    fabric_AllSeasons = [
        {optType:"Fabric", key: 'DBN839A', name: 'DBN839A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBN839A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBN840A', name: 'DBN840A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBN840A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBQ567A', name: 'DBQ567A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBQ567A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBQ568A', name: 'DBQ568A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBQ568A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBR109A', name: 'DBR109A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBR109A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBR110A', name: 'DBR110A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBR110A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBR111A', name: 'DBR111A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBR111A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBR112A', name: 'DBR112A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBR112A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBR113A', name: 'DBR113A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBR113A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBR115A', name: 'DBR115A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBR115A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBR116A', name: 'DBR116A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBR116A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBR117A', name: 'DBR117A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBR117A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBR118A', name: 'DBR118A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBR118A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBR119A', name: 'DBR119A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBR119A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBR120A', name: 'DBR120A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBR120A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBR179A', name: 'DBR179A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBR179A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBR181A', name: 'DBR181A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBR181A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBV061A', name: 'DBV061A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBV061A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBV062A', name: 'DBV062A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBV062A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBV063A', name: 'DBV063A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBV063A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBV064A', name: 'DBV064A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBV064A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBV065A', name: 'DBV065A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBV065A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBV066A', name: 'DBV066A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBV066A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBV067A', name: 'DBV067A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBV067A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBV068A', name: 'DBV068A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBV068A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBV069A', name: 'DBV069A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBV069A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBV070A', name: 'DBV070A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBV070A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBV071A', name: 'DBV071A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBV071A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBV072A', name: 'DBV072A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBV072A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DBV073A', name: 'DBV073A', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DBV073A.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0001', name: 'DHH0001', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0001.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0002', name: 'DHH0002', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0002.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0003', name: 'DHH0003', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0003.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0004', name: 'DHH0004', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0004.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0005', name: 'DHH0005', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0005.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0006', name: 'DHH0006', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0006.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0007', name: 'DHH0007', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0007.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0008', name: 'DHH0008', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0008.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0009', name: 'DHH0009', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0009.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0010', name: 'DHH0010', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0010.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0011', name: 'DHH0011', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0011.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0012', name: 'DHH0012', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0012.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0013', name: 'DHH0013', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0013.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0014', name: 'DHH0014', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0014.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0015', name: 'DHH0015', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0015.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0016', name: 'DHH0016', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0016.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0017', name: 'DHH0017', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0017.jpg') , price:600 , company:''},
        {optType:"Fabric", key: 'DHH0018', name: 'DHH0018', imageSrc: webImageNamed('Fabrics/OvercoatFabrics/DHH0018.jpg') , price:600 , company:''},
    ]
    fabric_FallWinter = [
        {optType:"Fabric", key: '820603', name: '820603', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820603.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820605', name: '820605', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820605.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820607', name: '820607', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820607.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820608', name: '820608', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820608.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820609', name: '820609', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820609.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820610', name: '820610', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820610.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820611', name: '820611', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820611.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820612', name: '820612', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820612.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820613', name: '820613', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820613.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820614', name: '820614', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820614.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820615', name: '820615', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820615.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820617', name: '820617', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820617.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820620', name: '820620', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820620.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820622', name: '820622', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820622.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820624', name: '820624', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820624.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820625', name: '820625', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820625.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820627', name: '820627', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820627.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820628', name: '820628', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820628.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820629', name: '820629', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820629.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820630', name: '820630', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820630.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820631', name: '820631', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820631.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820632', name: '820632', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820632.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820633', name: '820633', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820633.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820634', name: '820634', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820634.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820635', name: '820635', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820635.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820636', name: '820636', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820636.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820637', name: '820637', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820637.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820638', name: '820638', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820638.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820639', name: '820639', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820639.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820640', name: '820640', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820640.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820643', name: '820643', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820643.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820644', name: '820644', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820644.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820645', name: '820645', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820645.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820646', name: '820646', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820646.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820647', name: '820647', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820647.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820648', name: '820648', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820648.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820649', name: '820649', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820649.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820650', name: '820650', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820650.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820651', name: '820651', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820651.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820656', name: '820656', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820656.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820657', name: '820657', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820657.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820658', name: '820658', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820658.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820659', name: '820659', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820659.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820660', name: '820660', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820660.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820663', name: '820663', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820663.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820664', name: '820664', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820664.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820667', name: '820667', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820667.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820668', name: '820668', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820668.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820669', name: '820669', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820669.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820670', name: '820670', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820670.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820671', name: '820671', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820671.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820672', name: '820672', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820672.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820673', name: '820673', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820673.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820674', name: '820674', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820674.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820675', name: '820675', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820675.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820676', name: '820676', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820676.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820677', name: '820677', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820677.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820678', name: '820678', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820678.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820679', name: '820679', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820679.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820681', name: '820681', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820681.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '820682', name: '820682', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1000/820682.jpg')  , price:900 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201103', name: '201103', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201103.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201104', name: '201104', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201104.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201116', name: '201116', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201116.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201117', name: '201117', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201117.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201124', name: '201124', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201124.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201126', name: '201126', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201126.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201127', name: '201127', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201127.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201128', name: '201128', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201128.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201129', name: '201129', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201129.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201130', name: '201130', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201130.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201131', name: '201131', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201131.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201133', name: '201133', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201133.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201304', name: '201304', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201304.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201305', name: '201305', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201305.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201306', name: '201306', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201306.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201309', name: '201309', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201309.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201316', name: '201316', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201316.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201317', name: '201317', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201317.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201319', name: '201319', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201319.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201320', name: '201320', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201320.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201321', name: '201321', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201321.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201324', name: '201324', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201324.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '201350', name: '201350', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/201350.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620001', name: '620001', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620001.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620002', name: '620002', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620002.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620005', name: '620005', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620005.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620006', name: '620006', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620006.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620010', name: '620010', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620010.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620011', name: '620011', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620011.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620012', name: '620012', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620012.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620014', name: '620014', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620014.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620016', name: '620016', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620016.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620017', name: '620017', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620017.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620020', name: '620020', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620020.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620021', name: '620021', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620021.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620022', name: '620022', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620022.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620029', name: '620029', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620029.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620030', name: '620030', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620030.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620031', name: '620031', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620031.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620036', name: '620036', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620036.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620038', name: '620038', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620038.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620039', name: '620039', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620039.jpg')  , price:950 , company:'Jorge Carli'},
        {optType:"Fabric", key: '620040', name: '620040', imageSrc: webImageNamed('Fabrics/SuitFabric/FallWinter/JorgeCarliFallWinter1200/620040.jpg')  , price:950 , company:'Jorge Carli'},
    ]
    fabric_WaterResistant = [
        {optType:"Fabric", key: '280101', name: '280101', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280101.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280102', name: '280102', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280102.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280103', name: '280103', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280103.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280104', name: '280104', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280104.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280105', name: '280105', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280105.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280106', name: '280106', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280106.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280107', name: '280107', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280107.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280108', name: '280108', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280108.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280109', name: '280109', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280109.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280110', name: '280110', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280110.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280111', name: '280111', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280111.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280112', name: '280112', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280112.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280131', name: '280131', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280131.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280132', name: '280132', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280132.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280135', name: '280135', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280135.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280136', name: '280136', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280136.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280137', name: '280137', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280137.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280138', name: '280138', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280138.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280139', name: '280139', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280139.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280140', name: '280140', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280140.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280141', name: '280141', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280141.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280142', name: '280142', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280142.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280143', name: '280143', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280143.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280146', name: '280146', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280146.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280147', name: '280147', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280147.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280148', name: '280148', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280148.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280150', name: '280150', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280150.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280154', name: '280154', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280154.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280155', name: '280155', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280155.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280156', name: '280156', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280156.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280159', name: '280159', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280159.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280160', name: '280160', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280160.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280161', name: '280161', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280161.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280165', name: '280165', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280165.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280166', name: '280166', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280166.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280170', name: '280170', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280170.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280180', name: '280180', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280180.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280181', name: '280181', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280181.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280182', name: '280182', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280182.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280183', name: '280183', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280183.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280184', name: '280184', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280184.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280212', name: '280212', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280212.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280213', name: '280213', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280213.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280214', name: '280214', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280214.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280215', name: '280215', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280215.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280216', name: '280216', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280216.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280218', name: '280218', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280218.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280219', name: '280219', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280219.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280220', name: '280220', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280220.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280321', name: '280321', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280321.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280322', name: '280322', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280322.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280323', name: '280323', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280323.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280324', name: '280324', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280324.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280325', name: '280325', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280325.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280326', name: '280326', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280326.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280327', name: '280327', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280327.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280328', name: '280328', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280328.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280329', name: '280329', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280329.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280330', name: '280330', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280330.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280431', name: '280431', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280431.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280432', name: '280432', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280432.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280433', name: '280433', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280433.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280434', name: '280434', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280434.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280435', name: '280435', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280435.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280436', name: '280436', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280436.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280437', name: '280437', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280437.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280438', name: '280438', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280438.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280541', name: '280541', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280541.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280542', name: '280542', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280542.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280543', name: '280543', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280543.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280544', name: '280544', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280544.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280545', name: '280545', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280545.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280546', name: '280546', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280546.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280547', name: '280547', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280547.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280548', name: '280548', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280548.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280549', name: '280549', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280549.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280550', name: '280541', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280541.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280651', name: '280651', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280651.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280653', name: '280653', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280653.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280654', name: '280654', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280654.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280655', name: '280655', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280655.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280656', name: '280656', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280656.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280657', name: '280657', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280657.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280658', name: '280658', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280658.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280659', name: '280659', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280659.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280762', name: '280762', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280762.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280764', name: '280764', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280764.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280765', name: '280765', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280765.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280766', name: '280766', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280766.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280767', name: '280767', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280767.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280768', name: '280768', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280768.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280769', name: '280769', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280769.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280871', name: '280871', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280871.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280872', name: '280872', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280872.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280873', name: '280873', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280873.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280874', name: '280874', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280874.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280875', name: '280875', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280875.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280876', name: '280876', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280876.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280877', name: '280877', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280877.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280878', name: '280878', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280878.jpg')  , price:900 , company:''},
        {optType:"Fabric", key: '280880', name: '280880', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280880.jpg')  , price:900 , company:''},
    ]
    fabric_Buttons = [
        { optType: "Buttons", key: 'KB001', name: 'KB001', imageSrc: webImageNamed('Fabrics/SuitButtons/KB001.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB002', name: 'KB002', imageSrc: webImageNamed('Fabrics/SuitButtons/KB002.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB008', name: 'KB008', imageSrc: webImageNamed('Fabrics/SuitButtons/KB008.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB010', name: 'KB010', imageSrc: webImageNamed('Fabrics/SuitButtons/KB010.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB011', name: 'KB011', imageSrc: webImageNamed('Fabrics/SuitButtons/KB011.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB016', name: 'KB016', imageSrc: webImageNamed('Fabrics/SuitButtons/KB016.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB020', name: 'KB020', imageSrc: webImageNamed('Fabrics/SuitButtons/KB020.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB021', name: 'KB021', imageSrc: webImageNamed('Fabrics/SuitButtons/KB021.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB022', name: 'KB022', imageSrc: webImageNamed('Fabrics/SuitButtons/KB022.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB023', name: 'KB023', imageSrc: webImageNamed('Fabrics/SuitButtons/KB023.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB025', name: 'KB025', imageSrc: webImageNamed('Fabrics/SuitButtons/KB025.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB026', name: 'KB026', imageSrc: webImageNamed('Fabrics/SuitButtons/KB026.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB027', name: 'KB027', imageSrc: webImageNamed('Fabrics/SuitButtons/KB027.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB028', name: 'KB028', imageSrc: webImageNamed('Fabrics/SuitButtons/KB028.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB029', name: 'KB029', imageSrc: webImageNamed('Fabrics/SuitButtons/KB029.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KB030', name: 'KB030', imageSrc: webImageNamed('Fabrics/SuitButtons/KB030.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG024', name: 'KG024', imageSrc: webImageNamed('Fabrics/SuitButtons/KG024.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG027', name: 'KG027', imageSrc: webImageNamed('Fabrics/SuitButtons/KG027.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG032', name: 'KG032', imageSrc: webImageNamed('Fabrics/SuitButtons/KG032.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG053', name: 'KG053', imageSrc: webImageNamed('Fabrics/SuitButtons/KG053.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG054', name: 'KG054', imageSrc: webImageNamed('Fabrics/SuitButtons/KG054.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG055', name: 'KG055', imageSrc: webImageNamed('Fabrics/SuitButtons/KG055.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG056', name: 'KG056', imageSrc: webImageNamed('Fabrics/SuitButtons/KG056.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG057', name: 'KG057', imageSrc: webImageNamed('Fabrics/SuitButtons/KG057.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG062', name: 'KG062', imageSrc: webImageNamed('Fabrics/SuitButtons/KG062.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG064', name: 'KG064', imageSrc: webImageNamed('Fabrics/SuitButtons/KG064.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG068', name: 'KG068', imageSrc: webImageNamed('Fabrics/SuitButtons/KG068.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG069', name: 'KG069', imageSrc: webImageNamed('Fabrics/SuitButtons/KG069.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG157', name: 'KG157', imageSrc: webImageNamed('Fabrics/SuitButtons/KG157.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG168', name: 'KG168', imageSrc: webImageNamed('Fabrics/SuitButtons/KG168.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG178', name: 'KG178', imageSrc: webImageNamed('Fabrics/SuitButtons/KG178.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG190', name: 'KG190', imageSrc: webImageNamed('Fabrics/SuitButtons/KG190.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG191', name: 'KG191', imageSrc: webImageNamed('Fabrics/SuitButtons/KG191.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG192', name: 'KG192', imageSrc: webImageNamed('Fabrics/SuitButtons/KG192.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG195', name: 'KG195', imageSrc: webImageNamed('Fabrics/SuitButtons/KG195.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG196', name: 'KG196', imageSrc: webImageNamed('Fabrics/SuitButtons/KG196.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG197', name: 'KG197', imageSrc: webImageNamed('Fabrics/SuitButtons/KG197.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG198', name: 'KG198', imageSrc: webImageNamed('Fabrics/SuitButtons/KG198.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG199', name: 'KG199', imageSrc: webImageNamed('Fabrics/SuitButtons/KG199.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG202', name: 'KG202', imageSrc: webImageNamed('Fabrics/SuitButtons/KG202.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG203', name: 'KG203', imageSrc: webImageNamed('Fabrics/SuitButtons/KG203.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG204', name: 'KG204', imageSrc: webImageNamed('Fabrics/SuitButtons/KG204.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG206', name: 'KG206', imageSrc: webImageNamed('Fabrics/SuitButtons/KG206.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KG212', name: 'KG212', imageSrc: webImageNamed('Fabrics/SuitButtons/KG212.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ011', name: 'KNJ011', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ011.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ012', name: 'KNJ012', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ012.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ013', name: 'KNJ013', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ013.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ014', name: 'KNJ014', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ014.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ023', name: 'KNJ023', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ023.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ024', name: 'KNJ024', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ024.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ030', name: 'KNJ030', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ030.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ031', name: 'KNJ031', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ031.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ033', name: 'KNJ033', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ033.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ036', name: 'KNJ036', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ036.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KNJ039', name: 'KNJ039', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ039.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KQ022', name: 'KQ022', imageSrc: webImageNamed('Fabrics/SuitButtons/KQ022.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ133', name: 'KSZ133', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ133.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ134', name: 'KSZ134', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ134.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ137', name: 'KSZ137', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ137.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ138', name: 'KSZ138', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ138.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ139', name: 'KSZ139', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ139.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ140', name: 'KSZ140', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ140.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ150', name: 'KSZ150', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ150.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ151', name: 'KSZ151', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ151.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ152', name: 'KSZ152', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ152.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ153', name: 'KSZ153', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ153.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ154', name: 'KSZ154', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ154.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ155', name: 'KSZ155', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ155.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ161', name: 'KSZ161', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ161.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ162', name: 'KSZ162', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ162.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ165', name: 'KSZ165', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ165.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ175', name: 'KSZ175', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ175.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ176', name: 'KSZ176', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ176.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ177', name: 'KSZ177', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ177.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ191', name: 'KSZ191', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ191.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ192', name: 'KSZ192', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ192.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ193', name: 'KSZ193', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ193.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ195', name: 'KSZ195', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ195.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ196', name: 'KSZ196', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ196.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ197', name: 'KSZ197', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ197.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ198', name: 'KSZ198', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ198.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ199', name: 'KSZ199', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ199.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ200', name: 'KSZ200', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ200.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ201', name: 'KSZ201', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ201.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ205', name: 'KSZ205', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ205.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ206', name: 'KSZ206', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ206.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ207', name: 'KSZ207', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ207.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ220', name: 'KSZ220', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ220.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KSZ221', name: 'KSZ221', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ221.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW001', name: 'KW001', imageSrc: webImageNamed('Fabrics/SuitButtons/KW001.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW002', name: 'KW002', imageSrc: webImageNamed('Fabrics/SuitButtons/KW002.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW003', name: 'KW003', imageSrc: webImageNamed('Fabrics/SuitButtons/KW003.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW004', name: 'KW004', imageSrc: webImageNamed('Fabrics/SuitButtons/KW004.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW005', name: 'KW005', imageSrc: webImageNamed('Fabrics/SuitButtons/KW005.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW006', name: 'KW006', imageSrc: webImageNamed('Fabrics/SuitButtons/KW006.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW017', name: 'KW017', imageSrc: webImageNamed('Fabrics/SuitButtons/KW017.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW042', name: 'KW042', imageSrc: webImageNamed('Fabrics/SuitButtons/KW042.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW043', name: 'KW043', imageSrc: webImageNamed('Fabrics/SuitButtons/KW043.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW044', name: 'KW044', imageSrc: webImageNamed('Fabrics/SuitButtons/KW044.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW045', name: 'KW045', imageSrc: webImageNamed('Fabrics/SuitButtons/KW045.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW063', name: 'KW063', imageSrc: webImageNamed('Fabrics/SuitButtons/KW063.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW065', name: 'KW065', imageSrc: webImageNamed('Fabrics/SuitButtons/KW065.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW066', name: 'KW066', imageSrc: webImageNamed('Fabrics/SuitButtons/KW066.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW067', name: 'KW067', imageSrc: webImageNamed('Fabrics/SuitButtons/KW067.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW068', name: 'KW068', imageSrc: webImageNamed('Fabrics/SuitButtons/KW068.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW069', name: 'KW069', imageSrc: webImageNamed('Fabrics/SuitButtons/KW069.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW070', name: 'KW070', imageSrc: webImageNamed('Fabrics/SuitButtons/KW070.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'KW071', name: 'KW071', imageSrc: webImageNamed('Fabrics/SuitButtons/KW071.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'QMJQZ03', name: 'QMJQZ03', imageSrc: webImageNamed('Fabrics/SuitButtons/QMJQZ03.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'QZMAB01', name: 'QZMAB01', imageSrc: webImageNamed('Fabrics/SuitButtons/QZMAB01.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'QZMAB02', name: 'QZMAB02', imageSrc: webImageNamed('Fabrics/SuitButtons/QZMAB02.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'QZMAB03', name: 'QZMAB03', imageSrc: webImageNamed('Fabrics/SuitButtons/QZMAB03.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'QZMAB08', name: 'QZMAB08', imageSrc: webImageNamed('Fabrics/SuitButtons/QZMAB08.jpg'), price: null, company: '' },
    ]
    fabric_Linings = [
        { optType: "Lining", key: '4', name: '4', imageSrc: webImageNamed('Fabrics/LiningFabrics/4.jpg'), price: null, company: '' },
    { optType: "Lining", key: '5', name: '5', imageSrc: webImageNamed('Fabrics/LiningFabrics/5.jpg'), price: null, company: '' },
    { optType: "Lining", key: '11', name: '11', imageSrc: webImageNamed('Fabrics/LiningFabrics/11.jpg'), price: null, company: '' },
    { optType: "Lining", key: '12', name: '12', imageSrc: webImageNamed('Fabrics/LiningFabrics/12.jpg'), price: null, company: '' },
    { optType: "Lining", key: '13', name: '13', imageSrc: webImageNamed('Fabrics/LiningFabrics/13.jpg'), price: null, company: '' },
    { optType: "Lining", key: '19', name: '19', imageSrc: webImageNamed('Fabrics/LiningFabrics/19.jpg'), price: null, company: '' },
    { optType: "Lining", key: '20', name: '20', imageSrc: webImageNamed('Fabrics/LiningFabrics/20.jpg'), price: null, company: '' },
    { optType: "Lining", key: '21', name: '21', imageSrc: webImageNamed('Fabrics/LiningFabrics/21.jpg'), price: null, company: '' },
    { optType: "Lining", key: '22', name: '22', imageSrc: webImageNamed('Fabrics/LiningFabrics/22.jpg'), price: null, company: '' },
    { optType: "Lining", key: '23', name: '23', imageSrc: webImageNamed('Fabrics/LiningFabrics/23.jpg'), price: null, company: '' },
    { optType: "Lining", key: '24', name: '24', imageSrc: webImageNamed('Fabrics/LiningFabrics/24.jpg'), price: null, company: '' },
    { optType: "Lining", key: '25', name: '25', imageSrc: webImageNamed('Fabrics/LiningFabrics/25.jpg'), price: null, company: '' },
    { optType: "Lining", key: '26', name: '26', imageSrc: webImageNamed('Fabrics/LiningFabrics/26.jpg'), price: null, company: '' },
    { optType: "Lining", key: '29', name: '29', imageSrc: webImageNamed('Fabrics/LiningFabrics/29.jpg'), price: null, company: '' },
    { optType: "Lining", key: '30', name: '30', imageSrc: webImageNamed('Fabrics/LiningFabrics/30.jpg'), price: null, company: '' },
    { optType: "Lining", key: '31', name: '31', imageSrc: webImageNamed('Fabrics/LiningFabrics/31.jpg'), price: null, company: '' },
    { optType: "Lining", key: '32', name: '32', imageSrc: webImageNamed('Fabrics/LiningFabrics/32.jpg'), price: null, company: '' },
    { optType: "Lining", key: '33', name: '33', imageSrc: webImageNamed('Fabrics/LiningFabrics/33.jpg'), price: null, company: '' },
    { optType: "Lining", key: '34', name: '34', imageSrc: webImageNamed('Fabrics/LiningFabrics/34.jpg'), price: null, company: '' },
    { optType: "Lining", key: '36', name: '36', imageSrc: webImageNamed('Fabrics/LiningFabrics/36.jpg'), price: null, company: '' },
    { optType: "Lining", key: '39', name: '39', imageSrc: webImageNamed('Fabrics/LiningFabrics/39.jpg'), price: null, company: '' },
    { optType: "Lining", key: '40', name: '40', imageSrc: webImageNamed('Fabrics/LiningFabrics/40.jpg'), price: null, company: '' },
    { optType: "Lining", key: '44', name: '44', imageSrc: webImageNamed('Fabrics/LiningFabrics/44.jpg'), price: null, company: '' },
    { optType: "Lining", key: '45', name: '45', imageSrc: webImageNamed('Fabrics/LiningFabrics/45.jpg'), price: null, company: '' },
    { optType: "Lining", key: '47', name: '47', imageSrc: webImageNamed('Fabrics/LiningFabrics/47.jpg'), price: null, company: '' },
    { optType: "Lining", key: '48', name: '48', imageSrc: webImageNamed('Fabrics/LiningFabrics/48.jpg'), price: null, company: '' },
    { optType: "Lining", key: '49', name: '49', imageSrc: webImageNamed('Fabrics/LiningFabrics/49.jpg'), price: null, company: '' },
    { optType: "Lining", key: '50', name: '50', imageSrc: webImageNamed('Fabrics/LiningFabrics/50.jpg'), price: null, company: '' },
    { optType: "Lining", key: '53', name: '53', imageSrc: webImageNamed('Fabrics/LiningFabrics/53.jpg'), price: null, company: '' },
    { optType: "Lining", key: '54', name: '54', imageSrc: webImageNamed('Fabrics/LiningFabrics/54.jpg'), price: null, company: '' },
    { optType: "Lining", key: '56', name: '56', imageSrc: webImageNamed('Fabrics/LiningFabrics/56.jpg'), price: null, company: '' },
    { optType: "Lining", key: '59', name: '59', imageSrc: webImageNamed('Fabrics/LiningFabrics/59.jpg'), price: null, company: '' },
    { optType: "Lining", key: '60', name: '60', imageSrc: webImageNamed('Fabrics/LiningFabrics/60.jpg'), price: null, company: '' },
    { optType: "Lining", key: '63', name: '63', imageSrc: webImageNamed('Fabrics/LiningFabrics/63.jpg'), price: null, company: '' },
    { optType: "Lining", key: '64', name: '64', imageSrc: webImageNamed('Fabrics/LiningFabrics/64.jpg'), price: null, company: '' },
    { optType: "Lining", key: '67', name: '67', imageSrc: webImageNamed('Fabrics/LiningFabrics/67.jpg'), price: null, company: '' },
    { optType: "Lining", key: '68', name: '68', imageSrc: webImageNamed('Fabrics/LiningFabrics/68.jpg'), price: null, company: '' },
    { optType: "Lining", key: '70', name: '70', imageSrc: webImageNamed('Fabrics/LiningFabrics/70.jpg'), price: null, company: '' },
    { optType: "Lining", key: '71', name: '71', imageSrc: webImageNamed('Fabrics/LiningFabrics/71.jpg'), price: null, company: '' },
    { optType: "Lining", key: '75', name: '75', imageSrc: webImageNamed('Fabrics/LiningFabrics/75.jpg'), price: null, company: '' },
    { optType: "Lining", key: '76', name: '76', imageSrc: webImageNamed('Fabrics/LiningFabrics/76.jpg'), price: null, company: '' },
    { optType: "Lining", key: '78', name: '78', imageSrc: webImageNamed('Fabrics/LiningFabrics/78.jpg'), price: null, company: '' },
    { optType: "Lining", key: '81', name: '81', imageSrc: webImageNamed('Fabrics/LiningFabrics/81.jpg'), price: null, company: '' },
    { optType: "Lining", key: '83', name: '83', imageSrc: webImageNamed('Fabrics/LiningFabrics/83.jpg'), price: null, company: '' },
    { optType: "Lining", key: '97', name: '97', imageSrc: webImageNamed('Fabrics/LiningFabrics/97.jpg'), price: null, company: '' },
    { optType: "Lining", key: '101', name: '101', imageSrc: webImageNamed('Fabrics/LiningFabrics/101.jpg'), price: null, company: '' },
    { optType: "Lining", key: '102', name: '102', imageSrc: webImageNamed('Fabrics/LiningFabrics/102.jpg'), price: null, company: '' },
    { optType: "Lining", key: '103', name: '103', imageSrc: webImageNamed('Fabrics/LiningFabrics/103.jpg'), price: null, company: '' },
    { optType: "Lining", key: '106', name: '106', imageSrc: webImageNamed('Fabrics/LiningFabrics/106.jpg'), price: null, company: '' },
    { optType: "Lining", key: '108', name: '108', imageSrc: webImageNamed('Fabrics/LiningFabrics/108.jpg'), price: null, company: '' },
    { optType: "Lining", key: '109', name: '109', imageSrc: webImageNamed('Fabrics/LiningFabrics/109.jpg'), price: null, company: '' },
    { optType: "Lining", key: '111', name: '111', imageSrc: webImageNamed('Fabrics/LiningFabrics/111.jpg'), price: null, company: '' },
    { optType: "Lining", key: '112', name: '112', imageSrc: webImageNamed('Fabrics/LiningFabrics/112.jpg'), price: null, company: '' },
    { optType: "Lining", key: '114', name: '114', imageSrc: webImageNamed('Fabrics/LiningFabrics/114.jpg'), price: null, company: '' },
    { optType: "Lining", key: '122', name: '122', imageSrc: webImageNamed('Fabrics/LiningFabrics/122.jpg'), price: null, company: '' },
    { optType: "Lining", key: '125', name: '125', imageSrc: webImageNamed('Fabrics/LiningFabrics/125.jpg'), price: null, company: '' },
    { optType: "Lining", key: '128', name: '128', imageSrc: webImageNamed('Fabrics/LiningFabrics/128.jpg'), price: null, company: '' },
    { optType: "Lining", key: '129', name: '129', imageSrc: webImageNamed('Fabrics/LiningFabrics/129.jpg'), price: null, company: '' },
    { optType: "Lining", key: '130', name: '130', imageSrc: webImageNamed('Fabrics/LiningFabrics/130.jpg'), price: null, company: '' },
    { optType: "Lining", key: '131', name: '131', imageSrc: webImageNamed('Fabrics/LiningFabrics/131.jpg'), price: null, company: '' },
    { optType: "Lining", key: '133', name: '133', imageSrc: webImageNamed('Fabrics/LiningFabrics/133.jpg'), price: null, company: '' },
    { optType: "Lining", key: '134', name: '134', imageSrc: webImageNamed('Fabrics/LiningFabrics/134.jpg'), price: null, company: '' },
    { optType: "Lining", key: '139', name: '139', imageSrc: webImageNamed('Fabrics/LiningFabrics/139.jpg'), price: null, company: '' },
    { optType: "Lining", key: '143', name: '143', imageSrc: webImageNamed('Fabrics/LiningFabrics/143.jpg'), price: null, company: '' },
    { optType: "Lining", key: '144', name: '144', imageSrc: webImageNamed('Fabrics/LiningFabrics/144.jpg'), price: null, company: '' },
    { optType: "Lining", key: '145', name: '145', imageSrc: webImageNamed('Fabrics/LiningFabrics/145.jpg'), price: null, company: '' },
    { optType: "Lining", key: '146', name: '146', imageSrc: webImageNamed('Fabrics/LiningFabrics/146.jpg'), price: null, company: '' },
    { optType: "Lining", key: '147', name: '147', imageSrc: webImageNamed('Fabrics/LiningFabrics/147.jpg'), price: null, company: '' },
    { optType: "Lining", key: '148', name: '148', imageSrc: webImageNamed('Fabrics/LiningFabrics/148.jpg'), price: null, company: '' },
    { optType: "Lining", key: '151', name: '151', imageSrc: webImageNamed('Fabrics/LiningFabrics/151.jpg'), price: null, company: '' },
    { optType: "Lining", key: '154', name: '154', imageSrc: webImageNamed('Fabrics/LiningFabrics/154.jpg'), price: null, company: '' },
    { optType: "Lining", key: '157', name: '157', imageSrc: webImageNamed('Fabrics/LiningFabrics/157.jpg'), price: null, company: '' },
    { optType: "Lining", key: '181', name: '181', imageSrc: webImageNamed('Fabrics/LiningFabrics/181.jpg'), price: null, company: '' },
    { optType: "Lining", key: '182', name: '182', imageSrc: webImageNamed('Fabrics/LiningFabrics/182.jpg'), price: null, company: '' },
    { optType: "Lining", key: '187', name: '187', imageSrc: webImageNamed('Fabrics/LiningFabrics/187.jpg'), price: null, company: '' },
    { optType: "Lining", key: '190', name: '190', imageSrc: webImageNamed('Fabrics/LiningFabrics/190.jpg'), price: null, company: '' },
    { optType: "Lining", key: '192', name: '192', imageSrc: webImageNamed('Fabrics/LiningFabrics/192.jpg'), price: null, company: '' },
    { optType: "Lining", key: '201', name: '201', imageSrc: webImageNamed('Fabrics/LiningFabrics/201.jpg'), price: null, company: '' },
    { optType: "Lining", key: '202', name: '202', imageSrc: webImageNamed('Fabrics/LiningFabrics/202.jpg'), price: null, company: '' },
    { optType: "Lining", key: '212', name: '212', imageSrc: webImageNamed('Fabrics/LiningFabrics/212.jpg'), price: null, company: '' },
    { optType: "Lining", key: '219', name: '219', imageSrc: webImageNamed('Fabrics/LiningFabrics/219.jpg'), price: null, company: '' },
    { optType: "Lining", key: '220', name: '220', imageSrc: webImageNamed('Fabrics/LiningFabrics/220.jpg'), price: null, company: '' },
    { optType: "Lining", key: '225', name: '225', imageSrc: webImageNamed('Fabrics/LiningFabrics/225.jpg'), price: null, company: '' },
    { optType: "Lining", key: '226', name: '226', imageSrc: webImageNamed('Fabrics/LiningFabrics/226.jpg'), price: null, company: '' },
    { optType: "Lining", key: '227', name: '227', imageSrc: webImageNamed('Fabrics/LiningFabrics/227.jpg'), price: null, company: '' },
    { optType: "Lining", key: '228', name: '228', imageSrc: webImageNamed('Fabrics/LiningFabrics/228.jpg'), price: null, company: '' },
    { optType: "Lining", key: '231', name: '231', imageSrc: webImageNamed('Fabrics/LiningFabrics/231.jpg'), price: null, company: '' },
    { optType: "Lining", key: '232', name: '232', imageSrc: webImageNamed('Fabrics/LiningFabrics/232.jpg'), price: null, company: '' },
    { optType: "Lining", key: '234', name: '234', imageSrc: webImageNamed('Fabrics/LiningFabrics/234.jpg'), price: null, company: '' },
    { optType: "Lining", key: '237', name: '237', imageSrc: webImageNamed('Fabrics/LiningFabrics/237.jpg'), price: null, company: '' },
    { optType: "Lining", key: '238', name: '238', imageSrc: webImageNamed('Fabrics/LiningFabrics/238.jpg'), price: null, company: '' },
    { optType: "Lining", key: '239', name: '239', imageSrc: webImageNamed('Fabrics/LiningFabrics/239.jpg'), price: null, company: '' },
    { optType: "Lining", key: '240', name: '240', imageSrc: webImageNamed('Fabrics/LiningFabrics/240.jpg'), price: null, company: '' },
    { optType: "Lining", key: '241', name: '241', imageSrc: webImageNamed('Fabrics/LiningFabrics/241.jpg'), price: null, company: '' },
    { optType: "Lining", key: '243', name: '243', imageSrc: webImageNamed('Fabrics/LiningFabrics/243.jpg'), price: null, company: '' },
    { optType: "Lining", key: '245', name: '245', imageSrc: webImageNamed('Fabrics/LiningFabrics/245.jpg'), price: null, company: '' },
    { optType: "Lining", key: '246', name: '246', imageSrc: webImageNamed('Fabrics/LiningFabrics/246.jpg'), price: null, company: '' },
    { optType: "Lining", key: '247', name: '247', imageSrc: webImageNamed('Fabrics/LiningFabrics/247.jpg'), price: null, company: '' },
    { optType: "Lining", key: '248', name: '248', imageSrc: webImageNamed('Fabrics/LiningFabrics/248.jpg'), price: null, company: '' },
    { optType: "Lining", key: '254', name: '254', imageSrc: webImageNamed('Fabrics/LiningFabrics/254.jpg'), price: null, company: '' },
    { optType: "Lining", key: '255', name: '255', imageSrc: webImageNamed('Fabrics/LiningFabrics/255.jpg'), price: null, company: '' },
    { optType: "Lining", key: '257', name: '257', imageSrc: webImageNamed('Fabrics/LiningFabrics/257.jpg'), price: null, company: '' },
    { optType: "Lining", key: '259', name: '259', imageSrc: webImageNamed('Fabrics/LiningFabrics/259.jpg'), price: null, company: '' },
    { optType: "Lining", key: '261', name: '261', imageSrc: webImageNamed('Fabrics/LiningFabrics/261.jpg'), price: null, company: '' },
    { optType: "Lining", key: '262', name: '262', imageSrc: webImageNamed('Fabrics/LiningFabrics/262.jpg'), price: null, company: '' },
    { optType: "Lining", key: '267', name: '267', imageSrc: webImageNamed('Fabrics/LiningFabrics/267.jpg'), price: null, company: '' },
    { optType: "Lining", key: '268', name: '268', imageSrc: webImageNamed('Fabrics/LiningFabrics/268.jpg'), price: null, company: '' },
    { optType: "Lining", key: '269', name: '269', imageSrc: webImageNamed('Fabrics/LiningFabrics/269.jpg'), price: null, company: '' },
    { optType: "Lining", key: '270', name: '270', imageSrc: webImageNamed('Fabrics/LiningFabrics/270.jpg'), price: null, company: '' },
    { optType: "Lining", key: '271', name: '271', imageSrc: webImageNamed('Fabrics/LiningFabrics/271.jpg'), price: null, company: '' },
    { optType: "Lining", key: '272', name: '272', imageSrc: webImageNamed('Fabrics/LiningFabrics/272.jpg'), price: null, company: '' },
    { optType: "Lining", key: '273', name: '273', imageSrc: webImageNamed('Fabrics/LiningFabrics/273.jpg'), price: null, company: '' },
    { optType: "Lining", key: '274', name: '274', imageSrc: webImageNamed('Fabrics/LiningFabrics/274.jpg'), price: null, company: '' },
    { optType: "Lining", key: '275', name: '275', imageSrc: webImageNamed('Fabrics/LiningFabrics/275.jpg'), price: null, company: '' },
    { optType: "Lining", key: '276', name: '276', imageSrc: webImageNamed('Fabrics/LiningFabrics/276.jpg'), price: null, company: '' },
    { optType: "Lining", key: '277', name: '277', imageSrc: webImageNamed('Fabrics/LiningFabrics/277.jpg'), price: null, company: '' },
    { optType: "Lining", key: '278', name: '278', imageSrc: webImageNamed('Fabrics/LiningFabrics/278.jpg'), price: null, company: '' },
    { optType: "Lining", key: '289', name: '289', imageSrc: webImageNamed('Fabrics/LiningFabrics/289.jpg'), price: null, company: '' },
    { optType: "Lining", key: '290', name: '290', imageSrc: webImageNamed('Fabrics/LiningFabrics/290.jpg'), price: null, company: '' },
    { optType: "Lining", key: '291', name: '291', imageSrc: webImageNamed('Fabrics/LiningFabrics/291.jpg'), price: null, company: '' },
    { optType: "Lining", key: '292', name: '292', imageSrc: webImageNamed('Fabrics/LiningFabrics/292.jpg'), price: null, company: '' },
    { optType: "Lining", key: '293', name: '293', imageSrc: webImageNamed('Fabrics/LiningFabrics/293.jpg'), price: null, company: '' },
    { optType: "Lining", key: '294', name: '294', imageSrc: webImageNamed('Fabrics/LiningFabrics/294.jpg'), price: null, company: '' },
    { optType: "Lining", key: '295', name: '295', imageSrc: webImageNamed('Fabrics/LiningFabrics/295.jpg'), price: null, company: '' },
    { optType: "Lining", key: '296', name: '296', imageSrc: webImageNamed('Fabrics/LiningFabrics/296.jpg'), price: null, company: '' },
    { optType: "Lining", key: '297', name: '297', imageSrc: webImageNamed('Fabrics/LiningFabrics/297.jpg'), price: null, company: '' },
    { optType: "Lining", key: '298', name: '298', imageSrc: webImageNamed('Fabrics/LiningFabrics/298.jpg'), price: null, company: '' },
    { optType: "Lining", key: '299', name: '299', imageSrc: webImageNamed('Fabrics/LiningFabrics/299.jpg'), price: null, company: '' },
    { optType: "Lining", key: '300', name: '300', imageSrc: webImageNamed('Fabrics/LiningFabrics/300.jpg'), price: null, company: '' },
    { optType: "Lining", key: '301', name: '301', imageSrc: webImageNamed('Fabrics/LiningFabrics/301.jpg'), price: null, company: '' },
    { optType: "Lining", key: '302', name: '302', imageSrc: webImageNamed('Fabrics/LiningFabrics/302.jpg'), price: null, company: '' },
    { optType: "Lining", key: '303', name: '303', imageSrc: webImageNamed('Fabrics/LiningFabrics/303.jpg'), price: null, company: '' },
    ]


    fabricImageForSelectedKey = (tone, key) => {
        let opts = null
        switch (tone) {
            case this.TONE_KEYS.AllSeasons: opts = this.fabric_AllSeasons; break;
            case this.TONE_KEYS.FallWinter: opts = this.fabric_FallWinter; break;
            case this.TONE_KEYS.WaterResistant: opts = this.fabric_WaterResistant; break;
            default: opts = this.fabric_AllSeasons; break;
        }
        let thisObj = opts.filter((obj) => {
            return obj.key === key
        })
        return webImageNamed(thisObj[0].imageSrc)
    }


    optsForTone = (tone) => {
        switch (tone) {
            case this.TONE_KEYS.AllSeasons: return this.fabric_AllSeasons;
            case this.TONE_KEYS.FallWinter: return this.fabric_FallWinter;
            case this.TONE_KEYS.WaterResistant: return this.fabric_WaterResistant;
            default: return this.fabric_AllSeasons;
        }
    }

    fabricStep = () => {
        // Change images here with respect to button choices

        // console.log('Calculating Fabric again. SelectedTone =', this.state.selected_Tone.key)

        let thisTone = this.state.selected_Tone ? this.state.selected_Tone.key : this.TONE_KEYS.blues

        let opts = this.optsForTone(thisTone)


        console.log('OPTS NOW =', opts)
        console.log( opts)


        return {
            name: 'Pick a Fabric',
            key: this.STEP_KEYS.fabric,
            opts: opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1'
        }
    }


    






    scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(this.scrollToTop);
            window.scrollTo(0, c - c / 8);
        }
    }


    onChangeHandler = (direction) => {
        console.log('CHANGE HAPPEND -', direction)
        this.scrollToTop()
        if (direction === 'done') {
            this.donePressed()
        }
        if (direction === 'cancelled') {
            // Cancel Pressed
        }
    }



     

    donePressed = () => {

        // Create And Save Style Here first


        if (this._mounted) {
            let ns = { ...this.state }
            ns.isComplete = true
            this.setState(ns)


            console.log("--------SELELCTED FABRIC IS ---------");
            console.log(this.state.selected_Fabric);


            // CHECK 3
            // const frontImageObj = this.fro.filter((obj) => {
            //     return obj.key === this.state.selected_FrontButtons
            // })
            let mainImage = imageNamed('singalButton')

            if (this.state.selected_FrontButtons.imageSrc){
                mainImage = this.state.selected_FrontButtons.imageSrc
            }

            let fabricImage = null

            if (this.state.selected_Fabric.imageSrc){
                fabricImage = this.state.selected_Fabric.imageSrc
            }

            let uniqId = 'id' + (new Date()).getTime();
            let styleName = this.state.productType + "-" + makeid(3)
            
            if (this.state.editingProduct) {
                uniqId = this.state.editingProduct.key
                styleName = this.state.editingProduct.styleName
            }





            let allSelelected = [
                this.state.selected_FrontButtons, 
                this.state.selected_Lapel, 
                this.state.selected_LowerPockets, 
                this.state.selected_Vent, 
                this.state.selected_SleeveButtons,
                this.state.selected_SleeveButtonOrientation,
                this.state.selected_SleeveButtonHoleContrast,
                this.state.selected_SleeveButtonHoleContrastThread,
                this.state.selected_Lining, 
                this.state.selected_Monogram, 
                this.state.selected_MonogramThreadColor,
                this.state.selected_Tone, 
                this.state.selected_Fabric, 
                this.state.selected_FabricLining, 
                this.state.selected_SuitButtons,
                this.state.selected_ContrastOption, 
                this.state.selected_ContrastFabric
            ]



                let finalPrice = this.basePrice
                let addedPriceObjs = []
                let addOnPrice = 0
    
                allSelelected.forEach(asel => {

                    console.log("THIS ASEL");
                    console.log(asel);
                    
                    if (asel != null) {
                        if (asel.price) {
                            finalPrice += asel.price
                            addOnPrice += asel.price
                            addedPriceObjs.push({ type: asel.optType, name: asel.name, price: asel.price })
                        }
                    }
    
                })



            // allSelelected.forEach( selectedObj  => {
            //     if (selelectedObj.price) {
            //         finalPrice += selectedObj.price
            //         addedPriceObjs.push( { type: selectedObj.optType, name: selectedObj.name, price: selectedObj.price })
            //     }
            // })

            console.log("this.state.selected_FrontButtons = ")
            console.log(this.state.selected_FrontButtons)




            let style = {
                frontButtons: this.state.selected_FrontButtons,
                lapel: this.state.selected_Lapel,
                // lapelWidth: this.state.selected_LapelWidth,
                lowerPockets: this.state.selected_LowerPockets,
                vent: this.state.selected_Vent,
                sleeveButtons: this.state.selected_SleeveButtons,
                sleeveButtonOrientation: this.state.selected_SleeveButtonOrientation,
                sleeveButtonHoleContrast: this.state.selected_SleeveButtonHoleContrast,
                sleeveButtonHoleContrastThread: this.state.selected_SleeveButtonHoleContrastThread,
                lining: this.state.selected_Lining,
                monogram: this.state.selected_Monogram,
                monogramThreadColor: this.state.selected_MonogramThreadColor,
                tone: this.state.selected_Tone,
                fabric: this.state.selected_Fabric,
                fabricLining:   this.state.selected_FabricLining,
                suitButtons:   this.state.selected_SuitButtons,
                contrastOption:   this.state.selected_ContrastOption,
                contrastFabric:    this.state.selected_ContrastFabric
            }


            let styleArr = Object.keys(style).map(function (key) {

                return [key, style[key]];
            });

            let withoutNull = styleArr.filter(ar => {
                return !ar.includes(null)
            })


            let description = ' '
            withoutNull.forEach((ms, index) => {
                let type = ms[0]
                let val = ms[1].name


                description += ' ' + type + ':' + val 
                if (ms[1].customInput){
                    description += `<${ms[1].customInput}> `
                }
                if (index == (withoutNull.length - 1)){
                    description += ""
                }else{
                    description += ","
                }


            })

            // let prodObj = new C_Product(null,true,this.state.productType, uniqId,"",null, null,[], [], style, "", description, false, null, true, false, null, true, [], currencySymbol , this.basePrice,addOnPrice, 0,0, finalPrice, addedPriceObjs, styleName, fabricImage, mainImage)

            // let nc = new C_Product(null,null, null,null, null, null, null, null, null, null, null, null, null, null, null, null, )
            // prodObj.key = 'id' + (new Date()).getTime();

            let prod = Product.init()
            prod.type = this.state.productType
            prod.conciergeSelections = [style]

            let fabricAndToneMaterials = []

            let concToTwSelections = Object.values(style).map( (obj) => {
                if (obj === null) {
                    return null
                }

                if (obj.optType.toLowerCase() === 'fabric'){
                    fabricAndToneMaterials.push("Fabric - " + obj.name  + (obj.customInput ?  `<${obj.customInput}>` : "")  )
                    return null
                }

                if (obj.optType.toLowerCase() === 'tone'){
                    return null
                    // fabricAndToneMaterials.push("Fabric - " + obj.name  + (obj.customInput ?  `<${obj.customInput}>` : "")  )
                }

                return { 
                    "key" : obj.optType, 
                    "value" : obj.name  + (obj.customInput ?  `<${obj.customInput}>` : "") , 
                    "styleSelectionId": obj.styleSelectionId ?? "",
                    "imageSrc": obj.imageSrc ?? null, 
                    "isConciergeSelection" : true
                 }
            })

            concToTwSelections = concToTwSelections.filter( (obj) => {
                return (obj !== null)
            })

            prod.materialToSource = fabricAndToneMaterials


            prod.styleSelections = concToTwSelections

            prod.basePrice = this.basePrice
            prod.addOnPrice = addOnPrice
            prod.name = styleName
            prod.taxOnPrice = 0
            prod.shippingPrice = 0
            prod.taxDescription = ""
            prod.shippingDescription = ""
            prod.price = finalPrice
            prod.addedPriceObjs = addedPriceObjs
            prod.styleName = styleName
            prod.imageUrl = mainImage
            prod.fabricImage = fabricImage
            prod.imagesUrls = [mainImage, fabricImage]
            prod.description = description
            prod.isBespoke = true
            prod.isViaConcierge = true


            // let prodObj = {
            //     key: uniqId,
            //     styleName:  styleName,
            //     frontImage: mainImage,
            //     fabricImage: fabricImage,
            //     description: description,
            //     type: this.state.productType,
            //     basePrice: this.basePrice,
            //     price: finalPrice,
            //     addedPriceObjs: addedPriceObjs,
            //     selectedSelections: style
            // }

            console.log('Sending Style Object -', prod)

            this.props.isDone('created', prod)

        }
    }

    stepItemSelected = (stepKey, itemKey, customInput) => {
        console.log('Step selection happend in StepKey =' + stepKey + ', ItemKey= ', itemKey)

        if (this._mounted) {
            console.log('IS MOUNTED')
            let ns = { ...this.state }
            console.log("GOT Custom INPUT " + customInput)

                            // selected_FrontButtons : frontButtons
                // selected_Lapel : lapel
                // selected_LapelWidth : lapelWidth
                // selected_LowerPockets : lowerPockets
                // selected_Vent : vent
                // selected_SleeveButtons : sleeveButtons
                // selected_Lining : lining
                // selected_Monogram : monogram
                // selected_Tone : tone
                // selected_Fabric : fabric

                // ns[stepKey] = itemKey

                // console.log("ITEM KEY =" + itemKey)
                // let firstOPT = this.frontButtons_Opts.filter( opt => { return (opt.key === itemKey)})


                // console.log("FIRST OPT")
                // console.log(firstOPT)




            switch (stepKey) {
                case this.STEP_KEYS.frontButtons: ns.selected_FrontButtons =  this.frontButtons_Opts.filter( opt => { return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.lapel: ns.selected_Lapel = this.lapel_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                // case this.STEP_KEYS.lapelWidth: ns.selected_LapelWidth = this.lapelWidth_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.lowerPockets: ns.selected_LowerPockets = this.lowerPockets_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.vent: ns.selected_Vent = this.vent_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.sleeveButtons: ns.selected_SleeveButtons = this.sleeveButtons_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.sleeveButtonOrientation: ns.selected_SleeveButtonOrientation = this.sleeveButtonOrientation_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.sleeveButtonHoleContrast: ns.selected_SleeveButtonHoleContrast = this.sleeveButtonHoleContrast_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.sleeveButtonHoleContrastThread: ns.selected_SleeveButtonHoleContrastThread = this.sleeveButtonHoleContrastThread_Opts.filter(opt => { return opt.key === itemKey })[0]; break;

                case this.STEP_KEYS.lining: ns.selected_Lining = this.lining_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.monogram: ns.selected_Monogram = this.monogram_Opts.filter( opt => {return opt.key === itemKey})[0] ; if (customInput && customInput !== ""){ ns.selected_Monogram["customInput"] = customInput } ; break;
                case this.STEP_KEYS.monogramThreadColor: ns.selected_MonogramThreadColor = this.monogramThreadColor_Opts.filter(opt => { return opt.key === itemKey })[0]; break;

                case this.STEP_KEYS.tone: ns.selected_Tone = this.toneOpts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.fabric: ns.selected_Fabric = this.fabricStep().opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.fabricLining: ns.selected_FabricLining = this.fabricLining_Step().opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.suitButtons: ns.selected_SuitButtons = this.suitButtons_Step().opts.filter(opt => { return opt.key === itemKey })[0]; break;

                case this.STEP_KEYS.contrastOption: ns.selected_ContrastOption = this.contrast_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.contrastFabric: ns.selected_ContrastFabric = this.contrastFabricOpts.filter(opt => { return opt.key === itemKey })[0]; break;
                default: break;
            }




            this.setState(ns)
        }

    }

    createUploadChoiceMade = (name, code) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.selectedCreateOrUpload = code
            this.setState(ns)
        }
    }

    createUploadChooser = () => {
        // return <div> <Box className='is-radiusless is-shadowless layerGreyBG  minHeight300 myFlexContainer '>
        return <div> <Box className='is-radiusless is-shadowless  minHeight300 myFlexContainer '>
            <ChooseBox choices={[{ name: 'Create Style', code: this.STYLE_START.create }, { name: 'Upload Style', code: this.STYLE_START.upload }]} choiceMade={this.createUploadChoiceMade} />
            {/* <ChooseBox choices={[{ name: 'Choose Style' }, { name: 'Upload Style' }]} choiceMade={this.choiceMadeInChooseBox} /> */}
        </Box>
            <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button>
        </div>
    }




    uploadImageStarted = () => {
        console.log('UPLOAD IMAGE STARTED IN WIZARD')
    }






    overcoatWizard = () => {


        // selected_FrontButtons : frontButtons
        // selected_Lapel : lapel
        // selected_LapelWidth : lapelWidth
        // selected_LowerPockets : lowerPockets
        // selected_Vent : vent
        // selected_SleeveButtons : sleeveButtons
        // selected_Lining : lining
        // selected_Monogram : monogram
        // selected_Tone : tone
        // selected_Fabric : fabric

    
    // let productStep = this.productStep()
    let frontButtons_Step = this.frontButtons_Step()
    let lapel_Step = this.lapel_Step()
    // let lapelWidth_Step = this.lapelWidth_Step()
    let lowerPockets_Step = this.lowerPockets_Step()
    let vent_Step = this.vent_Step()
    let sleeveButtons_Step = this.sleeveButtons_Step()
    let sleeveButtonOrientation_Step = this.sleeveButtonOrientation_Step()

    let sleeveButtonHoleContrast_Step = this.sleeveButtonHoleContrast_Step()
    let sleeveButtonHoleContrastThread_Step = this.sleeveButtonHoleContrastThread_Step()

    let lining_Step = this.lining_Step()
    let monogram_Step = this.monogram_Step()
    let monogramThreadColor_Step = this.monogramThreadColor_Step()

    let toneStep = this.toneStep()
    let fabricStep = this.fabricStep()
    let fabricLining_Step = this.fabricLining_Step()
    let suitButtons_Step = this.suitButtons_Step()

    let contrast_Step = this.contrast_Step()
    let contrastFabric_Step = this.contrastFabricStep()

    let isContrastFabricNeeded = false
    if (this.state.selected_ContrastOption) {
        if (this.state.selected_ContrastOption !== this.contrast_Opts[0])  {
            isContrastFabricNeeded = true
        }
    } 

    // sleeveButtonHoleContrastThread conditioned on sleeveButtonHoleContrast
    let is_sleeveButtonHoleContrastThread_Needed = false
    if (this.state.selected_SleeveButtonHoleContrast) {
        if (this.state.selected_SleeveButtonHoleContrast !== this.sleeveButtonHoleContrast_Opts[0])  {
            is_sleeveButtonHoleContrastThread_Needed = true
        }
    } 

    // monogramThreadColor conditioned on monogram
    let is_monogramThreadColor_Needed = false
    if (this.state.selected_Monogram) {
        if (this.state.selected_Monogram !== this.monogram_Opts[0])  {
            is_monogramThreadColor_Needed = true
        }
    } 


                // sleeveButtonOrientation
            // sleeveButtonHoleContrast
            // sleeveButtonHoleContrastThread
            // monogramThreadColor
            // fabricLining
            // suitButtons
            // contrastOption
            // contrastFabric



    let title = "Let's Create a OverCoat Style"
    let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
    let points = [
        'To make it easy we pick the cuts and tones before finding the fabric.',
        'We will measure you right after we have created all the needed styles.'
    ]

    return (<div >
        {/* <div ref={this.mainStepperDiv}></div> */}
        {(this.state.isComplete || this.state.isCancelled) ?
            <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
            :
            <StepWizard className=' fadeIn'>
                {/* {showUploader ? <Step type='uploader' skipable={true} isFirst={true} existingUploadImage={this.state.uploadImage} uploadImagePressed={this.uploadImageStarted} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  ></Step> : null} */}
               {this.state.editingProduct ? null : <Step name="Welcome" imageSrc={imageNamed('IconBg-Style')} overrideNext='Start' isMessageScreen={true} title={title} subtitle={subtitle} points={points} isFirst={true} skipable={false} stepKey={"1ehieheo23"} exitingSelectedKey={null} opts={null} isPhotoBox={false} photoRatio={'16by9'} onChange={this.onChangeHandler} itemSelected={null}  ></Step> }  

               <Step name={frontButtons_Step.name}  skipable={frontButtons_Step.skipable ?? false} stepKey={frontButtons_Step.key }     exitingSelectedKey={this.state.selected_FrontButtons ? this.state.selected_FrontButtons.key  : null }     opts={frontButtons_Step.opts} isFirst={true} isPhotoBox={frontButtons_Step.isPhotoBox} photoRatio={frontButtons_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={frontButtons_Step.boldText ?? false}  boldText={frontButtons_Step.boldText ?? false} ></Step>
               <Step name={lapel_Step.name}         skipable={lapel_Step.skipable ?? false} stepKey={lapel_Step.key }                   exitingSelectedKey={this.state.selected_Lapel ? this.state.selected_Lapel.key         : null }            opts={lapel_Step.opts} isFirst={false} isPhotoBox={lapel_Step.isPhotoBox} photoRatio={lapel_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={lapel_Step.boldText ?? false}  boldText={lapel_Step.boldText ?? false} ></Step>
               {/* <Step name={lapelWidth_Step.name}    skipable={lapelWidth_Step.skipable ?? false} stepKey={lapelWidth_Step.key }         exitingSelectedKey={this.state.selected_LapelWidth ? this.state.selected_LapelWidth.key    : null }       opts={lapelWidth_Step.opts} isFirst={false} isPhotoBox={lapelWidth_Step.isPhotoBox} photoRatio={lapelWidth_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={lapelWidth_Step.boldText ?? false}  boldText={lapelWidth_Step.boldText ?? false} ></Step>                */}
               <Step name={lowerPockets_Step.name}  skipable={lowerPockets_Step.skipable ?? false} stepKey={lowerPockets_Step.key }     exitingSelectedKey={this.state.selected_LowerPockets ? this.state.selected_LowerPockets.key  : null }     opts={lowerPockets_Step.opts} isFirst={false} isPhotoBox={lowerPockets_Step.isPhotoBox} photoRatio={lowerPockets_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={lowerPockets_Step.boldText ?? false}  boldText={lowerPockets_Step.boldText ?? false} ></Step>
               <Step name={vent_Step.name}          skipable={vent_Step.skipable ?? false} stepKey={vent_Step.key }                     exitingSelectedKey={this.state.selected_Vent ? this.state.selected_Vent.key          : null }             opts={vent_Step.opts} isFirst={false} isPhotoBox={vent_Step.isPhotoBox} photoRatio={vent_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={vent_Step.boldText ?? false}  boldText={vent_Step.boldText ?? false} ></Step>
               <Step name={sleeveButtons_Step.name} skipable={sleeveButtons_Step.skipable ?? false} stepKey={sleeveButtons_Step.key }   exitingSelectedKey={this.state.selected_SleeveButtons ? this.state.selected_SleeveButtons.key : null }    opts={sleeveButtons_Step.opts} isFirst={false} isPhotoBox={sleeveButtons_Step.isPhotoBox} photoRatio={sleeveButtons_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={sleeveButtons_Step.boldText ?? false}  boldText={sleeveButtons_Step.boldText ?? false} ></Step>
               <Step name={sleeveButtonOrientation_Step.name} skipable={sleeveButtonOrientation_Step.skipable ?? false} stepKey={sleeveButtonOrientation_Step.key} exitingSelectedKey={this.state.selected_SleeveButtonOrientation ? this.state.selected_SleeveButtonOrientation.key : null} opts={sleeveButtonOrientation_Step.opts} isFirst={false} isPhotoBox={sleeveButtonOrientation_Step.isPhotoBox} photoRatio={sleeveButtonOrientation_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={sleeveButtonOrientation_Step.boldText ?? false} boldText={sleeveButtonOrientation_Step.boldText ?? false} ></Step>

                <Step name={sleeveButtonHoleContrast_Step.name} skipable={sleeveButtonHoleContrast_Step.skipable ?? false} stepKey={sleeveButtonHoleContrast_Step.key} exitingSelectedKey={this.state.selected_SleeveButtonHoleContrast ? this.state.selected_SleeveButtonHoleContrast.key : null} opts={sleeveButtonHoleContrast_Step.opts} isFirst={false} isPhotoBox={sleeveButtonHoleContrast_Step.isPhotoBox} photoRatio={sleeveButtonHoleContrast_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={sleeveButtonHoleContrast_Step.boldText ?? false} boldText={sleeveButtonHoleContrast_Step.boldText ?? false} ></Step>

                {
                    is_sleeveButtonHoleContrastThread_Needed ? 
                        <Step name={sleeveButtonHoleContrastThread_Step.name} skipable={sleeveButtonHoleContrastThread_Step.skipable ?? false} stepKey={sleeveButtonHoleContrastThread_Step.key} exitingSelectedKey={this.state.selected_SleeveButtonHoleContrastThread ? this.state.selected_SleeveButtonHoleContrastThread.key : null} opts={sleeveButtonHoleContrastThread_Step.opts} isFirst={false} isPhotoBox={sleeveButtonHoleContrastThread_Step.isPhotoBox} photoRatio={sleeveButtonHoleContrastThread_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={sleeveButtonHoleContrastThread_Step.boldText ?? false} boldText={sleeveButtonHoleContrastThread_Step.boldText ?? false} ></Step>
                    : null
                }
               
               <Step name={lining_Step.name}        skipable={lining_Step.skipable ?? false} stepKey={lining_Step.key }                 exitingSelectedKey={this.state.selected_Lining ? this.state.selected_Lining.key        : null }           opts={lining_Step.opts} isFirst={false} isPhotoBox={lining_Step.isPhotoBox} photoRatio={lining_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={lining_Step.boldText ?? false}  boldText={lining_Step.boldText ?? false} ></Step>
               <Step name={monogram_Step.name}   needsCustomInput={true}   skipable={monogram_Step.skipable ?? false} stepKey={monogram_Step.key }             exitingSelectedKey={this.state.selected_Monogram ? this.state.selected_Monogram.key      : null }         opts={monogram_Step.opts} isFirst={false} isPhotoBox={monogram_Step.isPhotoBox} photoRatio={monogram_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={monogram_Step.boldText ?? false}  boldText={monogram_Step.boldText ?? false} ></Step>
              
               {
                        is_monogramThreadColor_Needed ?
                            <Step name={monogramThreadColor_Step.name} needsCustomInput={false} skipable={monogramThreadColor_Step.skipable ?? false} stepKey={monogramThreadColor_Step.key} exitingSelectedKey={this.state.selected_MonogramThreadColor ? this.state.selected_MonogramThreadColor.key : null} opts={monogramThreadColor_Step.opts} isFirst={false} isPhotoBox={monogram_Step.isPhotoBox} photoRatio={monogramThreadColor_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={monogramThreadColor_Step.boldText ?? false} boldText={monogramThreadColor_Step.boldText ?? false} ></Step>
                        : null
                }

               <Step name={toneStep.name}           skipable={toneStep.skipable ?? false} stepKey={toneStep.key }                       exitingSelectedKey={this.state.selected_Tone ? this.state.selected_Tone.key          : null }             opts={toneStep.opts} isFirst={false} isPhotoBox={toneStep.isPhotoBox} photoRatio={toneStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={toneStep.boldText ?? false}  boldText={toneStep.boldText ?? false} ></Step>
               {/* <Step name={fabricStep.name}         skipable={fabricStep.skipable ?? false} stepKey={fabricStep.key }                   exitingSelectedKey={this.state.selected_Fabric ? this.state.selected_Fabric.key        : null }           opts={fabricStep.opts} isFirst={false} isPhotoBox={fabricStep.isPhotoBox} photoRatio={fabricStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={fabricStep.boldText ?? false}  boldText={fabricStep.boldText ?? false} ></Step> */}
               <Step name={fabricStep.name} skipable={fabricStep.skipable ?? false} stepKey={fabricStep.key} exitingSelectedKey={this.state.selected_Fabric ? this.state.selected_Fabric.key : null} opts={fabricStep.opts} isFirst={false} isPhotoBox={fabricStep.isPhotoBox} photoRatio={fabricStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={fabricStep.boldText ?? false} boldText={fabricStep.boldText ?? false} ></Step>


               <Step name={fabricLining_Step.name} skipable={fabricLining_Step.skipable ?? false} stepKey={fabricLining_Step.key} exitingSelectedKey={this.state.selected_FabricLining ? this.state.selected_FabricLining.key : null} opts={fabricLining_Step.opts} isFirst={false} isPhotoBox={fabricLining_Step.isPhotoBox} photoRatio={fabricLining_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={fabricLining_Step.boldText ?? false} boldText={fabricLining_Step.boldText ?? false} ></Step>
                <Step name={suitButtons_Step.name} skipable={suitButtons_Step.skipable ?? false} stepKey={suitButtons_Step.key} exitingSelectedKey={this.state.selected_SuitButtons ? this.state.selected_SuitButtons.key : null} opts={suitButtons_Step.opts} isFirst={false} isPhotoBox={suitButtons_Step.isPhotoBox} photoRatio={suitButtons_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={true} paddingLess={suitButtons_Step.boldText ?? false} boldText={suitButtons_Step.boldText ?? false} ></Step>
                {/* <Step name={contrast_Step.name} needsCustomInput={false} skipable={contrast_Step.skipable ?? false} stepKey={contrast_Step.key} exitingSelectedKey={this.state.selected_ContrastOption ? this.state.selected_ContrastOption.key : null} opts={contrast_Step.opts} isFirst={false} isPhotoBox={contrast_Step.isPhotoBox} photoRatio={contrast_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={!isContrastFabricNeeded} paddingLess={contrast_Step.boldText ?? false} boldText={contrast_Step.boldText ?? false} ></Step> */}
                {/* {
                    isContrastFabricNeeded  ?

                    <Step name={contrastFabric_Step.name} needsCustomInput={false} skipable={contrastFabric_Step.skipable ?? false} stepKey={contrastFabric_Step.key} exitingSelectedKey={this.state.selected_ContrastFabric ? this.state.selected_ContrastFabric.key : null} opts={contrastFabric_Step.opts} isFirst={false} isPhotoBox={contrastFabric_Step.isPhotoBox} photoRatio={contrastFabric_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={true} paddingLess={contrastFabric_Step.boldText ?? false} boldText={contrastFabric_Step.boldText ?? false} ></Step>
                    :
                    null
                } */}

            </StepWizard>
        }
        {/* <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button> */}
    </div>
    )
}



    

    render() {
        return (
            this.overcoatWizard()
        )
    }

}


export default WizardForOverCoat
