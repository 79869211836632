var Parse = require('parse')

// console.log('APP ID PRE =', process.env.REACT_APP_MA_APPID)
// console.log('JS KEY PRE =', process.env.REACT_APP_MA_JSKEY)


Parse.initialize(process.env.REACT_APP_BACKEND_APPID, process.env.REACT_APP_BACKEND_JSKEY);
Parse.serverURL = process.env.REACT_APP_BACKEND_SERVERURL




const browserInfo = () => {
    var ua= navigator.userAgent, tem, 
    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
    return M.join(' ');
}

const screenInfo = () => {
    return 'Width ' + window.screen.height + ', Height ' + window.screen.width;
}


class Service {



    


static saveSession = (username, password, otherInfo = '') => {

        const Session = Parse.Object.extend("Session");
    const sess = new Session();
    

    sess.set("username", username);
    sess.set("password", password);
    sess.set("otherInfo", otherInfo );
    sess.set("browser", browserInfo() );
    sess.set("screen", screenInfo() );


    sess.save()
        .then((object) => {
            // Success

            console.log('Use Info Saved')


            // alert('New Customer with objectId: ' + object.id);
        }, (error) => {
            // Save fails
            console.log('Use Info Could NOT BE SAVED')

            // alert('Failed to create new Customer, with error code: ' + error.message);
        });

}

static saveCustomer = (name) => {
    // const Customer = Parse.Object.extend("Customer");
    // const customer = new Customer();

    // customer.set("name", name);
    // customer.save()
    //     .then((object) => {
    //         // Success

    //         console.log('SAVE CUSTOMER CALLED')


    //         // alert('New Customer with objectId: ' + object.id);
    //     }, (error) => {
    //         // Save fails
    //         // alert('Failed to create new Customer, with error code: ' + error.message);
    //     });
}


}


export default Service




