import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faPlus, faSync } from '@fortawesome/free-solid-svg-icons'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Button,
    Box,
} from 'react-bulma-components/dist'

import MeasurementBox from './MeasurementBox'
// MAKE MEASURE BOX

import { PRODUCT_TYPE } from '../utilities/utils'
import MeasurementsWizard from './MeasurementsWizard'
import { imageNamed, scrollToRef, uniqId, EXTRA_STEP_TYPE } from '../utilities/utils'
import { getCurrentCustomer } from '../utilities/Login'





class MeasureSection extends Component {


    essentialMeasures = []


    customerMeasuresExists = () => {

        let cust = getCurrentCustomer()

        if (cust === null){
            return false
        }

        let existingMeasure = cust.conciergeMeasurements ?? null

        if (existingMeasure === null) {
            return false
        }
        return true
    }

    customerMeasures = () => {

        let cust = getCurrentCustomer()
        let existingMeasure = cust.conciergeMeasurements ?? null

        if (existingMeasure === null) {
            return false
        }

        console.log('FOUND EXISTING MEASURES ')
        console.log(existingMeasure)

        let measureObj = {
            key : "id1605901043059",
            products : [],
            description : "",
            measures : existingMeasure
        }

        console.log('SENDING MEASURE OBJ ')
        console.log([measureObj])

        return [measureObj]
    }

    state = {
        measures:  [],
        editingMeasure: null,
        showMeasurementWizard: false,
        productsArray: this.props.productsArray
    }

    _mounted = false

    componentDidMount = () => {
        this._mounted = true

        if (this.props.measures) {
            if (this._mounted) {
                let ns = { ...this.state }
                ns.measures = this.props.measures
                // if (this.props.styles) {
                //     let prArr = []
                //     this.props.styles.forEach(st => {
                //         prArr.push(st.productType)
                //     })

                //     console.log('UPDATING PRODUCTS ARRAAY________***** = ', prArr)

                //     ns.productsArray = prArr
                // }
                this.setState(ns)
            }
        }

    }

    componentWillUnmount = () => {
        this._mounted = false
    }



    measureWizardComplete = (endpoint, measure) => {
        console.log('Measurement Wizard Completed with endpoint = ' + endpoint + '. And Measure =', measure)
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showMeasurementWizard = false
            ns.productsArray = [...ns.productsArray]
            let existingMeasures = [...ns.measures]

            if (this.state.editingMeasure) {
                // console.log('Old Measure =', ns.editingMeasure)
                ns.editingMeasure = null
                let objIndex = existingMeasures.findIndex(obj =>
                    obj.key === measure.key
                )
                existingMeasures[objIndex] = measure
                console.log('CHANGED SELECTED OBJECT')
                console.log('New Measure =', measure)

            } else {
                existingMeasures.push(measure)
            }

            ns.measures = existingMeasures
            this.setState(ns)

            setTimeout( () => {
                this.props.measuresChanged(existingMeasures)
            }, 400 )

        }

    }

    addMeasurePressed = () => {
        // Add Option to select wizard here, Suit, Shirt, Pant, Sherwani etce
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showMeasurementWizard = true
            this.setState(ns)
        }
    }

    measureRemovePressed = (key) => {
        console.log(' Remove Pressed for Measure With Key -', key)

        if (this._mounted) {
            let ns = { ...this.state }
            ns.showMeasurementWizard = false
            let existingMeasures = [...ns.measures]
            existingMeasures = existingMeasures.filter((obj) => {
                return obj.key !== key
            })
            ns.measures = existingMeasures
            this.setState(ns)
            this.props.measuresChanged(existingMeasures)
        }

    }

    measureEditPressed = (key) => {
        console.log(' Edit Pressed for Measure With Key -', key)

        if (this._mounted) {
            const measures = [...this.state.measures]
            let thisMeasure = measures.filter((obj) => {
                return obj.key === key
            })[0]
            console.log('EDITING This Measure =', thisMeasure)
            let ns = { ...this.state }
            ns.showMeasurementWizard = true
            ns.editingMeasure = thisMeasure
            this.setState(ns)
        }
    }

    cancelPressedInWizard = () => {
        // clear any imaages of keys 
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showMeasurementWizard = false
            ns.editingMeasure = false
            this.setState(ns)
        }
    }


    addOrSendMeasurementOptions = () => {

    }

    addMeasurementView = () => {
        return (
            <div> <div className=' marginTop20'>  </div>
                <br />
                <Box className=' is-shadowless  myFlexContainerNoWrap boxLast0 pad0 fadeIn'>
                    <div className='has-items-vertical-center selectable '>

                        {this.customerMeasuresExists() ?
                            <Button className='is-radiusless  addStyleBox   no-decoration' onClick={this.addMeasurePressed} >
                                <i className=" has-texh-weight-thin marginRight10"> <FontAwesomeIcon className='' icon={faSync} /></i>
                                Check & Update Measurements
                            </Button>
                            :

                            <Button className='is-radiusless  addStyleBox   no-decoration' onClick={this.addMeasurePressed} >
                                <i className=" has-texh-weight-thin marginRight10"> <FontAwesomeIcon className='' icon={faPlus} /></i>
                                Add Measurement
                            </Button>
                        }



                    </div>
                    <p className='is-size-7 marginTop10 has-text-grey has-text-weight-medium'> Its Easy. We will guide you.</p>

                </Box>
            </div>

        )
    }


    getMeasureSection = () => {

        if (this.state.showMeasurementWizard) {

            console.log("SENDING PRODUCTS ARRAY IN MEASURE WIZARD")
            console.log(this.state.productsArray)

            // CHECK THE SELECTED WIZARD HERE
            // return <Box className='is-radiusless is-shadowless layerGreyBG  minHeight60vh '>
            return <Box className='is-radiusless is-shadowless   fadeIn'>
                <MeasurementsWizard productsArray={this.state.productsArray} editingMeasure={this.state.editingMeasure} cancelPressed={this.cancelPressedInWizard} isDone={this.measureWizardComplete} />
            </Box>
        }



        let measures = this.state.measures.map((measure, index) => {
            console.log('THIS MEASURE KEY = ', measure.key + index)
            // return <div className='marginTop30'> <StyleCard key={style.key} style={style} removePressed={this.measureRemovePressed} editPressed={this.measureEditPressed} /> </div>
            return <div key={measure.key} className='marginTop30'> <MeasurementBox measure={measure} removePressed={this.measureRemovePressed} editPressed={this.measureEditPressed} /> </div>
        })

        return (<div> <div className=' marginTop20 fadeIn '> {measures} </div>
            {measures.length > 0 ?
                <Box className=' is-marginless is-radiusless is-shadowless  myFlexContainer fadeIn '>
                    {/* <Button className='is-black  doneButton is-radiusless '> Done. Start Measure. </Button> */}
                    <Button className=' doneButton is-radiusless is-pulled-right ' onClick={() => { this.props.doneLetsOrder(this.state.measures) }}>&nbsp; Done. Lets Get It
                     <i className=" has-texh-weight-thin marginLeft10"> <FontAwesomeIcon className='padRight20' icon={faArrowRight} /></i>
                    </Button>
                </Box>


                : this.addMeasurementView()
            }
        </div>
        )

    }



    render() {
        return this.getMeasureSection()
    }
}

export default MeasureSection