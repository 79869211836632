import React from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentPage from './PaymentPage'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);



const PaymentWrapper = (props) => {

    console.log('')

  const  paymentWrapper = () => {
        return (
            <Elements stripe={stripePromise}>
                <PaymentPage {...props} order={props.order} orderSuccess={props.orderSuccess} >
                </PaymentPage>
            </Elements>

        )
    } 

    return (
        paymentWrapper()
    )
}


export default PaymentWrapper