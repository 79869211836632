import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import Logo from '../assets/tw-logo-140x30.svg'

import LoadingAux from './LoadingAux'
import ColumnAux from './ColumnAux'

import { getCurrentCustomer } from '../utilities/Login'
import { Customer, Order } from '../utilities/classes'
import OrderModal from './OrderModal'

import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal
} from 'react-bulma-components/dist'

import { imageNamed, PRODUCT_TYPE } from '../utilities/utils'
import OrderBox from './OrderBox'
import MyAddressForm from './MyAddressForm'
import MyProfileForm from './MyProfileForm'

import Service from './Service'
import PasswordForm from './MyPassword'
import MeasurementBox from './MeasurementBox'
import cogoToast from 'cogo-toast'



class MyAccount extends Component {

    MAIN_TABS = {
        myOrders: 'My Orders',
        myProfile: 'My Account',
        menuActive: false,
    }

    state = {
        isLoggingOut: false,
        mainTab: this.MAIN_TABS.myOrders,
        showEditProfile: false,
        showEditPassword: false,
        orders: [],
        showNoOrder: false,
        selectedOrder: null,
        fetching : false    
    }

    _mounted = false


    toggleFetching = () => {
        let ns = {...this.state}
        ns.fetching = !ns.fetching
        this.setState(ns)
    }

    getCustomerOrders = () => {

        // console.log('Getting Orders')

        this.toggleFetching()

        let cust = getCurrentCustomer()
        Order.getAllForCustomer(cust.objectId, (succ, orders, errMsg) => {
            this.toggleFetching()

            if (succ) {

                console.log('Orders =')
                console.log(orders)

                let ns = { ...this.state }
                ns.orders = orders
                if (orders.length === 0) {
                    ns.showNoOrder = true
                }
                this.setState(ns)
                // if (orders.length > 0) {
                //     ns.orders = orders
                //     existingOrders = 
                // }else {

                // }
            } else {
                cogoToast.warn("Could not fetch orders. Check internet connection.")
                console.log(errMsg)
            }
        })
    }

    componentDidMount = () => {
        this._mounted = true
        this.getCustomerOrders()
    }

    componentWillMount = () => {
        this._mounted = false
    }


    componentDidUpdate(prevProps) {
        // cogoToast.info("Checking recentPaymentUpdated")
        // console.log('\n\n Checking recentPaymentUpdated')
        // console.log('\n\n prevProps.recentPaymentUpdated = ',prevProps.recentPaymentUpdated)
        // console.log('\n\n this.props.recentPaymentUpdated = ',this.props.recentPaymentUpdated)

        if (prevProps.recentPaymentUpdated !== this.props.recentPaymentUpdated) {
            // cogoToast.info("recentPaymentUpdated is updated")

            if (this.props.recentPaymentUpdated === true) {
                // cogoToast.info("Refreshing Order List")
                this.getCustomerOrders()
            }
          }
        

    }


    changeMainTabHandler = (tabName) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.mainTab = tabName
            this.setState(ns)
        }
    }

    changeShowProfileForm = () => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showEditProfile = !ns.showEditProfile
            this.setState(ns)
        }
    }

    changeSelectedOrder = () => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.selectedOrder = null
            this.setState(ns)
        }
    }

    changeShowPasswordForm = () => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showEditPassword = !ns.showEditPassword
            this.setState(ns)
        }
    }




    orderUpdated = (order) => {

        // console.log('Need to update Order')

        if (this._mounted) {
            let ns = { ...this.state }
            let ords = [...ns.orders]
            let objIndex = ords.findIndex(obj =>
                obj.objectId === order.objectId
            )
            ords[objIndex] = order
            ns.orders = ords
            this.setState(ns)
        }
    }

    orderModal = () => {
        return <OrderModal order={this.state.selectedOrder} closed={this.changeSelectedOrder} orderUpdated={this.orderUpdated} />
    }

    profileFormModal = () => {

        let cust = getCurrentCustomer()



        return (
            <Modal show={this.state.showEditProfile} closeOnBlur={true} showClose={true} onClose={() => { this.changeShowProfileForm() }} >
                <Modal.Content className='is-padingless'>
                    <Box className='is-radiusless is-padingless fadeIn' >


                        <MyProfileForm customer={cust} />


                    </Box>
                </Modal.Content>
            </Modal>
        )
    }

    passwordFormModal = () => {
        return (
            <Modal show={this.state.showEditPassword} closeOnBlur={true} showClose={true} onClose={() => { this.changeShowPasswordForm() }} >
                <Modal.Content className='is-padingless'>
                    <Box className='is-radiusless is-padingless fadeIn' >


                        <PasswordForm />


                    </Box>
                </Modal.Content>
            </Modal>
        )
    }

    mainTabs = () => {
        return (<div className=''>

            <Heading size={4} className='is-size-4-mobile  has-text-centered  noLastMargin fadeIn ' >
                My Account
            </Heading>

            <Columns className='is-marginless is-mobile is-paddingless has-text-centered pad0'>
                <Columns.Column className='is-marginless is-paddingless  pad0'>
                    {/* {this.state.isPickingDone ? <p className="type60 has-text-weight-light"> <FontAwesomeIcon className='' icon={faCheck} /></p> : <p className='marginTop19'></p>} */}
                </Columns.Column>
                <Columns.Column className='is-marginless is-paddingless  pad0'>
                    {/* {this.state.isMeasureDone ? <p className="type60 has-text-weight-light"> <FontAwesomeIcon className='' icon={faCheck} /></p> : <p className='marginTop19'></p>} */}
                </Columns.Column>
                <Columns.Column className='is-marginless is-paddingless  pad0'>
                    {/* {this.state.isOrderDone ? <p className="type60 has-text-weight-light"> <FontAwesomeIcon className='' icon={faCheck} /></p> : <p className='marginTop19'></p>} */}
                </Columns.Column>
            </Columns>


            <Tabs fullwidth align='centered' className='is-mobile is-small marginTop20  boldTabs has-text-black fadeIn '>
                <Tabs.Tab active={this.state.mainTab === this.MAIN_TABS.myOrders} onClick={() => this.changeMainTabHandler(this.MAIN_TABS.myOrders)} >
                    MY ORDERS
                </Tabs.Tab>
                <Tabs.Tab active={this.state.mainTab === this.MAIN_TABS.myProfile} onClick={() => this.changeMainTabHandler(this.MAIN_TABS.myProfile)}  >
                    MY PROFILE
                </Tabs.Tab>
                {(getCurrentCustomer() !== null && this.props.isOpenedInIFrame) ?
                    <Tabs.Tab active={false} onClick={() => this.props.logoutPressed()}  >
                        LOGOUT
                    </Tabs.Tab>
                    : null
                }
            </Tabs>

        </div>)
    }

    toggleMenu = () => {
        const newState = { ...this.state }
        newState.menuActive = !newState.menuActive
        this.setState(newState)
    }

    header = (childElement) => {
        return (
            <div>
                { this.state.selectedOrder ? this.orderModal() : null}
                { this.state.showEditProfile ? this.profileFormModal() : null}
                { this.state.showEditPassword ? this.passwordFormModal() : null}

                <Columns className='is-centered  ' >
                    <Columns.Column size={8} className=' is-marginless   bottomPadding0'>
                        {this.mainTabs()}
                    </Columns.Column>
                </Columns>

                <Container className=''>
                    <Section>
                        {childElement}
                    </Section>
                </Container>
            </div>
        )
    }


    viewPressed = (order) => {
        // alert(`VIEW PRESSED FOR ORDER = ${order.orderNo} ` )
        if (this._mounted) {
            let ns = { ...this.state }
            ns.selectedOrder = order
            this.setState(ns)
        }
    }


    refetchOrders = () => {
        this.getCustomerOrders()
    }

    orders = () => {
        return (
            <LoadingAux isLoading={this.state.fetching} loaderHeight={400} size={'medium'} isMain={false} >
                <ColumnAux className=' fadeIn' isMiddle={true} size={6}>
                <div className=" marginBottom20 "> <Button className='is-small has-text-grey' onClick={this.refetchOrders }> Refresh Orders </Button>  </div> 
                    {this.state.showNoOrder ? <Heading size={6} className='has-text-centered has-text-grey-light'> No Orders </Heading> : null}

                    {/* <h4 className="has-text-centered"> Awaiting Order Confirmation.. </h4> */}

                    {
                        this.state.orders.map((ord) => {
                            return <OrderBox key={ord.objectId} order={ord} viewPressed={this.viewPressed} />
                        })
                    }
                </ColumnAux>
            </LoadingAux>


        )
    }


    measureEditPressed = () => {
        // CHANGE TO CONCIERGE
    }

    measures = (cust) => {

        if (cust.conciergeMeasurements === null) {
            return null
        } else {

            let measuresCopy = { ...cust.conciergeMeasurements }
            let measureObj = {
                measures: measuresCopy,
                description: "For All Products",
                key: "NSKJBKJBDJDJDBD"
            }
            return (<div key={measureObj.key} className='marginTop30'> <MeasurementBox measure={measureObj} removePressed={this.measureRemovePressed} editPressed={this.measureEditPressed} showMeasurePage={this.props.showMeasures} isAtProfile={true} /> </div>)


        }


    }


    profileDetails = (cust) => {
        return (
            <Container>
                <Columns>

                    <Columns.Column>
                        {/* <Heading size={6}>PROFILE</Heading> */}
                        <Table className=' is-shadowless is-plain is-borderless fadeIn'>
                            <tbody>
                                <tr>
                                    <td>
                                        <p className='is-size-7 has-text-grey'>NAME</p>
                                        <p>{cust.name} </p>
                                        <br />
                                        <p className='is-size-7 has-text-grey'>EMAIL</p>
                                        <p>{cust.email1}</p>
                                        <br />
                                        <p className='is-size-7 has-text-grey'>PHONE</p>
                                        <p>{cust.phone1}</p>
                                        <br />
                                        {this.measures(cust)}
                                        <br />
                                        {/* <p className='type60 has-text-grey'>Customer Since</p> */}
                                        {/* <p className='type60 has-text-grey'>1 July 2020</p> */}
                                        <br />
                                        <p>  <Button className='is-small is-light' onClick={this.changeShowProfileForm} > Edit Profile </Button> </p>
                                        <br />
                                        <p>  <Button className='is-small is-light' onClick={this.changeShowPasswordForm} > Change Password </Button> </p>
                                    </td>

                                    <td className='has-text-right'>

                                    </td>
                                </tr>
                            </tbody>

                        </Table>
                    </Columns.Column>
                    <Columns.Column size={1}>
                    </Columns.Column>
                </Columns>

            </Container>
        )
    }



    successSavingAddress = (data) => {
        console.log('SUCCESS SAVING ADDRESS = ', data)
    }

    errorSavingAddress = (data) => {
        console.log('ERRROR SAVING ADDRESS = ', data)
    }


    myAddressForm = (cust) => {
        return <MyAddressForm customer={cust} title='My Delivery Address' success={this.successSavingAddress} error={this.errorSavingAddress} />

    }

    profileContent = () => {
        return (
            <ColumnAux isMiddle={true} size={9} >

                {/* <Heading size={3}> Checkout </Heading> */}
                {/* {this.state.showModalProgress ? <ModalProgress height={200} title='Payment Authentication'  centerText='Do Not close any window' showDoNotClose={false}  onClose={this.closeModal} /> : null} */}
                <Columns>
                    <Columns.Column size={7}>
                        {/* <br /> */}
                        {/* <Heading size={7}>DESCRIPTION</Heading>
                        <p className='is-size-7'>Petierunt uti sibi concilium totius Galliae in diem certam indicere. Unam incolunt Belgae, aliam Aquitani, tertiam. Morbi fringilla convallis sapien, id pulvinar odio volutpat. Quid securi etiam tamquam eu fugiat nulla pariatur. Nihil hic munitissimus habendi senatus locus, nihil horum? At nos hinc posthac, sitientis piros Afros.</p> */}

                        {this.profileDetails(getCurrentCustomer())}


                    </Columns.Column>
                    <Columns.Column size={5}>
                        {/* <h4 className='subtitle strong'> Make Payment Via </h4> */}

                        {this.myAddressForm(getCurrentCustomer())}



                    </Columns.Column>
                </Columns>

            </ColumnAux>
        )
    }



    orderPage = () => {
        return this.header(this.orders())
    }


    profilePage = () => {
        return this.header(this.profileContent())
    }

    getCurrentPage = () => {
        // let currentPage = this.landingPage
        // if (this.state.isStarted) {
        //     if (this.state.mainTab === this.MAIN_TABS.pickStyles) {
        //         currentPage = this.getPickStylePage()
        //     }

        if (this.state.mainTab === this.MAIN_TABS.myOrders) {
            return this.orderPage()
        }
        if (this.state.mainTab === this.MAIN_TABS.myProfile) {
            return this.profilePage()
        }
        // }

    }

    render() {
        return (
            this.getCurrentPage()
        )
    }
}

export default MyAccount