import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import Home from './containers/Home'
import './App.css';

function App() {


  const devBaseName = '/'
  const productionBaseName = `${process.env.PUBLIC_URL}/`

  function giveBaseName() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // dev code
      console.log('Giving Base Name - ', devBaseName)
      return  devBaseName
    } else {
      // production code
      console.log('Giving Base Name - ', productionBaseName)
      return productionBaseName
    }
  }

  return (
    <BrowserRouter basename={giveBaseName()}>
        <Home />
    </BrowserRouter>
  );
}

export default App;
